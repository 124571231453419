/**
*
* PopupPriceDisclaimer
*
*/

import React, { useCallback, useState, useMemo } from 'react';
import PopupLayout from 'components/Popup/PopupLayout'
import PriceDisclaimer from '../PriceDisclaimer';
import { _localStorage } from 'helpers';

const PopupPriceDisclaimer = () => {
  const initialOpen = useMemo(() => {
    return !_localStorage.getItem('disclaimer_closed')
  })
  const [open, setOpen] = useState(initialOpen)

  const handleClose = useCallback(() => {
    setOpen(false)
    _localStorage.setItem('disclaimer_closed', "1")
  })
  const containerClick = useCallback((e) => e.stopPropagation())

  if (!open) return null

  return (
    <PopupLayout close={handleClose}>
      <div style={{maxWidth: 768}}>
        <PriceDisclaimer  showAsterisk={false} containerClick={containerClick} />
      </div>
    </PopupLayout>
  )
}

export default PopupPriceDisclaimer

// class Messages extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
//   constructor(props){
//     super(props);

//     this.containerClick = this.containerClick.bind(this)
//   }

//   containerClick(e) {
//     e.stopPropagation()
//   }

//   render() {
//     const { messages, currentUser, messagesShow} = this.props
//     if (!messagesShow || !currentUser) return null;
//     const records = messages ? Object.values(messages).filter(r => r.userId === currentUser.id && !r.readAt) : [];
//     if(records.length === 0) return null
//     return (
//       <div>
//         <PopupLayout close={() => this.props.setMessagesShow(false)} containerClick={this.containerClick} cls='notif'>
//           <h2>Уведомления</h2>
//           { records.map((notification) => {
//             return (
//               <div className='notification' key={notification.id}>
//                 <div className='notification__title'>
//                   {notification.title}
//                 </div>
//                 <div className='notification__text' dangerouslySetInnerHTML={{__html: notification.text}} />
//                 <div className="notification__close" onClick={() => this.props.readMessage(notification.id)}>
//                   Скрыть уведомление
//                 </div>
//               </div>
//             )
//             })
//           }
//         </PopupLayout>
//       </div>
//     );
//   }
// }



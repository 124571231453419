/* istanbul ignore file */
import { apicall } from 'utils/api';

export function createReturn (data) {
  return apicall('oreturns', { method: 'POST', body: JSON.stringify({ oreturn: data }) });
} 


export function updateReturn (data) {
  const { id, ...rest } = data;
  return apicall(`oreturns/${id}`, { method: 'PATCH', body: JSON.stringify({ oreturn: rest }) });
}

import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const Table = styled.table`
  width: 100%;
`

export const Thead = styled.thead`

`

export const Tbody = styled.tbody`

`

export const Tr = styled.tr`

`

export const Td = styled.td`
  padding: 10px;
`


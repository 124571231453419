/**
*
* FormNotification
*
*/

import React from 'react';
import { compose } from 'redux';
import styled from 'styled-components'
import vars from 'config/styles'

import {
  Field, reduxForm,
} from 'redux-form/immutable';
import { inputField } from 'components/Form/components';
import Button from 'components/Button';
import RFReactSelect from 'components/Form/components/RFReactSelect';
import { NOTIFICATION_STATUSES_TRANSLATION } from 'settings';

import { H4, Text2, Gapper, Flex } from 'components/Ui'

const Item = styled.div`
  font-size: 16px;
  padding: 4px;
  border-bottom: 1px dotted ${vars.grey};
  &:last-child {
    border-bottom: 0;
  }
`


class FormNotification extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const statusOptions = Object.keys(NOTIFICATION_STATUSES_TRANSLATION).map((x) => ({ value: x, label: NOTIFICATION_STATUSES_TRANSLATION[x] }));

    const {
      handleSubmit, submitting, podbor
    } = this.props;

    return (
      <Gapper gap={60}>
        <Gapper gap={10}>
          <H4>Информация о клиенте</H4>
          <div>
            <Flex spaced={10}>
              <Text2 bold>Имя:</Text2> 
              <Text2>{[podbor.surname, podbor.name].join(' ')}</Text2>
            </Flex>
          </div>
          <div>
            <Flex spaced={10}>
              <Text2 bold>Телефон:</Text2> 
              <Text2>{podbor.phone}</Text2>
            </Flex>
          </div>
          <div>
            <Flex spaced={10}>
              <Text2 bold>Email:</Text2> 
              <Text2>{podbor.email}</Text2>
            </Flex>     
          </div>
          <div>
            <Flex spaced={10}>
              <Text2 bold>VIN:</Text2> 
              <Text2>{podbor.vin}</Text2>
            </Flex>     
          </div>     
          <div>
            <Flex spaced={10}>
              <Text2 bold>Комментарий:</Text2> 
              <Text2>{podbor.comment}</Text2>
            </Flex>     
          </div>                  
          <Gapper>
            <H4>Список позиций</H4>
            {podbor.parts && podbor.parts.map(item => (
              <Item>
                {item}
              </Item>
            ))}
          </Gapper>
        </Gapper>
        <form onSubmit={handleSubmit}>
          <Field name="status" component={RFReactSelect} multi={false} clearable={false} options={statusOptions} label="Статус" />

          <Button loading={submitting} type="submit">
            Продолжить
          </Button>
        </form>
      </Gapper>
    );
  }
}

FormNotification.propTypes = {

};


const withForm = reduxForm({ form: 'notification-form' });

export default compose(
  withForm,
)(FormNotification);

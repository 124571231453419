/* eslint react/no-array-index-key: 0 */

/**
*
* Pagination
*
*/

import React from 'react';

import 'css/components/pagination.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

class Pagination extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const range = 3;

    const { pagination, pageClick } = this.props;
    const currentPage = pagination.page;
    const leftBound = currentPage - range > 1 ? currentPage - range : 1;
    const rightBound = currentPage + range < pagination.totalPages ? currentPage + range : pagination.totalPages;
    const previousPage = currentPage > 1 ? currentPage - 1 : null;
    const nextPage = currentPage < pagination.totalPages ? currentPage + 1 : null;
    return (
      <React.Fragment>
        {pagination &&
          (
            <div className="pagination">
              { previousPage &&
              (
                <span className="pagination__prev" onClick={(e) => pageClick(previousPage)}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </span>
              )
              }

              { leftBound > 1 && (
                <span className="pagination__page" onClick={(e) => pageClick(1)}>
                  {1}
                </span>
              )}

              { leftBound > 2 && (
                <span className="pagination__gap">
                  ...
                </span>
              )}

              { pagination.totalPages > 1 && [...Array(rightBound - leftBound + 1)].map((e, i) => {
                if (i + leftBound === currentPage) {
                  return (
                    <span className="pagination__curpage" key={leftBound + i}>
                      {' '}
                      {leftBound + i}
                    </span> // eslint-disable-line no-array-index-key
                  );
                }
                return (
                  // eslint-disable-next-line no-array-index-key
                  <span className="pagination__page" onClick={(e) => pageClick(leftBound + i)} key={leftBound + i}>
                    {' '}
                    {leftBound + i}
                  </span>
                );
              })}

              { rightBound < pagination.totalPages - 1 && (
                <span className="pagination__gap">
                  ...
                </span>
              )}

              { rightBound < pagination.totalPages && (
                <span className="pagination__page" onClick={(e) => pageClick(pagination.totalPages)}>
                  {pagination.totalPages}
                </span>
              )}

              { nextPage && (
                <span className="pagination__next" onClick={(e) => pageClick(nextPage)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              )}
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

Pagination.propTypes = {

};

export default Pagination;

/**
 *
 * routes
 *
 */

import React from 'react';
import { compose } from 'redux';

import RouteWithProps from 'components/RouteWithProps';

import Layout from 'containers/Layout';

import HomePage from 'containers/HomePage/Loadable';
import AuthError from 'containers/AuthError/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import Cart from 'containers/Cart/Loadable';
import CartThumb from 'containers/CartThumb';
import Checkout from 'containers/Checkout/index';
import CheckoutSuccess from 'containers/Checkout/Success';
import AuthUser from 'containers/AuthUser/Loadable';
import UserOrders from 'containers/UserOrders/Loadable';
import UserOrder from 'containers/UserOrder/Loadable';
import UserRequests from 'containers/UserRequests/Loadable';
import Goods from 'containers/Goods/';
import GoodPage from 'containers/GoodPage/';
import LoginPage from 'containers/LoginPage/Loadable';
import Profile from 'containers/Profile/Loadable';
import Favs from 'containers/Favs/Loadable';
import Compare from 'containers/Compare/Loadable';
import PaymentFailed from 'containers/PaymentFailed';
import Contacts from 'containers/Contacts/';
import ConfirmationPage from 'containers/ConfirmationPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/';
import SwitchProxy from 'components/SwitchProxy'
import InstructionGuest from 'containers/Instruction/Guest';
import InstructionUser from 'containers/Instruction/User';
import InstructionDealer from 'containers/Instruction/Dealer';
import InstructionDealerOrders from 'containers/Instruction/DealerOrders';
import InstructionImporter from 'containers/Instruction/Importer';
import SsoReturnPath from 'containers/Sso/Auth'
import AdminLogin from 'containers/AdminLogin';
import IdpLanding from 'containers/IdpLanding'
import PageConf from 'containers/Pages/Conf'
import PageAgreement from 'containers/Pages/Agreement'
import PagePaymentMethods from 'containers/Pages/PaymentMethods'
import PageReturn from 'containers/Pages/Return'
import PageDelivery from 'containers/Pages/Delivery'
import AutoAuth from 'containers/AutoAuth'

import { hiddenPath } from 'containers/Goods/settings';

export class Routes extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <React.Fragment>
        <SwitchProxy {...this.props}>
          <RouteWithProps exact path="/" component={HomePage}  />
          <RouteWithProps exact path="/auth_error" component={AuthError}  />
          <RouteWithProps exact path="/auth" component={SsoReturnPath}  />
          <RouteWithProps exact path="/protected" component={AdminLogin} />
          <RouteWithProps exact path="/cart" component={CartThumb}  />
          {/* <RouteWithProps exact path="/cart" component={Cart}  />
          <RouteWithProps exact path="/cart/auth" component={AuthUser}  />
          <RouteWithProps exact path="/cart/checkout" component={Checkout}  />
          <RouteWithProps exact path="/cart/success/:orderIds" component={CheckoutSuccess} /> */}
          <RouteWithProps exact path="/orders" component={UserOrders} />
          <RouteWithProps exact path="/orders/:id" component={UserOrder} />
          <RouteWithProps exact path="/requests" component={UserRequests} />
          <RouteWithProps exact path="/accessories" component={Goods}  />
          <RouteWithProps exact path={`/accessories/:hidden(${hiddenPath})`} component={Goods} />
          <RouteWithProps exact path="/accessories/:modelSlug" component={Goods}  />
          <RouteWithProps exact path="/accessories/category/:categorySlug" component={Goods}  />
          <RouteWithProps exact path="/accessories/show/:productId" component={GoodPage}  />
          <RouteWithProps exact path="/accessories/:modelSlug/:categorySlug" component={Goods}  />
          <RouteWithProps exact path="/profile" component={Profile}  />
          <RouteWithProps exact path="/favs" component={Favs}  />
          <RouteWithProps exact path="/compare" component={Compare}  />
          <RouteWithProps exact path="/contacts" component={Contacts}  />
          <RouteWithProps exact path="/help" component={InstructionGuest} />
          <RouteWithProps exact path="/help/user" component={InstructionUser} />
          <RouteWithProps exact path="/help/dealer" component={InstructionDealer} />
          <RouteWithProps exact path="/help/dealer_orders" component={InstructionDealerOrders} />
          <RouteWithProps exact path="/help/importer" component={InstructionImporter} />
          <RouteWithProps exact path="/skodaid" component={IdpLanding} />
          <RouteWithProps exact path="/pages/conf" component={PageConf} />
          <RouteWithProps exact path="/pages/agreement" component={PageAgreement} />
          <RouteWithProps exact path="/pages/payment" component={PagePaymentMethods} />
          <RouteWithProps exact path="/pages/return" component={PageReturn} />
          <RouteWithProps exact path="/pages/delivery" component={PageDelivery} />
          <RouteWithProps exact path="/payment_failed" component={PaymentFailed} />
          <RouteWithProps exact path="/auto_auth" component={AutoAuth} />
          <RouteWithProps
            component={() => (
              <div className="container">
                {' '}
                <NotFoundPage />
              </div>
            )}
            
          />
        </SwitchProxy>
      </React.Fragment>
    );
  }
}

Routes.propTypes = {
};

const withLayout = Layout();

export default compose(
  withLayout,
)(Routes);

/**
*
* EditNotificaion
*
*/

import React from 'react';

import FactoryEditEntity from 'components/FactoryEditEntity';

import { editNotification } from 'models/notifications/actions';
import { setPopup } from 'containers/App/actions';

import FormNotification from 'components/FormNotification';

const Component = FactoryEditEntity({
  editAction: editNotification,
  EntityForm: FormNotification,
  entity: 'notifications',
  onSuccess: (dispatch) => { dispatch(setPopup(null)); },
});

const EditNotificaion = (props) => (
  <Component {...props} />
);

export default EditNotificaion;

import React from 'react'
import heroAcc from 'images/heroacc.jpg';
import Container from 'components/Container';


const Hero = () => {
  return (
    <div className="hero hero_acc" style={{ backgroundImage: `url(${heroAcc})` }}>
      <Container>
        <span>
          Аксессуары
          <br />
          ŠKODA
        </span>
      </Container>
    </div>
  )
}

export default Hero;
/**
*
* DealerOrder
*
*/

import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

import { formatPrice } from 'helpers';

import Box, { Inner } from 'components/Box'
import Card from 'components/Card'
import CardContainer from 'components/CardContainer'
import SummaryRow from 'components/OrderItems/SummaryRow'
import { H7, H4, A, Line, Gapper } from 'components/Ui'
import SubRow from 'components/OrderItems/SubRow'

export class TotalSummary extends React.Component { // eslint-disable-line react/prefer-stateless-function

  render () {
    const {
      price, modprice, itemsPrice, deliveryPrice, checkout = false
    } = this.props;
    // const name = dealer && dealer.name;

    return (
      <CardContainer> 
        <Card d={8} t={12}>
          <Box>
            <Inner>        
              <Gapper gap={10}>
              <H7>Итого по заказам</H7>
                <div>
                  <SubRow text='Товаров на сумму' price={itemsPrice} checkout={checkout}/>
                  {!!deliveryPrice &&
                    <SubRow text='Доставка' price={deliveryPrice} checkout={checkout}/>
                  }
                  {!!modprice &&
                    <SubRow text='Скидка' price={modprice} checkout={checkout}/>
                  }
                </div>
                <SummaryRow price={price} text='Общая сумма заказов:' checkout={checkout}/>
              </Gapper>
            
            </Inner>        
          </Box>
        </Card>
        <Card d={4} t={12}>
        </Card>        
      </CardContainer>
    )
  }
}

TotalSummary.propTypes = {

};

export default TotalSummary;

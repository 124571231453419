/**
*
* Offer
*
*/

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'css/components/pupa.sass';
import OfferDealer from './OfferDealer';
import Button from 'components/Button';

class Offer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    this.state = { expanded: false };
  }

  render () {
    const {
      dealer, number, setPopup
    } = this.props;
    return (
      <React.Fragment>
        <tr className={this.state.expanded ? 'table__expanded' : null}>
          <td className="hoverable" onClick={() => this.setState((state) => ({ expanded: !state.expanded }))}>
            {dealer && dealer.name}
            &nbsp;
            <FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />
          </td>
          <td colSpan='4' className="table__actions"> 
            <Button small onClick={() => setPopup({productId: number, dealerId: dealer.id})}>
              Узнать о поступлении
            </Button>
          </td>
        </tr>
        {this.state.expanded &&
          (
            <OfferDealer dealer={dealer}/>
          )
        }
      </React.Fragment>
    );
  }
}

export default Offer;

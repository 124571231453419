
/**
*
* Facet
*
*/

import React from 'react';

import { shadeBlendConvert } from 'helpers';
import PropTypes from 'prop-types';
class Facet extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { color } = this.props;
    const abs = this.props.abs || false;
    const size = [1920, 320];

    const start = [0, 0];

    const y2 = start[1];
    const y1 = size[1];

    // const points = [237, 95];
    const points = [200 + Math.random() * Math.floor(1500), 95 + Math.random() * Math.floor(140)];
    const { style, ...rest } = this.props;
    const styles = { ...style, position: abs ? 'absolute' : 'static' };

    return (
      <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${size[0]} ${size[1]}`} height="17vw" preserveAspectRatio="xMinYMin" {...rest}>
        <defs>
          <linearGradient id="gr" x1="237" y1="302" x2={size[0]} y2="302" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor={shadeBlendConvert(-0.03, color)} />
            <stop offset="0.51" stopColor={shadeBlendConvert(-0.01, color)} />
            <stop offset="1" stopColor={color} />
          </linearGradient>
          <linearGradient id="gr2" y1="235" x2="237" y2="235" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor={shadeBlendConvert(0, color)} />
            <stop offset="0.39" stopColor={shadeBlendConvert(-0.01, color)} />
            <stop offset="0.84" stopColor={shadeBlendConvert(-0.035, color)} />
            <stop offset="1" stopColor={shadeBlendConvert(-0.05, color)} />
          </linearGradient>
        </defs>
        <polygon fill={color} points={`0 ${y1} ${points[0]} ${points[1]} ${size[0]} ${y1} ${size[0]} ${y2} 0 ${y2} 0 ${y1}`} />
        <polygon fill="url(#gr2)" points={`${points[0]} ${points[1]} 0 ${y2} 0 ${y1} ${points[0]} ${points[1]}`} />
        <polygon fill="url(#gr)" points={`${points[0]} ${points[1]} ${size[0]} ${y2} ${size[0]} ${y1} ${points[0]} ${points[1]}`} />
      </svg>
    );
  }
}

Facet.propTypes = {
  abs: PropTypes.string,
};

export default Facet;



const vars = {
  black: '#222',
  green: '#4ba82e',
  greenH: '#34861C',
  darkGrey: '#494949',
  bg: '#FAFAFA',
  grey: '#CACACA',
  grey2: '#6b6b6b',
  red: '#eb5757',
  bp: {
    mobile: 768,
    tablet: 1024,
    desktop: 1440,
  },
  gap: 24,
  lcolor1: '#dadee1',
  lcolor2: '#eceff0',
  red: '#da0000',
}


export default vars
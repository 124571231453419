/*
 *
 * Bonus reducer
 *
 */

import { fromJS } from 'immutable';
import {
  actionTypes
} from './actions';

const initialState = fromJS({
  points: 0,
  certs: []
});

function certsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_BONUS_INFO:
      const { payload } = action;
      const points = payload.points || 0
      const certs = payload.certs || []
      return state
        .set('points', points)
        .set('certs', certs)

    default:
      return state;
  }
}

export default certsReducer;


import React from 'react';

import { formatPrice } from 'helpers';

class Template extends React.PureComponent {
  render () {
    const {
      row, dealers,
    } = this.props;
    const dealer = (dealers && row.dealerId && dealers[row.dealerId]) ? dealers[row.dealerId] : {};
    return (
      <React.Fragment>
        <tr>
          <td>
            <b>
              {dealer.name}
            </b>
            <br />
            {row.dealerNumber}
          </td>
          <td>
            {formatPrice(row.price)}
          </td>
          <td>
            {row.quantity}
            {' '}

шт.
          </td>
          <td>
            {row.ordersCount}
            {' '}

шт.
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default Template;

/* istanbul ignore file */
/*
 *
 *
 *
 */

import {
  GET_GOOD_BY_ID,
  GET_GOOD_OFFERS,
  EDIT_DEALER_GOOD,
  EDIT_GOOD,
  GET_GOODS_PRODUCTS,
  DELETE_DEALER_GOOD,
  GET_GOOD_LOGS,
  GET_PRICE_BY_ID,
  DELETE_GOOD,
  ENSURE_FILTERS_EXIST,
} from './constants';

export function ensureFiltersExist (payload) {
  return {
    type: ENSURE_FILTERS_EXIST,
    payload,
  }
}

export function getGoodLogs (payload) {
  return {
    type: GET_GOOD_LOGS,
    payload,
  };
}

export function deleteDealerGood (payload) {
  return {
    type: DELETE_DEALER_GOOD,
    payload,
  };
}

export function getGoodById (payload) {
  return {
    type: GET_GOOD_BY_ID,
    payload,
  };
}

export function getPriceById (payload) { 
  return {
    type: GET_PRICE_BY_ID,
    payload,
  };
}


export function getGoodOffers (payload) {
  return {
    type: GET_GOOD_OFFERS,
    payload,
  };
}

export function editDealerGood (payload) {
  return {
    type: EDIT_DEALER_GOOD,
    payload,
  };
}

export function editGood (payload) {
  return {
    type: EDIT_GOOD,
    payload,
  };
}

export function deleteGood (payload) {
  return {
    type: DELETE_GOOD,
    payload,
  };
}


export function getGoodsProducts () {
  return {
    type: GET_GOODS_PRODUCTS,
  };
}

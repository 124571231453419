import React from 'react';
import styled from 'styled-components';
import { Text2 } from 'components/Ui'

import vars from 'config/styles'

const Wrapper = styled.div`
  padding: 0 0 0 30px;
  margin: 0 40px;
  box-shadow: inset 3px 0px 0px red;
`

function ContactNote() {
  return (
    <Wrapper>
      <Text2>
        Проверьте заказы на ошибки.
      </Text2>    
    </Wrapper>
  )
}

export default ContactNote;
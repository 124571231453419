/**
*
* PriceDisclaimer
*
*/

import React from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as metricsActions from 'containers/App/metricsActions'

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
`

function PriceDisclaimer({ msrp, forceAccessory = false, sendRaw, showAsterisk = true }) {
  const date = '10.03.2022';

  // let kind = msrp && msrp.actual && msrp.kind;
  let kind = msrp && msrp.kind;

  // if (msrp && !msrp.actual) {
  //   kind = 1;
  // }
  if (!msrp) {
    kind = 1;
  }
  if (forceAccessory) {
    kind = 0
  }
  const mcp = kind === 1;
  return (
    <Wrapper>
      {showAsterisk &&
        <div style={{ marginBottom: 20, fontWeight: 'normal' }}>
          * Актуальные цены и наличие запасных частей уточняйте в дилерском центре.
        </div>
      }
      <div>
        {mcp ? 'Указана максимальная цена перепродажи с НДС ' : 'Указаны рекомендованные розничные цены с НДС '}
        по состоянию на&nbsp;
        { date }
        . Обращаем Ваше внимание на то, что вся представленная на сайте информация, касающаяся
        {mcp ? ' ' : ' рекомендованных розничных '}
        цен, спецификаций и технических характеристик носит информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 (2) Гражданского кодекса Российской Федерации.Для получения подробной информации о возможности приобретения детали через магазин, пожалуйста, обращайтесь непосредственно к&nbsp;
        <a onClick={() => sendRaw('PartsPage', 'click_all_dealers')} href='http://www.skoda-avto.ru/dealer' className='link' target="_blank">официальному дилеру марки Шкода</a>
        .
      </div>
    </Wrapper>
  );
}

PriceDisclaimer.propTypes = {

};




function mapDispatchToProps(dispatch) {
  return {
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const withConnect = connect(null, mapDispatchToProps);


export default compose(
  withConnect,
)(PriceDisclaimer);



import React from 'react'
import { Switch } from 'react-router-dom';


class PropsProxy extends React.PureComponent {
  
  render() {
    const { children, ...rest } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { ...rest })
    );

    return <Switch>{childrenWithProps}</Switch>
  }
};

export default PropsProxy
/**
*
* NotifyStock
*
*/

import React from 'react';

import { createStructuredSelector } from 'reselect';
import Button from 'components/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { makeSelectCurrentUser } from 'containers/App/selectors'
import { makeSelectEntities } from 'models/selectors';


import { notifyStock } from 'containers/App/actions';

import {
  Field, reduxForm,
} from 'redux-form/immutable';
import { inputField, maskField } from 'components/Form/components';
import { notifyStockClickMetrics } from 'containers/App/metricsActions'




class FormNotify extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function


  render () {
    const {
      submitting, handleSubmit, full
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        { full && 
          <React.Fragment>
            <Field required type="text" name="fio" component={inputField} label="ФИО" />
            <Field required type="text" name="email" component={inputField} label="E-mail" />
            <Field required type="text" name="phone" component={maskField} mask="+7 (999) 999-99-99" label="Телефон" />
          </React.Fragment>
        }
        <Button loading={submitting} type="submit">
          Отправить
        </Button>
      </form>
    );
  }
}


const withForm = reduxForm({ form: 'form-notify' });
const DecoratedFormNotify = compose(
  withForm
)(FormNotify)


class NotifyStock extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { sent: false }
  }

  componentDidMount() {
    this.props.notifyStockClickMetrics(this.props.productId)
  }  

  handleSubmit(vals) {
    const { notifyStock } = this.props
    return new Promise((resolve, reject) => {
      notifyStock({data: vals.toJS(), resolve, reject})
    }).then((ok) => this.setState({ sent: true }))
  }

  render () {
    const { currentUser, dealerId, productId, notifyStockClickMetrics } = this.props
    return (
      <div style={{maxWidth: 650}}>
        <h1>Узнать о поступлении аксессуара в продажу</h1>
        {!this.state.sent &&
          <React.Fragment>
            <div style={{marginBottom: 20}}>Оставьте заявку и дилер свяжется с Вами как только аксессуар появится в продаже.</div>
            <DecoratedFormNotify  initialValues={{dealerId, productId}} full={!currentUser} onSubmit={this.handleSubmit} />
          </React.Fragment>
        }
        {this.state.sent &&
          <div>
            Заявка отправлена! Дилер свяжется с Вами как только аксессуар появится в продаже.
          </div>
        }
      </div>
      )
  }
}
  

NotifyStock.propTypes = {

};


const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

function mapDispatchToProps (dispatch) {
  return {
    notifyStock: (payload) => dispatch(notifyStock(payload)),
    notifyStockClickMetrics: (payload) => dispatch(notifyStockClickMetrics(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(NotifyStock);

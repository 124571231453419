export const actionTypes = {
  GET_DELIVERY_STATUS: '@delivery/GET_DELIVERY_STATUS',
  SET_PROCESSING: '@delivery/SET_PROCESSING',
  SET_APPLYING: '@delivery/SET_APPLYING',
  GET_ADDRESSES: '@delivery/GET_ADDRESSES',
  CREATE_ADDRESS: '@delivery/CREATE_ADDRESS',
  EDIT_ADDRESS: '@delivery/EDIT_ADDRESS',
  DELETE_ADDRESS: '@delivery/DELETE_ADDRESS',
  GET_LIST: '@delivery/GET_LIST',
  SET_CALC: '@delivery/SET_CALC',
  SET_CALC_ERROR: '@delivery/SET_CALC_ERROR',
  APPLY_DELIVERY: '@delivery/APPLY_DELIVERY',
  ACCEPT_DELIVERY: '@delivery/ACCEPT_DELIVERY',
  CONFIRM_DELIVERY: '@delivery/CONFIRM_DELIVERY',
  REFRESH_DELIVERY: '@delivery/REFRESH_DELIVERY',
  GET_ORDER_DELIVERY_STATUS: '@delivery/GET_ORDER_DELIVERY_STATUS',
  EDIT_DELIVERY: '@delivery/EDIT_DELIVERY',
  CALC: '@delivery/CALC',
}

export function applyDelivery(payload) {
  return {
    type: actionTypes.APPLY_DELIVERY,
    payload
  }
}

export function editDelivery(payload) {
  return {
    type: actionTypes.EDIT_DELIVERY,
    payload
  }
}

export function acceptDelivery(payload) {
  return {
    type: actionTypes.ACCEPT_DELIVERY,
    payload
  }
}

export function confirmDelivery(payload) {
  return {
    type: actionTypes.CONFIRM_DELIVERY,
    payload
  }
}

export function getOrderDeliveryStatus(payload) {
  return {
    type: actionTypes.GET_ORDER_DELIVERY_STATUS,
    payload
  }
}

export function setApplying(applying) {
  return {
    type: actionTypes.SET_APPLYING,
    applying
  }
}

export function refreshDelivery(payload) {
  return {
    type: actionTypes.REFRESH_DELIVERY,
    payload
  }
}

export function calc(payload) {
  return {
    type: actionTypes.CALC,
    payload,
  }
}

export function setCalc(payload) {
  return {
    type: actionTypes.SET_CALC,
    payload,
  }
}

export function setCalcError(error) {
  return {
    type: actionTypes.SET_CALC_ERROR,
    error,
  }
}

export function getDeliveryStatus() {
  return {
    type: actionTypes.GET_DELIVERY_STATUS,
  }
}

export function getList(payload) {
  return {
    type: actionTypes.GET_LIST,
    payload
  }
}


export function setProcessing(processing) {
  return {
    type: actionTypes.SET_PROCESSING,
    processing
  }  
}

export function getAddresses(payload) {
  return {
    type: actionTypes.GET_ADDRESSES,
    payload
  }  
}

export function createAddress(payload) {
  return {
    type: actionTypes.GET_ADDRESSES,
    payload
  }  
}

export function editAddress(payload) {
  return {
    type: actionTypes.EDIT_ADDRESS,
    payload
  }  
}

export function deleteAddress(payload) {
  return {
    type: actionTypes.DELETE_ADDRESS,
    payload
  }  
}
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import { H7, Gapper, Flex } from 'components/Ui'

import Pointer from 'components/Pointer'
import vars from 'config/styles'

const Wrapper = styled.div`
  margin-bottom: 10px;
`

const Items = styled.div`

`

const Item = styled.div`
  width: 100%;
  border-bottom: 1px solid ${vars.grey};
  &:hover {
    background: ${vars.grey};
  }
  ${({ active }) => active && css`
    color: #fff;
    background: ${vars.green} !important;
  `}
`

const Row = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  height: 50px;
  padding: 0 15px;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1px solid ${vars.grey};

`

const ColLogo = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 80px;
    max-height: 32px;    
  }
`

const ColMiddle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-rigth: 25px;
`

const ColLast = styled.div`
  width: 210px;
  display: flex;
  justify-content: space-between;
`

const ColDays = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColPrice = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const InputWrapper = styled.div`
  input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #9F9F9F;
    height: 34px;
    line-height: 34px;
    outline: none;
  }
  margin-bottom: 10px;
`

const ResultContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

const Details = styled.div`
  padding: 10px;
  background: #fff;
  color: #000;
  border: 1px solid #9F9F9F;
`

const DetailsTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
`

const DetailsLogo = styled.div`
  width: 80px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 32px;    
  }
`

const Desc = styled.div`
  font-size: 12px;
  line-height: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
  max-width: 800px;
`

const Worktime = styled.span`
  color: ${vars.green};
  cursor: pointer;
`

const MapContainer = styled.div`
  position: relative;
`

const Time = styled.div`
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const days = {
  1: 'пн',
  2: 'вт',
  3: 'ср',
  4: 'чт',
  5: 'пт',
  6: 'сб',
  7: 'вс',
}

const Moar = ({ row, companies }) => {
  const [showTime, setShowTime] = useState(false)
  return (
    <Details>
      <Flex>
        <DetailsTitle>
          {row.name}
        </DetailsTitle>
      </Flex>
      <MapContainer>
        <Pointer lat={row.latitude} lng={row.longitude} />
        { showTime &&
          <Time>
            <Close onClick={() => setShowTime(false)}>✕</Close>
            {row.schedule && Object.keys(row.schedule).map(d => (
              <Flex style={{padding: '0 3px'}}>
                <div style={{width: 30, fontWeight: 'bold'}}>{days[d]}</div>
                <div>{row.schedule[d]}</div>
              </Flex>
            ))}
          </Time>
        }
      </MapContainer>
      <Desc>{row.description}</Desc>
      <Worktime onClick={() => setShowTime(true)}>График работы</Worktime>
    </Details>
  )
}

const searchFilter = search => (p) => {
  try {
    if(!search) return true

    if(p.address.toLowerCase().includes(search.toLowerCase())) {
      return true
    }

    if(p.metro && p.metro.find(a => a.name.toLowerCase().includes(search.toLowerCase()))) {
      return true
    }

    return false
  } catch(e) {
    return false
  }
}

export const DeliveryCompanyField = (field) => {
  const { options = {}, onChangeFull = () => {}, dimensionsPresent } = field
  const { points = [], delivery = [] } = options
  const [search, setSearch] = useState('')
  const [companies, setCompanies] = useState({})
  const { value, onChange, ...rest } = field.input;
  useEffect(() => {
    setCompanies(delivery.reduce((acc, cur) => ({...acc, [cur.delivery_company_id]: cur }), {}))
  }, [delivery.length])
  return (
    <Wrapper> 
      <InputWrapper>
        <input placeholder="Начните вводить название улицы или станции метро" value={search} onChange={(e) => setSearch(e.target.value)} />
      </InputWrapper>
      <ResultContainer>
        {points.filter(searchFilter(search)).slice(0, 20).map(row => (
          <Item active={value === row.id} >
            <Row onClick={() => {onChangeFull(row); onChange(row.id)}}>
              <ColMiddle>
                <DetailsLogo>
                  <img src={companies[row.delivery_company_id] && companies[row.delivery_company_id].delivery_company_logo} />
                </DetailsLogo>              
                {row.address}
              </ColMiddle>
              <ColLast>
                <ColDays>
                  {row.delivery_date}
                </ColDays>
                <ColPrice>
                  {dimensionsPresent &&
                    <>{row.price_delivery} ₽</>
                  }
                </ColPrice>
              </ColLast>
            </Row>
            {(value === row.id) && <Moar row={row} companies={companies}/>}
          </Item>
        ))}
      </ResultContainer>
    </Wrapper>
  );
};

export default DeliveryCompanyField;

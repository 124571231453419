import React from 'react'
import styled, { css } from 'styled-components'

import vars from 'config/styles'

const Wrapper = styled.div` 
  ${({ bged }) => bged && css`
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    background: #FFFFFF;
  `};
  display: flex;
  flex-flow: column;
  padding: 10px 40px;
  height: ${({ mh }) => mh ? '100%' : 'auto'};
  @media (max-width: ${vars.bp.tablet}px) {
    padding: 10px 30px;
  }    
  @media (max-width: ${vars.bp.mobile}px) {
    padding: 10px 20px;
  }  

`

const Box = ({children, mh = false, bged = false}) => {
  return (
    <Wrapper mh={mh} bged={bged}>
      {children}
    </Wrapper>
  )
}

export const Inner = styled.div`
  padding: 20px 0;
  @media (max-width: ${vars.bp.tablet}px) {
    padding: 10px 0;
  } 
  @media (max-width: ${vars.bp.mobile}px) {
    padding: 0;
  }
  ${({ small }) => small && css`
    padding: 10px 0;
  `};  
`



export default Box; 
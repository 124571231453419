/**
*
* OfferDealer
*
*/

import React from 'react';
import 'css/components/pupa.sass';
import MapDealer from 'components/MapDealer';

const OfferDealer = ({dealer, sendRaw}) => { // eslint-disable-line react/prefer-stateless-function
    return (
      <React.Fragment>
        <tr className="table__expander">
          <td colSpan={6} className="table__fullwidth">
            <div className="flex flex_pad">
              <div className="pupa">
                <div className="pupa__title">

                Адрес
                </div>
                <div className="pupa__value">
                  {dealer.address}
                </div>
              </div>
              <div className="pupa">
                <div className="pupa__title">

                Телефон
                </div>
                <div className="pupa__value">
                  <a onClick={() => sendRaw('Accessories_BuyForm', 'click_dealer_phone', { code: dealer.code, name: dealer.name })} href={`tel:${dealer.phone}`}>
                    {dealer.phone}
                  </a>
                </div>
              </div>
              <div className="pupa">
                <div className="pupa__title">

                E-mail
                </div>
                <div className="pupa__value">
                  <a onClick={() => sendRaw('Accessories_BuyForm', 'click_dealer_email', { code: dealer.code, name: dealer.name })} href={`mailto:${dealer.email}`}>
                    {dealer.email}
                  </a>
                </div>
              </div>
              <div className="pupa">
                <div className="pupa__title">

                Сайт
                </div>
                <div className="pupa__value">
                  <a onClick={() => sendRaw('Accessories_BuyForm', 'go_to_dealer', { code: dealer.code, name: dealer.name })} href={dealer.site} target="_blank">
                    {dealer.site}
                  </a>
                </div>
              </div>
            </div>
            <div style={{ height: 200 }}>
              <MapDealer dealers={[dealer]} />
            </div>
          </td>
        </tr>
        <tr className="table__empty" />
      </React.Fragment>
    )

}

export default OfferDealer;

import React from 'react';
import styled from 'styled-components';

import { Flex, FlexFixed, FlexFluid, Gapper, Text1, Text4, Ellipsis } from 'components/Ui'

import vars from 'config/styles'
import { formatPrice } from 'helpers';

import ProductCartButtons from 'components/ProductCartButtons';
import Confirm from 'components/Confirm';
import Svg from 'components/Svg'
import Trashcan from 'images/trash.svg'
import { Col1, Col2, Col3, Col4, Row } from './Common'
import Image from './Image'

const Wrapper = styled.div`
  padding: 12px 0 12px 0;
  @media (max-width: ${vars.bp.mobile}px) {
    flex-wrap: wrap;

    ${Col1} {
      width: 100%;
      margin-bottom: 6px;
      padding-right: 0;
    }
    ${Col2} {
      width: auto;
      margin-bottom: 6px;
      padding-right: 0;
      white-space: nowrap;
    }    
    ${Col3} {
      margin-left: auto;
      width: auto;
      padding: 0;
    }
  }
`




function OrderRowCheckout({ item }) {
  return (
    <Wrapper>
      <Row>
        <Col1>
          <Flex>
            <FlexFixed>
              <Image good={item.good} />
            </FlexFixed>
            <FlexFluid>
              <Gapper gap={4}>
                <Text1><Ellipsis>{item.product && item.product.name}</Ellipsis></Text1>
                <Text4 style={{ marginBottom: 10 }}>Арт. {item.productId}</Text4>
              </Gapper>
            </FlexFluid>
          </Flex>
        </Col1>
        <Col2>
          <Gapper gap={4}>
            <Text1>
              <span style={{color: "#AAA"}}>{item.quantity}&nbsp;&nbsp;х</span>&nbsp;&nbsp;{formatPrice(item.price)}
            </Text1>
          </Gapper>
        </Col2>
        <Col3>
          <Text1>
            {formatPrice(item.price * item.quantity)}
          </Text1>
        </Col3>

      </Row>
    </Wrapper>
  )
}

export default OrderRowCheckout;
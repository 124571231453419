
const messages = {

  pages: {
    not_found: 'Ошибка 404. Страница не найдена.',
    reset_success: 'Пароль успешно изменён. Теперь Вы можете войти на сайт с новым паролем.',
    registration_success: 'Поздравляем! Регистрация успешно завершена! Теперь Вам необходимо активировать Ваш аккаунт перейдя по ссылке, полученной на email адрес, указанный при регистрации.',
    domainNotActive: '<h2>Ваш домен не подключён!</h2> Оставьте заявку на подключение в кабинете дилера',
    oldIe: '<h2>Браузер не поддерживается!</h2> Вы используете устаревший браузер. Обновите или используете другой.',
  },
  msrp: {
    0: 'Рекомендованная розничная цена',
    1: 'Максимальная цена перепродажи',
  },
};

export default messages;

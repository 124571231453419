import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { makeSelectCurrentDomain, makeSelectDomainDealers, makeSelectCurrentUser } from 'containers/App/selectors'

import { createPodbor } from 'containers/Parts/actions'
import { setPopup } from 'containers/App/actions'
import Button from 'components/Button'

import {
   formValueSelector,
} from 'redux-form/immutable';

import Form from './Form'
import { H1, Text3, Gapper, Flex } from 'components/Ui'
import { addToPodborMetric } from 'containers/App/metricsActions'


class Podbor extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { submitted: false, number: null }
  }

  componentDidMount() {
    this.props.addToPodborMetric(this.props.parts && this.props.parts[0])
  }

  handleSubmit(vals) {
    const values = vals ? vals.toJS() : {}
    const { createPodbor } = this.props
    return new Promise((resolve, reject) => {
      createPodbor({ resolve, reject, data: { ...values } })
    }).then(a => this.setState({ submitted: true, number: a.number }) )
  }

  render() {

    const { domain, domainDealers, user, parts, dealerId, selectedDealerId, setPopup } = this.props
    let initialValues = {
      parts: parts || [],
      dealerId
    }
    

    if (user) {
      initialValues = {
        ...initialValues,
        name: user.given_name,
        phone: user.phone,
        surname: user.family_name,
        email: user.email,
        // parts: parts.toJS && parts.toJS() || parts,
      }
    }

    if(domain && !domain.isCg) {
      initialValues.dealerId = domainDealers[0]
    }

    if(this.state.submitted) return (
      <div style={{maxWidth: '100%'}}>
        <Gapper gap={60} style={{maxWidth: 750}}>
          <H1 center>Запрос {this.state.number} отправлен</H1>
          <Text3 center>Специалисты выбранного Вами официального дилера ŠKODA свяжутся с Вами и помогут подобрать именно то, что нужно Вашему автомобилю.</Text3>
          <Flex right>
            <Button secondary onClick={() => setPopup(null)}>закрыть</Button>
          </Flex>
        </Gapper>
      </div>
    )

    return (
      <>
        <Form 
          initialValues={initialValues}
          forDealer={this.props.forDealer} 
          onSubmit={this.handleSubmit} 
          dealerId={selectedDealerId}
          parts={parts}
        />
      </>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  parts: (state) => formValueSelector('parts-podbor')(state, 'parts'),
  selectedDealerId: (state) => formValueSelector('parts-podbor')(state, 'dealerId'),
  dealerId: (state) => formValueSelector('parts-form')(state, 'dealerId'),
  domain: makeSelectCurrentDomain(),
  domainDealers: makeSelectDomainDealers(),
  user: makeSelectCurrentUser(),
});

function mapDispatchToProps (dispatch) {
  return {
    createPodbor: (payload) => dispatch(createPodbor(payload)),
    setPopup: (title) => dispatch(setPopup(title)),
    addToPodborMetric: payload => dispatch(addToPodborMetric(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect,
)(Podbor);



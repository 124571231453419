import React from 'react'

import styled from 'styled-components'


export const Wrapper = styled.footer`

`

export const FooterTop = styled.div`
`


export const FooterBottom = styled.div`
`

/**
 *
 * Button.react.js
 *
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import 'css/components/button.sass';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

function Button (props) {
  const {
    secondary, small, smaller, big, loading, ...other
  } = props;
  const cls = classNames('button', { button_big: big }, { button_small: small }, { button_smaller: smaller }, { button_secondary: secondary }, { button_loading: loading });
  let button = (
    <button type="button" className={cls} {...other}>
      <span>
        {Children.toArray(props.children)}
      </span>
    </button>
  );

  if (props.href) {
    button = (
      <a className={cls} href={props.href} {...other}>
        {Children.toArray(props.children)}
      </a>
    );
  }

  if (props.to) {
    button = (
      <Link className={cls} to={props.to} {...other}>
        {Children.toArray(props.children)}
      </Link>
    );
  }

  return button;
}

Button.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;

/**
 *
 * DealerOrdersStats
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { makeSelectEntities } from 'models/selectors';
import Filters from './Filters'
import { formatPrice } from 'helpers';
import { getFormValues } from 'redux-form/immutable';
import AdminOrders from 'containers/AdminOrders'
import Filter from 'components/Filter';

import { getDealerOrdersInfo, getDealerGraphInfo } from 'containers/AdminStats2/actions'

import Row from 'components/Row'
import Col from 'components/Col'
import Graph from './Graph'


export class DealerOrdersStats extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      graphData: null
    }
    this.refreshStats = this.refreshStats.bind(this)
    this.getGraphData = this.getGraphData.bind(this)
    this.refreshOrderList = this.refreshOrderList.bind(this)
  }

  componentDidMount () {
    const { row } = this.props
    this.setState({ data: row })
    this.refreshAll()
  }

  refreshAll() {
    setTimeout(() => {
      this.refreshStats()
      this.getGraphData()
      this.refreshOrderList()
    }, 100)
  }


  refreshStats () {
    const { getDealerOrdersInfo, row, form } = this.props
    new Promise((resolve, reject) => {
      getDealerOrdersInfo({ id: row.id, data: form, resolve, reject })
    }).then(data => this.setState({ data }))
      .catch(console.log)

  }

  getGraphData() {
    const { getDealerGraphInfo, row, form } = this.props
    new Promise((resolve, reject) => {
      getDealerGraphInfo({ id: row.id, data: form, resolve, reject })
    }).then(data => this.setState({ graphData: data }))
      .catch(console.log)
  }

  refreshOrderList() {
    const { id, form } = this.props
    const fls = form ? form.toJS() : {}
    console.log({ dealerId: id, date_from: fls.from, date_to: fls.to })
    this.props.filtersSubmit({ dealerId: id, date_from: fls.from, date_to: fls.to, processStatus: 20 })
  }


  render () {
    const { dealers, id, year, quarter, form } = this.props;
    const dealer = dealers && dealers[id] || {}
    const { data } = this.state
    return (
      <div>
        <h1>
          Статистика дилера {data.name} ({data.kod})
        </h1>
        <Filters year={year} quarter={quarter} onSubmit={(vals) => { this.refreshAll() }}/>
        <div style={{height: 20}} />
        <Row>
          <Col n={12}>
            <table className='table table_small'>
              <tbody>
                <tr>
                  <td>Количество заказов</td>
                  <td>{data.ordersCount}</td>
                </tr>
                <tr>
                  <td>Количество заказов</td>
                  <td>{data.ordersCount}</td>
                </tr>
                <tr>
                  <td>Заказов вовремя</td>
                  <td>{data.inTimeCount}</td>
                </tr>
                <tr>
                  <td>Процент вовремя</td>
                  <td>{data.ordersCount != 0 ? ((data.inTimeCount*100)/data.ordersCount).toFixed(2) : 0} %</td>
                </tr>
                <tr>
                  <td>Заказов просрочено</td>
                  <td>{data.notInTimeCount}</td>
                </tr>
                <tr>
                  <td>Процент просрочено</td>
                  <td>{data.ordersCount != 0 ? ((data.notInTimeCount*100)/data.ordersCount).toFixed(2) : 0} %</td>
                </tr>
                <tr>
                  <td>Средняя сумма</td>
                  <td>{formatPrice(data.sum/data.ordersCount)}</td>
                </tr> 
              </tbody>
            </table>             
          </Col>
          <Col n={12} >
            <div style={{height: 20}} />
            <AdminOrders noFilters noInstruction initialParams={{dealerId: id}} title='Просроченные заказы' />
          </Col>
          <Col n={12}>
            <Graph data={this.state.graphData}/>
          </Col>
        </Row>
      </div>
    );
  }
}


const withFilter = Filter({
  name: `admin-orders`, path: 'filter', model: 'orders', initialQuery: false,
});
    

const mapStateToProps = createStructuredSelector({
  dealers: makeSelectEntities('dealers'),
  form: (state) => getFormValues('admin-stats2-filters-detailed')(state),
});

function mapDispatchToProps (dispatch) {
  return {
    getDealerOrdersInfo: payload => dispatch(getDealerOrdersInfo(payload)),
    getDealerGraphInfo: payload => dispatch(getDealerGraphInfo(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect,
  withFilter,
)(DealerOrdersStats);

/**
*
* SearchBar
*
*/

import React from 'react';

import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class SearchBar extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount () {
  }

  render () {
    const { value, onChange, onSubmit, sendRaw } = this.props;
    return (
      <React.Fragment>

        <div className="input-group input-group_primary">
          <input
            onChange={onChange}
            onFocus={() => sendRaw('AccessoriesPage', 'click_search_bar')}
            type="text"
            value={value || ''}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
          />
          <div className="input-group__right">
            <FontAwesomeIcon icon={faSearch} onClick={onSubmit} />
          </div>
        </div>

      </React.Fragment>
    );
  }
}

SearchBar.propTypes = {

};

export default compose(
)(SearchBar);

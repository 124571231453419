
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Pagination from 'components/Pagination';
import { makeSelectFilter, makeSelectFilterResponse, makeSelectFilterStatus } from './selectors';
import {
  setFilter, setFilterStatus, downloadXls, saveSortOrder,
} from './actions';

function Filter (params) {
  return function (DecoratedComponent, context) {
    class UserFilter extends React.PureComponent {
      constructor (props) {
        super(props);

        this.filtersSubmit = this.filtersSubmit.bind(this);
        this.filtersReset = this.filtersReset.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.doSort = this.doSort.bind(this);
        this.setPerPage = this.setPerPage.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {};
      }

      componentDidMount () {
        if (typeof params.initialQuery === 'undefined' && params.initialQuery !== false) {
          this.props.setFilter({ data: { page: 1, perPage: params.perPage } });
          this.props.setFilterStatus({ fetching: false, initial: true, error: false });
        }
      }

      filtersSubmit (data, noRequest = false) {
        return new Promise((resolve, reject) => {
          this.props.setFilter({
            data: { 
              filters: data, 
              page: 1, 
              field: '', 
              perPage: this.props.filter && this.props.filter.perPage || params.perPage 
            }, noRequest, resolve, reject,
          });
        }).catch(console.log);
      }

      filtersReset () {
        this.props.setFilter({
          data: {
            page: 1, filters: {}, direction: '', field: '', perPage: params.perPage 
          },
        });
      }

      onPageClick (n) {
        return new Promise((resolve, reject) => {
          this.props.setFilter({ data: { page: n }, resolve, reject });
        });
      }

      loadMore () {
        const page = (this.props.filter || {}).page || 1;
        return new Promise((resolve, reject) => {
          this.props.setFilter({ data: { page: page + 1 }, append: true, resolve, reject });
        });
      }      

      setPerPage (perPage) {
        this.props.setFilter({ data: { page: 1, perPage } });
      }      

      doSort (data) {
        this.props.setFilter({ data: { page: 1, field: data.field, direction: data.direction } });
      }

      getRecords (ids, records) {
        return Object.values(ids.map((one) => records[one]));
      }

      render () {
        // const data = (records && filterResponse && filterResponse.ids) ? filterResponse.ids.map((key) => records[key]) : [];
        let props = {
          doSort: this.doSort,
          setPerPage: this.setPerPage,
          setFilter: this.props.setFilter,
          saveSortOrder: this.props.saveSortOrder,
          filtersSubmit: this.filtersSubmit,
          filtersReset: this.filtersReset,
          onPageClick: this.onPageClick,
          getRecords: this.getRecords,
          filter: this.props.filter,
          loadMore: this.loadMore, 
        };

        if (!params.onlyFunctions) {
          const { filterResponse } = this.props;
          const ids = filterResponse && filterResponse.ids ? filterResponse.ids : [];
          const pagination = filterResponse && filterResponse.pagination ? <Pagination pagination={filterResponse.pagination} pageClick={this.onPageClick} /> : null;
          props = {
            ...props,
            ids,
            pagination,
            count: filterResponse && filterResponse.pagination ? filterResponse.pagination.total : 0,
            currentPage: filterResponse && filterResponse.pagination ? filterResponse.pagination.page : 0,
            totalPages: filterResponse && filterResponse.pagination ? filterResponse.pagination.totalPages : 0,
          };
        }
        return (
          <DecoratedComponent {...props} {...this.props} />
        );
      }
    }

    const mapStateToProps = createStructuredSelector({
      filter: makeSelectFilter(params.name),
      filterResponse: makeSelectFilterResponse(params.name),
      filterStatus: makeSelectFilterStatus(params.name),
    });

    function mapDispatchToProps (dispatch, ownProps) {
      return {
        setFilter: (payload) => dispatch(setFilter(params.name, payload, params.model, params.path)),
        saveSortOrder: (payload) => dispatch(saveSortOrder(params.name, payload, params.model)),
        setFilterStatus: (payload) => dispatch(setFilterStatus(params.name, payload)),
        downloadXls: () => dispatch(downloadXls(params.name, params.model)),
        dispatch,
      };
    }

    return connect(mapStateToProps, mapDispatchToProps)(UserFilter);
  };
}

export default Filter;
// export default

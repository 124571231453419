import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { withRouter } from 'react-router';

import { initial } from './actions';

import { queryString } from 'helpers';



class Initial extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    const qs = queryString(this.props.location.search)

    this.props.initial(this.props.location.pathname === '/auth' && !!qs.code)
  }


  render() {
    return null
  }
}

  const mapStateToProps = createStructuredSelector({

  });

  function mapDispatchToProps(dispatch) {
    return {
      initial: payload => dispatch(initial(payload)),
    };
  }

const withConnect = connect(mapStateToProps, mapDispatchToProps)


export default compose(
  withRouter,
  withConnect,
)(Initial)
/* istanbul ignore file */
/*
 *
 * Parts constants
 *
 */

export const PARTS_SEARCH = 'app/Parts/PARTS_SEARCH';
export const ADD_TO_PODBOR = 'app/Parts/ADD_TO_PODBOR';
export const CREATE_PODBOR = 'app/Parts/CREATE_PODBOR';

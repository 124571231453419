import { createSelector } from 'reselect';

const selectDomain = state => state.get('order_comments');


export const makeSelectPreorderItems = () =>
  createSelector(selectDomain, orderCommentsState => {
    return orderCommentsState ? orderCommentsState.toJS() : {}
  });



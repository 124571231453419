/**
*
* Graph dealer order stats
*
*/

import React from 'react';
//  
import {Line, Pie} from 'react-chartjs-2';
import moment from 'moment'


import Row from 'components/Row'
import Col from 'components/Col'




const initdata = {
  labels: [],
  datasets: [
    {
      label: 'Все заказы',
      backgroundColor: 'rgba(75,168,46,0.2)',
      borderColor: 'rgba(75,168,46,1)',
      borderWidth: 2,
      hoverBackgroundColor: 'rgba(75,168,46,0.4)',
      hoverBorderColor: 'rgba(75,168,46,1)',
      data: []
    },
    {
      label: 'Обработанные вовремя заказы',
      backgroundColor: 'rgba(175,75,46,0.2)',
      borderColor: 'rgba(175,75,46,1)',
      borderWidth: 2,
      hoverBackgroundColor: 'rgba(175,75,46,0.4)',
      hoverBorderColor: 'rgba(175,75,46,1)',
      data: []
    }

  ]
};

const opts = {
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
              callback: function (value) { if (Number.isInteger(value)) { return value; } },
              stepSize: 1
          }
      }]
  }
}

class Graph extends React.PureComponent {
  render() {
    const { data } = this.props
    if(!data || !data.total || !data.finished) return null
    initdata.labels = data['total'].map((e) => e[0])
    initdata.datasets[0].data = data['total'].map((e) => e[1])
    initdata.datasets[1].data = data['finished'].map((e) => e[1])


    return (
      <div>
        <Line options={opts} data={initdata} width={100} height={40} />
      </div>
    );
  }
}


export default Graph;

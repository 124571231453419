import React from 'react';

import {
  withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow,
} from 'react-google-maps';

import Pointer from 'images/pointer.png';

import Button from 'components/Button';

/* istanbul ignore next */
const Gmap = withScriptjs(withGoogleMap((props) => {
  const {
    showDealer, dealers, zoomLimit, onMarkerClick, onMarkerClose, interactMap = () => { }, sendRaw = () => {}
  } = props;

  const bounds = new window.google.maps.LatLngBounds();
  dealers.filter((a) => (a.latitude && a.longitude)).map((model) => {
    const { latitude, longitude } = model;
    const latLng = new window.google.maps.LatLng(latitude, longitude);
    bounds.extend(latLng);
    return latLng;
  });
  return (
    <GoogleMap
      height={130}
      ref={zoomLimit(bounds)}
      defaultOptions={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
      onDragEnd={(e) =>interactMap('drag_end')}
      // onZoomChanged={e => {console.log(e); interactMap('zoom_changed')}}
      yesIWantToUseGoogleMapApiInternals
      maxZoom={12}
    >
      { dealers && dealers.map((dealer) => (
        <Marker key={dealer.id} position={{ lat: parseFloat(dealer.latitude), lng: parseFloat(dealer.longitude) }} icon={{ url: Pointer }} onClick={() => onMarkerClick(dealer.id)}>
          {dealer.id === showDealer &&
                 (
                   <InfoWindow onCloseClick={() => onMarkerClose(dealer)} style={{height: 500}} options={{ maxHeight: 500 }}>
                     <div style={{ lineHeight: '21px', fontSize: 12, maxWidth: 300 }}>
                       <h2>
                         {dealer.name}
                       </h2>
                       <hr />
                       {dealer.address}
                       <br />

                        Телефон:
                       {' '}
                        <a onClick={() => sendRaw('PartsPage_Map', 'click_dealer_phone', { code: dealer.number, title: dealer.name })} href={`tel:${dealer.phone}`}>
                         {dealer.phone}
                       </a>
                       <br />

                        Эл. почта:
                       {' '}
                       <a onClick={() => sendRaw('PartsPage_Map', 'click_dealer_email', { code: dealer.number, title: dealer.name })} href={`mailto:${dealer.email}`}>
                         {dealer.email}
                       </a>
                       <br />
                       {/* Часы работы: {dealer.worktime} */}
                       {/* <br/> */}
                        <Button onClick={() => sendRaw('PartsPage_Map', 'go_to_dealer', { code: dealer.number, title: dealer.name })} small href={`${dealer.accessoriesSite || dealer.site}`} target="_blank">
                          Перейти на веб-сайт дилера
                       </Button>
                       
                     </div>
                   </InfoWindow>
                 )
          }
        </Marker>

      ))
      }

    </GoogleMap>
  );
}));

export default Gmap;

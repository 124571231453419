import { fromJS } from 'immutable';

import {
  SET_FILTER,
  SET_FILTER_RESPONSE,
  SET_FILTER_STATUS,
} from './constants';

const initialState = fromJS({
});

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return state.setIn([action.name, 'request'], { ...state.getIn([action.name, 'request']), ...action.payload.data });
    case SET_FILTER_RESPONSE:
      // console.log(action)
      return state.setIn([action.name, 'response'], { ...state.getIn([action.name, 'response']), ...action.payload });
    case SET_FILTER_STATUS:
      return state.setIn([action.name, 'status'], { ...state.getIn([action.name, 'status']), ...action.payload });
    default:
      return state;
  }
};

export default filterReducer;

/* istanbul ignore file */

import {
  EDIT_NOTIFICATION,
} from './constants';

export function editNotification (payload) {
  return {
    type: EDIT_NOTIFICATION,
    payload,
  };
}

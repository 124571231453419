import React from 'react';
import styled from 'styled-components';

import { H7, H4, A, Text2, Gapper } from 'components/Ui'

import Box, { Inner } from 'components/Box'

function DealerInfoBox({ dealer, sendRaw }) {
  
  return (
    <Box>
      <Inner>
        <Gapper gap={16}>
          <H7>Дилер</H7>
          <H4>{dealer.name}</H4>
          <Gapper>
            <Text2>{dealer.address}</Text2>
            <Text2>{dealer.phone}</Text2>
            <Text2><A onClick={(e) => { sendRaw('Purchase_RequestForm', 'go_to_dealer', {name: dealer.name, code: dealer.number});}} href={dealer.site} target="_blank">{dealer.site}</A></Text2>
            <Text2><A onClick={(e) => { sendRaw('Purchase_RequestForm', 'click_dealer_email', {name: dealer.name, code: dealer.number});}} href={`mailto:${dealer.email}`}>{dealer.email}</A></Text2>
          </Gapper>
        </Gapper>
      </Inner>
    </Box>
  )
}

export default DealerInfoBox;
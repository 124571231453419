/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getAllRegionData () {
  return apicall('regions/allData');
}

export function getRegions () {
  return apicall('regions');
}

export function createRegion (data) {
  return apicall('regions', { method: 'POST', body: JSON.stringify({ region: data }) });
}

export function updateRegion (data) {
  const { id, ...rest } = data;
  return apicall(`regions/${id}`, { method: 'PATCH', body: JSON.stringify({ region: rest }) });
}

/**
*
* Transition
*
*/

import React from 'react';
import { TransitionMotion, spring } from 'react-motion';

const willLeave = () => ({
  opacity: spring(0),
});

const willEnter = () => ({
  opacity: 0,
});

const getStyles = () => ({
  opacity: spring(1),
});

const Transition = ({ children }) => (
  <TransitionMotion
    styles={children ? [{ key: 'key', style: getStyles(), data: children }] : []}
    willLeave={willLeave}
    willEnter={willEnter}
  >
    { (int) => (
      <React.Fragment>
        {int.map(({ key, style, data }) => (
          <div key={`${key}-transition`} style={{ ...style }}>
            {data}
          </div>
        ))}
      </React.Fragment>
    )}
  </TransitionMotion>
);

Transition.propTypes = {

};

export default Transition;

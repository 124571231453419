import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components'

const Wrapper = styled.div`
  .container { 

   }
  .input { 

   }
  .react-autosuggest__suggestions-container {
    position: absolute;
    background: #fff;
    z-index: 234;
    left: 0;
    right: 0;
    box-shadow: 0 0 4px -1px rgb(0 0 0 / 40%);
   }
   .react-autosuggest__suggestions-list {
     list-style-type: none;
     padding: 0;
     li {
       padding: 3px 10px;
     }
     .react-autosuggest__suggestion--highlighted {
       background: green;
       color: #fff;
     }
   }
  .suggestion { 

   }
  .suggestionHighlighted { 

   }
`

const renderSuggestion = suggestion => (
  <div>
    {suggestion}
  </div>
);

export default function AutosuggestField (props) {
  const {
    input, name, meta, label, required, placeholder, error, handleQuery
  } = props
  const {
    value, onBlur, onChange, onFocus, 
  } = input;

  const inputProps = { ...input, onChange: (e, { newValue }) => onChange(newValue) }
  const [suggestions, setSuggestions] = useState([])

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([])
  })

  const onSuggestionsFetchRequested = useCallback(async ({ value }) => {
    try {
      const result = await handleQuery(value)
      setSuggestions(result)
    } catch(e) {
      console.log(e)
    }
  }, [])

  return (
    <div className={`input-group ${error || meta.touched && meta.error ? 'input-group_error ' : ' '}`}>
      <label htmlFor={name}>
        {label}
        :
        {' '}
        { required && (
          <span>
          *
          </span>
        ) }
      </label>
      <Wrapper>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={suggestion => {
            return suggestion
          }}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </Wrapper>
      { meta.touched && meta.error && (
        <span>
          {meta.error}
        </span>
      )}
    </div>

  );
}

import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { updateOrder, getOrderLogs } from 'api/orders';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';

import { normalize } from 'normalizr';
import { apiRequest } from 'containers/App/saga';

import { setEntities, replacePassedEntities } from 'models/actions';
import { Order } from '..';
import { EDIT_ORDER, GET_ORDER_LOGS } from './constants';

export function * editOrderGen (action) {
  const {
    payload: {
      data, id, resolve, reject,
    },
  } = action;
  try {
    const order = yield call(apiRequest, updateOrder, id, data);
    const normalized = normalize(order, Order);
    yield put(replacePassedEntities(normalized, 'orders'));
    // yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export function * getOrderLogsGen (action) {
  const { payload: { id } } = action;
  try {
    const order = yield call(apiRequest, getOrderLogs, id);
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized, 'orders'));
    // resolve();
  } catch (e) {
    errorHandler(e);
    // reject();
  }
}

export default function * rootSaga () {
  yield all([
    takeLatest(EDIT_ORDER, editOrderGen),
    takeLatest(GET_ORDER_LOGS, getOrderLogsGen),
  ]);
}

import React from 'react'

import styled from 'styled-components'
import Container from 'components/Container'
import { H1, Gapper } from 'components/Ui'
import { Link } from 'react-router-dom';
import styles from 'config/styles'

const LinkDec = styled(Link)`
  color: ${styles.green};
  &:hover {
    color: ${styles.greenH};
  }
`


const PaymentFailed = () => {

  return (
    <>
      <Container>
        <Gapper gap={20}>
          <H1>Не удалось осуществить оплату</H1>
          <LinkDec to={'/orders'}>Вернуться к списку заказов</LinkDec>
        </Gapper>
      </Container>
    </>
  )
}

export default PaymentFailed
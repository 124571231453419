import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import { List } from 'immutable';

RFReactSelect.defaultProps = {
  multi: false,
  className: '',
  clearable: true,
  onHome: false,
};

RFReactSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // value: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  onHome: PropTypes.bool,
};

const customStyles = (onHome = false, error = false) => ({
  option: (provided, state) => ({
    ...provided,
    background: (state.isSelected || state.isFocused) ? 'rgba(0, 126, 0, 0.04)' : '#fff',
    color: '#000',
  }),
  control: (provided, state) => ({
    ...provided,
    height: state.isMulti ? 'auto' : 40,
    border: 0,
    paddingLeft: onHome ? 20 : 0,
    background: onHome ? '#fff' : '#fff',
    border: onHome ? `1px solid ${error ? '#da0000' : '#dadee1'}` : 'none',
    borderBottom: `1px solid ${error ? '#da0000' : '#dadee1'}`,
    borderColor: (state.isFocused) ? '#4ba82e !important' : '#9F9F9F',
    boxShadow: (state.isFocused) ? 'none' : 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: state.isMulti ? 'auto' : 40,
    position: 'static',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    maxWidth: 'calc(100% - 90px)',
  }),
});

export default function RFReactSelect ({
  input, name, options, multi, className, meta, label, required, clearable, placeholder, allowBlank, onHome, error, onMenuOpen = () => {}
}) {
  const {
    value, onBlur, onChange, onFocus,
  } = input;
  const transformedValue = transformValue(value, options, multi);
  return (
    <div className={`input-group ${error || meta.touched && meta.error ? 'input-group_error ' : ' '}`}>
      <label htmlFor={name}>
        {label}
        :
        {' '}
        { required && (
          <span>
          *
          </span>
        ) }
      </label>
      <Select
        id={name}
        valueKey="value"
        name={name}
        value={transformedValue}
        isMulti={multi}
        styles={customStyles(onHome, error)}
        options={options}
        isClearable={clearable}
        placeholder={placeholder || ''}
        onMenuOpen={onMenuOpen}
        onChange={multi
          ? multiChangeHandler(onChange)
          : singleChangeHandler(onChange)
        }
        onBlur={(event) => event.preventDefault()}
        onFocus={onFocus}
        className={className}
      />
      { meta.touched && meta.error && (
        <span>
          {meta.error}
        </span>
      )}
    </div>

  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler (func) {
  /* istanbul ignore next */
  return function handleSingleChange (value) {
    func(value ? value.value : '');
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler (func) {
  /* istanbul ignore next */
  return function handleMultiHandler (values) {
    // const vals = values.size && values.toJS() || []
    return func(List(values.map((value) => value.value)));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue (val, options, multi) {
  if (multi && typeof val === 'string') return [];
  const value = multi ? (val.size && val.toJS() || []) : val;
  const filteredOptions = options.filter((option) => multi
    ? value.map((a) => a.toString()).indexOf(option.value.toString()) !== -1
    : option.value && option.value.toString() === value.toString());
  return multi ? filteredOptions : (filteredOptions[0] || '');
}

/* istanbul ignore file */
import { apicall } from 'utils/api';

export function createMessage (data) {
  return apicall('messages', { method: 'POST', body: JSON.stringify({ notification: data }) });
} 

export function updateMessage (data) {
  const { id, ...rest } = data;
  return apicall(`messages/${id}`, { method: 'PATCH', body: JSON.stringify({ notification: rest }) });
}

export function readMessage (id) {
  return apicall(`messages/${id}/read`, { method: 'POST' });
} 

export const actionTypes = {
  GET_BONUSES: "PARTS/BONUS_POPUP/GET_BONUSES",
  SET_BONUS_INFO: "PARTS/BONUS_POPUP/SET_BONUS_INFO",
  APPLY_CERT: "PARTS/BONUS_POPUP/APPLY_CERT",
  APPLY_POINTS: "PARTS/BONUS_POPUP/APPLY_POINTS",
  REMOVE_BONUS: "PARTS/BONUS_POPUP/REMOVE_BONUS",
}


export const setBonusInfo = payload => {
  return {
    type: actionTypes.SET_BONUS_INFO,
    payload
  }
}

export const getBonuses = payload => {
  return {
    type: actionTypes.GET_BONUSES,
    payload
  }
}

export const applyCert = payload => ({
  type: actionTypes.APPLY_CERT,
  payload
})

export const applyPoints = payload => ({
  type: actionTypes.APPLY_POINTS,
  payload
})

export const removeBonus = payload => ({
  type: actionTypes.REMOVE_BONUS,
  payload
})

/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getCategories () {
  return apicall('categories');
}

export function createCategory (data) {
  return apicall('categories', { method: 'POST', body: JSON.stringify({ category: data }) });
}

export function updateCategory (data) {
  const { id, ...rest } = data;
  return apicall(`categories/${id}`, { method: 'PATCH', body: JSON.stringify({ category: rest }) });
}

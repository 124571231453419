import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'
import { FlexFluid, FlexFixed, Flex } from 'components/Ui'

export const Row = styled(Flex)`
  @media (max-width: ${vars.bp.mobile}px) {
    flex-wrap: wrap;
  }
`

export const Col1 = styled(FlexFluid)`
  padding-right: 15px;
  ${({pd}) => pd && css`
    padding: 0 30px;
  `}
  @media (max-width: ${vars.bp.tablet}px) {
    flex: 100%; 
  }
`
export const Col2 = styled(FlexFixed)`
  width: 200px;
  ${({pd}) => pd && css`
    padding: 0 30px;
  `}
  @media (max-width: ${vars.bp.mobile}px) {
    width: 120px;
  }
`

export const Col3 = styled(FlexFixed)`
  width: 140px;
  @media (max-width: ${vars.bp.mobile}px) {
    margin-left: 20px;
    width: 140px;
  }
`

export const Col3w = styled(FlexFixed)`
  width: 170px;
`

export const Col4 = styled(FlexFixed)`
  width: 22px;
  @media (max-width: ${vars.bp.mobile}px) {
    margin-left: auto;
  }
`

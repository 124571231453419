/* istanbul ignore file */

import {
  EDIT_PODBOR,
} from './constants';

export function editPodbor (payload) {
  return {
    type: EDIT_PODBOR,
    payload,
  };
}

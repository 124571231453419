import React from 'react';
import styled from 'styled-components';

import vars from 'config/styles'
import { Flex, FlexFixed, FlexFluid, Gapper, Text1, Text2, Text3, Text4, Ellipsis } from 'components/Ui'

import { formatPrice } from 'helpers';

import Svg from 'components/Svg'
import { Col1, Col2, Col3, Col4 } from './Common'
import Card from 'images/card.svg'

const Wrapper = styled(Flex)`
  padding: 12px 0;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: inset 0px 1px 0px #DADADA;
  @media (max-width: ${vars.bp.mobile}px) {
    flex-wrap: wrap;

    ${Col1} {
      width: 100%;
      margin-bottom: 6px;
    }
    ${Col3} {
      margin-left: auto;
      width: auto;
      padding: 0;
    }

  }  
`



function SummaryRow({ payable, price, text = 'Итого:' }) {
  return (
    <Wrapper>
      <Col1>
        {payable && (
          <Flex>
            <FlexFixed>
             <Svg icon={Card} small style={{marginRight: 5}}/>
            </FlexFixed>
            <FlexFluid>
              <Text2>
                Онлайн-оплата для данного заказа будет доступна после оформления
              </Text2>
            </FlexFluid>
          </Flex>
        )}
      </Col1>
      <Col2 pd>
        <Text3 style={{textAlign: 'right'}}>{text}</Text3>
      </Col2>
      <Col3>
        <Text1 style={{fontWeight: 'bold'}}>
          {formatPrice(price)}
        </Text1>
      </Col3>

    </Wrapper>
  )
}

export default SummaryRow;
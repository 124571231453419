import React from 'react'

import { Helmet } from 'react-helmet-async';
import Container from 'components/Container';
import HelpItem from 'components/HelpItem'
import d1 from 'images/help/d1.png'
import d2 from 'images/help/d2.png'
import d3 from 'images/help/d3.png'
import d4 from 'images/help/d4.png'
import d5 from 'images/help/d5.png'
import po1 from 'images/help/po1.png'
import po2 from 'images/help/po2.png'
import po3 from 'images/help/po3.png'
import po4 from 'images/help/po4.png'
import po5 from 'images/help/po5.png'
import po6 from 'images/help/po6.png'
import po7 from 'images/help/po7.png'
import po8 from 'images/help/po8.png'

export class InstructionGuest extends React.PureComponent {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Оригинальные детали ŠKODA
          </title>
          <meta name="description" content="Оригинальные детали ŠKODA" />
        </Helmet>   
        <Container>
          <div className='panel'>
            <h2>Навигация</h2>
            <div>
              Незарегистрированному пользователю доступны следующие разделы:
              <ul className='hlist'>
                <li>Детали</li>
                <li>Аксессуары</li>
                <li>Корзина</li>
                <li>Избранное</li>
                <li>Сравнение</li>
              </ul>
            </div>
            <HelpItem Title={<div>Оригинальные детали (Главная)</div>}>
              <div style={{marginBottom: 15}}>
                <p>В разделе «Оригинальные детали» поиск осуществляется по артикулу или названию детали. После введения артикула или названия детали, необходимо нажать кнопку «Поиск».</p>
                <img src={po1} style={{ maxWidth: '100%' }} />
                <p>После нажатия на кнопку «Поиск» можно будет ознакомиться со списком дилеров, у которых необходимая деталь в наличии.</p>
                <img src={po2} style={{ maxWidth: '100%' }} />
                <p>Если пользователь указал артикул или название детали, но не выбрал дилера, поиск будет осуществляться по всем дилерам соответствующего региона.</p>
                <img src={po3} style={{ maxWidth: '100%' }} />
                <p>Также можно выбрать дилеров, принимающих оплату онлайн, нажав на соответствующий чекбокс.</p>
                <img src={po4} style={{ maxWidth: '100%' }} />
                <p>Выбранный товар можно поместить в корзину, нажав на соответствующую кнопку.</p>
                <img src={po5} style={{ maxWidth: '100%' }} />
                <p>После того, как товар будет помещен в корзину, можно будет оформить заказ, нажав на кнопку «Перейти к оформлению заказа».</p>
                <img src={po6} style={{ maxWidth: '100%' }} />
                <p>Нажав кнопку «Продолжить», неавторизованный пользователь может завершить оформление заказа без авторизации, либо пройти регистрацию на ресурсе. При выборе оформления заказа без регистрации история заказов не сохранится и без регистрации невозможно будет провести онлайн-оплату заказа в случае, если такая оплата доступна для выбранного дилерского центра.
                  Возможность использования сертификата на скидку по программе лояльности ŠKODA Бонус или бонусных баллов при приобретении товара предоставляется в том случае, если пользователь вошёл на ресурс под учётной записью, используемой на ресурсе Личный кабинет ŠKODA.</p>
                <img src={po7} style={{ maxWidth: '100%' }} />
                <img src={po8} style={{ maxWidth: '100%' }} />

              </div>
              <b>Подбор деталей</b>
              <br/>
              <div>
                <p>Если после ввода артикула / названия детали отсутствуют предложения от дилеров, то можно будет добавить деталь к подбору, нажав на кнопку «Добавить к подбору».</p>
                <img src={d1} style={{ maxWidth: '100%' }} />
                <p>После этого нужно будет выбрать дилера и нажать кнопку «Далее».</p>
                <img src={d2} style={{ maxWidth: '100%' }} />
                <p>Также можно добавить дополнительные позиции по деталям, нажав на кнопку «Добавить деталь». После этого необходимо ввести название детали и нажать кнопку «Добавить».</p>
                <img src={d3} style={{ maxWidth: '100%' }} />
                <p>После того, как дилер будет выбран и будут указаны все интересующие для подбора детали, необходимо нажать кнопку «Далее».</p>
                <img src={d4} style={{ maxWidth: '100%' }} />
                <p>После этого необходимо будет заполнить соответствующую форму, указав контактные данные, и дать согласие на обработку персональных данных. После заполнения всех полей необходимо нажать кнопку «Отправить запрос». После того, как будет отправлен запрос выбранному дилеру, появится соответствующее информационное окно.</p>
                <img src={d5} style={{ maxWidth: '100%' }} />
              </div>
            </HelpItem>

            <HelpItem Title={<div>Аксессуары</div>}>
              В разделе аксессуаров отображаются все аксессуары с возможностью фильтрации по моделям и категориям, а также с возможностью поиска по названию и артикулу. 
              В карточке каждого аксессуара есть ссылка на страницу с его описанием, ссылки на добавление аксессуара в Избранное и К сравнению и кнопка добавления в корзину, нажав на которую, можно ознакомиться с предложениями дилеров.
            </HelpItem>

            <HelpItem Title={<div>Корзина</div>}>
              Состоит из трёх этапов:
              <ul className='hlist'>
                <li>На странице отражено текущее состояние корзины. Детали и аксессуары сгруппированы в несколько списков, в каждом из которых содержатся позиции определённого дилера и в дальнейшем рассматриваются как отдельные заказы.</li>
                <li>Пользователю предлагается пройти авторизацию, либо оформить заказ без регистрации, введя свои данные</li>
                <li>Финальный этап - отражена вся информация о заказах и информация о пользователе</li>
              </ul>
            </HelpItem>

            <HelpItem Title={<div>Избранное</div>}>
              Выводятся карточки аксессуаров, добавленных в избранное. Для незарегистрированного пользователя состояние этого раздела сохраняется до авторизации.
            </HelpItem>

            <HelpItem Title={<div>Сравнение</div>}>
              Выводятся все аксессуары с ценой и описанием, добавленные к сравнению. Для незарегистрированного пользователя состояние этого раздела сохраняется до авторизации.
            </HelpItem>                                                
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default InstructionGuest
/**
*
* FactoryEditEntity
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import { makeSelectEntities } from 'models/selectors';

/*
  Options {}
    editAction:action
    EntityForm:Component
    entity:string
    onSuccess:function - callback
    onFailure:function - callback
    sagaKey:string
    saga:saga to take editAction
*/

export default function wrapper (options) {
  const { EntityForm } = options;

  class FactoryEditEntity extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    constructor (props) {
      super(props);
      this.editEntity = this.editEntity.bind(this);
    }

    componentDidMount () {

    }

    editEntity (data) {
      const {
        id, editAction, dispatch, successCallback,
      } = this.props;
      // console.log(data.toJS())
      // const dataJS = data.toJS()
      // let formData = new FormData();
      // for ( var key in dataJS ) {
      // formData.append(key, dataJS[key]);
      // }
      return new Promise((resolve, reject) => {
        editAction({
          data: data.toJS ? data.toJS() : data, id, resolve, reject,
        });
      }).then((e) => {
        options.onSuccess && options.onSuccess(dispatch);
        successCallback && successCallback();
      }).catch((e) => {
        options.onFailure && options.onFailure(dispatch);
        if(!options.dontThrow) {
          throw e;
        } else {
          return e
        }
      });
    }

    render () {
      const { id, entities, initialValues } = this.props;
      return (
        <React.Fragment>
          <EntityForm {...this.props} initialValues={initialValues || entities[id]} onSubmit={this.editEntity} />
        </React.Fragment>
      );
    }
  }

  FactoryEditEntity.propTypes = {

  };

  function mapDispatchToProps (dispatch) {
    return {
      editAction: (payload) => dispatch(options.editAction(payload)),
      dispatch: (fn) => dispatch(fn),
    };
  }

  const mapStateToProps = createStructuredSelector({
    entities: makeSelectEntities(options.entity),
  });

  let composed = [];

  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  composed = [
    withConnect,
  ];
  if (options.saga && options.sagaKey) {
    const withSaga = injectSaga({ key: options.sagaKey, saga: options.saga });
    composed.push(withSaga);
  }

  return compose(
    ...composed
  )(FactoryEditEntity);
}

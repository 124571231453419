
import {
  call, put, select, takeEvery, all,
} from 'redux-saga/effects';
import { filterCall, filterXlsCall, saveSortOrder } from 'api/index';

import { setEntities } from 'models/actions';

import { filterToModel } from '../../models';
import { apiRequest } from 'containers/App/saga';

import { normalize } from 'normalizr';

import download from 'helpers/download2';
import { makeSelectFilter, makeSelectFilterResponse } from './selectors';
import { setFilterResponse, setFilterStatus } from './actions';
import { SET_FILTER, DOWNLOAD_XLS_FILTER, SAVE_SORT_ORDER } from './constants';

function * makeFilterRequestGen (action) {
  const {
    payload: { resolve, reject, noRequest, append }, model, path,
  } = action;
  try {

    if (noRequest) {
      resolve && resolve();
      return;
    }
    const filter = yield select(makeSelectFilter(action.name));
    const prevResponse = yield select(makeSelectFilterResponse(action.name));
    yield put(setFilterStatus(action.name, { fetching: true, error: false }));

    const { meta, data } = yield call(apiRequest, filterCall, model, filter, path);
    // console.log('thrown')
    // const response = yield call(() => body);
    const normalizedData = normalize(data, [filterToModel[model]]);
    yield put(setEntities(normalizedData));
    yield put(setFilterResponse(action.name, { ids: append ? (prevResponse.ids || []).concat(normalizedData.result) : normalizedData.result, pagination: meta }));
    yield put(setFilterStatus(action.name, { fetching: false, initial: false }));

    resolve && resolve({ ids: normalizedData.result, meta });
  } catch (e) {
    console.log(e)
    yield put(setFilterStatus(action.name, { fetching: false, error: true }));

    reject && reject(e);
  }
}

function * makeFilterXlsGen (action) {
  const filter = yield select(makeSelectFilter(action.name));
  try {
    const file = yield call(apiRequest, filterXlsCall, `${action.model}/serve_xls`, filter);
    download(file, `${action.model}.xlsx`, 'data:application/octet-stream');
  } catch (e) {
    // console.log(e);
    // toastr.error('Экспорт', 'Не удалось скачать файл');
  }
}

function * sortOrderGen (action) {
  const {
    payload: { resolve, reject, data, criteria }, model, path,
  } = action;
  // console.log(action)
  try {
    yield call(apiRequest, saveSortOrder, model, data, criteria, path);
    resolve && resolve();
  } catch (e) {
    console.log(e);
    reject && reject(e);
  }
}

export default function * filtersSaga () {
  yield all([
    takeEvery(SET_FILTER, makeFilterRequestGen),
    takeEvery(DOWNLOAD_XLS_FILTER, makeFilterXlsGen),
    takeEvery(SAVE_SORT_ORDER, sortOrderGen),
  ]);
}

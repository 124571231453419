import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';

import { routerMiddleware } from 'connected-react-router/immutable';
import { isServer } from 'helpers'

import createSagaMiddleware, { END } from 'redux-saga';
import createReducer from './reducers';
// A nice helper to tell us if we're on the server
import { createBrowserHistory, createMemoryHistory } from 'history';

export default (url = '/') => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({
      initialEntries: [url]
    })
    : createBrowserHistory();

  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;


    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const middleware = [sagaMiddleware, routerMiddleware(history)];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }
  // Create the store
  const store = createStore(
    createReducer({}, history),
    fromJS(initialState),
    composedEnhancers
  );


    // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry


  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     store.replaceReducer(createReducer(store.injectedReducers, history));
  //   });
  // }

  store.close = () => store.dispatch(END)

  return { store, history };
};
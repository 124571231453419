/**
*
* GoodView
*
*/

import React from 'react';

import PropTypes from 'prop-types';
import 'css/components/good.sass';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { serverUrl } from '../../config';
import { formatPrice } from 'helpers';
import ReactTooltip from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faStar, faTimes, faInfoCircle, faColumns,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

function GoodView ({
  row, images, setPopup, setPopupNotify, favToggle, favs, compares, dealerId, offers, singleDealerDomain, sendRaw
}) {
  let actualOffer = 0
  if (singleDealerDomain) {
    let offerId = row.offers.filter(offerId => offers[offerId].dealerId === dealerId);
    if (offerId.length) {
      actualOffer = offers[offerId].quantity
    }
  }


  const path = `/accessories/show/${row.productId}`;
  const fav = favs.includes(row.id);
  const comp = compares.includes(row.id);
  const favClasses = classnames('good__fav', { active: fav });
  return (
    <div className="good">
      <div
        className={favClasses}
        onClick={() => {
          sendRaw('Accessory_MiniPage', fav ? 'delete_favourite' : 'add_favourite', row.productId)
          new Promise((resolve, reject) => {
            ReactTooltip.rebuild();
            favToggle({ data: { goodId: row.id }, resolve, reject });
          });
        }}
      >
        <FontAwesomeIcon data-for={`fav-${row.id}`} data-tip={fav ? 'Убрать из избранного' : 'Добавить в избранное'} icon={faStar} />
        <ReactTooltip id={`fav-${row.id}`} />
      </div>
      <Link onClick={() => sendRaw('Accessory_MiniPage', 'open_page', row.productId)} to={path} className="good__image">
        {images && row.images && typeof row.images[0] !== 'object' && images[row.images[0]] &&
          <img alt="" src={serverUrl + images[row.images[0]].imageThumb} />
        }
        {images && row.images && typeof row.images[0] === 'object' && 
          <img alt="" src={serverUrl + row.images[0].imageThumb} />
        }
      </Link>
      <div className="good__content">
        <Link  onClick={() => sendRaw('Accessory_MiniPage', 'open_page', row.productId)} to={path} className="good__article ws-pre">
          {row.originalId}
        </Link>
        <Link  onClick={() => sendRaw('Accessory_MiniPage', 'open_page', row.productId)} to={path} className="good__title">
          {row.name}
        </Link>
        <div className="good__cols">
          <div className="def">
            <div className="def__title">
              Цена
            </div>
            <div className="def__val">
              {formatPrice(row.price, false)}
            </div>
          </div>
          { singleDealerDomain && 
            <div className="def">
              <div className="def__title">
                Наличие
              </div>
              <div className="def__val">
                {actualOffer} шт.
              </div>
            </div>          
          }
        </div>
        <div className="good__compare">
          { comp &&
            (
              <div className="flex flex_between">
              <Link onClick={() => sendRaw('Accessory_MiniPage', 'click_compare', row.productId)} to="/compare" className="hoverable">
                  Переити к сравнению
                </Link>
                <span
                  onClick={() => {
                  sendRaw('Accessory_MiniPage', 'delete_compare', row.productId)
                    new Promise((resolve, reject) => {
                      favToggle({ data: { goodId: row.id, type: 1 }, resolve, reject });
                    });
                  }}
                >
                  <FontAwesomeIcon data-for={`del-${row.id}`} data-tip="Убрать из сравнения" className="hoverable" icon={faTimes} />
                  <ReactTooltip id={`del-${row.id}`} />
                </span>
              </div>
            )
          }
          { !comp &&
            (
              <span
                className="hoverable"
                onClick={() => {
                  sendRaw('Accessory_MiniPage', 'add_compare', row.productId)

                  new Promise((resolve, reject) => {
                    favToggle({ data: { goodId: row.id, type: 1 }, resolve, reject });
                  });
                }}
              >
                <FontAwesomeIcon data-tip="Добавить к сравнению" data-for={`add-${row.id}`} icon={faColumns} />
                <ReactTooltip id={`add-${row.id}`} />
              </span>
            )
          }
        </div>
        <div className="good__bottom">
          { singleDealerDomain && !actualOffer && 
            <span className="good__notify" onClick={() => setPopupNotify({ productId: row.productId })}>
              Узнать
              <br/>
              о поступлении
            </span>
          }
          { !(singleDealerDomain && !actualOffer) && 
            <Button small secondary={false} onClick={() => setPopup({ id: row.id })}>
              Купить
            </Button>
          }          
          <Link onClick={() => sendRaw('Accessory_MiniPage', 'click_more', row.productId)} to={path} className="lnk">
            подробнее
            <FontAwesomeIcon style={{ marginLeft: 4 }} icon={faInfoCircle} />
          </Link>
        </div>
      </div>
    </div>
  );
}

GoodView.propTypes = {
  favs: PropTypes.array.isRequired,
  compares: PropTypes.array.isRequired,
  favToggle: PropTypes.func,
  setPopup: PropTypes.func,
  images: PropTypes.object,
  row: PropTypes.object.isRequired,
};

export default GoodView;

/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import entitiesReducer from 'models/reducer';
import filterReducer from 'components/Filter/reducer';
import globalReducer from 'containers/App/reducer';
import ssoReducer from 'containers/Sso/reducer';
import paymentReducer from 'containers/Payment/reducer';
import deliveryReducer from 'containers/Delivery/reducer';
import bonusReducer from 'containers/BonusPopup/reducer';
import orderCommentsReducer from 'containers/OrderComments/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer (injectedReducers, history) {
  return combineReducers({
    router: connectRouter(history),
    sso: ssoReducer,
    global: globalReducer,
    filters: filterReducer,
    form: formReducer,
    entities: entitiesReducer,
    payment: paymentReducer,
    delivery: deliveryReducer,
    order_comments: orderCommentsReducer,
    bonus: bonusReducer,
    ...injectedReducers,
  });
}

import React from 'react'

import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'

import vars from 'config/styles'

const moveBg = keyframes`
  from {
    transform: translateX(0);
    transform: translateX(46px);
  }
`

export const loadingMixin = css`
  ${({ loading }) => loading && css`
    overflow: hidden;
    pointer-events: none;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: -46px;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      background: repeating-linear-gradient(-55deg, ${vars.lcolor1} 1px, ${vars.lcolor2} 2px, ${vars.lcolor2} 11px, ${vars.lcolor1} 12px, ${vars.lcolor1} 20px);
      animation: ${moveBg} .5s linear infinite;
      opacity: 0.5;
    }
  `}    
`

export const Styles = css`
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  height: 48px;
  padding: 0 24px;
  font-weight: 700;
  display: inline-flex;
  background: ${vars.green};
  cursor: pointer;
  position: relative;
  &:hover {
    background: ${vars.greenH};
    color: #fff;
  }
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-shadow: rgba(75, 168, 46, 0.3) 0px 4px 8px;
  
  ${({ normal }) => normal && `
    width: 245px;
  `}  
  ${({ long }) => long && `
    width: 280px;
  `}  
  ${({ small }) => small && `
    height: 40px;
    font-size: 12px;
  `}
  ${({ xsmall }) => xsmall && `
    height: 36px;
    font-size: 12px;
  `}  
  ${({ disabled }) => disabled && `
    background: ${vars.grey};
    cursor: default;
    box-shadow: none;
    &:hover {
      background: ${vars.grey};
      cursor: default;
      box-shadow: none;
    }
  `}    
  ${({ secondary }) => secondary && `
    background: transparent;
    color: ${vars.darkGrey};
    border: 2px solid ${vars.grey};
    box-shadow: none;
    &:hover {
      border: 2px solid ${vars.darkGrey};
      background: transparent;
      color: ${vars.darkGrey};
    }
  `}    
  ${loadingMixin};
`

export const WrapperDiv = styled.div`${Styles}` 
export const WrapperLink = styled(Link)`${Styles}` 
export const WrapperA = styled.a`${Styles}` 

const Sbutton = (props) => {
  const { children, to, href } = props
  if(to)
    return (
      <WrapperLink {...props}>
        {children}
      </WrapperLink>
    )  

  if(href)
    return (
      <WrapperA {...props}>
        {children}
      </WrapperA>
    )    

  return (
    <WrapperDiv {...props}>
      {children}
    </WrapperDiv>
  )  

}

Sbutton.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
  secondary: PropTypes.bool,
  long: PropTypes.bool,
  normal: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Sbutton.defaultProps = {
  loading: false
};


export default Sbutton
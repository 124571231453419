/*
 *
 * users constants
 *
 */

export const EDIT_USER = 'SkodaParts/users/EDIT_USER';
export const CONFIRM_USER = 'SkodaParts/users/CONFIRM_USER';
export const RESET_PASSWORD = 'SkodaParts/users/RESET_PASSWORD';
export const RESTORE_PASSWORD = 'SkodaParts/users/RESTORE_PASSWORD';
export const GET_CURRENT_USER = 'SkodaParts/users/GET_CURRENT_USER';
export const CHECK_TOKEN = 'SkodaParts/users/CHECK_TOKEN';
export const REGISTER = 'SkodaParts/users/REGISTER';

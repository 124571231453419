import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const Gap = styled.div`
  margin-bottom: 48px;
  @media (max-width: ${vars.bp.tablet}px) {
    margin-bottom: 30px;
  }    
  @media (max-width: ${vars.bp.mobile}px) {
    margin-bottom: 15px;
  }   
`


/**
*
* Messages
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import PopupLayout from 'components/Popup/PopupLayout'
import { makeSelectEntities } from 'models/selectors';
import { makeSelectMessagesShow, makeSelectCurrentUser } from 'containers/App/selectors';
import { createStructuredSelector } from 'reselect';
import { setMessagesShow } from 'containers/App/actions';
import { readMessage } from 'models/messages/actions';

import 'css/components/notification.sass'

class Messages extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props){
    super(props);

    this.containerClick = this.containerClick.bind(this)
  }

  containerClick(e) {
    e.stopPropagation()
  }

  render() {
    const { messages, currentUser, messagesShow} = this.props
    if (!messagesShow || !currentUser) return null;
    const records = messages ? Object.values(messages).filter(r => r.userId === currentUser.id && !r.readAt) : [];
    if(records.length === 0) return null
    return (
      <div>
        <PopupLayout close={() => this.props.setMessagesShow(false)} containerClick={this.containerClick} cls='notif'>
          <h2>Уведомления</h2>
          { records.map((notification) => {
            return (
              <div className='notification' key={notification.id}>
                <div className='notification__title'>
                  {notification.title}
                </div>
                <div className='notification__text' dangerouslySetInnerHTML={{__html: notification.text}} />
                <div className="notification__close" onClick={() => this.props.readMessage(notification.id)}>
                  Скрыть уведомление
                </div>
              </div>
            )
            })
          }
        </PopupLayout>
      </div>
    );
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    setMessagesShow: (show) => dispatch(setMessagesShow(show)),    
    readMessage: (id) => dispatch(readMessage(id)),    
  };
}

const mapStateToProps = createStructuredSelector({
  messages: makeSelectEntities('messages'),
  currentUser: makeSelectCurrentUser(),
  messagesShow: makeSelectMessagesShow(),  
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(
  withConnect,
)(Messages);

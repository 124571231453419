import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { formatPhoneNumber } from 'helpers';

import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/ru';
moment.locale('ru');
import _ from 'lodash';

export const inputField = (field) => {
  const { value, ...rest } = field.input;
  return (
    <div className={`input-group ${field.meta.touched && (field.meta.error || field.meta.submitError) ? 'input-group_error ' : ' '}`}>
      <label htmlFor={field.name}>
        {field.label}
          :
        {' '}
        { field.required && (
          <span>
            *
          </span>
        ) }
      </label>
      <div className={`${field.loading ? 'loading' : ''}`}>
        <input id={field.name} type={field.type} {...rest} value={value || ''} />
      </div>
      { field.meta.touched && (field.meta.error || field.meta.submitError) && (
        <span>
          {(field.meta.error || field.meta.submitError)}
        </span>
      )}
    </div>
  );
};
/* istanbul ignore next */
const handleChange = (handler) => ({ target: { files } }) => handler(files.length ? files[0] : {});

export const fileField = ({
  input: {
    value: omitValue,
    onChange,
    onBlur,
    ...inputProps
  },
  meta: omitMeta,
  ...props
}) => (
  <div className={`input-group ${omitMeta.error ? 'input-group_error ' : ' '}`}>
    <label htmlFor={inputProps.name}>
      {props.label}
:
      {' '}
      { inputProps.required && (
        <span>
*
        </span>
      ) }
    </label>
    <input
      id={inputProps.name}
      onChange={handleChange(onChange)}
      onBlur={handleChange(onBlur)}
      type="file"
      {...inputProps}
      {...props}
    />
    { omitMeta.error && (
      <span>
        {omitMeta.error}
      </span>
    )}
  </div>
);

export const textField = (field) => {
  // console.log(field.input)
  const { value, ...rest } = field.input;

  return (
    <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>
      <label htmlFor={field.name}>
        {field.label}
:
        {' '}
        { field.required && (
          <span>
*
          </span>
        ) }
      </label>
      <textarea id={field.name} type={field.type} value={value || ''} {...rest}>
      </textarea>
      { field.meta.error && (
        <span>
          {field.meta.error}
        </span>
      )}
    </div>
  );
};

export const showField = (field) => {
  const { value, ...rest } = field.input;
  return (
    <div className={`showfield showfield_disabled ${field.meta.error ? ' showfield_error ' : ' '}`}>
      <label htmlFor={field.name}>
        {field.label}
:
        {' '}
        { field.required && (
          <span>
*
          </span>
        ) }
      </label>
      <input id={field.name} type="hidden" {...rest} value={value || ''} />
      <h6>
        {formatPhoneNumber(value)}
      </h6>
      { field.meta.error && (
        <span>
          {field.meta.error}
        </span>
      )}
    </div>
  );
};

export const maskField = (field) => (
  <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>
    <label htmlFor={field.name}>
      {field.label}
:
      {' '}
      { field.required && (
        <span>
*
        </span>
      ) }
    </label>
    <InputMask id={field.name} type={field.type} {...field.input} mask={field.mask} maskChar={field.maskChar} formatChars={{ 9: '[0-9]', t: '[0-9\-]', '?': '[0-9 ]' }} />
    { field.meta.error && (
      <span>
        {field.meta.error}
      </span>
    )}
  </div>
);

export const checkField = (field) => (
  <div>
    { field.meta.error &&
      (
        <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>

          <label htmlFor={field.name}>
            <input id={field.name} type={field.type} {...field.input} />
            {field.label}
            {' '}
            { field.required && (
              <span>
*
              </span>
            ) }
          </label>
          { field.meta.error && (
            <span>
              {field.meta.error}
            </span>
          )}
        </div>
      )
    }
  </div>
);

export class checkBoxField extends React.PureComponent {
  render () {
    const field = this.props;
    return (
      <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>
        <label className='cb' htmlFor={field.input.name}>
          <input id={field.input.name} type="checkbox" checked={field.input.value} {...field.input} />
          <div className='cb__box' />
        </label>
        <label htmlFor={field.input.name}>
          {field.label}
          {' '}
          {this.props.children}
          { field.required && (
            <span>
              *
            </span>
          ) }
        </label>
        { field.meta.error && (
          <span>
            {field.meta.error}
          </span>
        )}
        <br/>
      </div>
    );
  }
}

export class selectField extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  /* istanbul ignore next */
  constructor (props) {
    super(props);
    this.setValue = this.setValue.bind(this);
  }

  /* istanbul ignore next */
  componentDidMount () {
    this.setValue(this.props);
  }

  /* istanbul ignore next */
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!_.isEqual(this.props.options, nextProps.options)) {
      this.setValue(nextProps);
    }
  }

  /* istanbul ignore next */
  setValue (props) {
    const { options, input } = props;
    const values = options.map((o) => o.value.toString());
    const { value } = input;

    if (options.length > 0 && !props.allowBlank && (!value || !values.includes(value.toString()))) {
      props.input.onChange(parseInt(options[0].value, 0));
    }
  }

  /* istanbul ignore next */
  render () {
    const field = this.props;
    const { options } = field;
    if (field.disabled) {
      field.input.disabled = 'disabled';
    }

    // console.log(field.input.value)
    return (
      <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>

        <label htmlFor={field.name}>
          {field.label}
:
          {' '}
          { field.required && (
            <span>
*
            </span>
          ) }
        </label>
        <select id={field.name} name={field.name} {...field.input}>
          { field.allowBlank &&
          <option value="" key="0" />
          }
          {options && options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        { field.meta.error && (
          <span>
            {field.meta.error}
          </span>
        )}
      </div>
    );
  }
}

export const hiddenField = (field) => (
  <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>
    <input id={field.name} type="hidden" {...field.input} />
    { field.meta.error && (
      <span>
        {field.meta.error}
      </span>
    )}
  </div>
);

// export const dateTimePickerField = (field) => {
//   return (
//     <div className={'input-group ' + ( field.meta.error ? 'input-group_error ' : ' ') }>
//       <label htmlFor={field.name}>{field.label}: { field.required && <span>*</span> }</label>
//       <DatePicker id={field.name} {...field.input} value={field.input.value ? moment(field.input.value).format("YYYY-MM-DD HH:mm") : null} dateFormat="YYYY-MM-DD" showTimeSelect={true} timeFormat="HH:mm" timeIntervals={30} selected={field.input.value ? moment(field.input.value) : null} />
//       {field.meta.error && <span>{field.meta.error}</span>}
//     </div>
//   )
// };

export const datePickerField = (field) => (
  <div className={`input-group ${field.meta.error ? 'input-group_error ' : ' '}`}>
    <label htmlFor={field.name}>
      {field.label}
:
      {' '}
      { field.required && (
        <span>
*
        </span>
      ) }
    </label>
    <DatePicker locale="ru" autoComplete="off" id={field.name} {...field.input} minDate={field.minDate && moment(field.minDate, 'DD.MM.YYYY')} onChange={v=> {field.onChange && field.onChange(moment(v).format('YYYY-MM-DD')); field.input.onChange(moment(v).format('YYYY-MM-DD'))}} dateFormat="YYYY-MM-DD" selected={field.input.value ? moment(field.input.value) : null} />
    {field.meta.error && (
      <span>
        {field.meta.error}
      </span>
    )}
  </div>
);

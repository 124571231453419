import {
  AUTH_REQUEST,
  REG_REQUEST,
  GET_TOKEN,
  SET_USER,
  SET_AUTHING,
  SET_CLIENT,
  LOGOUT,
  COMPLETE_SSO_AUTH_CHECK,
  INITIAL,
  FINISH_AUTH,
  SET_SCOPES,
  SET_LOGGING_IN,
} from './constants';

export function authRequest(payload = {}, to) {
  return {
    type: AUTH_REQUEST,
    payload,
    to
  }
}

export function regRequest(payload = {}) {
  return {
    type: REG_REQUEST,
    payload
  }
}

export function setUser(user) {
  return {
    type: SET_USER,
    user
  }
}

export function setClient(client) {
  return {
    type: SET_CLIENT,
    client
  }
}

export function getToken(payload) {
  return {
    type: GET_TOKEN,
    payload
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}

export function completeSsoAuthCheck() {
  return {
    type: COMPLETE_SSO_AUTH_CHECK,
  }
}

export function setAuthing(authing) {
  return {
    type: SET_AUTHING,
    authing,
  }
}

export function initial(payload) {
  return {
    type: INITIAL,
    payload,
  }
}

export function finishAuth() {
  return {
    type: FINISH_AUTH,
  }
}

export function setScopes(scopes) { 
  return {
    type: SET_SCOPES,
    scopes,
  }
}


export function setLoggingIn(loggingIn) {
  return {
    type: SET_LOGGING_IN,
    loggingIn
  }
}

import React from 'react';

import 'css/components/fullscreen.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class FullScreen extends React.PureComponent {
  componentDidMount () {

  }

  render () {
    return (
      <div className="fullscreen">
        <div className="fullscreen__close" onClick={this.props.close}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default FullScreen;

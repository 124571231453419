/*
  Admin Goods Filters
*/

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { change as formChange, formValueSelector } from 'redux-form/immutable';
import FiltersHoc from 'components/FiltersHoc';
import { Field } from 'redux-form/immutable';
import RFReactSelect from 'components/Form/components/RFReactSelect';
import { datePickerField, checkBoxField } from 'components/Form/components';
import QuarterChooser from 'components/QuarterChooser'




import Row from 'components/Row'
import Col from 'components/Col'

import { ORDER_STATUSES_TRANSLATION } from 'settings';

function Cb ({ name, checked, onChange }) {
  return (
    <div className="input-group" style={{width: '100%', marginBottom: 15}}>
      <label className="cb" for={name}>
        <input id={name} checked={checked} onChange={onChange} type="checkbox" name={name} />
        <div className="cb__box"></div>
      </label>
      <label for={name}>Произвольный промежуток</label>
    </div>  
  )
}


class Filters extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: false }
  }



  render () {
    const { dealers, setForm, dateStart, dateEnd, year, quarter } = this.props;
    const { expanded } = this.state
    const statusOptions = Object.keys(ORDER_STATUSES_TRANSLATION).map((x) => ({ value: x, label: ORDER_STATUSES_TRANSLATION[x] }));
    const dealersOptions = dealers ? Object.values(dealers).map((x) => ({ value: x.id, label: x.name })) : [];
    return (
      <React.Fragment>
        <Row>
          <Col n={12}>
            <Cb checked={expanded} onChange={() => this.setState({ expanded: !expanded })} name='expanded-detailed' />
            {!expanded &&
              <>
                <QuarterChooser 
                  initialYear={year}
                  initialQuarter={quarter}
                  dateStart={dateStart} 
                  dateEnd={dateEnd}
                  setStart={(v) => setForm('from', v)} 
                  setEnd={(v) => setForm('to', v)}
                  />
              </>
            }
            <div style={{display: !expanded ? 'none' : 'block'}}>
              <Field name="from" label="с" autocomplete="off" component={datePickerField} type="text" />
              <Field name="to" label="по" autocomplete="off" component={datePickerField} type="text" />
            </div>            
          </Col>

        </Row>
      </React.Fragment>
    );
  }
}

const formName = 'admin-stats2-filters-detailed'

const mapStateToProps = createStructuredSelector({
  // params: paramsSelector()
  dateStart: (state) => formValueSelector(formName)(state, 'from'),
  dateEnd: (state) => formValueSelector(formName)(state, 'to')
});

function mapDispatchToProps (dispatch) {
  return {
    setForm: (name, value) => dispatch(formChange(formName, name, value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withFilters = FiltersHoc({ name: formName })

export default compose(
  withFilters,
  withConnect,
)(Filters);


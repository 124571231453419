/**
*
* DealerOrder
*
*/

import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

import 'css/components/table.sass';
import { formatPrice } from 'helpers';
import DealerOrderRow from './DealerOrderRow';
import DealerOrderRowShow from './DealerOrderRowShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'components/Row'
import Col from 'components/Col'
import OrderComment from 'components/OrderComment'
import { deliveryTypes, payTypes } from 'settings'

import Box, { Inner } from 'components/Box'
import Card from 'components/Card'
import CardContainer from 'components/CardContainer'
import DealerInfoBox from 'components/DealerInfoBox'
import OrderItemsCheckout from 'components/OrderItems/Checkout'
import Radio from 'components/Radio'
import { DELIVERY_FORM } from 'components/Popup/constants'
import DeliveryInfo from 'components/DeliveryInfo'
import Loader from 'components/Loader'
import Button from 'components/Button'


import { H7, H4, A, Gapper, Flex, Text2 } from 'components/Ui'

const Card1 = styled(Card)`
  order: 0;
  @media (max-width: ${vars.bp.tablet}px) {
    order: 1;
  }    
`

const Card2 = styled(Card)`
  order: 1;
  @media (max-width: ${vars.bp.tablet}px) {
    order: 0;
  }    
`


export class DealerOrderChechout extends React.Component { // eslint-disable-line react/prefer-stateless-function
  componentDidMount () {

  }

  render () {
    const {
      dealer, items, products, checkout = false, payable = false, deliverable = false, index, moditem, errors,
      setPreorderItem, preorderItems, user, removeBonus, setPopup, delivery, deliveryLoading, sendRaw
    } = this.props;
    const payType = preorderItems.payType || payTypes.ON_PICK
    const comment = preorderItems.comment || ''
    const address = preorderItems.address || ''
    const deliveryType = preorderItems.deliveryType || 0
    const deliveryData = (delivery || {}).data || {}
    const dimensionsPresent = !items.find((item) => !item.dimension )
    const { cashOnDelivery, freeCityDelivery, freeCityDeliveryThreshold } = dealer
    const itemsPrice = items.reduce((acc, item) => (acc + item.price * item.quantity), 0)
    const modAmount = moditem ? Math.min(moditem.amount, itemsPrice / 2) : 0
    const totalPrice = itemsPrice - modAmount
    return (
      <CardContainer>
        <Card1 d={8} t={12}>
          <OrderItemsCheckout 
            payType={payType} 
            deliveryType={deliveryType}
            payable={payable} 
            items={items} 
            index={index} 
            moditem={moditem} 
            delivery={delivery}
            deliveryLoading={deliveryLoading}
            errors={errors} 
            removeBonus={removeBonus}
          />
          
          <Row>
            <Col n={5} md={12}>
              <Box>
                <Inner>
                  <Gapper gap={24}>
                    <H7>Оплата</H7>
                    <Gapper gap={12}>
                      <Text2 style={{marginRight: 40}}>
                        <Radio checked={payType === payTypes.ON_PICK} disabled={deliveryLoading} onClick={() => {
                          sendRaw('Purchase_RequestForm', 'payment_type', 'offline')
                          setPreorderItem('payType', payTypes.ON_PICK);
                          if (!cashOnDelivery) {
                            setPreorderItem('deliveryType', 0);
                          }
                        }}>
                          <span style={{fontWeight: 'normal'}}>Оплата при получении</span>
                        </Radio>
                      </Text2>
                      <Text2>
                        <Radio checked={payType === payTypes.ONLINE} disabled={deliveryLoading && !user || !payable} 
                          onClick={() => {
                            sendRaw('Purchase_RequestForm', 'payment_type', 'online')
                            setPreorderItem('payType', payTypes.ONLINE)
                          }}>
                          <span style={{fontWeight: 'normal'}}>Оплата онлайн</span>
                          <br/>
                          {!payable && <>Оплата онлайн недоступна для дилера</>}
                          {payable && !user && <>Для возможности оплаты онлайн необходимо войти</>}
                        </Radio>
                      </Text2>
                    </Gapper>
                  </Gapper>
                </Inner>
              </Box>
            </Col>
            <Col n={7} md={12}>
              <Box>
                <Inner>
                  <Gapper gap={24}>
                    <H7>Получение заказа</H7>
                    <Gapper gap={12}>
                      <Text2 style={{paddingRight: 40, flex: '50%'}}>
                        <Radio checked={deliveryType === deliveryTypes.PICK} disabled={deliveryLoading} onClick={() => 
                          {
                            sendRaw('Purchase_RequestForm', 'delivery', 'no')
                            setPreorderItem('deliveryType', deliveryTypes.PICK)
                          }}>
                          <span style={{fontWeight: 'normal'}}>Самовывоз:</span>
                          <br/>
                          {dealer.address}
                        </Radio>
                      </Text2>

                      {(!freeCityDelivery && !deliverable) &&
                        <Text2 style={{paddingRight: 40, flex: '50%'}}>
                          <Radio disabled>
                            <span style={{fontWeight: 'normal'}}>Доставка:</span>
                            <br/>
                            Дилер не осуществляет доставку
                          </Radio>
                        </Text2>                    
                      }

                      {deliverable && payable && payType !== payTypes.ONLINE && !cashOnDelivery &&
                        <Text2>
                          <Radio disabled>
                            <span style={{fontWeight: 'normal'}}>Доставка:</span>
                            <br/>
                            Доставка доступна только при оплате онлайн.
                          </Radio>
                        </Text2>                    
                      }

                      {deliverable && !user &&
                        <Text2 style={{paddingRight: 40, flex: '50%'}}>
                          <Radio disabled>
                            <span style={{fontWeight: 'normal'}}>Доставка:</span>
                            <br/>
                            Доставка доступна только для зарегистрированных пользователей.
                          </Radio>                      
                        </Text2>
                      }

                      {deliverable && user && ((!cashOnDelivery && payType === payTypes.ONLINE) || cashOnDelivery) &&
                        <Text2 style={{paddingRight: 40, flex: '50%'}}>
                        <Radio checked={deliveryType === deliveryTypes.DELIVERY} disabled={deliveryLoading} onClick={() => {
                          sendRaw('Purchase_RequestForm', 'delivery', 'yes')
                          setPreorderItem('deliveryType', deliveryTypes.DELIVERY)
                        }}>
                          <span style={{ fontWeight: 'normal' }}>Доставка:{!dimensionsPresent && <span style={{ color: '#eb5757' }}>*</span>} {deliveryLoading && <Loader />}</span>
                            {deliveryType === deliveryTypes.DELIVERY &&
                              <Button 
                                small
                                onClick={() => setPopup(DELIVERY_FORM, { paid: payType === payTypes.ONLINE, dealerId: dealer.id, dealerCode: dealer.kod, delivery: deliveryData, dimensionsPresent })}
                                style={{ marginTop: 10 }}
                              >
                                Параметры доставки
                              </Button>
                            }
                            <DeliveryInfo noLogo delivery={delivery} />
                            {!dimensionsPresent && <div><span style={{color: '#eb5757'}}>*</span>Стоимость доставки для данного заказа будет рассчитана после проверки заказа дилером </div>}
                          </Radio>
                        </Text2>                    
                      }

                      {freeCityDelivery && totalPrice >= freeCityDeliveryThreshold && payType === payTypes.ONLINE &&
                        <Text2>
                          <Radio checked={deliveryType === deliveryTypes.CITY_DELIVERY} disabled={deliveryLoading} onClick={() => {
                            sendRaw('Purchase_RequestForm', 'delivery', 'yes')
                            setPreorderItem('deliveryType', deliveryTypes.CITY_DELIVERY)
                          }}>
                            <span style={{ fontWeight: 'normal' }}>Доставка по городу БЕСПЛАТНО:</span>
                            <br />
                            на заказы свыше {freeCityDeliveryThreshold} руб.
                          </Radio>
                          {deliveryType === deliveryTypes.CITY_DELIVERY &&
                            <OrderComment value={address} placeholder='Укажите адрес доставки' setValue={(val) => setPreorderItem('address', val)} />
                          }
                        </Text2>
                      }

                      {freeCityDelivery && (totalPrice < freeCityDeliveryThreshold || payType !== payTypes.ONLINE) &&
                        <Radio disabled>
                          <Text2 grey opacity>
                            <span style={{ fontWeight: 'normal' }}>Доставка по городу БЕСПЛАТНО:</span>
                            <br />
                            на заказы свыше {freeCityDeliveryThreshold} руб при оплате онлайн.
                          </Text2>
                        </Radio>
                      }
                      
                    </Gapper>
                  </Gapper>
                </Inner>
              </Box>
            </Col>
          </Row>
        </Card1>
        <Card2 d={4} t={12}>
          <DealerInfoBox dealer={dealer} />
          <OrderComment value={comment} setValue={(val) => { sendRaw('Purchase_RequestForm', 'input_comment', val); setPreorderItem('comment', val)}}/>
        </Card2>        
      </CardContainer>
    )

  }
}

DealerOrderChechout.propTypes = {

};

export default DealerOrderChechout;

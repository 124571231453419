/**
*
* Popup
*
* Список всех возможных попапов
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import GoodOffers from 'components/GoodOffers/Loadable';
import ProductOffers from 'components/ProductOffers';
import EditClient from 'components/EditClient/Loadable';
import EditUser from 'components/EditUser/Loadable';
import EditGood from 'components/EditGood/Loadable';
import EditDealerGood from 'components/EditDealerGood/Loadable';
import EditModel from 'components/EditModel/Loadable';
import EditCategory from 'components/EditCategory/Loadable';
import EditDealer from 'components/EditDealer/Loadable';
import EditSetting from 'components/EditSetting/Loadable';
import EditOrder from 'components/EditOrder/Loadable';
import EditDealerOrder from 'components/EditDealerOrder/Loadable';
import EditImporterOrder from 'components/EditImporterOrder/Loadable';
import EditDomain from 'components/EditDomain/Loadable';
import EditRegion from 'components/EditRegion/Loadable';
import EditCity from 'components/EditCity/Loadable';
import EditUpdate from 'components/EditUpdate/Loadable';
import EditManager from 'components/EditManager/Loadable';
import ShowOrder from 'components/ShowOrder/Loadable';
import ShowDealerOrder from 'components/ShowDealerOrder/Loadable';
import ShowOrderPayment from 'components/ShowOrderPayment/Loadable';
import LogsOrder from 'components/LogsOrder/Loadable';
import LogsGood from 'components/LogsGood/Loadable';
import LogsDealer from 'components/LogsDealer/Loadable';
import RestorePassword from 'components/RestorePassword/Loadable';
import CreateFeedback from 'components/CreateFeedback/Loadable';
import AdminOrders from 'containers/AdminOrders/';
import AdminDealers from 'containers/AdminDealers/';
import AdminOffers from 'containers/AdminOffers/';
import NotifyStock from 'components/NotifyStock/';
import EditNotification from 'components/EditNotification/';
import EditPodbor from 'components/EditPodbor/';
import Agreement2 from 'components/Agreement2/';
import UserAgreement from 'components/UserAgreement/';
import DealerOrdersStats from 'components/DealerOrdersStats'
import BonusPopup from 'containers/BonusPopup'
import DeliveryForm from 'containers/DeliveryForm'
import EditReturn from 'components/EditReturn'
import Podbor from 'components/Podbor'

import PopupLayout from './PopupLayout';

import * as popupConstants from './constants';

class Popup extends React.PureComponent {
  constructor (props) {
    super(props);

    this.containerClick = this.containerClick.bind(this);

    this.popups = {
      [popupConstants.GOOD_OFFERS]: GoodOffers,
      [popupConstants.PRODUCT_OFFERS]: ProductOffers,
      [popupConstants.EDIT_CLIENT]: EditClient,
      [popupConstants.EDIT_USER]: EditUser,
      [popupConstants.EDIT_GOOD]: EditGood,
      [popupConstants.EDIT_DEALER_GOOD]: EditDealerGood,
      [popupConstants.EDIT_MODEL]: EditModel,
      [popupConstants.EDIT_CATEGORY]: EditCategory,
      [popupConstants.EDIT_DEALER]: EditDealer,
      [popupConstants.EDIT_ORDER]: EditOrder,
      [popupConstants.EDIT_IMPORTER_ORDER]: EditImporterOrder, 
      [popupConstants.EDIT_DEALER_ORDER]: EditDealerOrder,
      [popupConstants.EDIT_DOMAIN]: EditDomain,
      [popupConstants.EDIT_REGION]: EditRegion,
      [popupConstants.EDIT_CITY]: EditCity,
      [popupConstants.EDIT_UPDATE]: EditUpdate,
      [popupConstants.EDIT_MANAGER]: EditManager,
      [popupConstants.SHOW_ORDER]: ShowOrder,
      [popupConstants.SHOW_DEALER_ORDER]: ShowDealerOrder,
      [popupConstants.SHOW_ORDER_LOGS]: LogsOrder,
      [popupConstants.SHOW_GOOD_LOGS]: LogsGood,
      [popupConstants.SHOW_DEALER_LOGS]: LogsDealer,
      [popupConstants.RESTORE_PASSWORD]: RestorePassword,
      [popupConstants.CREATE_FEEDBACK]: CreateFeedback,
      [popupConstants.SHOW_ORDERS]: AdminOrders,
      [popupConstants.SHOW_DEALERS]: AdminDealers,
      [popupConstants.SHOW_OFFERS]: AdminOffers,
      [popupConstants.NOTIFY_STOCK]: NotifyStock,
      [popupConstants.EDIT_NOTIFICATION]: EditNotification,
      [popupConstants.EDIT_PODBOR]: EditPodbor,
      [popupConstants.USER_AGREEMENT]: UserAgreement,
      [popupConstants.AGREEMENT2]: Agreement2,
      [popupConstants.SHOW_ORDER_PAYMENT]: ShowOrderPayment,
      [popupConstants.DEALER_ORDERS_STATS]: DealerOrdersStats,
      [popupConstants.BONUS_POPUP]: BonusPopup,
      [popupConstants.DELIVERY_FORM]: DeliveryForm,
      [popupConstants.EDIT_RETURN]: EditReturn,
      [popupConstants.SET_PODBOR]: Podbor,
      [popupConstants.EDIT_SETTING]: EditSetting,
    };
  }

  containerClick (e) {
    e.stopPropagation();
  }

  render () {
    const { popup, popupInfo } = this.props;
    return (
      <React.Fragment>
        {popup.map((one, i) => {
          const PopupComponent = this.popups[one];
          return (
            <PopupLayout key={one} cls={one} close={this.props.close} containerClick={this.containerClick}>
              <PopupComponent {...popupInfo[i]} />
            </PopupLayout>
          );
        })}
      </React.Fragment>
    );
  }
}

Popup.propTypes = {
  popup: PropTypes.array,
  // popupInfo: React.PropTypes.array,
  close: PropTypes.func,

};

// Wrap the component to inject dispatch and state into it
export default Popup;

/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getDomainInfo () {
  return apicall('domains/getDomainInfo');
}

export function createDomain (data) {
  return apicall('domains', { method: 'POST', body: JSON.stringify({ domain: data }) });
}

export function updateDomain (data) {
  const { id, ...rest } = data;
  return apicall(`domains/${id}`, { method: 'PATCH', body: JSON.stringify({ domain: rest }) });
}

export function deleteDomain (id) {
  return apicall(`domains/${id}`, { method: 'DELETE' });
}

export function domainRequest (data) {
  return apicall('domains/request', { method: 'POST', body: JSON.stringify({ domain: data }) });
}

import React, { useCallback } from 'react'
import styled from 'styled-components'

import  Field  from 'helpers/Field'
import { inputField, suggestionField } from 'components/Form/components'
import vars from 'config/styles'
import { H7 } from 'components/Ui'
import AutosuggestField from 'components/Form/components/AutosuggestField';
import { suggestStreet } from 'api/address'
import { debounce } from 'helpers'


const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    flex-wrap: wrap;
  }
`

const LeftBlock = styled.div`
  flex-grow: 1;
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
  }
`

const RightBlock = styled.div`
  flex-grow: 0;
  display: flex;
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
  }  
`

const HouseBlock = styled.div`
  width: 90px;
  margin-left: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    margin-left: 0;
    width: auto;
    flex: 1;
  }
`

const KBlock = styled.div`
  width: 60px;
  margin-left: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    width: auto;
    flex: 1;
  }
`

const FlatBlock = styled.div`
  width: 100px;
  margin-left: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    width: auto;
    flex: 1;
  }
`

const ZipBlock = styled.div`
  width: 170px;
  margin-top: 10px;
`

const AddressBlock = ({ withZip, cityId }) => {

  const handleQuery = useCallback(async (query) => {
    try {
      return new Promise((resolve, reject) => {
        debounce(async () => {
          const res = await (await suggestStreet(cityId, query))()
          resolve(res.map(row => row.data.street))
        }, 300)()
      })
    } catch (e) {
      
      return []
    }
  }, [cityId])


  return (
    <>
      <H7>Адрес доставки</H7>
      <Wrapper>
        <LeftBlock>
          <Field required handleQuery={handleQuery} name="street" component={AutosuggestField} label="Улица" />
        </LeftBlock>
        <RightBlock>
          <HouseBlock>
            <Field required type="text" name="house" component={inputField} label="Дом" />
          </HouseBlock>
          <KBlock>
            <Field type="text" name="korpus" component={inputField} label="Корпус" />
          </KBlock>
          <FlatBlock>
            <Field type="text" name="flat" component={inputField} label="Квартира" />
          </FlatBlock>

        </RightBlock>
      </Wrapper>
      {withZip && 
        <ZipBlock>
          <Field required type="text" name="zip" component={inputField} label="Почтовый индекс" />
        </ZipBlock>
      }
    </>
  )
}

export default AddressBlock
import { all, take, call, put, fork, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from 'api/delivery'

import { actionTypes } from './actions';
// import {  } from './selectors';
import { isServer } from 'helpers'
import { setEntities, replaceEntities } from 'models/actions';
import { setCalc, setProcessing, setCalcError, setApplying } from './actions'
import { normalize } from 'normalizr';
import { ensureSsoAuthCheckIsComplete } from 'containers/Sso/saga';

import { Deliverable, Order, Cart } from '../../models';

import { notify, serialize, formatErrors } from 'helpers';

export function* getDeliveryStatusGen(action) {
  try {
    yield call(ensureSsoAuthCheckIsComplete);
    const deliverables = yield call(api.getDeliveryStatus())
    const normalized = normalize(deliverables, [Deliverable]);
    yield put(setEntities(normalized));
  } catch(e) {
    // console.log(e)
  }
}


export function * getAddressesGen(action) {
  const { payload: { resolve, reject } } = action
  try {
    // console.log(action)
    const result = yield call(api.getAddresses())
    resolve && resolve(result)
  } catch(e) {
    notify('Адрес', 'Не удалось загрузить список адресов', 'error')
    reject && reject()
  }
}

export function * createAddressGen(action) {
  const { payload: { address, resolve, reject } } = action
  try {
    // console.log(action)
    yield call(api.createAddress(address))
    const result = yield call(api.getAddresses())
    resolve && resolve(result)    
  } catch(e) {
    notify('Адрес', 'Не удалось создать адрес', 'error')
    reject && reject()
  }
}

export function * editAddressGen(action) {
  const { payload: { id, address, resolve, reject } } = action
  try {
    // console.log(action)
    yield call(api.editAddress(id, address))
    const result = yield call(api.getAddresses())
    resolve && resolve(result)    
  } catch(e) {
    notify('Адрес', 'Не удалось отредактировать адрес', 'error')
    reject && reject()
  }
}

export function * deleteAddressGen(action) {
  const { payload: { address, resolve, reject } } = action
  try {
    // console.log(action)
    yield call(api.deleteAddress(id))
    const result = yield call(api.getAddresses())
    resolve && resolve(result)    
  } catch(e) {
    notify('Адрес', 'Не удалось удалить адрес', 'error')
    reject && reject()
  }
}

export function * getListGen(action) {
  const { payload: { dealerCode, path, params, resolve, reject } } = action
  try {
    // console.log(action)
    const result = yield call(api.getList(dealerCode, path, serialize(params)))
    resolve && resolve(result)    
  } catch(e) {
    // notify('Адрес', 'Не удалось удалить адрес', 'error')
    reject && reject()
  }
}

export function * calcGen(action) {
  const { payload } = action
  console.log(action)
  yield put(setProcessing(true))
  yield put(setCalcError(false))
  const { dealerCode, resolve = () => {}, reject = () => {}, ...data } = payload
  console.log({payload})
  try {
    // console.log(action)
    const result = yield call(api.calc(dealerCode, data))
    yield put(setCalc(result.data))
    yield put(setProcessing(false))
    resolve()
  } catch(e) {
    console.log(e)
    yield put(setCalcError(true))
    yield put(setProcessing(false))
    reject()
  }
}


export function * applyDeliveryGen(action) {
  const { payload } = action
  console.log(action)
  const { dealerId, data, resolve, reject } = payload
  try {
    // console.log(action)
    yield put(setApplying(true))
    const result = yield call(api.applyDelivery(dealerId, data))
    const normalizedCart = normalize(result, Cart);
    yield put(replaceEntities(normalizedCart, 'carts'));
    yield put(replaceEntities(normalizedCart, 'items'));    
    // yield put(setCalc(result.data))
    // yield put(setProcessing(false))
    yield put(setApplying(false))
    resolve()
  } catch(e) {
    console.log(e)
    yield put(setApplying(false))
    reject()
    // yield put(setCalcError(true))
    // yield put(setProcessing(false))
  }
}

export function * editDeliveryGen(action) {
  const { payload } = action
  const { id, data, resolve = () => {}, reject = () => {} } = payload
  try {
    // console.log(action)
    const result = yield call(api.editDelivery(id, data))
    const normalizedOrder = normalize(result, Order);
    yield put(setEntities(normalizedOrder));    
    resolve()
  } catch(e) {
    console.log(e)
    reject()
  }
}

export function * refreshDeliveryGen(action) {
  const { payload } = action
  const { dealerId, paid, ...data } = payload
  try {
    // console.log(action)
    yield put(setApplying(true))
    const result = yield call(api.refreshDelivery(dealerId, { paid }))
    const normalizedCart = normalize(result, Cart);
    yield put(replaceEntities(normalizedCart, 'carts'));
    yield put(replaceEntities(normalizedCart, 'items')); 
    // yield put(setCalc(result.data))
    // yield put(setProcessing(false))
    yield put(setApplying(false))
  } catch(e) {
    console.log(e)
    // yield put(setCalcError(true))
    // yield put(setProcessing(false))
    yield put(setApplying(false))
  }
}


export function * getOrderDeliveryStatusGen(action) {
  const { payload } = action
  const { id, resolve, reject } = payload
  try {
    // console.log(action)
    const result = yield call(api.getOrderDeliveryStatus(id))
    const normalizedOrder = normalize(result, Order);
    yield put(replaceEntities(normalizedOrder, 'orders')); 
    resolve()
  } catch(e) {
    console.log(e)
    reject()
  }
}

export function * confirmDeliveryGen(action) {
  const { payload } = action
  const { id, resolve, reject } = payload
  try {
    // console.log(action)
    const result = yield call(api.confirmDelivery(id))
    const normalizedOrder = normalize(result, Order);
    yield put(replaceEntities(normalizedOrder, 'orders')); 
    yield getOrderDeliveryStatusGen({ payload: { id, resolve: () => { } } })
    resolve()
  } catch(e) {
    reject(formatErrors(e)._error)
    yield getOrderDeliveryStatusGen({ payload: { id, resolve: () => {} } })
  }
}

export function * acceptDeliveryGen(action) {
  const { payload } = action
  const { id, resolve = () => {}, reject = () => {} } = payload
  try {
    // console.log(action)
    const result = yield call(api.acceptDelivery(id))
    const normalizedOrder = normalize(result, Order);
    yield put(replaceEntities(normalizedOrder, 'orders')); 
    resolve()
  } catch(e) {
    reject(formatErrors(e)._error)
  }
}



export default function * paymentSaga () {
  yield all([
    yield takeEvery(actionTypes.GET_DELIVERY_STATUS, getDeliveryStatusGen),
    yield takeEvery(actionTypes.GET_ORDER_DELIVERY_STATUS, getOrderDeliveryStatusGen),
    yield takeEvery(actionTypes.GET_ADDRESSES, getAddressesGen),
    yield takeEvery(actionTypes.CREATE_ADDRESS, createAddressGen),
    yield takeEvery(actionTypes.EDIT_ADDRESS, editAddressGen),
    yield takeEvery(actionTypes.DELETE_ADDRESS, deleteAddressGen),
    yield takeEvery(actionTypes.GET_LIST, getListGen),
    yield takeEvery(actionTypes.CALC, calcGen),
    yield takeEvery(actionTypes.APPLY_DELIVERY, applyDeliveryGen),
    yield takeEvery(actionTypes.REFRESH_DELIVERY, refreshDeliveryGen), 
    yield takeEvery(actionTypes.CONFIRM_DELIVERY, confirmDeliveryGen),
    yield takeEvery(actionTypes.ACCEPT_DELIVERY, acceptDeliveryGen),
    yield takeEvery(actionTypes.EDIT_DELIVERY, editDeliveryGen),
  ])
}

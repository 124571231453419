import moment from 'moment';
// moment.locale('ru')
import { ORDER_STATUSES, ROLES } from 'settings';
import { findDOMNode } from 'react-dom';

export const filterUrl = (resourse) => `${resourse}/filter`;

export const _localStorage =
  typeof localStorage !== 'undefined' && localStorage
    ? localStorage
    : {
        _data: {},
        setItem: (id, val) => {
          _localStorage['_data'][id] = String(val);
        },
        getItem: (id) => {
          return _localStorage['_data'].hasOwnProperty(id) ? _localStorage['_data'][id] : undefined;
        },
        removeItem: (id) => {
          delete _localStorage['_data'][id];
        },
        clear: () => {
          _localStorage['_data'] = {};
        }
      };



export /* istanbul ignore next */ const formatErrors = (e, errors = {}) => {
  let ret = {};
  if (e && e.response && e.response.errors && e.response.status !== 400) {
    const errs = Object.keys(e.response.errors).reduce((previous, current) => {
      const modPrev = previous;
      if (Array.isArray(e.response.errors[current])) {
        modPrev[current] = e.response.errors[current].join(', ');
      } else {
        modPrev[current] = e.response.errors[current];
      }
      return modPrev;
    }, {});

    ret = ({
      ...errs,
      ...errors,
    });
  }
  return ret;
};

// Возвращает сумму и количество позиций в корзине
export const orderData = (items, cartId, moditems = [], preorderData = {}, deliveries = {}) => {
  if (!cartId) { return { count: 0, price: 0 }; }
  const cartItems = items ? Object.values(items).filter((one) => one.cartId == cartId) : [];
  const count = cartItems.length ? cartItems.reduce((sum, one) => sum + one.quantity, 0) : 0;
  const price = cartItems.length ? cartItems.reduce((sum, one) => sum + one.quantity * one.price, 0).toFixed(2) : 0;
  const dealerItems = itemsByDealers(items, cartId)
  let deliveryPrice = 0.0
  const modprice = Object.keys(dealerItems).reduce((acc, dealerId) => {
    // console.log({preorderData})
    const items = dealerItems[dealerId]
    const dimensionsPresent = !items.find((item) => !item.dimension )
    if(deliveries[dealerId] && preorderData[dealerId] && preorderData[dealerId].deliveryType === 1) {
      deliveryPrice += dimensionsPresent ? (+ parseFloat(deliveries[dealerId].price).toFixed(2) || 0.0) : 0
    }

    if(!moditems[dealerId] || !moditems[dealerId].amount) //  || !preorderData[dealerId] || preorderData[dealerId].payType !== 1
      return acc
    const curItemsPrice = dealerItems[dealerId].reduce((acc, cur) => acc + cur.quantity * cur.price,0)
    const currentmod = Math.min(curItemsPrice / 2, moditems[dealerId].amount)
    return acc + currentmod
  }, 0)
  const itemsPrice = +parseFloat(price).toFixed(2)
  return { count, itemsPrice: +itemsPrice, modprice, deliveryPrice, price: itemsPrice + deliveryPrice - modprice };
};

export const itemsByDealers = (items, cartId, products = {}) => {
  const result = {};
  const cartItems = items ? Object.values(items).filter((one) => one.cartId == cartId).map(item => ({...item, product: products && products[item.productId]}) ) : [];
  cartItems.forEach((item) => {
    if (!result.hasOwnProperty(item.dealerId)) {
      result[item.dealerId] = [item];
    } else {
      result[item.dealerId].push(item);
    }
  });

  return result;
};

export const cartPresent = (cartId, carts) => {
  const cart = cartId && carts && carts[cartId] && carts[cartId].items && carts[cartId].items.length;
  return cart;
};

export /* istanbul ignore next */ const delay = (() => {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export const formatPrice = (x, pointed = true) => {
  if (!x) return '';
  try {
    const parts = parseFloat(x).toFixed(pointed ? 2 : 0).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${parts.join(',')} руб.`;
  } catch (e) {
    return '';
  }
};

export const formatPrice2 = (x) => {
  if (!x) return '';
  try {
    let copecks = x % 100
    copecks = copecks < 9 ? `0${copecks}` : copecks
    let rubs = `${Math.floor(x/100)}`
    rubs = rubs.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${rubs},${copecks} руб.`;
  } catch (e) {
    console.log(e)
    return '';
  }
};


export const formatDate = (date) => date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : date;

export const mergeDealerGood = ({ row, dealerGoods, dealerId }) => {
  if (!row.dealerGoods || !row.dealerGoods.length) return row;
  const dealerGood = row.dealerGoods.map((id) => dealerGoods[id]).filter((one) => one.dealerId == dealerId)[0];
  const good = dealerGood ? { ...row, ...dealerGood, id: row.id } : row;
  return good;
};

export const hasDealerGood = (row) => {
  if (row.dealerGoods && row.dealerGoods.length) return true;
};

export const swap = (json) => {
  const ret = {};
  for (const key in json) {
    ret[json[key]] = key;
  }
  return ret;
};

export /* istanbul ignore next */ const orderStatusToBadgeClass = (status) => {
  switch (status) {
    case ORDER_STATUSES.CURRENT:
      return 'badge_primary';
      break;
    case ORDER_STATUSES.ACTIVE:
      return 'badge_';
      break;
    case ORDER_STATUSES.FINISHED:
      return 'badge_grey';
      break;
    case ORDER_STATUSES.CANCELLED:
      return 'badge_palegrey';
      break;
    default:
      return '';
      break;
  }
};


export /* istanbul ignore next */ const orderStatusToBadgeClass2 = (status) => {
  switch (status) {
    case ORDER_STATUSES.CURRENT:
      return 'badge_red';
      break;
    case ORDER_STATUSES.ACTIVE:
      return 'badge_yellow';
      break;
    case ORDER_STATUSES.FINISHED:
      return 'badge_green';
      break;
    case ORDER_STATUSES.CANCELLED:
      return 'badge_palegrey';
      break;
    default:
      return '';
      break;
  }
};


export /* istanbul ignore next */ const paymentStatusToBadgeClass = (status) => {
  switch (status) {
    case 0:
    case 6:
      return 'badge_primary';
      break;
    case 1:
    case 2:
      return 'badge_';
      break;
    case 3:
    case 4:
      return 'badge_palegrey';
      return 'badge_grey';
      break;
  }
};


export /* istanbul ignore next */ const paymentStatusToBadgeClassDealer = (status) => {
  switch (status) {
    case 0:
    case 6:
      return 'badge_palegrey';
      break;
    case 1:
      return 'badge_red';
      break;    
    case 2:
      return 'badge_';
      break;
    case 3:
    case 4:
      return 'badge_palegrey';
      return 'badge_grey';
      break;
    case 5:
      return 'badge_yellow'
      break;
  }
};


export /* istanbul ignore next */ const userRoleToBadgeClass = (status) => {
  switch (status) {
    case ROLES.USER:
      return '';
      break;
    case ROLES.DEALER:
      return 'badge_primary';
      break;
    case ROLES.IMPORTER:
      return 'badge_deep';
      break;
    case ROLES.ADMIN:
      return 'badge_deep';
      break;
    default:
      return '';
      break;
  }
};

export /* istanbul ignore next */ function shadeBlendConvert(p, from, to) {
  if (typeof (p) !== 'number' || p < -1 || p > 1 || typeof (from) !== 'string' || (from[0] != 'r' && from[0] != '#') || (typeof (to) !== 'string' && typeof (to) !== 'undefined')) return null; // ErrorCheck
  const sbcRip = (d) => {
    let l = d.length,
      RGB = new Object();
    if (l > 9) {
      d = d.split(',');
      if (d.length < 3 || d.length > 4) return null;// ErrorCheck
      RGB[0] = i(d[0].slice(4)), RGB[1] = i(d[1]), RGB[2] = i(d[2]), RGB[3] = d[3] ? parseFloat(d[3]) : -1;
    } else {
      if (l == 8 || l == 6 || l < 4) return null; // ErrorCheck
      if (l < 6)d = `#${d[1]}${d[1]}${d[2]}${d[2]}${d[3]}${d[3]}${l > 4 ? `${d[4]}${d[4]}` : ''}`; // 3 digit
      d = i(d.slice(1), 16), RGB[0] = d >> 16 & 255, RGB[1] = d >> 8 & 255, RGB[2] = d & 255, RGB[3] = l == 9 || l == 5 ? r(((d >> 24 & 255) / 255) * 10000) / 10000 : -1;
    }
    return RGB;
  };
  var i = parseInt,
    r = Math.round,
    h = from.length > 9,
    h = typeof (to) === 'string' ? to.length > 9 ? true : to == 'c' ? !h : false : h,
    b = p < 0,
    p = b ? p * -1 : p,
    to = to && to != 'c' ? to : b ? '#000000' : '#FFFFFF',
    f = sbcRip(from),
    t = sbcRip(to);
  if (!f || !t) return null; // ErrorCheck
  if (h) return `rgb(${r((t[0] - f[0]) * p + f[0])},${r((t[1] - f[1]) * p + f[1])},${r((t[2] - f[2]) * p + f[2])}${f[3] < 0 && t[3] < 0 ? ')' : `,${f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 10000) / 10000 : t[3] < 0 ? f[3] : t[3]})`}`;
  return `#${(0x100000000 + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 255) : t[3] > -1 ? r(t[3] * 255) : f[3] > -1 ? r(f[3] * 255) : 255) * 0x1000000 + r((t[0] - f[0]) * p + f[0]) * 0x10000 + r((t[1] - f[1]) * p + f[1]) * 0x100 + r((t[2] - f[2]) * p + f[2])).toString(16).slice(f[3] > -1 || t[3] > -1 ? 1 : 3)}`;
}

export const declOfNum = (n, titles) => titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];

export const createUrl = (modelSlug, categorySlug) => {
  if (modelSlug && categorySlug) {
    return `/accessories/${modelSlug}/${categorySlug}`;
  } if (modelSlug && !categorySlug) {
    return `/accessories/${modelSlug}`;
  } if (categorySlug) {
    return `/accessories/category/${categorySlug}`;
  }
  return '/accessories';
};

export function formatPhoneNumber(num) {
  if (!num) {
    return num;
  }
  const str = num.toString();
  const matched = str.match(/\d+\.?\d*/g);
  if (!matched) return '';

  if (matched.length === 1) {
    if (matched[0].length >= 11) {
      return `+${matched[0].substr(0, 1)} (${matched[0].substr(1, 3)}) ${matched[0].substr(4, 3)}-${matched[0].substr(7, 2)}-${matched[0].substr(9)}`;
    }
  }

  // Format failed, return number back
  return num;
}

export /* istanbul ignore next */ const setMessage = (text) => {
  console.log(text);
};

export const errorHandler = (e) => {
  if (e.status === 401) {
    _localStorage.removeItem('auth_token');
    _localStorage.removeItem('cartId');
    window.location.reload();
  }

  if (process.env.NODE_ENV !== 'test') {
    /* istanbul ignore next */
    console.error(e);
  }
};

export const removeEmpty = (obj) => {
  // Protect against null/undefined object passed in
  return Object.keys(obj || {}).reduce((x, k) => {
    // Check for null or undefined
    if (obj[k] != null) {
      x[k] = obj[k];
    }
    return x;
  }, {});
}


export function queryString(str) {
  const a = str.substr(1).split('&');
  if (a === '') return {};
  const b = {};
  for (let i = 0; i < a.length; i += 1) {
    const index = a[i].indexOf("=");
    const key = a[i].substr(0, index)
    const value = a[i].substr(index + 1)
    // const p = a[i].split('=', 2);
    // console.log(p)
    if (!value) b[key] = '';
    else b[key] = decodeURIComponent(value);
  }
  return b;
}

export function numberSpacer(number){
  return number.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
}

export const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const notify = function(title, text, status = 'success') {
  if (status === 'success') {
    console.log(title, text)
  } else {
    console.log(title, text)
  }

}
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const duratHuman = (days, hours, minutes) => `${days ? `${days} ${declOfNum(days, ['день', 'дня', 'дней'])}` : ''} ${hours ? `${hours} ${declOfNum(hours, ['час', 'часа', 'часов'])}` : ''} ${minutes} ${declOfNum(minutes, ['минуту', 'минуты', 'минут'])}`;

export const orderDurations = (order) => {
  const base = moment(order.createdAt);
  const right = order.touchedAt ? moment(order.touchedAt) : moment();
  const diff = right.diff(base);
  const diffDuration = moment.duration(diff);
  const diffDuration2 = moment.duration(order.timePassed);
  const [days, hours, minutes] = [Math.floor(diffDuration.asDays()), diffDuration.hours(), diffDuration.minutes()];
  const [hours2, minutes2] = [Math.floor(diffDuration2.asHours()), diffDuration2.minutes()];
  return [duratHuman(0, hours2, minutes2), duratHuman(days, hours, minutes)]
}

export function generateRandomString(length) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}


export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getGood (id) {
  return apicall(`goods/${id}`);
}

export function getGoodById (productId) {
  return apicall(`goods/byProductId/${productId}`);
}

export function getPriceById (productId) {
  return apicall(`goods/byProductId/${productId}/price`);
}

export function getGoodsWithProducts () {
  return apicall('goods');
}

export function getGoodOffers (id) {
  return apicall(`goods/${id}/with_offers`);
}

export function createGood (data) {
  return apicall('goods', { method: 'POST', body: JSON.stringify(data) });
}

export function updateGood (id, data) {
  return apicall(`goods/${id}`, { method: 'PATCH', body: JSON.stringify(data) });
}

export function destroyGood (id) {
  return apicall(`goods/${id}`, { method: 'DELETE' });
}

export function editDealerGood (id, data) {
  return apicall(`goods/${id}/dealer`, { method: 'POST', body: JSON.stringify(data) });
}

export function deleteDealerGood (id) {
  return apicall(`goods/${id}/dealer`, { method: 'DELETE' });
}

export function ensureFiltersExist (modelSlug, categorySlug) {
  return apicall('goods/ensureFiltersExist', { method: 'POST', body: JSON.stringify({ modelSlug, categorySlug }) });
}

export function getGoodLogs (id) {
  return apicall(`goods/${id}/logs`, { method: 'GET' });
}

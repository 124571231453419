import React, { useState } from 'react'

import { formatPrice } from 'helpers'
import Button from 'components/Button'
import PayBlock from 'components/PayBlock'
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPrint, faInfoCircle, faChevronUp, faChevronDown, faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import ShowOrder from 'components/ShowOrder';
import { Gapper } from 'components/Ui'


export default function SuccessOrder({ order, orders, items, dealers, dealer, products, payables, makePayment, allowedToPay }) {
  const [makingPayment, setMakingPayment] = useState(false);

  return (
    <Gapper>
      <ShowOrder id={order.id} orders={orders} items={items} products={products} dealers={dealers} hideDisclaimer />

      {allowedToPay && (!payables || !payables[dealer.kod]) &&
        <>
          Оплата недоступна&nbsp;
          <FontAwesomeIcon icon={faInfoCircle} data-effect="solid" data-tip="Дилер не имеет возможности приёма оплаты онлайн" />
          <ReactTooltip />
        </>
      }    
      {allowedToPay && payables && payables[dealer.kod] && 
        <PayBlock orderId={order.id} />
      }
      { !allowedToPay && payables && payables[dealer.kod] &&
        <div>
          Оплата заказа онлайн доступна доступна только для зарегистрированных пользователей.
        </div>
      }
    </Gapper>
  )
}
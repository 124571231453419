/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getDealers () {
  return apicall('dealers');
}

export function createDealer (data) {
  return apicall('dealers', { method: 'POST', body: JSON.stringify({ dealer: data }) });
}

export function updateDealer (data) {
  const { id, ...rest } = data;
  return apicall(`dealers/${id}`, { method: 'PATCH', body: JSON.stringify({ dealer: rest }) });
}

export function getDealerLogs (id) {
  return apicall(`dealers/${id}/logs`, { method: 'GET' });
}


export function getDealerOrdersInfo (id, data) {
  return apicall(`dealers/${id}/orders_info`, { method: 'POST', body: JSON.stringify({ filters: data }) });
}

 

export function getDealerGraphInfo (id, data) {
  return apicall(`dealers/${id}/graph_info`, { method: 'POST', body: JSON.stringify({ filters: data }) });
}

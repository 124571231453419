import { requiredScopes } from './config';



export const errors = {
  authFail: "authFail",
  wrongState: "wrongState",
  scopeFail: "scopeFail",
}



export const scopeIsOk = (scope) => {
  const passedScope = scope.split(' ');
  return requiredScopes.every(v => passedScope.includes(v))
}
import React from 'react';

import { googleApiKey } from 'settings';
import Gmap from 'components/Gmap';

/* istanbul ignore next */
const zoomLimit = (bounds) => (map) => {
  if (map) {
    setTimeout(() => {
      map.fitBounds(bounds);
      // console.log(map)
      const zoom = map.getZoom();
      // console.log('wtf')
      // console.log(!!zoom)
      if (!zoom || zoom > 10) {
        // ayy
        map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(10); // eslint-disable-line no-underscore-dangle
      }
    }, 200);
  }
};

/* istanbul ignore next */
const MapDealer = ({ dealers }) => (
  <Gmap
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleApiKey}`}
    dealers={dealers}
    onMarkerClick={() => {}}
    onMarkerClose={() => {}}
    zoom={12}
    zoomLimit={zoomLimit}
    showDealer={null}
    loadingElement={(
      <div style={{ height: '100%' }}>

          Загрузка
      </div>
    )}
    containerElement={
      <div style={{ height: '100%' }} />
    }
    mapElement={
      <div style={{ height: '100%' }} />
    }
  />
);

export default MapDealer;

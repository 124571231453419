import React from 'react';
import styled, { css } from 'styled-components'
import vars from 'config/styles'

const Label = styled.label`
  padding-left: 20px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  height: 20px;
  &:hover {
    // color: ${vars.green};
  }  
  a {
    color: ${vars.green};
  }
  &:hover > span {
    border-color: ${vars.green};
  }
`

const Mask = styled.span`
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #cacaca;
    ${({ checked }) => checked && css`
      border: none !important;
      background-color: ${vars.green};
      &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 5px;
        width: 8px;
        height: 5px;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        transform: rotate(-43deg);      
      }
    `}  

  ${({ disabled }) => disabled && css`
    background-color: #f0f0f0!important;
    border: none!important;
    &:before {
      border-bottom: 2px solid #a7aeb4!important;
      border-left: 2px solid #a7aeb4!important;      
    }
  `}

`

const Text = styled.div`

`


const Checkbox = ({ checked, onClick, children, disabled = false }) => {
  return (
    <Label onClick={() => {onClick && !disabled && onClick()}}>
      <Mask checked={checked} disabled={disabled}/>
      {children && <Text>{children}</Text>}
    </Label>
  );

};

export default Checkbox;

import React from 'react'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { makeSelectSeoTags } from './selectors';


function Seo (options) {
  const mapStateToProps = createStructuredSelector({
    seoTags: makeSelectSeoTags,
  });

  function wrapped (Component) {
    class DecoratedWithSeo extends React.PureComponent {
      render () {
        return (
          <Component {...this.props} />
        );
      }
    }

    const withConnect = connect(mapStateToProps, null);

    return compose(
      withConnect,
    )((DecoratedWithSeo));
  }

  return wrapped;
}

export default Seo;
import React from 'react'
import styled from 'styled-components'

import  Field  from 'helpers/Field'
import { datePickerField } from 'components/Form/components';
import RFReactSelect from 'components/Form/components/RFReactSelect';
import vars from 'config/styles'
import { H7 } from 'components/Ui'

const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    flex-wrap: wrap;
  }
`

const LeftBlock = styled.div`
  flex-grow: 0;
  margin-right: 10px;
  min-width: 250px;
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
  }
`

const RightBlock = styled.div`
  flex-grow: 0;
  min-width: 250px;
  display: flex;
  &>div {
    width: 100%;
  }
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
  }  
`

const HouseBlock = styled.div`
  width: 90px;
  margin-left: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    margin-left: 0;
    width: auto;
    flex: 1;
  }
`

const KBlock = styled.div`
  width: 60px;
  margin-left: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    width: auto;
    flex: 1;
  }
`

const FlatBlock = styled.div`
  width: 100px;
  margin-left: 10px;
  @media (max-width: ${vars.bp.tablet}px) {
    width: auto;
    flex: 1;
  }
`

const ZipBlock = styled.div`
  width 170px;
  margin-top: 10px;
`

const AddressBlock = ({ schedules, handleDateChange, minDate }) => {
  return (
    <>
      <H7>Дата доставки</H7>
      <Wrapper>
        <LeftBlock>
          <Field required type="text" name="date" minDate={minDate} autocomplete="off" onChange={handleDateChange} component={datePickerField} label="Дата доставки" />
        </LeftBlock>
        <RightBlock>
          <Field type="text" name="courierSchedule"  component={RFReactSelect} options={schedules} label="Время доставки" />
        </RightBlock>
      </Wrapper>

    </>
  )
}

export default AddressBlock
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux'

import { makeSelectLoading } from 'containers/App/selectors';

import './style.sass';
// import spinner from 'components/Loading/spinner.svg';

class Loading extends React.Component {

  render() {
    const { loading } = this.props;
    if (!loading) return null;
    return (
      <div className='gloading'>
        {/*<img src={spinner} />       */}
      </div>
    )
  }
}


export function mapDispatchToProps(dispatch) {
  return {
  
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)


export default compose(withConnect)(Loading);
import { schema } from 'normalizr';

export const Client = new schema.Entity('clients');
export const Image = new schema.Entity('images');
export const File = new schema.Entity('files');
export const Stat = new schema.Entity('stats');
export const Manager = new schema.Entity('managers');
export const Fav = new schema.Entity('favs');
export const Message = new schema.Entity('messages');

const Dealer = new schema.Entity('dealers', {
  // city: City,
});

export const Payable = new schema.Entity('payables', {}, { idAttribute: 'code' });
export const Deliverable = new schema.Entity('deliverables', {}, { idAttribute: 'code' });


export const City = new schema.Entity('cities', {
  dealers: [Dealer],
});

export const Region = new schema.Entity('regions', {
  cities: [City],
});

export const Offer = new schema.Entity('offers', {
  dealer: Dealer,
});

export const Msrp = new schema.Entity('msrps', {}, { idAttribute: 'productId' });

export const Product = new schema.Entity('products', {
  offers: [Offer],
  msrp: Msrp,
});

export const Item = new schema.Entity('items', {
  product: Product,
  dealer: Dealer,
});

export const Cart = new schema.Entity('carts', {
  items: [Item],
  client: Client,
});

export const Domain = new schema.Entity('domains', {
  dealers: [Dealer],
  mainDealer: Dealer,
  image: Image,
});

export const User = new schema.Entity('users', {
  cart: Cart,
  domain: Domain,
  userFavs: [Fav],
  messages: [Message],
  // dealers: [Dealer]
});

export const Log = new schema.Entity('logs', {
  user: User,
});

export const Update = new schema.Entity('updates', {
  attachment: File,
});

Dealer.define({
  logs: [Log],
  update: Update,
  city: City
});

export { Dealer };

export const Category = new schema.Entity('categories', {
  image: Image,
});
export const Model = new schema.Entity('models', {
  image: Image,
});

export const Order = new schema.Entity('orders', {
  items: [Item],
  client: Client,
  user: User,
  dealer: Dealer,
  domain: Domain,
  logs: [Log],
});

export const DealerGood = new schema.Entity('dealerGoods');

const Good = new schema.Entity('goods', {
  categories: [Category],
  models: [Model],
  offers: [Offer],
  images: [Image],
  dealerGoods: [DealerGood],
  msrp: Msrp,
  logs: [Log],
  product: Product,
});

Good.define({
  // collaterals: [Good],
});

export { Good };

export const Notification = new schema.Entity('notifications', {
  user: User,
  dealer: Dealer
})

export const Oreturn = new schema.Entity('oreturns')
export const Podbor = new schema.Entity('podbors')
export const Setting = new schema.Entity('settings')

export const filterToModel = {
  orders: Order,
  goods: Good,
  offers: Offer,
  users: User,
  categories: Category,
  models: Model,
  dealers: Dealer,
  domains: Domain,
  regions: Region,
  cities: City,
  updates: Update,
  managers: Manager,
  notifications: Notification,
  podbors: Podbor,
  settings: Setting,
};

/* istanbul ignore file */
import { apicall } from 'utils/api';

export function addToCart (data) {
  return apicall('carts/add_item', { method: 'POST', body: JSON.stringify(data) });
}

export function getCart (cartId) {
  return apicall(`carts/${cartId}`);
}

import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import vars from 'config/styles'
import injectSaga from 'utils/injectSaga';
import { formatPrice } from 'helpers'

import { setPopup } from 'containers/App/actions'
import { getBonuses, applyCert, applyPoints } from './actions'

import { selectDealer, selectCerts, selectPoints } from './selectors'

import {   
  selectCartModItems,
} from 'containers/App/selectors';


import { H1, H7, Gapper, Flex, FlexFixed, FlexFluid, Text3, Tab, TabWrapper,
  Table, Tr, Thead, Tbody, Td, Frame, Center, Text2, Right
 } from 'components/Ui'
import Box, { Inner } from 'components/Box'
import Card from 'components/Card'
import CardContainer from 'components/CardContainer'
import Radio from 'components/Radio'
import Sbutton from 'components/Sbutton'
import Svg, { SvgWrapper } from 'components/Svg'
import Bonus from 'images/points.svg'
import * as metricsActions from 'containers/App/metricsActions'

const BTr = styled(Tr)`
  &:hover {
    background: ${vars.bg};
    cursor: pointer;
  }
  ${({checked}) => checked && css`
    background: ${vars.grey} !important;
  `}
`

const BTable = styled(Table)`
  box-shadow: inset 0px -1px 0px #DADADA;
`

const Wrapper = styled.div`
  max-width: 810px;
`

const Input = styled.input`
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: ${vars.black};
  width: 100%;
  border: 0;
  border-bottom: 1px solid #DADADA;
`


class BonusPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: false,
      mode: 0,
      cert: null,
      points: 0,
      error: null
    }
    this.handleCertSubmit = this.handleCertSubmit.bind(this)
    this.handlePointsSubmit = this.handlePointsSubmit.bind(this)
  }

  componentDidMount() {
    this.props.sendRaw('Purchase_Bonus', 'open_form')
  }

  componentWillUnmount() {
    this.props.sendRaw('Purchase_Bonus', 'close_form')
  }

  handleCertSubmit() {
    const { dealer, applyCert, setPopup } = this.props
    new Promise((resolve, reject) => {
      this.setState({ loading: true })
      this.props.sendRaw('Purchase_Bonus', 'send_certificate', this.state.cert)
      applyCert({ resolve, reject, certCode: this.state.cert, dealerId: dealer.id })
    }).then(() => {
      this.props.sendRaw('Purchase_Bonus', 'success', this.state.cert)
      setPopup(null)
      this.setState({ loading: false })
    }).catch(e => {
      this.setState({ loading: false, error: e })
    })
  }

  handlePointsSubmit() {
    const { dealer, applyPoints, setPopup } = this.props
    new Promise((resolve, reject) => {
      this.setState({ loading: true })
      this.props.sendRaw('Purchase_Bonus', 'send_bonus', this.state.points)
      applyPoints({ resolve, reject, amount: this.state.points, dealerId: dealer.id })
    }).then(() => {
      setPopup(null)
      this.props.sendRaw('Purchase_Bonus', 'success', this.state.points)
      this.setState({ loading: false })
    }).catch(e => {
      this.setState({ loading: false, error: e })
    })
  }




  render() {
    const { dealer, price, points, certs, moditems, sendRaw } = this.props
    const { mode } = this.state
    const pointsApplied = Object.values(moditems).filter(item => item.kind === 'LK_POINTS').reduce((acc, cur) => acc + cur.amount, 0)
    return (
      <Wrapper>
        <Gapper gap={16}>
          <H1 center>ŠKODA бонус к заказу</H1>
          <H7 center>{dealer.name} на сумму {formatPrice(price)}</H7>
          <Frame gap={40}>
            <TabWrapper center>
              <Tab active={mode === 0} onClick={() => this.setState({ mode: 0 })}>Сертификаты</Tab>
              <Tab active={mode === 1} onClick={() => { sendRaw('Purchase_Bonus', 'click_bonus'); this.setState({ mode: 1 })}}>Баллы ({points})</Tab>
            </TabWrapper>
          </Frame>
          
          {mode === 0 && (
            <div>
              <BTable>
                <Thead>
                  <Tr>
                    <Td></Td>
                    <Td><Text2 normal black>Создан</Text2></Td>
                    <Td><Text2 normal black>Номер сертифиакта</Text2></Td>
                    <Td><Text2 normal black>Количество баллов *</Text2></Td>
                  </Tr>
                </Thead>
                <Tbody>
          
                  {certs.filter(cert => !cert.expired && !cert.used_at).map(cert => {
                    const alreadyApplied = !!Object.values(moditems).find(item => item.title === cert.code)

                    return (
                      <BTr key={`cert-${cert.id}`} checked={this.state.cert === cert.code} onClick={() => !alreadyApplied && this.setState({ cert: cert.code })}>
                        <Td>
                          <Radio disabled={alreadyApplied} empty checked={this.state.cert === cert.code}>
                            &nbsp;
                          </Radio>
                        </Td>
                        <Td>
                          <Text3>{cert.created_at}</Text3>
                        </Td>
                        <Td>
                          <Text3>{cert.code}</Text3>
                        </Td>
                        <Td>
                          <Text3 bold black>
                            <Svg icon={Bonus}/>
                            {cert.amount}
                          </Text3>
                        </Td>
                      </BTr>
                    )
                  })}
                </Tbody>
              </BTable>

              <Frame gap={24}>
                <Center>
                  <Text3>* 1 балл = 1 рублю, вы можете оплатить до 50% суммы заказа</Text3>
                </Center>
              </Frame>

              <Right>
                <Sbutton disabled={!this.state.cert} onClick={this.handleCertSubmit}>Использовать сертификат</Sbutton>
              </Right>

            </div>
          )}

          {mode === 1 && (
            <>
              <CardContainer>
                <Card d={6}>
                  <Box>
                    <Inner>
                      <Gapper>
                        <H7>Доступно баллов *</H7>
                        <H1>{points - pointsApplied} из {points}</H1>
                        <Text3>* 1 балл = 1 рублю, вы можете оплатить до 50% суммы заказа</Text3>
                      </Gapper>
                    </Inner>
                  </Box>
                </Card>
                <Card d={6}>
                  <Box bged>
                    <Inner>
                      <Gapper>
                        <H7>Использовать баллы *</H7>
                        <Input onChange={(e) => { const pt = parseInt(Math.min(points - pointsApplied, price / 2, Number(e.target.value.replace(/[^\d]/g, '')) || 0)); sendRaw('Purchase_Bonus', 'change_bonus', pt); this.setState({ points: pt })}} value={this.state.points} />
                        <Text3>* не более {Number(price/2)} баллов</Text3>
                        {/*<Text3>* не более 50% от суммы заказа</Text3>*/}
                      </Gapper>
                    </Inner>
                  </Box>
                </Card>
              </CardContainer>
              <Right>
                <Sbutton disabled={!this.state.points} onClick={this.handlePointsSubmit}>Использовать баллы</Sbutton>
              </Right>
            </>
          )}          

        </Gapper>
      </Wrapper>
    )
  }
  
}


const mapStateToProps = createStructuredSelector({
  dealer: selectDealer(),
  certs: selectCerts(),
  points: selectPoints(),
  moditems: selectCartModItems(),
});

function mapDispatchToProps (dispatch) {
  return {
    applyBonus: payload => dispatch(applyBonus(payload)),
    applyCert: payload => dispatch(applyCert(payload)),
    applyPoints: payload => dispatch(applyPoints(payload)),  
    setPopup: code => dispatch(setPopup(code)),  
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(BonusPopup);
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { createReturn, updateReturn } from 'api/returns';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';
import { apiRequest } from 'containers/App/saga';

import { normalize } from 'normalizr';

import { setEntities } from 'models/actions';
import { Order } from 'models';
import { EDIT_RETURN } from './constants';

export function * editReturnGen (action) {
  const { payload: { data, resolve, reject } } = action;
  try {
    let order = null;
    if (!data.id) {
      order = yield call(apiRequest, createReturn, data);
    } else {
      order = yield call(apiRequest, updateReturn, data);
    }
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(EDIT_RETURN, editReturnGen),
  ]);
}

import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { readMessage } from 'api/messages';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';
import { apiRequest } from 'containers/App/saga';

import { normalize } from 'normalizr';

import { setEntities } from 'models/actions';
import { Message } from 'models';
import { READ_MESSAGE } from './constants';

export function * readMessageGen (action) {
  const { payload: id } = action;
  try {
    const messages = yield call(apiRequest, readMessage, id);
    const normalized = normalize(messages, [Message]);
    yield put(setEntities(normalized));
    // resolve();
  } catch (e) {
    errorHandler(e);
    // reject(formError);
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(READ_MESSAGE, readMessageGen),
  ]);
}

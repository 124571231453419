/**
 *
 * Goods
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import 'css/components/gpage.sass';

import { GOOD_OFFERS, NOTIFY_STOCK } from 'components/Popup/constants';

import { makeSelectEntities } from 'models/selectors';
import { frontloadConnect } from 'react-frontload';
import { makeSelectPageData, makeSelectIsDealerDomain, makeSelectCurrentDomain, makeSelectFavs } from 'containers/App/selectors';



import { setPageData, setPopup, favToggle } from 'containers/App/actions';
import { ensureFiltersExist } from 'models/goods/actions';

import Filter from 'components/Filter';
import DisplayContainer from 'components/DisplayContainer';
import Good from 'components/Good';
import DealerGood from 'components/Good/DealerGood';

import NotFound from 'containers/NotFoundPage';
import Container from 'components/Container';
import Button from 'components/Button';

import FacetRev from 'components/FacetRev';

import { createUrl, removeEmpty } from 'helpers';
import MobileFilters from './MobileFilters';
import SearchBar from './SearchBar';
import EmptyResult from './EmptyResult'
import FoundCount from './FoundCount'
import Hero from './Hero'
import CategoriesMenu from './CategoriesMenu'
import ModelsMenu from './ModelsMenu'
import SelectedFilters from './SelectedFilters'
import SortResult from './SortResult'
import PerPage from './PerPage'
import Seo from 'components/Seo';
import * as metricsActions from 'containers/App/metricsActions'
import { makeSelectSeoTags } from 'components/Seo/selectors';
import BreadCrumbs from 'components/BreadCrumbs';


export class Goods extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    const { isDealerDomain } = props;
    this.setCategory = this.setCategory.bind(this);
    this.setModel = this.setModel.bind(this);
    this.state = { filters: isDealerDomain ? { dealerStatus: true } : { status: true } };
    this.setFilters = this.setFilters.bind(this);
    this.makeUrl = this.makeUrl.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.setSearchQuery = this.setSearchQuery.bind(this);
  }

  componentDidMount () {
    console.log('did mount')
    const { categorySlug, modelSlug, hidden } = this.props;
    const filters = { categorySlug, modelSlug }
    if (hidden) {
      filters.dealerOnly = true;
    }
    console.log('set filters')
    this.setFilters(filters)
  }

  setFilters (filters = {}) {
    const { isDealerDomain, filtersSubmit } = this.props;

    this.setState((prevState) => ({ filters: { ...prevState.filters, ...filters } }), () => {
      const compactedFilters = removeEmpty(this.state.filters)
      const filtersToSubmit = compactedFilters;
      if (Object.keys(compactedFilters).length === 1) {
        filtersToSubmit[isDealerDomain ? 'dealerFeaturedCatalog' : 'featuredCatalog'] = true;
      }
      filtersSubmit(filtersToSubmit);
    });
  }

  resetFilters () {
    const searchQueryName = this.props.isDealerDomain ? 'dealerSearchQuery' : 'searchQuery';

    this.setFilters({ categorySlug: null, modelSlug: null, [searchQueryName]: null });
  }

  setCategory (id) {
    this.setFilters({ categorySlug: id });
  }

  setModel (id) {
    this.setFilters({ modelSlug: id });
  }

  setSearchQuery (id) {
    const searchQueryName = this.props.isDealerDomain ? 'dealerSearchQuery' : 'searchQuery';

    this.setFilters({ [searchQueryName]: id });
  }

  makeUrl (newState) {
    const { modelSlug, categorySlug } = { ...this.state.filters, ...newState };
    return createUrl(modelSlug, categorySlug);
  }

  onSearchSubmit () {
    this.props.filtersSubmit(this.state.filters);
  }

  onSearchChange (e) {
    e.persist();
    const searchQueryName = this.props.isDealerDomain ? 'dealerSearchQuery' : 'searchQuery';
    this.props.sendRaw('AccessoriesPage', 'input_detail', e.target.value)
    this.setFilters({ [searchQueryName]: e.target.value })
  }

  makeTitle ({ modelName, categoryName }) {
    if (modelName && categoryName) {
      return `${categoryName} ${modelName}`;
    } if (modelName && !categoryName) {
      return `${modelName}`;
    } if (!modelName && categoryName) {
      return `${categoryName}`;
    }
    return 'Аксессуары ŠKODA';
  }

  render () {
    const {
      filterStatus, ids, goods, getRecords, pagination, filter, loadMore, totalPages, currentPage, hidden, currentUser,
      categories, models, setPopup, images, isDealerDomain, dealerGoods, domain, count, favToggle, favs, compares, offers,
      seoTags, sendRaw, categorySlug, modelSlug
    } = this.props;
    const compactedFilters = removeEmpty(this.state.filters)
    // const noFiltersSelected = Object.keys(compactedFilters).length === 1
    const noFiltersSelected = !categorySlug && !modelSlug
    // console.log({ noFiltersSelected, compactedFilters })
    const records = getRecords(ids, goods);
    const Template = isDealerDomain ? DealerGood : Good;
    const searchQueryName = isDealerDomain ? 'dealerSearchQuery' : 'searchQuery';
    const categoryName = (Object.values(categories).find((one) => one.slug === categorySlug) || {}).name;
    const modelName = (Object.values(models).find((one) => one.slug && (one.slug === modelSlug)) || {}).name;
    const currentFilter = filter || {}
    // console.log({ categorySlug, modelSlug })

    let breadcrumbItems = []

    if (noFiltersSelected) {
      breadcrumbItems = [{
        title: 'Аксессуары',
        link: '/accessories',
        last: true
      }];
    } else {
      breadcrumbItems = [{
        title: 'Аксессуары',
        link: '/accessories'
      }];      
      breadcrumbItems.push({
        title: this.makeTitle({ modelName, categoryName }),
        // link: this.makeUrl(this.state.filters),
        link: this.makeUrl({ categorySlug, modelSlug }),
        last: true
      })
    }


    // if (goodDisplay.categories && goodDisplay.categories[0] && categories[goodDisplay.categories[0]]) {
    //   breadcrumbItems.push({
    //     title: categories[goodDisplay.categories[0]].name,
    //     link: createUrl(null, categories[goodDisplay.categories[0]].slug),
    //     onClick: () => sendRaw('Accessory_MainPage', 'click_accessories', categories[goodDisplay.categories[0]].name)
    //   });
    // }

    // breadcrumbItems.push({
    //   title: goodDisplay.name,
    // });

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {seoTags.metaTitle}
          </title>
          <meta name="description" content={seoTags.metaDescription} />
        </Helmet>

        <Hero />

        <FacetRev color="#fff" abs="true" />

        <Container>
          <div className="gpage" id="gpage">

            <CategoriesMenu setCategory={this.setCategory}
                        categorySlug={this.state.filters.categorySlug}
                        categories={categories}
                        makeUrl={this.makeUrl}
                        hidden={hidden} 
                        currentUser={currentUser}
                        sendRaw={sendRaw}
            />


            <div className="gpage__layout">
              <h1>
                {seoTags.h1}
              </h1>
              <BreadCrumbs items={breadcrumbItems} />

              <SelectedFilters  filters={this.state.filters} 
                                searchQueryName={searchQueryName} 
                                setSearchQuery={this.setSearchQuery}
                                models={models}
                                categories={categories}
                                makeUrl={this.makeUrl}
                                setCategory={this.setCategory}
                                setModel={this.setModel}
                                categoryName={categoryName}
                                modelName={modelName}
              />

              <ModelsMenu setModel={this.setModel}
                          modelSlug={this.state.filters.modelSlug}
                          models={models}
                          makeUrl={this.makeUrl}
                          images={images}
                          sendRaw={sendRaw}
              />

              <SearchBar sendRaw={sendRaw} value={this.state.filters[searchQueryName]} onChange={this.onSearchChange} onSubmit={this.onSearchSubmit} />

              <div className="gpage__mobile">
                <MobileFilters
                  makeUrl={this.makeUrl}
                  categories={categories}
                  setCategory={this.setCategory}
                  categorySlug={this.state.filters.categorySlug}
                  models={models}
                  setModel={this.setModel}
                  modelSlug={this.state.filters.modelSlug}
                />
              </div>

              <div className="gpage__panel">
                {noFiltersSelected && <span>Новые аксессуары</span>}
                {!noFiltersSelected && <FoundCount count={count}/>}
                <div style={{marginLeft: 10}}>
                  <SortResult sendRaw={sendRaw} selected={currentFilter.field} doSort={this.props.doSort}/> 
                </div>
              </div>

              <DisplayContainer
                records={records}
                loading={filterStatus && filterStatus.fetching && !filterStatus.initial}
                showInitial={filterStatus && filterStatus.initial}
                empty={() => <EmptyResult resetFilters={this.resetFilters} isEmpty={count === 0 && (this.state.filters.modelSlug || this.state.filters.categorySlug)}/>}
                images={images}
                Template={Template}
                templateProps={{
                  sendRaw, dealerGoods, dealerId: domain && domain.dealerId, favToggle, favs, compares, singleDealerDomain: domain && domain.dealers && !domain.dealers.length, offers: offers
                }}
                containerClassName="goods"
                setPopup={(opts) => { setPopup(GOOD_OFFERS, opts); }}
                setPopupNotify={(opts) => { setPopup(NOTIFY_STOCK, opts); }}
              />
              
              <PerPage selected={currentFilter.perPage} setPerPage={this.props.setPerPage}/>

              {currentPage < totalPages &&
                <Button small onClick={(e) => { sendRaw('AccessoriesPage', 'show_more'); loadMore(e) }}>
                  Показать еще
                </Button>
              }       
              {pagination}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

function Loading ({}) {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Загрузка
        </title>
        <meta name="description" content="Аксессуары" />
      </Helmet>
      <div className="panel">
          Загрузка..
      </div>
    </React.Fragment>
  );
}

const frontload = async (props) => {
  const { modelSlug, categorySlug, hidden } = props.computedMatch.params;
  const path = props.computedMatch.path
  const location = props.location.pathname
  if (modelSlug === 'novyj-superb') {
    if (props.staticContext) {
      props.staticContext.url = location.replace('novyj-superb', 'superb');
      return false;
    }
  }
  if (modelSlug === 'novyj-rapid') {
    if (props.staticContext) {
      props.staticContext.url = location.replace('novyj-rapid', 'rapid');
      return false;
    }
  }
  
  if (!modelSlug && !categorySlug) {
    props.setAccessoriesData('status', 'success')
  } else {
    await new Promise((resolve, reject) => {
      props.ensureFiltersExist({ resolve, reject, modelSlug, categorySlug })
    }).then(() => props.setAccessoriesData('status', 'success'))
      .catch(() => {
        props.setAccessoriesData('status', 'error')
        if (props.staticContext) props.staticContext.not_found = true;
      });
  }
}

class GoodsDecorated extends React.PureComponent {
  constructor (props) {
    super(props);
    // this.props.setAccessoriesData('status', 'loading')
  }


  render () {
    const { accessoriesData } = this.props
    const { modelSlug, categorySlug, hidden } = this.props.computedMatch.params;
    if(accessoriesData.status === 'success')
      return <Goods modelSlug={modelSlug} categorySlug={categorySlug} hidden={hidden === 'hidden'} {...this.props} />

    if(accessoriesData.status === 'error')
      return <NotFound wrap/>

    return <Loading />
  }
}

GoodsDecorated.propTypes = {
  dispatch: PropTypes.func.isRequired,
};



const mapStateToProps = createStructuredSelector({
  accessoriesData: makeSelectPageData('accessories'),
  goods: makeSelectEntities('goods'),
  categories: makeSelectEntities('categories'),
  models: makeSelectEntities('models'),
  images: makeSelectEntities('images'),
  dealerGoods: makeSelectEntities('dealerGoods'),
  offers: makeSelectEntities('offers'),
  isDealerDomain: makeSelectIsDealerDomain(),
  domain: makeSelectCurrentDomain(),
  favs: makeSelectFavs(),
  compares: makeSelectFavs(1),
});

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
    setAccessoriesData: (name, value) => dispatch(setPageData({ page: 'accessories', name, value })),
    favToggle: (payload) => dispatch(favToggle(payload)),
    ensureFiltersExist: (payload) => dispatch(ensureFiltersExist(payload)),
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSeo = Seo() 

const withFilter = Filter({
  name: 'accessories-index', model: 'goods', perPage: 12, initialQuery: false,
});

const withFrontload = frontloadConnect(frontload)

export default compose(
  withConnect,
  withFilter,
  withSeo,
  withFrontload,
)(GoodsDecorated);

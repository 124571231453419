import React from 'react';

import Dropdown from 'components/Dropdown'

const perPageOptions = [
  ["12", '12'],
  ["24", '24'],
  ["48", '48']
]

class PerPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.setPerPage(value)
  }

  render() {
    return (
      <div style={{display: 'inline-block', float: 'right'}}>
        <span style={{fontSize: 11}}>На странице: &nbsp;</span>
        <Dropdown selected={this.props.selected} options={perPageOptions} onChange={this.handleChange}/>
      </div>
    )
  }
}


export default PerPage;

import {
  all, call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import {
  getGoodById, getGoodOffers, editDealerGood, createGood, updateGood, getGoodsWithProducts, deleteDealerGood, 
  getGoodLogs, getPriceById, destroyGood, ensureFiltersExist,
} from 'api/goods';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';
import { apiRequest } from 'containers/App/saga';

import { normalize } from 'normalizr';

import { setEntities, replacePassedEntities } from 'models/actions';
import { Good } from '..';
import {
  GET_GOOD_BY_ID, GET_GOOD_OFFERS, EDIT_DEALER_GOOD, EDIT_GOOD, GET_GOODS_PRODUCTS, DELETE_DEALER_GOOD,
  GET_GOOD_LOGS, GET_PRICE_BY_ID, DELETE_GOOD, ENSURE_FILTERS_EXIST,
} from './constants';

export function * getGoodByIdGen (action) {
  const { payload: { productId, resolve, reject } } = action;
  try {
    const good = yield call(apiRequest, getGoodById, productId);

    const normalized = normalize(good, Good);
    yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    // console.log('===')
    // console.error(e)
    // errorHandler(e);
    reject(e);
  }
}

export function * getPriceByIdGen (action) {
  const { payload: { productId, resolve, reject } } = action;
  try {
    const good = yield call(apiRequest, getPriceById, productId);

    // const normalized = normalize(good, Good);
    // yield put(setEntities(normalized));
    resolve(good);
  } catch (e) {
    // errorHandler(e);
    reject(e);
  }
}



export function * getGoodOffersGen (action) {
  const { payload: { id, resolve, reject } } = action;
  try {
    const good = yield call(apiRequest, getGoodOffers, id);
    const normalizedEntities = normalize(good, Good);
    yield put(setEntities(normalizedEntities));
    resolve();
  } catch (e) {
    errorHandler(e);
    reject(e);
  }
}

export function * editDealerGoodGen (action) {
  const {
    payload: {
      data, id, resolve, reject,
    },
  } = action;
  try {
    const good = yield call(apiRequest, editDealerGood, id, data);
    const normalized = normalize(good, Good);
    yield put(setEntities(normalized, 'goods'));
    // yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export function * editGoodGen (action) {
  const {
    payload: {
      data, id, resolve, reject,
    },
  } = action;
  let good;
  try {
    if (!id) {
      good = yield call(apiRequest, createGood, data);
    } else {
      good = yield call(apiRequest, updateGood, id, data);
    }
    const normalized = normalize(good, Good);
    yield put(replacePassedEntities(normalized, 'goods'));
    // yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export function * deleteDealerGoodGen (action) {
  const { payload: { id } } = action;
  try {
    const good = yield call(apiRequest, deleteDealerGood, id);
    const normalized = normalize(good, Good);
    yield put(replacePassedEntities(normalized, 'goods'));
    // yield put(setEntities(normalized));
    // resolve();
  } catch (e) {
    errorHandler(e);
    // reject(formError);
  }
}



export function * deleteGoodGen (action) {
  const { payload: { id, resolve, reject } } = action;
  try {
    const good = yield call(apiRequest, destroyGood, id);
    // const normalized = normalize(good, Good);
    // yield put(replacePassedEntities(normalized, 'goods'));
    // yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    // errorHandler(e);
    reject(e)
    // reject(formError);
  }
}

export function * getGoodsProductsGen (action) {
  // const { payload: { resolve, reject } } = action;
  try {
    const goods = yield call(apiRequest, getGoodsWithProducts);
    const normalized = normalize(goods, [Good]);
    yield put(setEntities(normalized));
    // resolve && resolve();
  } catch (e) {
    errorHandler(e);
    // reject && reject();
  }
}

export function * getGoodLogsGen (action) {
  const { payload: { id } } = action;
  try {
    const good = yield call(apiRequest, getGoodLogs, id);
    const normalized = normalize(good, Good);
    yield put(setEntities(normalized, 'goods'));
    // resolve();
  } catch (e) {
    errorHandler(e);
    // reject();
  }
}

export function * ensureFiltersExistGen (action) {
  const { payload: { resolve, reject, modelSlug, categorySlug } } = action
  try {
    yield call(apiRequest, ensureFiltersExist, modelSlug, categorySlug)
    resolve()
  } catch(e) {
    reject()
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(DELETE_DEALER_GOOD, deleteDealerGoodGen),
    takeEvery(GET_GOOD_BY_ID, getGoodByIdGen),
    takeEvery(GET_PRICE_BY_ID, getPriceByIdGen),
    takeEvery(GET_GOOD_OFFERS, getGoodOffersGen),
    takeEvery(EDIT_DEALER_GOOD, editDealerGoodGen),
    takeLatest(EDIT_GOOD, editGoodGen),
    takeLatest(GET_GOODS_PRODUCTS, getGoodsProductsGen),
    takeLatest(GET_GOOD_LOGS, getGoodLogsGen),
    takeLatest(DELETE_GOOD, deleteGoodGen),
    takeEvery(ENSURE_FILTERS_EXIST, ensureFiltersExistGen),
  ]);
}

import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

const BaseText = styled.div`
  ${({ bold }) => bold && css`
    font-weight: bold;
  `}
  ${({ normal }) => normal && css`
    font-weight: normal;
  `}  
  ${({ black }) => black && css`
    color: ${vars.black};
  `}
  ${({ grey }) => grey && css`
    color: ${vars.darkGrey};
  `}
  ${({ crossed }) => crossed && css`
    text-decoration: line-through;
  `}
  ${({ red }) => red && css`
    color: ${vars.red};
  `}
  ${({ center }) => center && css`
    text-align: center;
  `}
  ${({ opacity }) => opacity && css`
    opacity: 0.5;
  `}
`

export const Text1 = styled(BaseText)`
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  color: ${vars.black};
  letter-spacing: 0.01em;
`

export const Text2 = styled(BaseText)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${vars.darkGrey};
`

export const Text3 = styled(BaseText)`
  font-size: 16px;
  line-height: 26px;
  color: ${vars.black};
  letter-spacing: 0.01em;
`

export const Text4 = styled(BaseText)`
  font-size: 11px;
  line-height: 18px;
  color: ${vars.darkGrey};
  letter-spacing: 0.01em;

`

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.01em;
`

export const Center = styled.div`
  text-align: center;
`

export const Right = styled.div`
  text-align: right;
`
/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import messages from '../../config/messages';
import { frontloadConnect } from 'react-frontload';
import { withRouter } from 'react-router';
import Container from 'components/Container'

const frontload = async props => {
  if (props.staticContext) props.staticContext.not_found = true;
}


class NotFound extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const Wrapper = this.props.wrap ? Container : React.Fragment
    return (
      <Wrapper>
        <h1 style={{position: 'relative'}}>
          {messages.pages.not_found}
        </h1>
      </Wrapper>
    );
  }
}

const withFrontload = frontloadConnect(frontload)

export default withRouter(withFrontload(NotFound));

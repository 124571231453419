import React from 'react';
import styled, { css } from 'styled-components'

import { Wrapper, ErrorDiv } from '../styles'



export function InputField(props) {
  const { input, meta, cfn = (v) => v } = props;
  return (
    <Wrapper style={props.style} filled={!!input.value.toString()} error={(meta.error || meta.submitError) && meta.touched}>
      <input  type='text' {...input} onChange={(e) => { input.onChange(cfn(e.target.value)) }} autoComplete="off" />
      <label>{props.label}{ props.required && (<span>*</span>) }</label>
      {(meta.error || meta.submitError ) && meta.touched && <ErrorDiv>{(meta.error || meta.submitError)}</ErrorDiv>}
    </Wrapper>
  );

};

export default InputField;


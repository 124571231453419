import React from 'react';
import styled from 'styled-components';

import { Form } from 'react-final-form'
import  Field  from 'helpers/Field'
import { inputField } from 'components/Form/components'
import Button from 'components/Button'
import Loading from 'components/Loading'
import RFReactSelect from 'components/Form/components/RFReactSelect';
import SelectAsync from 'components/Form/components/RFReactSelectAsync'

import DeliveryTypeField from 'containers/Delivery/Fields/DeliveryTypeField'
import DeliveryPointField from 'containers/Delivery/Fields/DeliveryPointField'
import DeliveryCompanyField from 'containers/Delivery/Fields/DeliveryCompanyField'
import Loader from 'components/Loader'
// import PostField from 'containers/Delivery/Fields/PostField'
import AddressBlock from './AddressBlock'
import DateBlock from './DateBlock'

import { H4, H7, Flex, FlexFluid } from 'components/Ui'

const resetDelivery = (args, state, utils) => {
  utils.changeValue(state, 'deliveryCompanyId', () => null)
  utils.changeValue(state, 'deliveryPointId', () => null)
  utils.changeValue(state, 'delivery', () => null)
}

const resetDatetime = (args, state, utils) => {
  utils.changeValue(state, 'date', () => null)
  utils.changeValue(state, 'courierSchedule', () => null)
}

const resetTime = (args, state, utils) => {
  utils.changeValue(state, 'courierSchedule', () => null)
}

const setCity = (args, state, utils) => {
  utils.changeValue(state, 'city', () => args[0])
}

const setDelivery = (args, state, utils) => {
  utils.changeValue(state, 'delivery', () => args[0])
}

const validate = (vals) => {
  const errors = {}

  if(!vals.deliveryType) {
    errors.deliveryType = 'Выберите тип доставки'
  }

  if([2,3].includes(parseInt(vals.deliveryType)) ) {
    
    if(!vals.deliveryCompanyId) {
      errors.deliveryType = 'Выберите компанию доставки'
    }

    if(!vals.street) {
      errors.street = 'Введите название улицы'
    }

    if(!vals.house) {
      errors.house = 'Введите номер дома'
    }

  }


  if([2].includes(parseInt(vals.deliveryType)) ) {

    if(!vals.date) {
      errors.zip = 'Выберите дату доставки'
    }

  }

  if([3].includes(parseInt(vals.deliveryType)) ) {

    if(!vals.zip) {
      errors.zip = 'Введите почтовый индекс'
    }

  }



  if([1].includes(parseInt(vals.deliveryType)) && !vals.deliveryPointId) {
    errors.deliveryType = 'Выберите пункт самовывоза'
  }



  return errors
}

const Total = ({ price }) => {
  if(!price) return null

  return (
    <div style={{marginBottom: 10}}>
      <div>Стоимость доставки:</div>
      <div style={{fontSize: 18, fontWeight: 'bold'}}>{price} ₽</div>
    </div> 
  )
}

const DeliveryBlock = ({ deliveryType, calcResult, resetDelivery, setDelivery, resetDatetime, dimensionsPresent }) => {
  if(!calcResult)
    return null

  return (
    <div style={{marginBottom: 20}}>
      <Field name="deliveryType" onChange={resetDelivery} component={DeliveryTypeField} options={calcResult} />

      {deliveryType == 1 && 
        <>
          <Field name="deliveryPointId" dimensionsPresent={dimensionsPresent} onChangeFull={value => {setDelivery(value);}} component={DeliveryPointField} options={calcResult[1]} />
        </>
      }

      {deliveryType == 2 && 
        <Field name="deliveryCompanyId" dimensionsPresent={dimensionsPresent} onChangeFull={value => {setDelivery(value); resetDatetime()}} component={DeliveryCompanyField} options={calcResult[2]} />
      }
      
      {deliveryType == 3 && 
        <Field name="deliveryCompanyId" dimensionsPresent={dimensionsPresent} onChangeFull={value => {setDelivery(value); }} component={DeliveryCompanyField} options={calcResult[3]} />
      }      
    </div>
  )
}




function ViewDeliveryForm({ order, initialValues = {}, onSubmit, loadCities, calculate, calcResult, loading, error, valid, applying, handleDateChange, schedules, dimensionsPresent }) {
  return (
    <>
      <H4>Доставка</H4>
      
      <H7>Город</H7>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          deliveryType: 0,
          ...initialValues
        }} 
        validate={validate}
        mutators={{ resetDelivery, setCity, setDelivery, resetDatetime, resetTime }}
      >
        {({ handleSubmit, form, submitting, pristine, values, valid, form:{ mutators:{ resetDelivery, setCity, setDelivery, resetDatetime, resetTime } } }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field 
                name="cityId" 
                disabled={loading} 
                options={[]} 
                dValue={initialValues.city && {label: initialValues.city, value: initialValues.cityId}}
                onChange={(cityId) => {calculate(cityId); resetDatetime()}}
                onChangeFull={(value) => setCity(value && value.label)}
                loadOptions={loadCities} 
                component={SelectAsync} 
                label="Начните вводить город" 
              />
              
              <br/>
              <br/>
              
              {loading && 
                <div style={{textAlign: 'center'}}>
                  <Loader />
                  Загрузка способов доставки..
                </div>
              }

              {!loading && error &&
                <div style={{textAlign: 'center', color: 'red'}}>
                  Ошибка загрузки способов доставки
                </div>
              }
              
              {values.cityId && !loading && <DeliveryBlock dimensionsPresent={dimensionsPresent} deliveryType={values.deliveryType} calcResult={calcResult} resetDatetime={resetDatetime} resetDelivery={(a) => {resetDelivery(a); }} setDelivery={setDelivery}/>}

              {values.deliveryCompanyId && !loading && <AddressBlock cityId={values.cityId} withZip={values.deliveryType == 3}/>}
              
              {values.deliveryType === 2 && !loading && values.deliveryCompanyId && <DateBlock minDate={values.delivery.delivery_min_date} schedules={schedules} handleDateChange={(date) => {handleDateChange(values.cityId, values.deliveryCompanyId, date); resetTime()}} />}
              

              {dimensionsPresent && <Total price={values.delivery && values.delivery.total_price}/>}

              {!dimensionsPresent && 
                <div style={{marginBottom: 20, marginTop: 20}}>
                  Стоимость доставки для данного заказа будет рассчитана после проверки заказа дилером.
                </div>
              }

              <Button loading={applying} disabled={!valid || loading} onClick={handleSubmit} loading={submitting} style={{marginBottom: 10}}>
                {order ? 'Редактировать' : 'Добавить к заказу'}
              </Button>
            </div>
          </form>
        )} 
      </Form>      
    </>
  )
}

export default ViewDeliveryForm;  






 
import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const Block = styled.div`
  padding: 80px 0;
  background: #fff;
  @media (max-width: ${vars.bp.tablet}px) {
    padding: 40px 0;
  }    
  @media (max-width: ${vars.bp.mobile}px) {
    padding: 20px 0;
  }      
  ${({small}) => small && css`
    padding: 40px 0;
    @media (max-width: ${vars.bp.tablet}px) {
      padding: 20px 0;
    }        
  `}
  ${({grey}) => grey && css`
    background: ${vars.bg};
  `}  
`


import { createSelector } from 'reselect';


import { makeSelectCurrentDomain } from 'containers/App/selectors'
import { selectEntities } from 'models/selectors';


const selectRoute = (state, props) => props.computedMatch

const modelMap = {
  "yeti": "Йети",
  "superb": "Суперб",
  "rapid": "Рапид",
  "octavia": "Октавия",
  "novyj-superb": "Суперб",
  "kodiaq": "Кодиак",
  "novyj-rapid": "Рапид",
  "karoq": "Карок",
  "novaya-octavia": "Октавия",
}

const modelMap2 = {
  "yeti": "YETI",
  "superb": "SUPERB",
  "rapid": "RAPID",
  "octavia": "OCTAVIA",
  "novyj-superb": "SUPERB",
  "kodiaq": "KODIAQ",
  "novyj-rapid": "RAPID",
  "karoq": "KAROQ",
  "novaya-octavia": "OCTAVIA",
}

const newMap = {
  'novyj-superb': 'нового',
  'novyj-rapid': 'нового',
  'novaya-octavia': 'новой'
}

const newMap2 = {
  'novyj-superb': 'новый',
  'novyj-rapid': 'новый',
  'novaya-octavia': 'новая'
}

const categoryOnly = {
  "avtokosmetika": {
    h1: 'Автокосметика ŠKODA',
    metaTitle: 'Автокосметика ŠKODA – купите автокосметику и автохимию Шкода у официальных дилеров',
    metaDescription: 'Оригинальные запчасти и аксессуары ŠKODA. Автокосметика для автомобилей ŠKODA. Закажите автокосметику и автохимию для автомобилей у официальных дилеров.'
  },
  "elektrooborudovanie": {
    h1: 'Электрооборудование ŠKODA',
    metaTitle: 'Электрооборудование ŠKODA – купите электрооборудование Шкода у официальных дилеров',
    metaDescription: 'Оригинальные запчасти и аксессуары ŠKODA. Электрооборудование для автомобилей ŠKODA. Закажите аксессуары для автомобилей у официальных дилеров.'
  },
  "kolesa-i-diski": {
    h1: 'Колеса и диски ŠKODA',
    metaTitle: 'Колеса и диски ŠKODA – купите оригинальные диски Шкода у официальных дилеров',
    metaDescription: 'Оригинальные запчасти и аксессуары ŠKODA. Колеса и диски для автомобилей ŠKODA. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "muzyka-i-svyaz": {
    h1: 'Музыка и связь ŠKODA',
    metaTitle: 'Магнитолы ŠKODA – купите штатные автомагнитолы Шкода у официальных дилеров',
    metaDescription: 'Оригинальные аксессуары ŠKODA. Магнитолы для автомобилей ŠKODA. Закажите штатные автомагнитолы для автомобилей у официальных дилеров.'
  },
  "pervaya-pomosh": {
    h1: 'Товары первой помощи ŠKODA',
    metaTitle: 'Первая помощь ŠKODA – купите скребки для льда, щетки для снега и буксировочные тросы Шкода',
    metaDescription: 'Оригинальные аксессуары ŠKODA. Товары первой помощи для автомобилей ŠKODA. Закажите скребки для льда, буксировочные тросы для автомобилей у официальных дилеров.'
  },
  "sport": {
    h1: 'Спортивные аксессуары ŠKODA',
    metaTitle: 'Тюнинг ŠKODA – купите накладки, спойлеры и педали для автомобилей Шкода',
    metaDescription: 'Оригинальные запчасти и аксессуары ŠKODA. Спортивные аксессуары для автомобилей ŠKODA. Закажите накладки, спойлеры и педали для автомобилей у официальных дилеров.'
  },
  "suvenirnaya-produkciya": {
    h1: 'Сувенирная продукция ŠKODA',
    metaTitle: 'Сувениры ŠKODA – купите часы, очки, бейсболки, брелоки и ручки с логотипом Шкода',
    metaDescription: 'Оригинальные запчасти и аксессуары ŠKODA. Сувенирная продукция ŠKODA. Закажите часы, очки, бейсболки, брелоки и ручки с логотипом ŠKODA у официальных дилеров.'
  },
  "transportirovka": {
    h1: 'Товары для транспортировки ŠKODA',
    metaTitle: 'Транспортировка ŠKODA – багажники на крышу, детские кресла Шкода и крепления для велосипеда',
    metaDescription: 'Оригинальные аксессуары ŠKODA. Товары для транспортировки для автомобилей ŠKODA. Закажите багажники на крышу, детские кресла для автомобилей у официальных дилеров.'
  },
  "tyagovo-scepnye-ustrojstva": {
    h1: 'Тягово–сцепные устройства ŠKODA',
    metaTitle: 'Фаркопы ŠKODA – купите тягово–сцепные устройства для Шкода у официальных дилеров',
    metaDescription: 'Оригинальные запчасти и аксессуары ŠKODA. Тягово–сцепные устройства для автомобилей ŠKODA. Закажите фаркопы для автомобилей у официальных дилеров.'
  },
  "vneshnee-oborudovanie": {
    h1: 'Внешнее оборудование ŠKODA',
    metaTitle: 'Внешнее оборудование ŠKODA – купите брызговики, дефлекторы, накладки на пороги и бамперы Шкода',
    metaDescription: 'Оригинальные аксессуары ŠKODA. Внешнее оборудование для автомобилей ŠKODA. Закажите брызговики, дефлекторы, накладки на пороги и бамперы у официальных дилеров.'
  },
  "vnutrennee-oborudovanie": {
    h1: 'Внутреннее оборудование ŠKODA',
    metaTitle: 'Внутреннее оборудование ŠKODA – купите коврики в багажник и салон, сетки в багажник и сумки Шкода',
    metaDescription: 'Оригинальные аксессуары ŠKODA. Внутреннее оборудование для автомобилей ŠKODA. Закажите коврики в багажник и салон, сетки в багажник у официальных дилеров.'
  },
}

const modelOnly = {
  "yeti": {
    h1: 'Аксессуары для ŠKODA YETI',
    metaTitle: 'Аксессуары для ŠKODA YETI – купите оригинальные аксессуары для Шкода Йети у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA YETI. Закажите аксессуары и запасные части для автомобилей ŠKODA у официальных дилеров.'
  },
  "superb": {
    h1: 'Аксессуары для ŠKODA SUPERB',
    metaTitle: 'Аксессуары для ŠKODA SUPERB – купите оригинальные аксессуары для Шкода Суперб у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA SUPERB. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "rapid": {
    h1: 'Аксессуары для ŠKODA RAPID',
    metaTitle: 'Аксессуары для ŠKODA RAPID – купите оригинальные аксессуары для Шкода Рапид у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA RAPID. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "octavia": {
    h1: 'Аксессуары для ŠKODA OCTAVIA',
    metaTitle: 'Аксессуары для ŠKODA OCTAVIA – купите оригинальные аксессуары для Шкода Октавия у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA OCTAVIA. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "novyj-superb": {
    h1: 'Аксессуары для нового ŠKODA SUPERB',
    metaTitle: 'Аксессуары для нового ŠKODA SUPERB – купите оригинальные аксессуары для нового Шкода Суперб у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA Новый SUPERB. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "kodiaq": {
    h1: 'Аксессуары для ŠKODA KODIAQ',
    metaTitle: 'Аксессуары для ŠKODA KODIAQ – купите оригинальные аксессуары для Шкода Кодиак у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA KODIAQ. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "novyj-rapid": {
    h1: 'Аксессуары для нового ŠKODA RAPID',
    metaTitle: 'Аксессуары для нового ŠKODA RAPID – купите оригинальные аксессуары для нового Шкода Рапид у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для нового ŠKODA RAPID. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "karoq": {
    h1: 'Аксессуары для ŠKODA KAROQ',
    metaTitle: 'Аксессуары для ŠKODA KAROQ – купите оригинальные аксессуары для Шкода Карок у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для ŠKODA KAROQ. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
  "novaya-octavia": {
    h1: 'Аксессуары для новой ŠKODA OCTAVIA',
    metaTitle: 'Аксессуары для новой ŠKODA OCTAVIA – купите оригинальные аксессуары для новой Шкода Октавия у официальных дилеров',
    metaDescription: 'Оригинальные детали ŠKODA. Аксессуары для новой ŠKODA OCTAVIA. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  },
}


const categoryAndModel = (modelSlug) => ({
  "avtokosmetika": {
    h1: `Автокосметика для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Автокосметика для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите автокосметику и автохимию для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]} у официальных дилеров`,
    metaDescription: `Оригинальные запчасти и аксессуары ŠKODA. Автокосметика для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите автокосметику и автохимию для автомобилей у официальных дилеров.`
  },
  "elektrooborudovanie": {
    h1: `Электрооборудование для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Электрооборудование для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите электрооборудование для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]} у официальных дилеров`,
    metaDescription: `Оригинальные запчасти и аксессуары ŠKODA. Электрооборудование для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите аксессуары для автомобилей у официальных дилеров.`
  },
  "kolesa-i-diski": {
    h1: `Колеса и диски для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Колеса и диски для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите оригинальные диски для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]} у официальных дилеров`,
    metaDescription: `Оригинальные запчасти ŠKODA. Колеса и диски для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.`
  },
  "muzyka-i-svyaz": {
    h1: `Музыка и связь для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Магнитолы для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите штатные автомагнитолы для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]} у официальных дилеров`,
    metaDescription: `Оригинальные аксессуары ŠKODA. Магнитолы для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите штатные автомагнитолы для автомобилей у официальных дилеров.`
  },
  "pervaya-pomosh": {
    h1: `Товары первой помощи для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Первая помощь для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите скребки для льда, щетки для снега и буксировочные тросы для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]}`,
    metaDescription: `Оригинальные аксессуары ŠKODA. Товары первой помощи для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите скребки для льда, буксировочные тросы у официальных дилеров.`
  },
  "sport": {
    h1: `Спортивные аксессуары ${newMap2[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Тюнинг для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите накладки, спойлеры и педали для автомобилей ${newMap2[modelSlug] || ''} Шкода ${modelMap[modelSlug]}`,
    metaDescription: `Оригинальные запчасти и аксессуары ŠKODA. Спортивные аксессуары для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите накладки, спойлеры педали у официальных дилеров.`
  },
  "suvenirnaya-produkciya": {
    h1: `Сувенирная продукция ${newMap2[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Сувениры ${newMap2[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите часы, очки, бейсболки, брелоки и ручки с логотипом ${newMap2[modelSlug] || ''} Шкода ${modelMap[modelSlug]}`,
    metaDescription: `Оригинальные запчасти и аксессуары ŠKODA. Сувенирная продукция ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}. Закажите часы, очки, брелоки и ручки с логотипом ŠKODA у официальных дилеров.`
  },
  "transportirovka": {
    h1: `Товары для транспортировки для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Транспортировка для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – багажники на крышу, детские кресла для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]} и крепления для велосипеда`,
    metaDescription: `Оригинальные аксессуары ŠKODA. Товары для транспортировки для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите багажники на крышу, детские кресла у официальных дилеров.`
  },
  "tyagovo-scepnye-ustrojstva": {
    h1: `Тягово–сцепные устройства для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Фаркопы для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите тягово–сцепные устройства для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]} у официальных дилеров`,
    metaDescription: `Оригинальные запчасти и аксессуары ŠKODA. Тягово–сцепные устройства для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите фаркопы для автомобилей у официальных дилеров.`
  },
  "vneshnee-oborudovanie": {
    h1: `Внешнее оборудование для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Внешнее оборудование для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите брызговики, дефлекторы, накладки на пороги и бамперы для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]}`,
    metaDescription: `Оригинальные аксессуары ŠKODA. Внешнее оборудование для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите брызговики, накладки на пороги и бамперы у официальных дилеров.`
  },
  "vnutrennee-oborudovanie": {
    h1: `Внутреннее оборудование для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]}`,
    metaTitle: `Внутреннее оборудование для ${newMap[modelSlug] || ''} ŠKODA ${modelMap2[modelSlug]} – купите коврики в багажник и салон, сетки в багажник и сумки для ${newMap[modelSlug] || ''} Шкода ${modelMap[modelSlug]}`,
    metaDescription: `Оригинальные аксессуары ŠKODA. Внутреннее оборудование для автомобилей ŠKODA ${modelMap2[modelSlug]}. Закажите коврики в салон, сетки в багажник у официальных дилеров.`
  },
})

const seoMap = ({ categorySlug, modelSlug, modelName }) => {
  if (categorySlug && !modelSlug) {
    return categoryOnly[categorySlug] || {}
  }

  if (!categorySlug && modelSlug) {
    return modelOnly[modelSlug] || {}
  }

  if (categorySlug && modelSlug) {
    return categoryAndModel(modelSlug)[categorySlug]
  }

  return {
    h1: 'Аксессуары ŠKODA',
    metaTitle: 'Аксессуары ŠKODA – купите оригинальные аксессуары для автомобилей Шкода', 
    metaDescription: 'Оригинальные аксессуары ŠKODA. Купите детали для ŠKODA по низким ценам. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  }
  
}


const indexPage = (domain, entitiesState) => {
  const seo = {
    metaTitle: 'Оригинальные запчасти ŠKODA – купите оригинальные запчасти для автомобилей Шкода', 
    metaDescription: 'Оригинальные запчасти для автомобилей ŠKODA. Закажите запасные части и аксессуары онлайн по артикулу. Купите запчасти у ближайшего официального дилера ŠKODA.'
  }
  try {
    const dealers = entitiesState && entitiesState.get('dealers') && entitiesState.get('dealers').toJS() || {}

    if (domain && domain.isCG === false) {
      const dealer = dealers && dealers[domain.dealerId]
      seo.metaTitle = `Оригинальные запчасти ŠKODA – купите оригинальные запчасти для автомобилей Шкода. Предложения дилера ${dealer.name}`
      seo.metaDescription = `Оригинальные запчасти для автомобилей ŠKODA. Закажите запасные части и аксессуары онлайн по артикулу. Купите запчасти у ближайшего официального дилера ŠKODA ${dealer.name}.`

    }

    if (domain && domain.isCG === true) {
      seo.metaTitle = `Оригинальные запчасти ŠKODA – купите оригинальные запчасти для автомобилей Шкода. Предложения группы компаний ${domain.title}`
      seo.metaDescription = `Оригинальные запчасти для автомобилей ŠKODA. Закажите запасные части и аксессуары онлайн по артикулу. Купите запчасти у ближайшего официального дилера ŠKODA. Предложения группы компаний ${domain.title}.`
    }

    return seo
  } catch(e) {
    return seo
  }
}


const accessoriesPage = (domain, entitiesState, matchParams) => {
  const seo = {
    h1: 'Аксессуары ŠKODA',
    metaTitle: 'Аксессуары ŠKODA – купите оригинальные аксессуары для автомобилей Шкода', 
    metaDescription: 'Оригинальные аксессуары ŠKODA. Купите детали для ŠKODA по низким ценам. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.'
  }


  try {
    const dealers = entitiesState && entitiesState.get('dealers') && entitiesState.get('dealers').toJS() || {}
    const categories = entitiesState && entitiesState.get('categories') && entitiesState.get('categories').toJS() || {}
    const models = entitiesState && entitiesState.get('models') && entitiesState.get('models').toJS() || {}
    const categoryName = (Object.values(categories).find((one) => one.slug === matchParams.categorySlug) || {}).name;
    const categorySlug = (Object.values(categories).find((one) => one.slug === matchParams.categorySlug) || {}).slug;
    const modelName = (Object.values(models).find((one) => one.slug && (one.slug === matchParams.modelSlug)) || {}).name;
    const modelSlug = (Object.values(models).find((one) => one.slug && (one.slug === matchParams.modelSlug)) || {}).slug;

    // const baseName = categoryName || 'Оригинальные аксессуары'
    // const carName = modelName ? `ŠKODA ${modelName}` : 'ŠKODA'
    // console.log({ categorySlug, modelName, modelSlug })
    let seo = seoMap({ categorySlug, modelName, modelSlug })
    // console.log({ domain, seo })
    // const seo = {
    //   metaTitle: `${baseName} ${carName}. Предложения от официальных дилеров ŠKODA`, 
    //   metaDescription: `${baseName} ${carName}. Каталог предложений от официальных дилеров ŠKODA`
    // }
    const resultSeo = {...seo}

    if (domain && domain.isCG === false) {
      const dealer = dealers && dealers[domain.dealerId]
    //   seo.metaTitle = `${baseName} ${carName}. Предложения дилера ${dealer.name}`
    //   seo.metaDescription = `${baseName} ${carName}. Каталог предложений официального дилера ŠKODA ${dealer.name}.`
      resultSeo.metaTitle = `${seo.metaTitle}. Предложения дилера ${dealer.name}`
      resultSeo.metaDescription = `${seo.metaDescription}. Предложения дилера ${dealer.name}`
    }

    if (domain && domain.isCG === true) {
    //   seo.metaTitle = `${baseName} ${carName}. Предложения группы компаний ${domain.title}`
    //   seo.metaDescription = `${baseName} ${carName}. Каталог предложений группы компаний ${domain.title}.`
      resultSeo.metaTitle = `${seo.metaTitle}. Предложения группы компаний ${domain.title}`
      resultSeo.metaDescription = `${seo.metaDescription}. Предложения группы компаний ${domain.title}`    
    }

    return resultSeo
  } catch(e) {
    console.log(e)
    return seo
  }
}


export const makeSelectSeoTags =  createSelector( 
  makeSelectCurrentDomain(),
  selectEntities,
  selectRoute,
  (domain, entitiesState, routeState) => {

    const matchParams = routeState.params

    // index page
    if (routeState.path === '/') {
      return indexPage(domain, entitiesState, matchParams)
    }

    // accessories catalog
    if (['/accessories', '/accessories/:modelSlug', '/accessories/category/:categorySlug', '/accessories/:modelSlug/:categorySlug']
        .includes(routeState.path)) {
      return accessoriesPage(domain, entitiesState, matchParams)
    }

    // accessory catalog

    return {}
  }
);

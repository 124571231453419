import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const TabWrapper = styled.div`
  display: flex;
  ${({ center }) => center && css`
    justify-content: center;
  `}
`

export const Tab = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 0 7px 8px;
  padding: 0 10px;
  cursor: pointer;
  color: ${vars.darkGrey};
  ${({ active }) => active && css`
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      right: 0;
      height: 5px;
      box-shadow: inset 0px -5px 0px ${vars.green};
    }
  `}
`

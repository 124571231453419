/*
 * SsoReducer
 *
 */

import { fromJS } from 'immutable';


import {
  SET_USER,
  COMPLETE_SSO_AUTH_CHECK,
  SET_SCOPES,
  SET_CLIENT,
  SET_LOGGING_IN,
} from './constants';

const initialState = fromJS({
  user: null,
  authChecking: true,
  scopes: [],
  client: null,
  loggingIn: false,
});

function ssoReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return state
        .set('user', action.user);
    case SET_CLIENT:
      return state
        .set('client', action.client);        
    case COMPLETE_SSO_AUTH_CHECK:
      return state.set('authChecking', false);
    case SET_SCOPES:
      return state.set('scopes', action.scopes);
    case SET_LOGGING_IN:
      return state.set('loggingIn', action.loggingIn)
    default:
      return state;
  }
}

export default ssoReducer;

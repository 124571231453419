/**
 *
 * Checkout
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import {
  orderData, itemsByDealers, cartPresent, formatPrice, formatPhoneNumber,
} from 'helpers';


import { EDIT_CLIENT } from 'components/Popup/constants';

import { makeSelectCurrentCartId, makeSelectPageData, makeSelectCurrentUser } from 'containers/App/selectors';
import { makeSelectEntities } from 'models/selectors';
import { makeSelectPreorderItems } from 'containers/OrderComments/selectors'
import { setPreorderItem } from 'containers/OrderComments/actions'
import { makeOrder, setPopup, setPageData } from 'containers/App/actions';
import { refreshDelivery } from 'containers/Delivery/actions'
import { selectApplying } from 'containers/Delivery/selectors'

import { push } from 'connected-react-router';

import DealerOrder from 'components/DealerOrder';
import Button from 'components/Button';
import Container from 'components/Container';
import OrderSuccess from './OrderSuccess';
import DealerOrderCheckout from 'components/DealerOrder/Checkout';
import TotalSummary from 'components/TotalSummary';

import { H1, Block, Gap, Gapper } from 'components/Ui';

import Card from 'components/Card'
import CardContainer from 'components/CardContainer'
import PriceDisclaimer from 'components/PriceDisclaimer';
import Sbutton from 'components/Sbutton'
import ContactNote from 'components/ContactNote'
import ErrorsNote from 'components/ErrorsNote'
import { getBonuses, removeBonus } from 'containers/BonusPopup/actions'
import * as metricsActions from 'containers/App/metricsActions'


export class Checkout extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      status: 0,
      errors: {},
    };
    this.makeOrder = this.makeOrder.bind(this);
  }

  componentDidMount () {
    const { cartId, carts, items, products, refreshDelivery, preorderItems } = this.props;

    const dealerItems = itemsByDealers(items, cartId, products);
    const ids = Object.keys(dealerItems)
    ids.map(id => {
      const item = preorderItems[id] || {}
      refreshDelivery({ dealerId: id, paid: (item.payType || 0) === 1 })
    })


    if (!cartPresent(cartId, carts)) {
      this.props.push('/cart');
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps)
    const { preorderItems, refreshDelivery } = this.props
    Object.keys(preorderItems || {}).map(dealerId => {
      const item = preorderItems[dealerId]
      const prevItem = (prevProps.preorderItems || {})[dealerId] || {}
      if(item.payType != prevItem.payType) {
        refreshDelivery({ dealerId, paid: item.payType === 1 })
      }

    })
  }

  makeOrder () {
    const { sendRaw } = this.props
    this.setState({ loading: true });
    
    return new Promise((resolve, reject) => {
      sendRaw('Purchase_RequestForm', 'submit')
      this.setState({ errors: {} })
      this.props.makeOrder({ resolve, reject });
    }).then((orderIds) => {  
      sendRaw('Purchase_RequestForm', 'success')
      this.props.setCartPageData('justOrdered', true); this.props.push(`/cart/success/${orderIds}`) 
    }).catch((errors) => { this.setState({ errors: errors, status: 0, loading: false }); });
  }

  handleBonusRemove(dealerId) {
    new Promise((resolve, reject) => {
      this.props.removeBonus({ resolve, reject, dealerId }) 
    }).then(() => {
    }).catch(e => {
    })
  }  

  render () {
    const {
      cartId, users, items, dealers, products, clients, currentUser, clientId, 
      setPopup, payables, setPreorderItem, moditems, preorderItems, deliverables, deliveries,
      deliveryLoading, sendRaw
    } = this.props;
    const { itemsPrice, price, modprice, deliveryPrice} = orderData(items, cartId, moditems, preorderItems, deliveries);
    const { loading } = this.state
    const dealerItems = itemsByDealers(items, cartId, products);
    const user = currentUser && users && users[currentUser.id];
    const client = clientId && clients && clients[clientId];
    // if(this.state.loading)
    //   return (
    //     <div>загрузка</div>
    //   )
    const deliveriesPresent = Object.keys(preorderItems).reduce((acc, id) => {
      const item = preorderItems[id]
      if(item.deliveryType !== 1) return acc
      return acc && deliveries[id]
    }, true)

    const submitDisabled = !deliveriesPresent || deliveryLoading || loading



    if (this.state.status === 1)
      return <OrderSuccess />;

    const hasErrors = Object.keys(this.state.errors).length > 0
    return (
      <>
        <Helmet>
          <title>
            Оформление заказа
          </title>
          <meta name="description" content="Оформление заказа" />
        </Helmet>
        <Block grey>
          <Container>
            <H1 center>
              Оформление заказа
            </H1>    

            <Gapper gap={20}>
              {Object.keys(dealerItems).map((dealerId, index) => {
                const dealer = dealers && dealers[dealerId] || {}
                const payable = payables[dealer.kod]
                const deliverable = deliverables[dealer.kod]
                return (
                  <DealerOrderCheckout 
                    index={index}
                    errors={this.state.errors}
                    key={`dealer-order-${dealerId}`}
                    dealer={dealer}
                    items={dealerItems[dealerId]}
                    setPreorderItem={(name, value) => setPreorderItem(dealerId, name, value)}
                    preorderItems={preorderItems[dealerId] || {}}
                    moditem={moditems[dealerId]}
                    delivery={deliveries[dealerId]}
                    deliveryLoading={deliveryLoading}
                    payable={payable}
                    deliverable={deliverable}
                    removeBonus={() => this.handleBonusRemove(dealerId)}
                    user={user}
                    setPopup={setPopup}
                    checkout
                    sendRaw={sendRaw}
                  />
                )})
              }
            </Gapper>

            <TotalSummary preorderItems checkout modprice={modprice} deliveryPrice={deliveryPrice} itemsPrice={itemsPrice} price={price} />

 


            <CardContainer> 
              <Card d={8} t={12}>
                <div style={{textAlign: 'right'}}>
                  <Sbutton disabled={submitDisabled} onClick={() => {!submitDisabled && this.makeOrder() }}>Оформить заказ{Object.keys(dealerItems).length > 1 ? 'ы' : ''}</Sbutton>
                </div>              
              </Card>
              <Card d={4} t={12}>
                { !hasErrors &&
                  <ContactNote />
                }
                { hasErrors && 
                  <ErrorsNote />
                }
              </Card>        
            </CardContainer>

          </Container>
        </Block>


      </>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  cartId: makeSelectCurrentCartId(),
  carts: makeSelectEntities('carts'),
  items: makeSelectEntities('items'),
  dealers: makeSelectEntities('dealers'),
  products: makeSelectEntities('products'),
  payables: makeSelectEntities('payables'),
  deliverables: makeSelectEntities('deliverables'),
  clients: makeSelectEntities('clients'),
  users: makeSelectEntities('users'),
  cartPageData: makeSelectPageData('cart'),
  preorderItems: makeSelectPreorderItems(),
  user: makeSelectCurrentUser(),
  deliveryLoading: selectApplying(),
});

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    makeOrder: (payload) => dispatch(makeOrder(payload)),
    push: (url) => dispatch(push(url)),
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
    setCartPageData: (name, value) => dispatch(setPageData({ page: 'cart', name, value })),
    setPreorderItem: (dealerId, name, value) => dispatch(setPreorderItem(dealerId, name, value)),
    removeBonus: payload => dispatch(removeBonus(payload)),
    refreshDelivery: payload => dispatch(refreshDelivery(payload)),
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Checkout);

export const actionTypes = {
  GET_DEALER_ORDERS_INFO: "parts/AdminStats2/GET_DEALER_ORDERS_INFO",
  GET_DEALER_GRAPH_INFO: "parts/AdminStats2/GET_DEALER_GRAPH_INFO"
}

export const getDealerOrdersInfo = (payload) => {
  return {
    type: actionTypes.GET_DEALER_ORDERS_INFO,
    payload
  }
}

export const getDealerGraphInfo = (payload) => {
  return {
    type: actionTypes.GET_DEALER_GRAPH_INFO,
    payload
  }
}
import React from 'react';
import styled, { css } from 'styled-components'
import { H7, Gapper } from 'components/Ui'

import vars from 'config/styles'
import { deliveryTypes } from '../settings'


const Wrapper = styled.div`
  margin-bottom: 10px;
`

const Items = styled.div`
  display: flex;

`

const Item = styled.div`
  width: 150px;
  margin-right: 20px;
  border: 1px solid ${vars.green};
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  ${({ active }) => active && css`
    color: #fff;
    background: ${vars.green};
  `}
`

export const DeliveryTypeField = (field) => {
  const { options, onChange: onChangeSide } = field
  const { value, onChange, ...rest } = field.input;
  // const vals = Object.keys(options || {}).map(id => {
  //   return ({label: options[id].type_name, value: id})
  // }) || []
  const vals = [2,1,3].map(id => {
    return ({label: deliveryTypes[id], value: id})
  })
  return (
    <Wrapper>
      <Gapper>
        <H7>Тип доставки</H7>
        <Items>
          {vals.map(type => (
            <Item active={value == type.value} onClick={() => {onChangeSide(); onChange(type.value)}}>
              {type.label}
            </Item>
          ))}
        </Items>
      </Gapper>
    </Wrapper>
  );
};

export default DeliveryTypeField;

import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const Gapper = styled.div`
  & > * {
    margin-bottom: 10px;
  }

  ${({ gap }) => gap && css`
    & > * {
      margin-bottom: ${gap}px;
    }
  `}

  & > *:last-child {
    margin-bottom: 0;
  }
`

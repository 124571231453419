import React from 'react';

import Container from 'components/Container';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux'
import { Link, withRouter } from 'react-router-dom';
import { setPopup } from 'containers/App/actions'


import { makeSelectCurrentUser } from 'containers/App/selectors'

import FooterTop from './FooterTop'
import FooterBottom from './FooterBottom'
import { Wrapper } from './styled'

function Footer({ categories, models, setPopup, currentUser }) {

  return (
    <Wrapper>
      <FooterTop categories={categories} models={models} currentUser={currentUser} setPopup={setPopup} />

      <FooterBottom />
    </Wrapper>
  );
}



const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    setPopup: (id) => dispatch(setPopup(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)


export default compose(
  withConnect,
  withRouter
)(Footer)


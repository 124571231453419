import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { register, updateUser, restorePassword, checkToken, getCurrentUser, authping } from 'api/users';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler, setMessage } from 'helpers';
import { apiRequest } from 'containers/App/saga';

import { normalize } from 'normalizr';

import { setEntities } from 'models/actions';
import { setPageData } from 'containers/App/actions';

import { User } from 'models';
import { EDIT_USER, RESTORE_PASSWORD, CHECK_TOKEN, GET_CURRENT_USER, REGISTER } from './constants';
import { FINISH_AUTH } from 'containers/Sso/constants';


export function * editUserGen (action) {
  const { payload: { data, resolve, reject } } = action;
  try {
    let user = null;
    if (!data.id) {
      user = yield call(apiRequest, register, data);
    } else {
      user = yield call(apiRequest, updateUser, data);
    }
    const normalized = normalize(user, User);
    yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export function* finishAuthReg() {
  try {
    yield call(apiRequest, authping);
  } catch(e) {
    console.log(e)
  }
}

export function * checkTokenGen (action) {
  const { payload: { token, resolve, reject } } = action;
  try {
    yield call(apiRequest, checkToken, token);
    resolve();
  } catch (err) {
    reject(err && err.response && err.response.errors && err.response.errors.token)
  }
}

export function * registerGen (action) {
  const { payload: { data, resolve, reject } } = action;
  try {
    yield call(apiRequest, register, data);
    yield put(setPageData({ page: 'registration', name: 'submitted', value: true }));
    resolve();
    // const normalized_user = normalize(user.user, User)
    // yield put(setEntities(normalized_user))
    // toastr.success('Логин', 'Вход успешно выполнен')
  } catch (e) {
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}


export default function * usersSaga () {
  yield all([
    takeEvery(EDIT_USER, editUserGen),
    takeEvery(FINISH_AUTH, finishAuthReg),
    takeEvery(CHECK_TOKEN, checkTokenGen),
    takeEvery(REGISTER, registerGen)
  ]);
}


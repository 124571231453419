import moment from 'moment';


export const orderTimers = {
  preExpired: moment.duration(1, 'hours'), // Отправка почты дилеру за час до истечения срока обработки
  expired: moment.duration(2, 'hours'), // Отправка почты дилеру после истечения срока обработки
  dayAfter: moment.duration(24, 'hours') // Отправка почты региональному менеджеру

}

export const genders = {
  0: 'Не указан',
  1: 'Мужской',
  2: 'Женский',
};

export const ROLES = {
  // GUEST: 0,
  USER: 1,
  DEALER: 2,
  IMPORTER: 3,
  ADMIN: 10,
};

export const ROLES_TRANSLATION = {
  // 0: 'Гость',
  1: 'Пользователь',
  2: 'Дилер',
  3: 'Импортер',
  10: 'Админ',
};

export const ORDER_STATUSES = {
  CURRENT: 0,
  ACTIVE: 1,
  FINISHED: 5,
  CANCELLED: 10,
};

export const ORDER_STATUSES_TRANSLATION = {
  0: 'Новый',
  1: 'В работе',
  5: 'Выполнен',
  10: 'Отменён',
};

export const PAYMENT_STATUSES = {
  UNPAID: 0,
  PAID: 1,
  DEPOSITED: 2,
  REVERSED: 3,
  REFUNDED: 4,
  PAYING: 5,
  CANCELLED: 6
}

export const PAYMENT_STATUSES_TRANSLATION = {
  0: 'Не оплачен',
  1: 'Оплачен', //предавторизованная сумма удержана (для двухстадийных платежей);
  2: 'Оплачен', // проведена полная авторизация суммы заказа;
  3: 'Оплата отклонена', //авторизация отменена;
  4: 'Оплата возвращена', //по транзакции была проведена операция возврата;
  5: 'В процессе оплаты', // инициирована авторизация через сервер контроля доступа банка-эмитента;
  6: 'Оплата отклонена' //авторизация отклонена.
}

export const PAYMENT_STATUSES_EXPLAINED = {
  0: 'Заказ не оплачен',
  1: 'Предавторизованная сумма удержана', //предавторизованная сумма удержана (для двухстадийных платежей);
  2: 'Проведена полная авторизация суммы заказа', // проведена полная авторизация суммы заказа;
  3: 'Оплата отклонена', //авторизация отменена;
  4: 'По транзакции была проведена операция возврата', //по транзакции была проведена операция возврата;
  5: 'В процессе оплаты', // инициирована авторизация через сервер контроля доступа банка-эмитента;
  6: 'Истекло время ожидания оплаты.' //авторизация отклонена.
}

export const PAYMENT_STATUSES_EXPLAINED_USER = {
  0: 'Заказ не оплачен',
  1: 'Проверка платежа', //предавторизованная сумма удержана (для двухстадийных платежей);
  2: 'Оплата прошла успешно', // проведена полная авторизация суммы заказа;
  3: 'Оплата отклонена', //авторизация отменена;
  4: 'Средства возвращены', //по транзакции была проведена операция возврата;
  5: 'В процессе оплаты', // инициирована авторизация через сервер контроля доступа банка-эмитента;
  6: 'Истекло время ожидания оплаты.' //авторизация отклонена.
}



export const PROCESS_STATUSES_TRANSLATION = {
  0: 'Новый',
  5: 'В работе',
  10: 'Завершен',
  20: 'Просрочен'
}

export const NOTIFICATION_STATUSES_TRANSLATION = {
  0: 'Новый',
  1: 'В работе',
  5: 'Выполнен',
};

export const MENU = {
  GUEST: -1,
  USER: 1,
  DEALER: 2,
  IMPORTER: 3,
  ADMIN: 4,
  MOBILE: 10,
};

export const refuseReasons = [
  'Дорого',
  'Отсутствует желаемый способ доставки',
  'Долго ждать/нет в наличии',
]

export const DEALER_TYPES = {
  0: 'Ответственный сотрудник',
  1: 'Руководитель отдела запасных частей'
}

export const ORDER_EMAIL_EXPIRATION = {
  preExpired: 'Повторное сообщение через 1 час',
  expired: 'Повторное сообщение через 2 часа',
  dayAfter: 'Повторное сообщение через 24 часа',
  // LEGACY EXPIRATION LABELS
  initial: 'Повторное сообщение через 3 часа',
  final: 'Повторное сообщение через 24 часа',
};

export const MENU_ITEMS = {
  common: [
    { title: 'Аксессуары', link: '/accessories' },
    { title: 'Оригинальные детали', link: '/' },

    { title: 'Контакты', link: '/contacts', onlyDealer: true },
  ],

  userSubmenu: [
    { title: 'Профиль', link: '/profile', code: 'profile' },
    { title: 'Избранное', link: '/favs', code: 'favs' },
    { title: 'Сравнение', link: '/compare', code: 'compare' },

    { title: 'Корзина', link: '/cart', code: 'cart' },
    { title: 'Заказы', link: '/orders', code: 'orders' },
    { title: 'Заявки на подбор', link: '/requests', code: 'requests' },
  ],

  dealerSubmenu: [
    { title: 'Профиль', link: '/profile', code: 'profile' },
    { title: 'Избранное', link: '/favs', code: 'favs' },
    { title: 'Сравнение', link: '/compare', code: 'compare' },
    { title: 'Управление', link: '/dashboard', code: 'admin', wrapper: true },
    { title: 'Заказы', link: '/dashboard/orders', code: 'dealerOrders', wrapper: true },
    { title: 'Заявки на подбор', link: '/dashboard/podbors', code: 'dealerRequests', wrapper: true },
  ],

  importerSubmenu: [
    { title: 'Профиль', link: '/profile', code: 'profile' },
    { title: 'Избранное', link: '/favs', code: 'favs' },
    { title: 'Сравнение', link: '/compare', code: 'compare' },
    { title: 'Заказы', link: '/orders', code: 'orders' },
    { title: 'Заявки на подбор', link: '/requests', code: 'requests' },

    { title: 'Управление', link: '/admin', code: 'admin', wrapper: true },
  ],

  adminSubmenu: [
    { title: 'Профиль', link: '/profile', code: 'profile' },
    { title: 'Избранное', link: '/favs', code: 'favs' },
    { title: 'Сравнение', link: '/compare', code: 'compare' },
    { title: 'Заказы', link: '/orders', code: 'orders' },
    { title: 'Заявки на подбор', link: '/requests', code: 'requests' },
    { title: 'Управление', link: '/admin', code: 'admin', wrapper: true },
  ],

};

export const updateTypes = {
  0: 'Предложения дилеров',
  1: 'Рекомендованные цены',
};

export const updateStatuses = {
  1: 'Завершено',
  0: 'Не завершено',
  '-1': 'Завершено с ошибками',
  '-2': 'Ошибка',
};

export const sloganXPositions = {
  left: 'Слева',
  center: 'По центру',
  right: 'Справа',
};

export const sloganYPositions = {
  top: 'Сверху',
  center: 'По центру',
  bottom: 'Снизу',
};

export const pages = {
  guarantee: '/pages/return',
}

export const deliveryTypes = {
  PICK: 0,
  DELIVERY: 1,
  CITY_DELIVERY: 2
}

export const payTypes = {
  ON_PICK: 0,
  ONLINE: 1
}

export const googleApiKey = 'AIzaSyBsmEEUBK4AH2A_JTFoSbp1pnkNMqZw5C8';

/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getPayableStatus () {
  return apicall('dealers/payment_status');
}


export function makePayment (orderId) {
  return apicall('payments', { method: 'POST', body: JSON.stringify({ payment: { orderId } }) });
}

export function refreshStatus (orderId) {
  return apicall(`payments/${orderId}`, { method: 'GET' });
}

export function deposit (orderId, amount = 0) {
  return apicall(`payments/${orderId}/deposit`, { method: 'POST', body: JSON.stringify({ payment: { amount } }) });
}

export function reverse (orderId) {
  return apicall(`payments/${orderId}/reverse`, { method: 'POST' });
}

export function refund (orderId, amount = 0) {
  return apicall(`payments/${orderId}/refund`, { method: 'POST', body: JSON.stringify({ payment: { amount } }) });
}
/**
*
* FiltersHoc
*
*/

import React from 'react';

import { compose } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import 'css/components/filter.sass';
import Button from 'components/Button';

const wrapper = (options) => (Component) => {
  class FiltersHoc extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render () {
      const {
        handleSubmit, reset, submitting, submit,
      } = this.props;
      return (
        <div className="filter">
          <form onSubmit={handleSubmit}>
            <div className="filter__fields">
              <Component {...this.props} />
            </div>
            <Button loading={submitting} small type="submit">
                Продолжить
            </Button>
            <Button secondary onClick={async () => { await reset(); submit(); }} small type="button">
                Сброс
            </Button>
          </form>

        </div>
      );
    }
  }

  FiltersHoc.propTypes = {

  };

  const withForm = reduxForm({ form: options.name });

  return compose(
    withForm,
  )(FiltersHoc);
};

export default wrapper;

/**
*
* HelpItem
*
*/

import React from 'react';

import {Collapse} from 'react-collapse'

class HelpItem extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.state = {isopen: false}
    this.toggle = this.toggle.bind(this)
  }

  toggle(){
    this.setState((prevState, props) => {
      return {isopen: !prevState.isopen};
    });
  }

  render() {
    const Title = this.props.Title
    return (
      <div className='help'>

        <div className='help__title' onClick={this.toggle}>
          {Title}
        </div>
        <div className='help__content'>
          <Collapse isOpened={this.state.isopen}>
            {this.props.children}
          </Collapse>
        </div>

      </div>
    );
  }
}

HelpItem.propTypes = {

};

export default HelpItem;

import React from 'react'

import { Link } from 'react-router-dom';
import { ROLES } from 'settings'
import classNames from 'classnames';
import { hiddenPath } from './settings';

const ExtraCategories = ({ hidden, currentUser }) => {
  if (!currentUser || currentUser.role < ROLES.DEALER) return null

  const cls = classNames('gmenu__item', { active: hidden });

  return (
    <Link to={`/accessories/${hiddenPath}`} className={cls}>Скрытое</Link>
  )
}

export default ExtraCategories;
import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const H7 = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${vars.black};
  // margin-bottom: 30px;
  ${({ center }) => center && css`
    text-align: center;
  `}

`

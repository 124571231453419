import { ssoBaseUrl } from 'config'
// https://vw-auth.cinimex.ru/auth/realms/vw/protocol/openid-connect/auth?response_type=code&client_id=vwpublic&redirect_uri=http://localhost/auth&scope=openid+email+profilep
const baseUrl = ssoBaseUrl || 'https://idp-test.vwgroup.ru'
export const ssoUrl = `${baseUrl}/auth/realms/vw/protocol/openid-connect`;
// export const clientId = 'vwpublic'
// export const clientId = 'skodaparts-accessories.aspec-lider.ru';
export const clientId = 'skodaparts';
export const adminClientId = "skodapartsadmin";
export const requiredScopes = ['openid', 'email', 'phone', 'address', 'profile'];
export const redirectPath = '/auth';
export const REALMS = {
  vw: 'vw',
  dealers: 'dealers'
}

export const profileUrl = `${baseUrl}/auth/realms/vw/account?referrer=${clientId}`;

/* istanbul ignore file */
import fetch from 'isomorphic-fetch';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON (response) {
  return response;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
// function checkStatus(response) {
//   if (response.status === 204) {
//     return {};
//   }

//   const json = response.json(); // there's always a body
//   if (response.status >= 200 && response.status < 300) {
//     return json;
//   }
//   return json.then((res) => {
//     const error = new Error(response.statusText);
//     error.response = res;
//     error.status = response.status;
//     throw error;
//   });
// }

function checkStatus (response) {
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }

  // parse response
  return response.json().then((json) => ({
    json,
    status: response.status,
    throwError: true,
  }));
}

// this checks if response had an error and in this case it throws it
function checkException (response) {

  if (response.throwError === true) {
    // console.log(response)
    const error = new Error(response.statusText);
    error.response = response.json;
    error.status = response.status;

    throw error;
  }

  return response;
}

function checkStatusDownload (response) {
  if (response.status === 204) {
    return {};
  } if (response.status >= 200 && response.status < 300) {
    return response.blob();
  }
  const json = response.json(); // there's always a body
  return json.then((res) => {
    const error = new Error(response.statusText);
    error.response = res;
    error.status = response.status;
    throw error;
  });
}

function checkStatusPagination (response) {
  if (response.status === 204) {
    return {};
  }
  const json = response.json(); // there's always a body
  if (response.status >= 200 && response.status < 300) {
    return { pagination: response.headers.get('X-Pagination'), body: json };
  }

  return json.then((res) => {
    const error = new Error(response.statusText);
    error.response = res;
    error.status = response.status;
    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export function requestWithPagination (url, options) {
  return fetch(url, options)
    .then(checkStatusPagination)
    .then(parseJSON);
}

export function request (url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(checkException)
    .then(parseJSON);// .catch(console.log);
}

export function requestRaw (url, options) {
  return fetch(url, options)
    .then(checkStatusDownload);
  // .then(parseJSON);
}

import { actionTypes } from './actions';
import { Map, fromJS } from 'immutable';


const initialState = fromJS({});

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PREORDER_ITEM:
      return state.setIn([action.dealerId, action.name], action.value)
    case actionTypes.CLEAR:
      return Map({})
    default:
      return state;      
  }
}

export default commentsReducer;


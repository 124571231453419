/* istanbul ignore file */
/*
 *
 * Parts actions
 *
 */

import {
  PARTS_SEARCH,
  ADD_TO_PODBOR,
  CREATE_PODBOR,
} from './constants';

export function partsSearch (payload) {
  return {
    type: PARTS_SEARCH,
    payload,
  };
}


export function addToPodbor (item) {
  return {
    type: ADD_TO_PODBOR,
    item,
  };
}

export function createPodbor (payload) {
  return {
    type: CREATE_PODBOR,
    payload,
  };
}




import { createSelector } from 'reselect';

const selectEntities = (state) => state.get('entities');

const makeSelectEntities = (entity) => createSelector(
  selectEntities,
  (entitiesState) => {
    if (entitiesState.get(entity)) {
      return entitiesState.get(entity).toJS();
    }
    return {};
  }
);


const makeSelectEntitiesAsOptions = (entity, title = 'name') => createSelector(
  selectEntities,
  (entitiesState) => {
    let entities = {}
    if (entitiesState.get(entity)) {
      entities = entitiesState.get(entity).toJS();
    }
    return Object.values(entities)
      .sort((a, b) => a[title].localeCompare(b[title]))
      .map(value => ({
        label: value[title], value: value.id 
      }))
  }
);

const makeSelectEntity = (entity) => createSelector(
  selectEntities,
  (entitiesState) => (entityId) => {
    if (entitiesState.get(entity)) {
      const entities = entitiesState.get(entity).toJS() || {};
      return entities[entityId] || {}
    }
    return {};
  }
);

const makeSelectSortedValues = (entity, sortField = null) => createSelector(
  selectEntities,
  (entitiesState) => {
    if (entitiesState.get(entity)) {
      const ents =  entitiesState.get(entity).toJS() || {};
      const vals = Object.values(ents)
      if(!sortField) return vals
      return vals.sort((a, b) => a[sortField].localeCompare(b[sortField]))
    }
    return [];
  }
);




export {
  selectEntities,
  makeSelectEntities,
  makeSelectSortedValues,
  makeSelectEntity,
  makeSelectEntitiesAsOptions,
};

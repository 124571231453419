import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCurrentUser,
} from 'containers/App/selectors';

import {
  logout,
  setPopup,
} from 'containers/App/actions';

import fetch from 'isomorphic-fetch';

import { apiUrl } from 'config'

import { getClient } from 'containers/Sso/selectors'; // getRealm
import { withRouter } from 'react-router-dom';

import { authRequest } from 'containers/Sso/actions';

// import { REALMS, dealerClientId, requiredScopes } from 'containers/Sso/config';
import { TopBar } from '@vgr/skoda-storybook';

import 'images/1280:skoda-logo.png';
import 'images/1280:skoda-logo@2x.png';
import 'images/1280:skoda-logo@3x.png';
import 'images/320:skoda-logo.png';
import 'images/320:skoda-logo@2x.png';
import 'images/320:skoda-logo@3x.png';
import 'images/768:skoda-logo.png';
import 'images/768:skoda-logo@2x.png';
import 'images/768:skoda-logo@3x.png';


const Header = function(props) {
  // const auth = useCallback(() => {
  //     return props.location.pathname === '/dealer'
  //       ? authRequest(
  //         {
  //           client_id: dealerClientId,
  //           scope: requiredScopes.concat(['email_required']).join(' '),
  //         },
  //         REALMS.DEALERS,
  //       )
  //       : authRequest();
  // }, []);
  
  const auth = useCallback(() => { authRequest(); }, []);

  const {
    user,
    client,
    setPopup,
    logout,
    menu_open,
    setMenuOpen,
    authRequest,
    realm,
  } = props;

  const config = useConfig()
  const points = user ? user.points : 0;
  const name = user ? user.preferred_username : '';

  // return <Test >dsa</Test>
  // return <Button label="Label" />
  const userData = useMemo(() => {
    if (!user) return null
    else return {
      email: user.email,
      firstName: user.given_name || '',
      lastName: user.family_name || '',
    }
  }, [user])
  if(!config) return null
  return (
    <TopBar
      config={config}
      authProps={{
        accountLink: 'https://lk.skoda-avto.ru/auto_auth',
        onLoginClick: auth,
        onLogoutClick: logout,
        userData: userData,
      }}
      currentLocation={props.location.pathname}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectCurrentUser(),
  client: getClient(),
  // realm: getRealm(),
});

function mapDispatchToProps(dispatch) {
  return {
    // setMenuOpen: status => dispatch(setMenuOpen(status)),
    setPopup: id => dispatch(setPopup(id)),
    logout: () => dispatch(logout()),
    authRequest: (payload, realm) => dispatch(authRequest(payload, realm)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Header));

const indexUrl = 'https://www.skd-avto.ru/'

const useConfig = () => {
  const [cfg, setCfg] = useState(null)
  useEffect( () => {
    const ft = async () => {
      const res = await fetch(apiUrl + 'data/header')
      // console.log(res)
      const result = await res.json()
      const replacedConfig = JSON.parse(
        JSON.stringify(result)
          .replace(/title/g, 'text')
          .replace(/items/g, 'subgroups')
          .replace(/"href":"\//g, `"href":"${indexUrl}`)
          .replace(/"overviewLink":"\//g, `"overviewLink":"${indexUrl}`)
          .replace(/"image":"\//g, `"image":"${indexUrl}`)
          .replace(/"previewWithText":"\//g, `"previewWithText":"${indexUrl}`)
          .replace(/"previewSimple":"\//g, `"previewSimple":"${indexUrl}`),
      )
      const updatedHeaderConfig = {
        cta: [
          {
            href: `https://cars.skoda-avto.ru/`,
            text: 'Авто в наличии',
          },
          {
            href: `${indexUrl}test-drive`,
            text: 'ТЕСТ-ДРАЙВ',
          },
        ],
        logo: {
          city: '',
          dealerName: '',
          href: `${indexUrl}`,
        },
        main: [replacedConfig.modelSubmenu, ...replacedConfig.allMainNavConfig.nodes],
      }
      setCfg(updatedHeaderConfig)
      return () => { }
    }
    ft()
  }, [])
  return cfg
}
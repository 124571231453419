import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const SelectedFilters = ({ filters, searchQueryName, setSearchQuery, models, categories, makeUrl, setModel, setCategory, categoryName, modelName }) => {
  const { modelSlug, categorySlug } = filters;
  const searchQuery = filters[searchQueryName]; 
    
  if (modelSlug || categorySlug || searchQuery) 
    return (
      <div className="gpage__path">
        {modelSlug && Object.values(models) && models &&
        (
          <span>
            {modelName}
            {' '}
            <Link to={makeUrl({ modelSlug: null })} onClick={() => { setModel(null); }}>
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </span>
        )
        }
          &nbsp;
        {categorySlug && Object.values(categories) && categories &&
        (
          <span>
            {categoryName}
            {' '}
            <Link to={makeUrl({ categorySlug: null })} onClick={() => { setCategory(null); }}>
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </span>
        )
        }
          &nbsp;
        {searchQuery &&
        (
          <span>
          Поиск:
            {' '}
            <i>
              {searchQuery}
            </i>
            {' '}
            <span className="hoverable" onClick={() => { setSearchQuery(null); }}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </span>
        )
        }

      </div>
    )

  else 
    return null

}

export default SelectedFilters;
/**
*
* PopupLayout
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import 'css/components/popup.sass';

import Logo from 'images/logo_skoda.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function PopupLayout (props) {
  return (
    <div className="popup" type={props.cls && props.cls.toLowerCase().replace(/\//g, '-')}>
      <div className="popup__bg"></div>
      <div className="popup__center">
        <div className="popup__scroll">
          <div className="popup__table">
            <div className="popup__row">
              <div className="popup__cell" onClick={props.close}>
                <div className="popup__container" onClick={props.containerClick}>
                  <div className="popup__close" onClick={props.close}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                  <div className="popup__header">
                    <div className="popup__logo" onClick={props.close}>
                    </div>
                  </div>
                  <div className="popup__content">
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PopupLayout.propTypes = {
  close: PropTypes.func,
};

export default PopupLayout;

/* istanbul ignore file */
import { apicall } from 'utils/api';


export function createPodbor (data) {
  return apicall('podbors', { method: 'POST', body: JSON.stringify({ podbor: data }) });
}


export function updatePodbor (data) {
  const { id, ...rest } = data;
  return apicall(`podbors/${id}`, { method: 'PATCH', body: JSON.stringify({ podbor: rest }) });
}

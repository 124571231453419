export const actionTypes = {
 SET_PREORDER_ITEM: '@orderComments/SET_PREORDER_ITEM',
 CLEAR: '@orderComments/CLEAR',
}

export function setPreorderItem(dealerId, name, value) {
  return {
    type: actionTypes.SET_PREORDER_ITEM,
    dealerId,
    name,
    value
  }
}

export function clear() {
  return {
    type: actionTypes.CLEAR,
  }
}

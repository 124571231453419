/**
*
* UserAgreement
*
*/

import React from 'react';
//  


function UserAgreement() {
  return (
    <div style={{width: 850}}>
      <h3 style={{padding: 20, fontSize: 20, fontWeight: 'bold', textAlign: 'center'}}>Пользовательское соглашение</h3>
      <div style={{fontSize: 14}}>
        <p>Настоящим даю согласие на обработку моих перечисленных в настоящей форме для регистрации в личном кабинете персональных данных оператору персональных данных: ООО «ФОЛЬКСВАГЕН Груп Рус» (248926, Россия, г. Калуга, ул. Автомобильная, д.1, &nbsp;адрес Филиала &nbsp;в г. Москве (почтовый адрес): 117485, г. Москва, ул. Обручева, д. 30/1)</p>
        <p>с целями: учета предоставленной мной информации в базах данных; проведения статистических исследований, а также исследований, направленных на улучшение качества продукции и услуг; проведения маркетинговых программ, в том числе для продвижения товаров, работ, услуг на рынке; информирования меня о новых товарах и услугах Оператора и партнеров Оператора (например: посредством отправки журналов, отправки приглашений на презентации продуктов, сообщений о технических нововведениях, условиях покупки нового автомобиля и т.д.) с помощью различных средств связи, а именно:<br/>
        электронная почта; телефон; &nbsp;SMS-/MMS-сообщения, сообщения по средствам информационно-коммуникационных сервисов, таких как Viber/Whatsup и прочие.<br/>
        В целях проведения маркетинговых программ, в том числе, для продвижения товаров, работ и услуг, Оператор может заключать соответствующие Договоры с третьими лицами относительно условий предоставления финансовых услуг. Подписанием настоящего документа я даю свое согласие на получение информации о предлагаемых такими третьими лицами финансовых услугах. Указанная информация может быть доведена до моего сведения как Оператором, так и самими компаниями-партнерами Оператора, предлагающими такие финансовые услуги, включая следующие: ООО «Фольксваген Банк РУС» (117485, г. Москва, ул. Обручева, д. 30/1, стр.1, ИНН: 7750005605, лицензия ЦБ РФ №3500), &nbsp;ООО «ФВ Груп Финанц» (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702349370); ООО «Фольксваген Финансовые Услуги &nbsp;РУС» (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702656396.<br/>
        Я разрешаю совершать с вышеперечисленными персональными данными следующие действия: сбор, систематизацию, накопление, хранение (в электронном виде и на бумажном носителе), уточнение (обновление, изменение), использование, распространение (в том числе передачу) &nbsp;моих персональных данных третьим лицам, с которыми у ООО «ФОЛЬКСВАГЕН Груп Рус» имеются действующие договоры, в рамках которых ООО «ФОЛЬКСВАГЕН Груп Рус» поручает обработку моих персональных данных в указанных в настоящем заявлении целях, включая трансграничную передачу персональных данных, обезличивание, блокирование, уничтожение, с использованием средств автоматизации и без использования таких средств.<br/>
        Для реализации вышеуказанных целей Оператор может поручить обработку персональных данных третьим лицам на основании заключенного с ними договора при условии соблюдения требований законодательства об обеспечении конфиденциальности персональных данных и безопасности персональных данных при их обработке.<br/>
        Настоящее согласие на обработку персональных данных действует до момента его отзыва в соответствии со ст. 9 ФЗ от 27.07.2006 г. № 152-ФЗ «О персональных данных» посредством направления соответствующего письменного заявления.<br/>
        Я подтверждаю, что я уведомлен(а) о том, что требование об исключении или исправлении (дополнении) неверных или неполных персональных данных, а также об отзыве настоящего согласия может быть направлено в виде соответствующего письменного заявления &nbsp;заказным письмом с описью вложения по почтовому адресу Оператора: 117485, г. Москва, ул. Обручева, д. 30/1 с пометкой «Для службы безопасности». Заявление должно содержать ФИО, телефон и E-mail заявителя, а также дату составления &nbsp;заявления и собственноручную подпись заявителя. Заявление может быть также направлено в электронной форме посредством заполнения специальной формы «Обратная связь» на сайте www.lk.skoda-avto.ru.</p>
      </div>
    </div>
  );
}

UserAgreement.propTypes = {

};

export default UserAgreement;

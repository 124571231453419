import React from 'react';
import styled, { css } from 'styled-components';

import { Flex, FlexFixed, FlexFluid, Gapper, Text1, Text4, Ellipsis } from 'components/Ui'

import { formatPrice } from 'helpers';

import ProductCartButtons from 'components/ProductCartButtons';
import Confirm from 'components/Confirm';
import Svg, { SvgWrapper } from 'components/Svg'
import Trashcan from 'images/trash.svg'
import { Col1, Col2, Col3, Col4 } from './Common'
import Bonus from 'images/points.svg'

import { Frame } from 'components/Ui'

const CFlex = styled(Flex)`
  ${SvgWrapper} {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }  
`

const Title = styled(FlexFixed)`
  ${({ errored }) => errored && css`
    color: red;
    ${Text1} {
      color: red;
    }
    ${Text4} {
      color: red;
    }
  `}

`

function OrderRowModCheckout({ moditem, amount, crossed, error, removeBonus, showCert = true }) {
  return (
    <Frame>
      <Flex title=''>
        <Col1>
          <Gapper gap={4}>
            <CFlex>
              <FlexFixed>
                <Svg icon={Bonus}/>
              </FlexFixed>
              <Title errored={!!error}>
                {moditem.kind === 'LK_POINTS' && (
                  <>
                    <Text1 crossed={crossed}><Ellipsis>Бонусные баллы</Ellipsis></Text1>
                    {showCert && moditem.title && <Text4 style={{marginBottom: 10}}>({moditem.title})</Text4>}
                  </>
                )}
                {moditem.kind === 'LK_CERT' && (
                  <>
                    <Text1 crossed={crossed}><Ellipsis>Сертификат на сумму {moditem.amount} руб.</Ellipsis></Text1>
                    <Text4 style={{marginBottom: 10}}>{moditem.title}</Text4>
                  </>
                )}                
                {error && <div style={{color: 'red'}}>{error}</div>}

              </Title>
            </CFlex>            
          </Gapper>
        </Col1>
        <Col2>

        </Col2>
        <Col3>
          <Text1 crossed={crossed} style={{color: '#4BA82E'}}>
            - {formatPrice(amount)}
            {error && 
              <Svg 
                icon={Trashcan} 
                style={{cursor: 'pointer'}}
                onClick={() => { 
                  Confirm(() => { 
                    removeBonus() }, 'Вы действительно хотите удалить бонус?'); 
                  }
                }
              />
            }                
          </Text1>
        </Col3>

      </Flex>
    </Frame>
  )
}

export default OrderRowModCheckout;
/**
*
* DisplayContainer
*
*/

import React from 'react';

import 'css/components/table.sass';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

const DivWrapper = (props) => (
  <div {...props}>
    {props.children}
  </div>
);

const TbodyWrapper = (props) => (
  <tbody {...props}>
    {props.children}
  </tbody>
);

const TableWrapper = (props) => (
  <table className="table" {...props}>
    {props.children}
  </table>
);

const onSortEnd = (sortArray) => ({ oldIndex, newIndex }) => {
  sortArray((ids) => arrayMove(ids, oldIndex, newIndex));
};

function DisplayContainer (props) {
  const {
    loading, records, Template, Intro, empty, showInitial, containerClassName, error, table, sortable, sortArray,
  } = props;

  const Wrapper = table ? TableWrapper : DivWrapper;
  const SubWrapper = table ? TbodyWrapper : DivWrapper;
  const SubWrapperDec = sortable ? SortableContainer((props) => (
    <SubWrapper className={containerClassName}>
      {props.children}
    </SubWrapper>
  )) : SubWrapper;
  const TemplateDec = sortable ? SortableElement((props) => (
    <Template {...props} />
  )) : Template;
  let Empty;
  if (typeof empty === 'function') {
    Empty = empty;
  }
  let wrapperProps = {
    className: containerClassName,
  };
  if (sortable) {
    wrapperProps = {
      lockAxis: 'y',
      distance: 1,
      helperClass: 'table-row-detached',
      onSortEnd: onSortEnd(sortArray),
      ...wrapperProps,
    };
  }
  return (
    <div className={loading ? 'loading' : null}>
      {showInitial && !error && (
        <div className="panel">
          Загрузка..
        </div>
      )}
      { error && (
        <div className="panel">
          Не узалось загрузить записи
        </div>
      )}
      {records && !records.length && !showInitial && !error &&
        (
          <div className="pep">
            { typeof empty === 'function' &&
              <Empty />
            }
            { typeof empty === 'string' && empty }
          </div>
        )
      }
      { !(records && !records.length) && !showInitial && !error &&
        (
          <Wrapper>
            <Intro />
            <SubWrapperDec {...wrapperProps}>
              {records && records.map((row, index) => row ? <TemplateDec index={index} key={row.id} row={row} {...props} {...props.templateProps} /> : null) }
            </SubWrapperDec>
          </Wrapper>
        )
      }
    </div>
  );
}

DisplayContainer.propTypes = {
  // Intro: PropTypes.element,
};

DisplayContainer.defaultProps = {
  Intro: () => (<div></div>),
};

export default DisplayContainer;

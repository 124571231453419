import { createSelector } from 'reselect';

const selectDelivery = state => state.get('delivery');

export const selectProcessing = () =>
  createSelector(selectDelivery, deliveryState => deliveryState.get('processing'));

export const selectApplying = () =>
  createSelector(selectDelivery, deliveryState => deliveryState.get('applying'));

export const selectError = () =>
  createSelector(selectDelivery, deliveryState => deliveryState.get('error'));

export const selectCalc = () =>
  createSelector(selectDelivery, deliveryState => deliveryState.get('calcResult'));

export const selectCompanies = () =>
  createSelector(selectDelivery, deliveryState => deliveryState.get('companies'));

import {
  all, call, takeEvery,
} from 'redux-saga/effects';
import { createFeedback } from 'api/feedbacks';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';
import { apiRequest } from 'containers/App/saga';

import { CREATE_FEEDBACK } from './constants';

export function * createFeedbackGen (action) {
  const { payload: { data, resolve, reject } } = action;
  try {
    yield call(apiRequest, createFeedback, data);

    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(CREATE_FEEDBACK, createFeedbackGen),
  ]);
}

import React from 'react';
import styled from 'styled-components';

import { H7, H4, A, Line, Gapper, Frame } from 'components/Ui'

import Box, { Inner } from 'components/Box'
import OrderRowCheckout from './OrderRowCheckout'
import OrderRowModCheckout from './OrderRowModCheckout'
import OrderRowDeliveryCheckout from './OrderRowDeliveryCheckout'
import SummaryRowCheckout from './SummaryRowCheckout'

function OrderItemsCheckout({ items, index, payable, deliveryType, delivery = {}, moditem, payType, errors, removeBonus, deliveryLoading }) {
  const itemsPrice = items.reduce((acc, item) => (acc + item.price * item.quantity), 0)
  const modAmount = moditem  ? Math.min(moditem.amount, itemsPrice/2) : 0
  const deliveryData = (delivery || {}).data || {}
  const deliveryPrice = deliveryData.delivery ? (deliveryData.delivery.total_price || deliveryData.delivery.price_delivery) : 0
  // const totalPrice = itemsPrice - ( payType === 1 ? modAmount : 0)
  const dimensionsPresent = !items.find((item) => !item.dimension )
  // console.log({dimensionsPresent})

  const totalPrice = itemsPrice - modAmount + ((deliveryType === 1 && dimensionsPresent) ? deliveryPrice : 0)
  return (
    <Box bged>
      <Frame>
        <Frame pad={8}>
          <H7>Заказ {index+1}</H7>
        </Frame>
        {items.map((item, i) => (
          <OrderRowCheckout key={item.productId} item={item} />
        ))}
        {deliveryType === 1 && deliveryData.delivery && (deliveryData.delivery.total_price || deliveryData.delivery.price_delivery) &&
          <OrderRowDeliveryCheckout dimensionsPresent={dimensionsPresent} loading={deliveryLoading} delivery={deliveryData.delivery} error={errors && errors.deliveries && errors.deliveries[delivery.id]} />
        }
        {moditem && (
          <OrderRowModCheckout crossed={false} moditem={moditem} amount={modAmount} removeBonus={removeBonus} error={errors && errors.moditems && errors.moditems[moditem.id]} />
        )}     
      </Frame>
      <SummaryRowCheckout payable={payable} price={totalPrice}/>
    </Box>
  )
}

export default OrderItemsCheckout;
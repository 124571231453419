
import '@babel/polyfill';
import 'url-polyfill';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
import 'raf/polyfill';
// import 'helpers/polyfills';

import React from "react";
import ReactDOM from "react-dom";
import FontFaceObserver from 'fontfaceobserver';
import { ConnectedRouter, Route } from 'connected-react-router/immutable';
import 'sanitize.css/sanitize.css';
import 'css/main.sass';
import Root from 'containers/App';
import ReactGa from 'react-ga'
import { Frontload } from 'react-frontload';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import createStore from './initStore'
import '!file-loader?name=[name].[ext]!./images/favicon.png';

const skodaNextObserver = new FontFaceObserver('Skoda Next', {});
const skodaNextBoldObserver = new FontFaceObserver('Skoda Next', { weight: 'bold' });
const skodaNextItalicObserver = new FontFaceObserver('Skoda Next', { style: 'italic' });

Promise.all([skodaNextObserver.load(), skodaNextBoldObserver.load(), skodaNextItalicObserver.load()]).then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  document.body.classList.remove('fontLoaded');
});


// console.log(process.env)


if (process.env.NODE_ENV === 'production') {
  ReactGa.initialize('UA-20921179-11')
  ReactGa.pageview(window.location.pathname + window.location.search);
  try {
    window.dispatchEvent(
      new window.CustomEvent('HistoryChange', {
        detail: {
          analyticsContext: {
            href: window.location.pathname + window.location.search,
          },
        },
      })
    );  
  } catch(e) {
    console.error(e)
  }
}

const MOUNT_NODE = document.getElementById("app");

const { store, history } = createStore()
const hydrate = () => {
  return ReactDOM.hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Frontload >
          <HelmetProvider>
            <Root />
          </HelmetProvider>
        </Frontload>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};



if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    hydrate();
  });
}

hydrate();

/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getModels () {
  return apicall('models');
}

export function getAllModels () {
  return apicall('models/all');
}


export function createModel (data) {
  return apicall('models', { method: 'POST', body: JSON.stringify({ model: data }) });
}

export function updateModel (data) {
  const { id, ...rest } = data;

  return apicall(`models/${id}`, { method: 'PATCH', body: JSON.stringify({ model: rest }) });
}

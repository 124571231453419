import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Async as AsyncSelect } from 'react-select';
// import AsyncSelect from 'react-select/async';


AsyncSelectField.defaultProps = {
  multi: false,
  className: '',
  clearable: false,
  isSearchable: true,
  options: []
};

AsyncSelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // value: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  isSearchable: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

const customStyles =  ({
  option: (provided, state) => ({
    ...provided,
    background: (state.isSelected || state.isFocused) ? 'rgba(0, 126, 0, 0.04)' : '#fff',
    color: '#000',
    fontWeight: 300,

  }),
  control: (provided, state) => ({
    ...provided,
    height: state.isMulti ? 'auto' : 40,
    border: 0,
    paddingLeft: 0,
    background:  '#fff',
    borderBottom: '1px solid #CACACA',
    borderColor: ( state.isFocused) ? '#4ba82e !important' : '#9F9F9F',
    boxShadow: ( state.isFocused) ? 'none' : 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: state.isMulti ? 'auto' : 40,
    padding: 0,
    position: 'static',
    fontWeight: 300,
  }),  
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),  
})

export function AsyncSelectField (props) {
  const {
    input, name, options, multi, className, meta, label, required, clearable, placeholder, allowBlank, isSearchable, dValue, loadOptions,
    onChange: onSideChange = () => {}, onChangeFull = () => {},
  } = props
  const {
    value, onBlur, onChange, onFocus, 
  } = input;


  const transformedValue = transformValue(value, options, multi) || dValue;
  
  useEffect(() => {
    dValue && dValue.value && onChange(dValue.value)
  }, [!transformedValue])
  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      onBlur={() => onBlur(value)}
      placeholder={placeholder || label}
      onFocus={onFocus}
      value={ transformedValue }
      isSearchable={isSearchable}
      defaultValue={dValue}
      isMulti={multi}
      id={name}
      valueKey="value"
      name={name}
      styles={customStyles}
      defaultOptions
      onChange={multi
        ? multiChangeHandler(onChange)
        : singleChangeHandler(onChange, onSideChange, onChangeFull)
      }
    />      
  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler (func, func2, func3) {
  /* istanbul ignore next */
  return function handleSingleChange (value) {
    func(value ? value.value : '');
    func2(value ? value.value : '');
    func3(value)
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler (func) {
  /* istanbul ignore next */
  return function handleMultiHandler (values) {
    // const vals = values.size && values.toJS() || []
    return func((values || []).map((value) => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue (val, options, multi) {
  if (multi && typeof val === 'string') return [];
  const value = multi ? ( val || [] ) : val
  const filteredOptions = options.filter((option) => multi
    ? value.map(a => a.toString()).indexOf(option.value.toString()) !== -1
    : option.value && option.value.toString() === value.toString());
  return multi ? filteredOptions : filteredOptions[0];
}


export default AsyncSelectField
import styled, { css } from 'styled-components'

import vars from 'config/styles'

export const ErrorDiv = styled.div`
  top: 100%;
  font-size: 12px;
  line-height: 18px;
  color: ${vars.red};
  // position: absolute;
  // left: 0;
  transition: .3s;
  // white-space: nowrap;
  pointer-events: none;
`

export const ErrorDiv2 = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: ${vars.red};
  transition: .3s;
  white-space: nowrap;
  pointer-events: none;
`

export const WrapperBasic = styled.div`
  position: relative;
  margin-bottom: 20px;
  font-weight: 300;
`

export const Wrapper = styled(WrapperBasic)`
  margin-bottom: 30px;

  .react-datepicker-wrapper {
    display: block;
  }
  
  input, textarea {
    width: 100%;
    background: transparent;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    color: ${vars.black};
    border: 0;
    padding: 0;
    outline: none;
    font-size: 16px;
    border-bottom: 1px solid ${vars.grey};
    &:focus {
      border-bottom: 1px solid ${vars.primary};
      & + label {
        transform: translateY(-170%);
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
      }
    }
  }

  textarea {
    border: 1px solid ${vars.grey};
    padding: 15px;
    height: auto;
    min-height: 115px;
    line-height: 18px;
    & + label {
      top: 20px !important;
      left: 15px;
    }
    &:focus {
      border: 1px solid ${vars.primary};
      & + label {
        transform: translateY(-240%);
        left: 0;
      }      
    }
  }

  label {
    // top: 50%;
    top: 20px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 26px;
    color: #6b6b6b;    
    position: absolute;
    left: 0;
    transition: .3s;
    white-space: nowrap;
    font-weight: 300;
    pointer-events: none;    
    span {
      color: ${vars.red};
    }
  }

  ${({ filled }) => filled && css`
    label {
      transform: translateY(-170%);
      font-size: 12px;
      line-height: 18px;
    }
    textarea {
      & + label {
        transform: translateY(-240%);
        left: 0;
    }
    .react-datepicker-wrapper {
      display: block;
    }    
  `}  

  ${({ error }) => error && css`
    input, textarea {
      border-color: ${vars.red}; 
    }
    label {
      color: ${vars.red};
    }
  `}  




`





// ul.react-datepicker__time-list
//   padding: 0 !important

//   li.react-datepicker__time-list-item--selected
//     background: $green !important
/* istanbul ignore file */
import { apicall } from 'utils/api';
import { deliveryHost } from 'config'

const deliveryQuery = (path, options = {}) => (params = {}) =>
  apicall(deliveryHost + path, options, { 'Content-Type': 'application/json' }, 'absolute')(params);

export function applyDelivery(dealerId, data) {
  return apicall(`orders/apply_delivery/${dealerId}`, {method: 'POST', body: JSON.stringify(data)})
}

export function editDelivery(orderId, data) {
  return apicall(`orders/${orderId}/edit_delivery`, {method: 'POST', body: JSON.stringify(data)})
}

export function refreshDelivery(dealerId, data) {
  return apicall(`orders/refresh_delivery/${dealerId}`, {method: 'POST', body: JSON.stringify(data)})
}

export function getOrderDeliveryStatus(orderId) {
  return apicall(`orders/${orderId}/delivery_status`)
}

export function confirmDelivery(orderId) {
  return apicall(`orders/${orderId}/confirm_delivery`, { method: 'POST' })
}

export function acceptDelivery(orderId) {
  return apicall(`orders/${orderId}/accept_delivery`, { method: 'POST' })
}

export function getDeliveryStatus () {
  return deliveryQuery('dealers')
}

export function getAddresses () {
  return deliveryQuery('addresses')
}

export function calc (dealerCode, body) {
  return deliveryQuery(`${dealerCode}/calc`, {method: "POST", body: JSON.stringify(body)})
}


export function createAddress (body) {
  return deliveryQuery('addresses', {method: "POST", body: JSON.stringify(body)})
}

export function editAddress (id, body) {
  return deliveryQuery(`addresses/${id}`, {method: "PATCH", body: JSON.stringify(body)})
}

export function deleteAddress (id) {
  return deliveryQuery(`addresses/${id}`, {method: "DELETE"})
}

export function getList (dealerCode, path, params) {
  return deliveryQuery(`${dealerCode}/list/${path}?${params}`)
}


// export function makePayment (orderId) {
//   return apicall('payments', { method: 'POST', body: JSON.stringify({ payment: { orderId } }) });
// }

// export function refreshStatus (orderId) {
//   return apicall(`payments/${orderId}`, { method: 'GET' });
// }

// export function deposit (orderId, amount = 0) {
//   return apicall(`payments/${orderId}/deposit`, { method: 'POST', body: JSON.stringify({ payment: { amount } }) });
// }

// export function reverse (orderId) {
//   return apicall(`payments/${orderId}/reverse`, { method: 'POST' });
// }

// export function refund (orderId, amount = 0) {
//   return apicall(`payments/${orderId}/refund`, { method: 'POST', body: JSON.stringify({ payment: { amount } }) });
// }
/* istanbul ignore file */
import { apicall } from 'utils/api';

export function createOrder (data) {
  return apicall('orders/', { method: 'POST', body: JSON.stringify(data) });
}

export function dealerOrderCount (dealerId) {
  return apicall(`orders/dealerCount/${dealerId}`);
}

export function updateOrder (id, data) {
  return apicall(`orders/${id}`, { method: 'PATCH', body: JSON.stringify({ order: data }) });
}

export function getOrderLogs (id) {
  return apicall(`orders/${id}/logs`, { method: 'GET' });
}

export function getOrder (id) {
  return apicall(`orders/${id}`)
}


export function editOrderItems(id, items, delivery) {
  return apicall(`orders/${id}/edititems`, { method: 'POST', body: JSON.stringify({ items: items, delivery }) }); 
}

export function setOrderDimension(id) {
  return apicall(`orders/${id}/set_dimensions`)
}
/**
*
* Offer
*
*/

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'css/components/pupa.sass';
import { formatPrice } from 'helpers';
import ProductCartButton from 'components/ProductCartButton';
import OfferDealer from './OfferDealer';
import ReactTooltip from 'react-tooltip';
import Card from 'images/card.svg'
import Truck from 'images/truck.svg'
import Svg from 'components/Svg'

class Offer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    this.state = { expanded: false };
  }

  render () {
    const {
      offer, dealer, addItemToCart, currentCartId, items, cities, payable, deliverable, sendRaw
    } = this.props;
    const item = Object.values(items).find((one) => one.cartId === currentCartId && one.dealerId === offer.dealerId && one.productId === offer.productId);
    const city = cities && dealer.city && cities[dealer.city]
    return (
      <React.Fragment>
        <tr className={this.state.expanded ? 'table__expanded' : null}>
          <td className="hoverable" onClick={() => { sendRaw('Accessories_BuyForm', 'choose_dealer', { code: dealer.code, name: dealer.name }); this.setState((state) => ({ expanded: !state.expanded }))}}>
            {dealer && dealer.name}
            &nbsp;
            <FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />
            { payable &&
              <>
                &nbsp;
                <Svg icon={Card} small style={{marginLeft: 5}} data-for={`offer-${offer.id}`} data-tip={'Доступна оплата онлайн'} />
                <ReactTooltip id={`offer-${offer.id}`} />
              </>
            }
            { deliverable && 
              <>
                &nbsp;
                <Svg icon={Truck} small style={{marginLeft: 5}} data-for={`offer-${offer.id}`} data-tip={'Доступна доставка'} />
                <ReactTooltip id={`offerd-${offer.id}`} />
              </>
            }
          </td>
          <td>
            {city && city.name}
          </td>
          <td>
            {formatPrice(offer.price)}
          </td>
          <td>
            {offer.quantity}
            {' '}
            шт.
          </td>
          <td className="table__actions">
            <ProductCartButton
              addItemToCart={addItemToCart}
              productId={offer.productId}
              dealerId={offer.dealerId}
              limit={offer.quantity}
              item={item}
            />

          </td>
        </tr>
        {this.state.expanded &&
          (
            <OfferDealer dealer={dealer} sendRaw={sendRaw}/>
          )
        }
      </React.Fragment>
    );
  }
}

export default Offer;

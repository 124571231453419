import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectEntities } from 'models/selectors';
import { makeSelectDomainDealers, makeSelectCurrentCartId } from 'containers/App/selectors'
import { setPopup, addToCart } from 'containers/App/actions'
import { PRODUCT_OFFERS, NOTIFY_STOCK } from 'components/Popup/constants'
import { formatPrice, declOfNum } from 'helpers'
import vars from 'config/styles'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Button'
import ProductCartButton from 'components/ProductCartButton'
import { H7, Flex, FlexFluid, FlexFixed } from 'components/Ui'
import Plus from 'images/plus2.svg'
import Cross from 'images/cross.svg'
import Svg, { SvgWrapper } from 'components/Svg'
import InputField from 'components/Fields/InputField/Raw'

const Wrapper = styled.div`
  margin-bottom: 60px;
`

const Error = styled.span`
  color: #da0000;
`

const Pill = styled.div`
  padding: 0 2px 0 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: inline-block;
  background: #f4f4f4;
  margin-bottom: 10px;
  line-height: 28px;
  vertical-align: top;
`

const PillDel = styled.div`
  padding: 0 4px 0 4px;
  line-height: 28px;
  vertical-align: top;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: inline-block;
  background: #f4f4f4;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-left: 1px;
  font-size: 12px;
  &:hover {
    background: #38aa34;
    color: #fff;
  }
`

const Row = styled.div`
  display: flex;
  height: 46px;
  align-items: center;
  margin-bottom: 24px;
`

const AddPart = styled.div`
  color: ${vars.green};
  cursor: pointer;
  &:hover {
    color: ${vars.greenH};
  }
`

const Del = styled.div`
  margin-left: auto;
  &:hover {
    ${SvgWrapper} {
      path {
        fill: ${vars.green};
      }
    }
  }
`

const AddForm = styled(Flex)`
  @media (max-width: ${vars.bp.mobile}px) {
    flex-wrap: wrap;
  }
`

const AddFormInput = styled(FlexFluid)`
  @media (max-width: ${vars.bp.mobile}px) {
    flex: 100%;
    margin-bottom: 10px !important;
  }
`

// const OfferInfo = function({ product, productOffers, dealerId }) {
//   if(!product) return (<div/>)
//   if(dealerId) {
//     const offer = productOffers.find(o => o.dealerId === dealerId)

//     if(!offer) return (
//       <div  style={{textAlign: 'right'}}>
//         Нет в наличии
//       </div>
//     )

//     return (
//       <>
//         <div>
//           {formatPrice(offer.price)}
//         </div>
//         <div style={{textAlign: 'right'}}>
//           В наличии 
//           {' '} 
//           {offer.quantity} шт
//         </div>      
//       </>
//     )
//   }
//   const countDealers = productOffers.filter(o => !!o.quantity).length
//   if(!countDealers) return (<div style={{textAlign: 'right'}}>Нет в наличии</div>)
//   return (
//     <div style={{textAlign: 'right'}}>
//       В наличии у {countDealers} {declOfNum(countDealers, ['дилера', 'дилеров', 'дилеров'])}
//     </div>
//   )
// }

// const ActionTd = ({ setPopup, product, productOffers, dealerId, addItemToCart, items, currentCartId }) => {
  
//   if(dealerId) {
//     const offer = productOffers.find(o => o.dealerId === dealerId)
//     const item = offer && Object.values(items).find((one) => one.cartId === currentCartId && one.dealerId === offer.dealerId && one.productId === offer.productId)

//     return (

//       <div style={{textAlign: 'right', paddingRight: 15}}>
//         {product && offer && (
//           <ProductCartButton 
//             addItemToCart={addItemToCart}
//             productId={product.id}
//             dealerId={dealerId}
//             limit={offer.quantity}
//             item={item}
//           />
//         )}

//         {product && !offer && (
//           <Button style={{marginRight: 0}} small onClick={() => setPopup(NOTIFY_STOCK, { productId: product.id, dealerId })}>Узнать о поступлении</Button>
//         )}
//       </div>
//     )
//   }
  
//   const countDealers = productOffers.filter(o => !!o.quantity).length

//   return (
//     <div style={{textAlign: 'right'}}>{product && (
//       <Button small onClick={() => setPopup(PRODUCT_OFFERS, {id: product.id})}>
//         {countDealers ? 'Купить' : 'Узнать о поступлении'}
//       </Button>
//     )}</div>
//   )
// }


class PodborField extends React.Component {
  constructor(props) {
    super(props)
    // this.addToCart = this.addToCart.bind(this)
    this.state = {
      addFormActive: false,
      addPart: '',
    }
  }


  // addToCart (productId, dealerId, quantity) {
  //   return new Promise((resolve, reject) => {
  //     this.props.addToCart({ data: { productId, dealerId, quantity }, resolve, reject });
  //   }).catch((err) => { throw err; });
  // }
  
  //              <OfferInfo product={product} productOffers={productOffers} dealerId={dealerId}/>
  //          <ActionTd product={product} productOffers={productOffers} dealerId={dealerId} setPopup={setPopup} items={items} currentCartId={currentCartId} addItemToCart={this.addToCart}/>

  render() {
    const field = this.props
    const { sendRaw, msrps, offers, products, setPopup, dealerId, forDealer, items, currentCartId, onFieldNullify = () => {}, addToPodbor } = this.props
    const { value, onChange, ...rest } = field.input;
    const curValue = Array.isArray(value) ? value : (value && value.toJS && value.toJS() || [])
    //console.log({ value, curValue })
    // console.log(this.props)

    return (
      <Wrapper>
        {curValue.map((v, i) => {
          //const product = products[v.replace(/[\s\-]/g, '').toUpperCase()]
          //const msrp = msrps[v.replace(/[\s\-]/g, '').toUpperCase()]
          //const productOffers = product ? product.offers.map(offerId => offers ? offers[offerId] : {}) : []
          return (
            <Row>
              <div style={{width: 24}}>{i+1}.</div> {v} 
              <Del>
                <Svg icon={Cross} className='hoverable' onClick={() => {
                  onChange(curValue.filter((v, j) => i!==j))
                  if(curValue.length === 1) {
                    onFieldNullify()
                  }
                }}/>
              </Del>              
            </Row>
          )
        })}

        {!this.state.addFormActive && <AddPart onClick={() => { sendRaw('RequestPartsForm', 'add_parts'); this.setState({ addFormActive: true })}}>
          <Svg icon={Plus}/> Добавить ещё деталь
        </AddPart>}

        {this.state.addFormActive && <AddForm center space={10}>
          <AddFormInput>
            <InputField style={{marginBottom: 0}} value={this.state.addPart} onChange={(e) => this.setState({ addPart: e.target.value })} label="Введите название детали" />
          </AddFormInput>
          <FlexFixed>
            <Button small onClick={() => {
              addToPodbor(this.state.addPart);
              sendRaw('RequestPartsForm', 'input_parts', this.state.addPart); 
              this.setState({ addPart: '', addFormActive: false })
            }}>Добавить</Button>
          </FlexFixed>
          <Del>
            <Svg icon={Cross} className='hoverable' onClick={() => {
              this.setState({ addFormActive: false })
            }}/>
          </Del>            
        </AddForm>}

        { (field.meta.error || field.meta.submitError) && (
          <Error>
            {(field.meta.error || field.meta.submitError)}
          </Error>
        )}      
      </Wrapper>
    );
  };
}

const mapStateToProps = createStructuredSelector({
  offers: makeSelectEntities('offers'),
  products: makeSelectEntities('products'),
  msrps: makeSelectEntities('msrps'),
  dealerIds: makeSelectDomainDealers(),
  items: makeSelectEntities('items'),
  currentCartId: makeSelectCurrentCartId(),

});

function mapDispatchToProps (dispatch) {
  return {
    addToCart: (payload) => dispatch(addToCart(payload)),
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect,
)(PodborField);

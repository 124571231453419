/**
 *
 * AdminAuth
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectIsDealerDomain } from 'containers/App/selectors'
import { authRequest } from 'containers/Sso/actions';
import { adminClientId } from 'containers/Sso/config';
import { RedirectAdminClient } from 'components/Auth'
import Button from 'components/Button';

export class AdminAuth extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { authRequest, isDealerDomain } = this.props;

    if(isDealerDomain) return (
      <div className='container'>
        <div className='cpanel' style={{marginTop: 40}}>
          Административная панель недоступна на дилерском сайте, используйте витрину импортера&nbsp;
          <a href="https://parts.skd-avto.ru">https://parts.skd-avto.ru</a>
        </div>
      </div>
    )

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Вход в кабинет
          </title>
          <meta name="description" content="вход в кабинет" />
        </Helmet>
        <div className='container'>
          <div className='cpanel' style={{marginTop: 40}}>
            <h2>Вход в панель управления</h2>
            <Button small onClick={() => authRequest({ client_id: adminClientId })}>
              Войти
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AdminAuth.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  isDealerDomain: makeSelectIsDealerDomain(),
});

function mapDispatchToProps (dispatch) {
  return {
    authRequest: (payload) => dispatch(authRequest(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  RedirectAdminClient,
  withConnect,
)(AdminAuth); 
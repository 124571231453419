/**
*
* Agreement2
*
*/

import React from 'react';
//  


function Agreement2() {
  return (
    <div style={{width: 850}}>
      <h3 style={{padding: 20, fontSize: 20, fontWeight: 'bold', textAlign: 'center'}}>Согласие на рекламную коммуникацию</h3>
      <div style={{fontSize: 14}}>
        <p>Настоящим даю согласие на обработку моих вышеуказанных персональных данных Оператору: ООО "ФОЛЬКСВАГЕН Груп Рус" (248926, г. Калуга, ул. Автомобильная, д. 1 / (почтовый адрес) адрес Филиала в г. Москве) 117485, г. Москва, ул. Обручева, д. 30/1) с целями: учета предоставленной информации в базах данных; проведения статистических исследований, а также исследований, направленных на улучшение качества продукции и услуг; проведения маркетинговых программ, в том числе, для продвижения товаров, работ, услуг на рынке; информирования меня о новых товарах и услугах Оператора и партнеров Операторов (например, посредством отправки журналов, отправки приглашений на презентации продуктов, сообщений о технических нововведениях, предстоящих работах по сервисному обслуживанию, условиях покупки нового автомобиля и т.д.) <b>с помощью различных средств связи</b>, а именно посредством: 
          </p>
        <p><i>интернет; сообщений на адрес электронной почты; коротких текстовых сообщений (SMS) и мультимедийных сообщений (MMS) на номер телефона; а также посредством использования информационно-коммуникационных сервисов, таких как Viber, WhatsApp и тому подобных; телефонных звонков.
        </i></p>
        <p> Я разрешаю <b>совершать со своими персональными данными следующие действия:</b> сбор, систематизацию, накопление, хранение (в электронном виде и на бумажном носителе), уточнение (обновление, изменение), использование, распространение (в том числе передачу) моих персональных данных третьим лицам, с которыми у Операторов имеются действующие договоры, в рамках которых Операторы поручают обработку персональных данных в вышеуказанных целях, включая трансграничную передачу персональных данных, обезличивание, блокирование, уничтожение, с использованием средств автоматизации и без использования таких средств.
        </p>
        <p>В целях проведения маркетинговых программ, в том числе для продвижения товаров, работ и услуг, ООО «ФОЛЬКСВАГЕН Груп Рус» может заключать соответствующие Договоры с третьими лицами относительно условий предоставления мне финансовых услуг. Настоящим я даю свое согласие на получение информации о предлагаемых такими третьими лицами финансовых услугах. Указанная информация может быть доведена до моего сведения как ООО «ФОЛЬКСВАГЕН Груп Рус», так и самими компаниями-партнерами ООО «ФОЛЬКСВАГЕН Груп Рус», предлагающими такие финансовые услуги, включая следующие: ООО «Фольксваген Банк РУС» (117485, г. Москва, ул. Обручева, д. 30/1, стр.1, ИНН: 7750005605, лицензия ЦБ РФ №3500), ООО «ФВ Груп Финанц» (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702349370); ООО «Фольксваген Финансовые Услуги РУС» (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702656396. Полный перечень компаний-партнеров ООО «ФОЛЬКСВАГЕН Груп Рус» в области предоставления финансовых услуг размещен на сайте <a href="http://www.skoda-avto.ru">www.skoda-avto.ru</a>.
        </p>
        <p> Настоящее согласие на обработку персональных данных действует до момента его отзыва в соответствии со статьей 9 Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» посредством предоставления письменного заявления по почтовым адресам Операторов, указанных в настоящем согласии или в электронной форме посредством заполнения специальной формы «Обратная связь» на сайте <a href="https://www.lk.skoda-avto.ru">www.lk.skoda-avto.ru</a>.
        </p>
      </div>
    </div>
  );
}

Agreement2.propTypes = {

};

export default Agreement2;

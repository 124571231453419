import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const Frame = styled.div`
  ${({ pad = 12 }) => pad && css`
    padding: ${pad}px 0;
  `}
`


/**
 */

import {
  all, call, take, put, select, takeLatest, takeEvery, delay, fork
} from 'redux-saga/effects';


import { LOCATION_CHANGE } from 'connected-react-router';
import { SubmissionError } from 'redux-form/immutable';
import { SET_MENU, LOGOUT } from './constants'
import { AUTH_REQUEST } from 'containers/Sso/constants'

import { metricsActions } from 'containers/App/metricsActions'
import { makeSelectEntities } from 'models/selectors';

const sendData = function* (data) {
  const sendMetrics = process.env.SEND_METRICS
  console.log(data)
  console.log({ sendMetrics })
  if (sendMetrics === '1') {
    window.dataLayer_kdx = window.dataLayer_kdx || [];
    window.dataLayer_kdx.push({
      'event': 'send_ga',
      'eventCategory': data.category,
      'eventAction': data.action,
      'eventLabel': data.label,
      eventNoInteract: false 
    });  
  }
}


function* authRequestGen() {
  yield sendData({ category: 'TopBar', action: 'log_in' })
}

function* logoutGen() {
  yield sendData({ category: 'TopBar', action: 'log_out' })
}



function* setMenuGen(action) {
  const { id } = action
  if (id === 1) {
    yield sendData({ category: 'TopBar', action: 'click_account' })
  }
}

function* partsDealerChangeGen(action) {
  try {
    // console.log(action)
    const dealers = yield select(makeSelectEntities('dealers'))
    let dealer = dealers && dealers[action.payload.e] || {}
    if (action.payload.e === 0) dealer = {name: 'Все дилеры'}
    // console.log({ dealers, e: payload.e, dealer })
    yield sendData({ category: 'PartsPage', action: 'choose_dealer', label: { code: dealer.number, title: dealer.name } })
  } catch(e) {
    console.log(e)
  }
}

function* partsDealerOpenDropdownGen(action) {
  try {
    // console.log(action)

    yield sendData({ category: 'PartsPage', action: 'widget_click_dealer' })
  } catch (e) {
    console.log(e)
  }
}


function* partsPageNoneFoundGen(action) {
  try {

    yield sendData({ category: 'PartsPage', action: 'none_found', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}

function* interactMapGen(action) {
  try {
    yield sendData({ category: 'PartsPage_Map', action: 'interact_map', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}

function* mapDealerClickGen(action) {
  try {
    const dealers = yield select(makeSelectEntities('dealers'))
    let dealer = dealers && dealers[action.payload] || {}
    yield sendData({ category: 'PartsPage_Map', action: 'choose_dealer', label: { code: dealer.number, title: dealer.name } })
  } catch (e) {
    console.log(e)
  }
}

function* addToPodborGen(action) {
  try {
    yield sendData({ category: 'RequestPartsForm', action: 'click_add_parts', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}

function* submitPodborGen(action) {
  try {
    yield sendData({ category: 'RequestPartsForm', action: 'submit', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}


function* submitPodborSuccessGen(action) {
  try {
    yield sendData({ category: 'RequestPartsForm', action: 'success', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}

function* goodMiniBuyClickGen(action) {
  try {
    const goods = yield select(makeSelectEntities('goods'))
    let good = goods && goods[action.payload] || {}
    yield sendData({ category: 'Accessory_MiniPage', action: 'click_buy', label: good.productId })
  } catch (e) {
    console.log(e)
  }
}

function* goodOffersBuyClickGen(action) {
  try {
    yield sendData({ category: 'Accessories_BuyForm', action: 'add_to_cart', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}

function* NotifyStockClickGen(action) {
  try {
    // const goods = yield select(makeSelectEntities('goods'))
    // let good = goods && goods[action.payload] || {}
    yield sendData({ category: 'Accessories_AvailibilityForm', action: 'availability', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}


function* NotifyStockSubmitGen(action) {
  try {
    // const goods = yield select(makeSelectEntities('goods'))
    // let good = goods && goods[action.payload] || {}
    yield sendData({ category: 'Accessories_AvailibilityForm', action: 'submit', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}


function* NotifyStockSubmitSuccessGen(action) {
  try {
    // const goods = yield select(makeSelectEntities('goods'))
    // let good = goods && goods[action.payload] || {}
    yield sendData({ category: 'Accessories_AvailibilityForm', action: 'success', label: action.payload })
  } catch (e) {
    console.log(e)
  }
}

function* CartAuthGen(action) {
  try {
    // const goods = yield select(makeSelectEntities('goods'))
    // let good = goods && goods[action.payload] || {}
    yield sendData({ category: 'Purchase_RequestForm', action: 'log_in' })
  } catch (e) {
    console.log(e)
  }
}

function* CartRegGen(action) {
  try {
    // const goods = yield select(makeSelectEntities('goods'))
    // let good = goods && goods[action.payload] || {}
    yield sendData({ category: 'Purchase_RequestForm', action: 'register' })
  } catch (e) {
    console.log(e)
  }
}

function* SendRawGen(act) {
  try {
    const { category, action, label } = act
    // const goods = yield select(makeSelectEntities('goods'))
    // let good = goods && goods[action.payload] || {}
    yield sendData({ category, action, label })
  } catch (e) {
    console.log(e)
  }
}



export default function * rootSaga () {
  yield all([
    takeEvery(metricsActions.AUTH_REQUEST_METRICS_TOP, authRequestGen),
    takeEvery(SET_MENU , setMenuGen),
    takeEvery(LOGOUT, logoutGen),
    takeEvery(metricsActions.PARTS_DEALER_CHANGE, partsDealerChangeGen),
    takeEvery(metricsActions.PARTS_DEALER_OPEN_DROPDOWN, partsDealerOpenDropdownGen),
    takeEvery(metricsActions.PARTS_PAGE_NONE_FOUND, partsPageNoneFoundGen),
    takeEvery(metricsActions.INTERACT_MAP, interactMapGen),
    takeEvery(metricsActions.MAP_DEALER_CLICK, mapDealerClickGen),
    takeEvery(metricsActions.ADD_TO_PODBOR, addToPodborGen),
    takeEvery(metricsActions.SUBMIT_PODBOR, submitPodborGen),
    takeEvery(metricsActions.SUBMIT_PODBOR_SUCCESS, submitPodborSuccessGen),
    takeEvery(metricsActions.GOOD_MINI_BUY_CLICK, goodMiniBuyClickGen),
    takeEvery(metricsActions.GOOD_OFFERS_BUY_CLICK, goodOffersBuyClickGen),
    takeEvery(metricsActions.NOTIFY_STOCK_CLICK, NotifyStockClickGen),
    takeEvery(metricsActions.NOTIFY_STOCK_SUBMIT, NotifyStockSubmitGen),
    takeEvery(metricsActions.NOTIFY_STOCK_SUBMIT_SUCCESS, NotifyStockSubmitSuccessGen),
    takeEvery(metricsActions.CART_AUTH, CartAuthGen),
    takeEvery(metricsActions.CART_REG, CartRegGen),
    takeEvery(metricsActions.SEND_RAW, SendRawGen),
  ]); 
}


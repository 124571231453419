import React from 'react';
import styled from 'styled-components';

import { H7, H4, A, Line, Gapper, Frame } from 'components/Ui'

import Box, { Inner } from 'components/Box'
import OrderRow from './OrderRow'
import SummaryRow from './SummaryRow'
import OrderRowDeliveryCheckout from './OrderRowDeliveryCheckout'
import OrderRowModCheckout from './OrderRowModCheckout'

function OrderItems({ items, moditem, payType, deliveryType, delivery, errors, dimensionsPresent }) {
  const itemsPrice = items.reduce((acc, item) => (acc + item.price * item.quantity), 0)
  const modAmount = moditem  ? Math.min(moditem.amount, itemsPrice/2) : 0
  // const totalPrice = itemsPrice - ( payType === 1 ? modAmount : 0)
  const deliveryData = (delivery || {}).data || {}
  const deliveryPrice = +(delivery ? delivery.price : 0)
  // const totalPrice = itemsPrice - modAmount;

  // const totalPrice = itemsPrice - ( payType === 1 ? modAmount : 0)
  const totalPrice = itemsPrice - modAmount + ((dimensionsPresent && deliveryType === 1) ? deliveryPrice : 0)

  return (
    <Box bged>
      <Frame>
        <Gapper gap={20}>
        <H7>Позиции заказа</H7>
          {items.map((item, i) => (
            <OrderRow key={item.productId} item={item}/>
          ))}
          {deliveryType === 1 && deliveryData.delivery && (deliveryData.delivery.total_price || deliveryData.delivery.price_delivery) &&
            <OrderRowDeliveryCheckout dimensionsPresent={dimensionsPresent} delivery={deliveryData.delivery} error={errors && errors.deliveries && errors.deliveries[delivery.id]} />
          }        
          {moditem && (
            <OrderRowModCheckout crossed={false} moditem={moditem} amount={modAmount} />
          )}      
        </Gapper>     
      </Frame>
      <SummaryRow checkout price={totalPrice}/>
    </Box>
  )
}

export default OrderItems;
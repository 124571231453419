/* istanbul ignore file */
export const metricsActions = {
  AUTH_REQUEST_METRICS_TOP: "AUTH_REQUEST_METRICS_TOP",
  PARTS_DEALER_CHANGE: "PARTS_DEALER_CHANGE",
  PARTS_DEALER_OPEN_DROPDOWN: "PARTS_DEALER_OPEN_DROPDOWN",
  PARTS_PAGE_NONE_FOUND: 'PARTS_PAGE_NONE_FOUND',
  INTERACT_MAP: 'INTERACT_MAP',
  MAP_DEALER_CLICK: 'MAP_DEALER_CLICK',
  ADD_TO_PODBOR: 'ADD_TO_PODBOR',
  SUBMIT_PODBOR: 'SUBMIT_PODBOR',
  SUBMIT_PODBOR_SUCCESS: 'SUBMIT_PODBOR_SUCCESS',
  GOOD_MINI_BUY_CLICK: 'GOOD_MINI_BUY_CLICK',
  GOOD_OFFERS_BUY_CLICK: 'GOOD_OFFERS_BUY_CLICK',
  NOTIFY_STOCK_CLICK: 'NOTIFY_STOCK_CLICK',
  NOTIFY_STOCK_SUBMIT: 'NOTIFY_STOCK_SUBMIT',
  NOTIFY_STOCK_SUBMIT_SUCCESS: 'NOTIFY_STOCK_SUBMIT_SUCCESS',
  CART_AUTH: 'CART_AUTH',
  CART_REG: 'CART_REG',
  SEND_RAW: 'SEND_RAW',
}

export function partsDealerChange(payload) {
  return {
    type: metricsActions.PARTS_DEALER_CHANGE,
    payload
  }
}

export function partsDealerOpenDropDown(payload) {
  return {
    type: metricsActions.PARTS_DEALER_OPEN_DROPDOWN,
    payload
  }
}

export function partsPageNoneFound(payload) {
  return {
    type: metricsActions.PARTS_PAGE_NONE_FOUND,
    payload
  }
}

export function interactMap(payload) {
  return {
    type: metricsActions.INTERACT_MAP,
    payload
  }
}

export function mapDealerClick(payload) {
  return {
    type: metricsActions.MAP_DEALER_CLICK,
    payload
  }
}

export function addToPodborMetric(payload) {
  return {
    type: metricsActions.ADD_TO_PODBOR,
    payload
  }
}

export function submitPodborMetrics(payload) {
  return {
    type: metricsActions.SUBMIT_PODBOR,
    payload
  }
}

export function submitPodborSuccessMetrics(payload) {
  return {
    type: metricsActions.SUBMIT_PODBOR_SUCCESS,
    payload
  }
}

export function goodMiniBuyClick(payload) {
  return {
    type: metricsActions.GOOD_MINI_BUY_CLICK,
    payload
  }
}

export function goodOffersBuyClick(payload) {
  return {
    type: metricsActions.GOOD_OFFERS_BUY_CLICK,
    payload
  }
}

export function notifyStockClickMetrics(payload) {
  return {
    type: metricsActions.NOTIFY_STOCK_CLICK,
    payload
  }
}

export function notifyStockSubmitMetrics(payload) {
  return {
    type: metricsActions.NOTIFY_STOCK_SUBMIT,
    payload
  }
}

export function notifyStockSubmitSuccessMetrics(payload) {
  return {
    type: metricsActions.NOTIFY_STOCK_SUBMIT_SUCCESS,
    payload
  }
}

export function cartAuth(payload) {
  return {
    type: metricsActions.CART_AUTH,
    payload
  }
}

export function cartReg(payload) {
  return {
    type: metricsActions.CART_REG,
    payload
  }
}

export function authRequestMetricsTop(payload) {
  return {
    type: metricsActions.AUTH_REQUEST_METRICS_TOP,
    payload
  }
}

export function sendRaw(category, action, label) {
  return {
    type: metricsActions.SEND_RAW,
    category,
    action,
    label
  }
}
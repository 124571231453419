/* istanbul ignore file */

import {
  EDIT_MANAGER,
  GET_MANAGERS,
  DELETE_MANAGER,
} from './constants';

export function editManager (payload) {
  return {
    type: EDIT_MANAGER,
    payload,
  };
}

export function deleteManager (payload) {
  return {
    type: DELETE_MANAGER,
    payload,
  };
}

export function getManagers () {
  return {
    type: GET_MANAGERS,
  };
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Wrapper, ErrorDiv } from '../styles'
// import vars from 'config/theme'

SelectField.defaultProps = {
  multi: false,
  className: '',
  clearable: false,
  isSearchable: false,
  options: []
};

SelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // value: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  isSearchable: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

const customStyles =  ({
  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    background: (state.isSelected || state.isFocused) ? 'rgba(0, 126, 0, 0.04)' : '#fff',
    color: '#000',
    fontWeight: 300,

  }),
  control: (provided, state) => ({
    ...provided,
    height: state.isMulti ? 'auto' : 40,
    border: 0,
    paddingLeft: 0,
    background:  '#fff',
    borderBottom: '1px solid #CACACA',
    borderRadius: 0,
    borderColor: ( state.isFocused) ? '#4ba82e !important' : '#9F9F9F',
    boxShadow: ( state.isFocused) ? 'none' : 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: state.isMulti ? 'auto' : 40,
    padding: 0,
    position: 'static',
    fontWeight: 300,
    minHeight: 40,
    'input[readonly]': { width: 1 },
  }),  
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }), 
  multiValue: (provided, state) => ({
    ...provided,
    margin: 2,
    padding: 2,
    // background: vars.primary,
    // background: vars.primary,
    color: '#fff'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#fff'
  }),
  container: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'absolute',
    zIndex: '4',
  }),  
  

})

export function SelectField (props) {
  const {
    input, name, options, multi, className, meta, label, required, clearable, placeholder, allowBlank, isSearchable, dValue, onChange: onSideChange = () => {}
  } = props
  const {
    value, onBlur, onChange, onFocus, 
  } = input;

  const transformedValue = transformValue(value, options, multi) || dValue;
  
  useEffect(() => {
    !value && dValue && dValue.value && onChange(dValue.value)
  }, [])

  return (
    <Wrapper filled={!!input.value} error={(meta.error || meta.submitError) && meta.touched}>

      <Select
        id={name}
        valueKey="value"
        name={name}
        value={ transformedValue || '' }
        isMulti={multi}
        styles={customStyles}
        options={options}
        isClearable={clearable}
        placeholder={placeholder || label}
        isSearchable={isSearchable}
        defaultValue={dValue}
        onChange={multi
          ? multiChangeHandler(onChange, onSideChange)
          : singleChangeHandler(onChange, onSideChange)
        }
        onBlur={() => onBlur(value)}
        onFocus={onFocus}
        className={className}
      />
      {(meta.error || meta.submitError ) && meta.touched && <ErrorDiv>{(meta.error || meta.submitError)}</ErrorDiv>}
    </Wrapper>
  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler (func, func2) {
  /* istanbul ignore next */
  return function handleSingleChange (value) {
    func2(value ? value.value : '')
    func(value ? value.value : '');
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler (func, func2) {
  /* istanbul ignore next */
  return function handleMultiHandler (values) {
    // const vals = values.size && values.toJS() || []
    func2((values || []).map((value) => value.value))
    return func((values || []).map((value) => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue (val, options, multi) {
  if (multi && typeof val === 'string') return [];
  const value = multi ? ( val || [] ) : val
  // const value = val || []
  const filteredOptions = options.filter((option) => multi
    ? value.map(a => a.toString()).indexOf(option.value && option.value.toString()) !== -1
    : typeof option.value !== 'undefined' && option.value.toString() && option.value.toString() === value.toString());
  return multi ? filteredOptions : (filteredOptions[0] || '');
}


export default SelectField
import { createSelector } from 'reselect';
import { clientId } from './config';

import { makeSelectCurrentDomain } from 'containers/App/selectors'

const selectSso = state => state.get('sso');

export const makeSelectIsSsoAuthChecking = () =>
  createSelector(selectSso, ssoState => ssoState.get('authChecking'));

export const scopes = () =>
  createSelector(selectSso, ssoState => ssoState.get('scopes'));

export const getClient = () =>
  createSelector(selectSso, ssoState => ssoState.get('client'));

export const hasSsoCreds = () =>
  createSelector(selectSso, ssoState => !!ssoState.get('user'));

export const getDomainClient = (forceClient) =>
  createSelector(selectSso, makeSelectCurrentDomain(), (ssoState, domain) =>  {
    if (forceClient) {
      return forceClient
    }
    return domain && domain.appclient && domain.appclient.clientId || clientId;
  }
);
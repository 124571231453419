import React from 'react';

import MapDealer from 'components/MapDealer';

import 'css/components/dealer-view.sass';

const DealerView = (props) => {
  const { dealer } = props;
  if (!dealer) return null
  return (
    <div className="dealer-view">
      <div className="dealer-view__title">
        {dealer.name}
      </div>
      <div className="dealer-view__content">
        <div className="dealer-view__map">
          <MapDealer dealers={[dealer]} />
        </div>
        <div className="dealer-view__info">
          <div className="dealer-view__item-title">

            Адрес
          </div>
          <div className="dealer-view__item-value">
            {dealer.address}
          </div>
          <div className="dealer-view__item-title">

            Телефон
          </div>
          <div className="dealer-view__item-value">
            <a href={`tel:${dealer.phone}`}>
              {dealer.phone}
            </a>
          </div>
          <div className="dealer-view__item-title">

            E-mail
          </div>
          <div className="dealer-view__item-value">
            <a href={`mailto:${dealer.email}`}>
              {dealer.email}
            </a>
          </div>
          <div className="dealer-view__item-title">

            Сайт
          </div>
          <div className="dealer-view__item-value">
            <a href={dealer.site} target="_blank">
              {dealer.site}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerView;

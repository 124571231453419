/**
*
* DealerOrderRow
*
*/

import React from 'react';

import 'css/components/table.sass';
import { formatPrice } from 'helpers';
import ProductCartButton from 'components/ProductCartButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Confirm from 'components/Confirm';

class DealerOrderRow extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      deleting: false,
    };
  }

  render () {
    const {
      n, products, row, addItemToCart,
    } = this.props;
    return (
      <tr>
        <td className={`table__order0 ${this.state.deleting ? ' loading' : ''}`}>
          {n}
        </td>
        <td className={`table__bold table__order0 table__left${this.state.deleting ? ' loading' : ''}`}>
          {products[row.productId] && products[row.productId].name}
          <br />
          <span className="badge badge_big">
            {products[row.productId] && (products[row.productId].originalId || row.productId)}
          </span>
        </td>
        <td className={`table__order3 table__left${this.state.deleting ? ' loading' : ''}`}>
          <span className="hide-lg">
            Цена:
            {' '}
          </span>
          {formatPrice(row.price)}
        </td>
        <td className="table__br table__order2" />
        <td className={`table__order3 table__right${this.state.deleting ? ' loading' : ''}`}>
          <ProductCartButton
            addItemToCart={addItemToCart}
            productId={row.productId}
            dealerId={row.dealerId}
            item={row}
          />
        </td>
        <td className="table__br table__order3" />
        <td className={`table__right table__order3${this.state.deleting ? ' loading' : ''}`}>
          {formatPrice(row.quantity * row.price)}
        </td>
        <td className={`table__right table__order1${this.state.deleting ? ' loading' : ''}`}>
          <span className="hoverable" onClick={() => { Confirm(() => { this.setState({ deleting: true }); addItemToCart(row.productId, row.dealerId, -row.quantity); }, 'Вы действительно хотите удалить аксессуар из корзины?'); }}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </td>
      </tr>
    );
  }
}

DealerOrderRow.propTypes = {

};

export default DealerOrderRow;

import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectEntity } from 'models/selectors';

import { makePayment, refreshStatus } from 'containers/Payment/actions';
import { editOrder } from 'models/orders/actions';

import Button from 'components/Button';
import { Text3 } from 'components/Ui';

class PayBlock extends React.Component {
  constructor (props) {
    super(props);
    this.state = { makingPayment: false, paymentFailed: false };
    this.payCash = this.payCash.bind(this);
    this.payTypeReset = this.payTypeReset.bind(this);
    this.makePayment = this.makePayment.bind(this);
  }

  payCash () {
    const { editOrder, orderId } = this.props;
    return new Promise((resolve, reject) => {
      editOrder({
        id: orderId, data: { payType: 0 }, resolve, reject,
      });
    });
  }

  payTypeReset () {
    const { editOrder, orderId } = this.props;
    return new Promise((resolve, reject) => {
      editOrder({
        id: orderId, data: { payType: 1 }, resolve, reject,
      });
    });
  }

  makePayment () {
    const { makePayment } = this.props;
    return new Promise((resolve, reject) => {
      this.setState({ makingPayment: true });
      makePayment(this.props.orderId, { resolve, reject });
    }).then().catch((e) => {
      this.setState({ paymentFailed: true, makingPayment: false });
    });
  }

  render() {
    const { makePayment, orderId, orderSelector } = this.props
    const order = orderSelector(orderId)
    const { payType, deliveryType, dimensions } = order
      
    if (payType === 0) { // cash selected
      return (
        <>
          {!dimensions && deliveryType === 1 && <span>Стоимость доставки будет доступна после проверки заказа дилером</span> }
          {/*<Button secondary onClick={this.payTypeReset}>Выбрать другой способ оплаты</Button>*/}
        </>
      );
    }

    return (
      <div>
        {(deliveryType === 0 || deliveryType === 2 || (deliveryType === 1 &&  dimensions)) && <Button loading={this.state.makingPayment} small onClick={() => {this.setState({makingPayment: true}); makePayment(orderId)}}>Оплатить онлайн</Button>}
        {!dimensions && deliveryType === 1 && <span>Оплата будет доступна после проверки заказа дилером</span>}
        {/*<Button secondary small onClick={() => this.payCash()}>Оплатить при получении</Button>*/}
        {this.state.paymentFailed && (
          <Text3 red style={{ padding: '10px 0 0' }}>
            Не удалось оплатить заказ
          </Text3>
        )}        
      </div>
    );
  }
}

function mapDispatchToProps (dispatch) {
  return {
    makePayment: (orderId, payload) => dispatch(makePayment(orderId, payload)),
    editOrder: (payload) => dispatch(editOrder(payload)),
  };
}

const mapStateToProps = createStructuredSelector({
  orderSelector: makeSelectEntity('orders'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(PayBlock);

/**
*
* ShowOrder
*
*/

import React from 'react';
import styled from 'styled-components'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import 'css/components/show-order.sass';
// import 'css/print.sass';
import { selectOrderItems } from 'containers/App/selectors'

import DealerOrder from 'components/DealerOrder';
import { makeSelectEntities } from 'models/selectors';
import PriceDisclaimer from 'components/PriceDisclaimer';
import { ORDER_STATUSES_TRANSLATION } from 'settings';
import { orderStatusToBadgeClass } from 'helpers';
import vars from 'config/styles'

 
import OrderNumber from 'components/OrderNumber';

class ShowOrder extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const {
      id, orders, items, dealers, hideDisclaimer = false
    } = this.props;
    const order = orders && orders[id];
    const dealer = dealers && dealers[order.dealerId];
    return (
      <React.Fragment>
        <div className="show-order">
          <div className="show-order__top">
            <div className="show-order__title">
              <h2>
                Заказ
                {' '}
                <OrderNumber order={order} />
                &nbsp;
                <span style={{marginTop: -6, verticalAlign: 'middle'}} className={`badge badge_big ${orderStatusToBadgeClass(order.status)}`}>
                  {ORDER_STATUSES_TRANSLATION[order.status]}
                </span>
              </h2>
            </div>
            <div className="show-order__date">
              {order.date}
            </div>
          </div>
        
          <DealerOrder
            dealer={dealer}
            order={order}
            moditem={order.moditems && order.moditems[0]}
            comment={order.userComment}
            items={items}
          />
          {!hideDisclaimer && 
            <PriceDisclaimer />
          }
        </div>

      </React.Fragment>
    );
  }
}

ShowOrder.propTypes = {

};

const mapStateToProps = createStructuredSelector({
  orders: makeSelectEntities('orders'),
  items: selectOrderItems(),
  products: makeSelectEntities('products'),
  dealers: makeSelectEntities('dealers'),
});

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    // setPopup: (id, opts) => dispatch(setPopup(id, opts)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(ShowOrder);

import React from 'react'
import styled, { css } from 'styled-components'

import vars from 'config/styles'


const TabletWidth = css`
  @media (max-width: ${vars.bp.tablet}px) {
    width: ${({t}) => (t/12) * 100}%;
  }  
`
const MobileWidth = css`
  @media (max-width: ${vars.bp.mobile}px) {
    width: ${({m}) => (m/12) * 100}%;
  }  
`


const Wrapper = styled.div` 
  padding: 0 ${vars.gap/2}px;
  width: ${({d}) => (d/12) * 100}%;
  ${({t}) => t && TabletWidth};
  ${({m}) => m && MobileWidth};
`



export default Wrapper; 
import React from 'react'
import styled from 'styled-components'

import Container from 'components/Container'

// import Fb from 'images/facebook.svg'
// import Ig from 'images/instagram.svg'
import Yt from 'images/youtube.svg'
import Vk from 'images/vk.svg'
import Od from 'images/od.svg'

import vars from 'config/styles'

const Wrapper = styled.div`
  background: #222222;
`

const Section1 = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #fff;
  @media (max-width: ${vars.bp.tablet}px) {
    flex-wrap: wrap;
  }  
`

const Text = styled.div`
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }  
`

const Images = styled.div`
  width: 100%;
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
    text-align: center;
  }  

`

const Section2 = styled.div`
  padding: 26px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${vars.bp.tablet}px) { 
    flex-wrap: wrap;
  }    
 
`

const Left = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  order: 1;
  @media (max-width: ${vars.bp.tablet}px) { 
    width: 100%;
    padding-top: 16px;
    text-align: center;
  }     
`

const Right = styled.div`
  display: flex;
  order: 2;
  @media (max-width: ${vars.bp.tablet}px) {
    width: 100%;
    justify-content: center;
    order: 0;
  }    

`

const Soc = styled.a`
  margin-left: 10px;
  width: 36px;
  height: 36px;
  display: block;
  svg {
    width: 100%;
    height: 100%;
    fill: transparent;
  }
  &:hover {
    svg {
      fill: $green;
    }
  }
`


const FooterBottom = () => {
  return (
    <Wrapper>
      <Container>
        <Section2>
          <Left>
            © ŠKODA AUTO Россия {new Date().getFullYear()}
          </Left>
          <Right>
            {/* <Soc href="https://www.facebook.com/skodarussia?_ga=2.261834445.1181855257.1602200105-1954533628.1576487646" dangerouslySetInnerHTML={{__html: Fb}} /> */}
            {/* <Soc href="https://instagram.com/skodarussia" dangerouslySetInnerHTML={{__html: Ig}} /> */}
            <Soc href="http://www.youtube.com/user/skodarussia?_ga=2.258075978.1181855257.1602200105-1954533628.1576487646" dangerouslySetInnerHTML={{__html: Yt}} />
            <Soc href="https://vk.com/skodarussia?_ga=2.258075978.1181855257.1602200105-1954533628.1576487646" dangerouslySetInnerHTML={{__html: Vk}} />
            <Soc href="http://odnoklassniki.ru/skodarussia?_ga=2.258075978.1181855257.1602200105-1954533628.1576487646" dangerouslySetInnerHTML={{__html: Od}} />
          </Right>          
        </Section2>
      </Container>
    </Wrapper>
  )
}


export default FooterBottom; 
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import {
  createDealer, updateDealer, getDealers, getDealerLogs,
} from 'api/dealers';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';

import { normalize } from 'normalizr';
import { apiRequest } from 'containers/App/saga';

import { setEntities } from 'models/actions';
import { Dealer } from '..';
import { EDIT_DEALER, GET_DEALERS, GET_DEALER_LOGS } from './constants';

export function * editDealerGen (action) {
  const { payload: { data, resolve, reject } } = action;
  try {
    let dealer = null;
    if (!data.id) {
      dealer = yield call(apiRequest, createDealer, data);
    } else {
      dealer = yield call(apiRequest, updateDealer, data);
    }
    const normalized = normalize(dealer, Dealer);
    yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export function * getDealersGen () {
  try {
    const dealers = yield call(apiRequest, getDealers);
    const normalized = normalize(dealers, [Dealer]);
    yield put(setEntities(normalized));
    // resolve();
  } catch (e) {
    errorHandler(e);
    // reject(e);
  }
}

export function * getDealerLogsGen (action) {
  const { payload: { id } } = action;
  try {
    const dealer = yield call(apiRequest, getDealerLogs, id);
    const normalized = normalize(dealer, Dealer);
    yield put(setEntities(normalized, 'dealers'));
    // resolve();
  } catch (e) {
    errorHandler(e);
    // reject();
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(EDIT_DEALER, editDealerGen),
    takeEvery(GET_DEALERS, getDealersGen),
    takeEvery(GET_DEALER_LOGS, getDealerLogsGen),
  ]);
}

/**
*
* DealerGood
*
*/

import React from 'react';
import { mergeDealerGood } from 'helpers';
import GoodView from './GoodView';

function DealerGood (props) {
  const { row, dealerGoods, dealerId } = props;
  const good = mergeDealerGood({ row, dealerGoods, dealerId });
  return (
    <GoodView {...props} row={good} />
  );
}

DealerGood.propTypes = {

};

export default DealerGood;

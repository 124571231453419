import React from 'react';
import styled, { css } from 'styled-components'

import { Wrapper, ErrorDiv } from '../styles'

import {IMaskInput} from 'react-imask';



export function MaskField(props) {
  const { input, meta, cfn = (v) => v } = props;
  return (
    <Wrapper style={props.style} filled={!!input.value.toString()} error={(meta.error || meta.submitError) && meta.touched}>
      {/*<input  type='text' {...input} onChange={(e) => { input.onChange(cfn(e.target.value)) }} autoComplete="off" />*/}
      <IMaskInput type='text' {...input}  onAccept={
        (value, mask) => console.log(value)
      }  mask={props.mask} />
      <label>{props.label}{ props.required && (<span>*</span>) }</label>
      {(meta.error || meta.submitError ) && meta.touched && <ErrorDiv>{(meta.error || meta.submitError)}</ErrorDiv>}
    </Wrapper>
  );

};

export default MaskField;


import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { selectProcessing, selectApplying, selectError, selectCalc } from 'containers/Delivery/selectors'
import { makeSelectCurrentCartId, selectCartItems } from 'containers/App/selectors'
import { setPopup } from 'containers/App/actions'
import { getAddresses, createAddress, editAddress, deleteAddress, getList, calc, applyDelivery, editDelivery } from 'containers/Delivery/actions'
import { makeSelectEntities } from 'models/selectors';


import ViewDeliveryForm from 'components/ViewDeliveryForm'

class DeliveryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { schedules: [] }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.calculate = this.calculate.bind(this)
    this.applyDelivery = this.applyDelivery.bind(this)
    this.editDelivery = this.editDelivery.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    new Promise((resolve, reject) => {
      this.props.getDeliveryTypes({ resolve, reject, dealerCode: this.props.dealerCode })
    })
    // trigger initial deliveries load if not present
    const { calcResult, calc, dealerCode, items, delivery, paid, order } = this.props
    // if(!calcResult && Object.keys(delivery).length && delivery.cityId) {
    if(Object.keys(delivery).length && delivery.cityId) {
      new Promise((resolve, reject) => {
        this.props.calc({ resolve, reject, orderId: order && order.id, cityId: delivery.cityId, dealerCode, items, deliveryCompanyId: delivery.deliveryCompanyId, paid })
      }).then(a => {
        this.props.calc({ cityId: delivery.cityId, dealerCode, items, paid, orderId: order && order.id }) 
      }).catch(e => {
        this.props.calc({ cityId: delivery.cityId, dealerCode, items, paid, orderId: order && order.id })
      })
    }
    if(Object.keys(delivery).length) {
      this.handleDateChange(delivery.cityId, delivery.deliveryCompanyId, delivery.date)
    }
  }

  handleSubmit(vals) {
    // console.log(vals)
    if(this.props.order) {
      this.editDelivery(vals)
    } else {
      this.applyDelivery(vals)
    }
  }

  handleDateChange(city_fias, company_id, date) {
    const { getSchedules, dealerCode } = this.props
    new Promise((resolve, reject) => {
      getSchedules({ resolve, reject, dealerCode: dealerCode, params: { company_id, city_fias, date } })
    }).then(res => this.setState({ schedules: res.map(item => ({ label: `${item.time_from} – ${item.time_to}`, value: item.id })) }))
  }

  applyDelivery(data) {
    const { dealerId, applyDelivery } = this.props
    return new Promise((resolve, reject) => {
      applyDelivery({ dealerId, data, resolve, reject })
    }).then(a => {
      this.props.setPopup(null)
    })
  }

  editDelivery(data) {
    const { order, editDelivery } = this.props
    return new Promise((resolve, reject) => {
      editDelivery({ id: order.id, data, resolve, reject })
    }).then(a => {
      this.props.setPopup(null)
    }).catch(console.log)
  }
  

  calculate(cityId) {
    const { dealerCode, items, paid } = this.props
    this.props.calc({ cityId, dealerCode, items, paid })
  }

  render() {
    const { loadCities, calcResult, error, loading, applying, delivery, dimensionsPresent, order } = this.props;
    return (
      <ViewDeliveryForm 
        loadCities={(inputValue, callback) => loadCities(inputValue, callback, this.props.dealerCode)}
        calculate={this.calculate}
        onSubmit={this.handleSubmit}
        handleDateChange={this.handleDateChange}
        schedules={this.state.schedules}
        calcResult={calcResult}
        error={error}
        loading={loading}
        applying={applying}
        initialValues={delivery}
        dimensionsPresent={dimensionsPresent}
        order={order}
      />
    )
  }

}

const mapStateToProps = createStructuredSelector({
  cartId: makeSelectCurrentCartId(),
  loading: selectProcessing(),
  applying: selectApplying(),
  error: selectError(),
  calcResult: selectCalc(),
  items: selectCartItems(),
});

function mapDispatchToProps (dispatch) {
  return {
    getDeliveryTypes: payload => dispatch(getList({...payload, path: 'delivery-type'})),
    getSchedules: payload => dispatch(getList({...payload, path: 'courier-schedule'})),
    getAddresses: payload => dispatch(getAddresses(payload)), 
    createAddress: payload => dispatch(createAddress(payload)), 
    editAddress: payload => dispatch(editAddress(payload)), 
    deleteAddress: payload => dispatch(deleteAddress(payload)),
    applyDelivery: payload => dispatch(applyDelivery(payload)),
    editDelivery: payload => dispatch(editDelivery(payload)),
    setPopup: payload => dispatch(setPopup(payload)),
    loadCities: (inputValue, callback, dealerCode) => {
      // if(!inputValue) return callback([])
      new Promise((resolve, reject) => {
        dispatch(getList({ resolve, reject, params: { name: inputValue }, dealerCode, path: 'city' }))
      }).then(result => {
        callback(result.map(r=> {
          return {label: r.name, value: r.fias}
        }))
      })      
    },
    calc: payload => dispatch(calc(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(DeliveryForm);

import React from 'react'

import { Helmet } from 'react-helmet-async';
import Container from 'components/Container';
import HelpItem from 'components/HelpItem'
import { Link } from 'react-router-dom';
import InsDealer from 'images/ins_dealer.jpg'
import InsDealer2 from 'images/ins_dealer2.jpg'
import heroAcc from 'images/heroacc.jpg';

import accelPic from 'images/accel.jpg'
import ordersPic from 'images/help/orders.jpg'
import newOrderPic from 'images/help/new_order.jpg'
import orderExpandedPic from 'images/help/order_expanded.jpg'
import orderEditPic from 'images/help/edit_order.jpg'
import paymentShortPic from 'images/help/payment_short.jpg'
import paymentDetailedPic from 'images/help/payment_detailed.jpg'
import depositPic from 'images/help/deposit.jpg'
import depositedPic from 'images/help/deposited.jpg'
import refundedPic from 'images/help/refunded.jpg'
import editPic from 'images/help/edit.jpg'

export class InstructionDealer extends React.PureComponent {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Оригинальные детали ŠKODA
          </title>
          <meta name="description" content="Оригинальные детали ŠKODA" />
        </Helmet>   

        <div className="hero hero_small" style={{ backgroundImage: `url(${accelPic})` }}>
          <Container>
          </Container>
        </div>        
        <Container>
          <div className='panel helpo'>
            <h2>Инструкция по обработке заказов</h2>
            <div style={{display: 'flex'}}>
              <div style={{paddingRight: 20, flex: '50% 1 1'}}>
                <img src={newOrderPic} style={{float: 'right', margin: '0 0 10px 10px', maxWidth: '100%', width: 600}} />
                В разделе отображается список заказов, поступивших с сайта-витрины импортера, а также с дилерского сайта (при наличии). Первоначально информация о заказах отображается в сокращенном виде.
                  <br/>
                  Для просмотра доступны: 
                  <ul>
                    <li>номер заказа</li>
                    <li>время поступления</li>
                    <li>статус</li>
                  </ul>
                  Заказы, требующие действия, имеют пиктограмму красного восклицательного знака рядом с номером заказа.

              </div>
     

            </div>
            <HelpItem Title={<div>Детальная информация о заказе</div>}>
              <img src={orderExpandedPic} style={{float: 'right', margin: '0 0 10px 10px', maxWidth: '100%', width: 600}} />
              Детальная информация о заказе доступна по нажатию на номер заказа. В расширенном виде карточка заказа содержит следующие поля: время прошедшее с момента поступления заказа до принятия в работу, сумма заказа и количество позиций, а также информация о клиенте. В случае если для дилера подключена онлайн-оплата, карточка заказа будет содержать блок с деталями оплаты заказа.
              <br/>
              Просмотр информации о позициях, включенных в заказ, доступен при нажатии на сумму заказа с пиктограммой «глаз».
            </HelpItem>            
            <HelpItem Title={<div>Обработка заказа</div>}>
              <img src={orderEditPic} style={{float: 'right', margin: '0 0 10px 10px', maxWidth: '100%', width: 300}} />

              Вновь поступившие заказы имеют статус «Новый» и должны быть обработаны в течение 2-х часов с момента поступления. Для обработки заказа необходимо раскрыть детальную информацию, нажав на номер заказа и открыть всплывающее окно по кнопке «Обработать», после чего перевести заказ в соответствующий статус. Если от клиента поступил отказ, то перевести заказ следует в статус «Отменен» и указать причину отказа. До момента смены статуса с первоначального, заказ считается необработанным и по нему рассылаются все уведомления о необходимости принять в работу, а после двух часов он приобретает статус «Просрочен».
              <br style={{clear: 'both'}}/>


            </HelpItem> 
            <HelpItem Title={<div>Почтовые уведомления о заказе</div>}>
              <ol style={{margin: 0}}>
                <li>При поступлении заказа на ресурсе уведомление приходит сразу на учетную запись «Ответственный сотрудник» (как правило: online-shop@ДоменДЦ.ru)</li>
                <li>Спустя 1 час, с учетом рабочего времени дилера и часового пояса, если заказ не обработан - уведомления приходят на учетные записи «Ответственный сотрудник» + «Руководитель отдела запасных частей»</li>
                <li>Спустя 2 часа, с учетом рабочего времени дилера и часового пояса, если заказ не обработан - уведомления приходят на учетные записи «Ответственный сотрудник» + «Руководитель отдела запасных частей», а так же заказ приобретает статус «Просрочен»</li>
                <li>Спустя 24 часа, с учетом рабочего времени дилера и часового пояса, если заказ всё еще не обработан - уведомления приходят на учетные записи «Ответственный сотрудник» + «Руководитель отдела запасных частей» + «Региональный менеджер Aftersales».</li>
              </ol>     
            </HelpItem>            
            <HelpItem Title={<div>Управление оплатой</div>}>
              <img src={paymentShortPic} style={{float: 'right', margin: '0 0 10px 2s0px', maxWidth: '100%', width: 300}} />
              В случае подключенной онлайн-оплаты для дилера клиент имеет возможность оплатить заказ с карты онлайн. Для просмотра деталей оплаты в расширенном виде карточки заказа имеется блок «Оплата» с краткой информацией об оплате.
              <br style={{clear: 'both'}}/>
              <img src={paymentDetailedPic} style={{float: 'left', margin: '10px 20px 10px 0', maxWidth: '100%', width: 600}} /> 
              Управление оплатой осуществляется во всплывающем окне доступном при нажатии на кнопку «Детали оплаты». 
              Для оператора доступен различный набор действий в зависимости от статуса заказа. Всего существует 7 различных статусов оплаты:
              <ul style={{margin: 0, overflow: 'hidden'}}>
                <li>Не оплачен</li>
                <li>Оплачен (сумма заблокирована на счету клиента)</li>
                <li>Оплачен (сумма полностью списана)</li>
                <li>Оплата отклонена (дилером)</li>
                <li>Оплата возвращена</li>
                <li>В процессе оплаты</li>
                <li>Оплата отклонена (клиентом)</li>
              </ul>
              Как только клиент производит оплату, заказ приобретает статус «Оплачен» и требует обязательного действия от дилера: необходимо подтвердить списание либо отменить оплату и разблокировать деньги на счету клиента. 
              <br style={{clear: 'both'}} />

              <img src={depositPic} style={{float: 'right', margin: '10px 0 20px 10px', maxWidth: '100%', width: 300}} />
              Для списания доступна полная сумма по товарным позициям в заказе, таким образом для того, чтобы списать сумму меньшую, чем была заблокирована первоначально, необходимо отредактировать заказ. Списание происходит после нажатия на кнопку «Списать» и нажатия «Подтвердить» рядом с появившимся окошком суммы к списанию
              <br style={{clear: 'both'}} />
              <img src={depositedPic} style={{float: 'left', margin: '10px 20px 10px 0', maxWidth: '100%', width: 300}} />
              После подтверждения оплаты, заказ переходит в статус полностью оплаченного и деньги поступают на счёт дилера. Для полностью оплаченного заказа доступно действие возврата денег по любым товарным позициям в заказе. Для возврата средств необходимо отредактировать заказ, удалив из него позиции, денежные средства за которые должны быть возвращены. Возврат может применяться несколько раз, до тех пор, пока вся первоначальная сумма не будет возвращена. После возврата средств заказ приобретает статус «Оплата возвращена».
              <br style={{clear: 'both'}} />
              <img src={refundedPic} style={{float: 'left', margin: '10px 20px 10px 0', maxWidth: '100%', width: 450}} />
              <b>Захолдировано</b> - Это сумма, которая была первоначально заблокирована на счету клиента.
              <br/>
              <b>Списано</b> - Средства, поступившие на счет дилера
              <br/>
              <b>Возвращено</b> - Возвращенные обратно на счет клиента средства, после подтверждения оплаты.
              <br style={{clear: 'both'}} />
              <h3>Редактирование заказа</h3>
              В случае, если какие-либо позиции в оплаченном клиентом заказе отсутствуют, необходимо отредактировать заказ, убрав лишние элементы и сохранив изменения. После чего можно произвести подтверждение списания средств со счета клиента. Для списания будет установлена сумма не заблокированных изначально средств, а отредактированного заказа. Так же заказ должен быть отредактирован при возврате. Позиции, денежные средства за которые должны быть возвращены, необходимо удалить из заказа. 
              <div style={{margin: '20px 30px 0'}}>
                <img src={editPic} style={{ maxWidth: '100%'}} />
              </div>

            </HelpItem>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default InstructionDealer
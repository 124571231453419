import React from 'react'

import { Helmet } from 'react-helmet-async';
import Container from 'components/Container';
import HelpItem from 'components/HelpItem'
import { Link } from 'react-router-dom';
import py1 from 'images/help/py1.jpg'
import py2 from 'images/help/py2.jpg'
import py3 from 'images/help/py3.jpg'
import py4 from 'images/help/py4.jpg'
import py5 from 'images/help/py5.jpg'
import py6 from 'images/help/py6.jpg'
import py7 from 'images/help/py7.jpg'
import py8 from 'images/help/py8.jpg'
import py9 from 'images/help/py9.jpg'

export class InstructionUser extends React.PureComponent {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Оригинальные детали ŠKODA
          </title>
          <meta name="description" content="Оригинальные детали ŠKODA" />
        </Helmet>   
        <Container>
          <div className='panel'>
            <h2>Навигация</h2>
            <div>
              Зарегистрированному пользователю в дополнение к разделам, доступным <Link className='green link' to='/help'>гостю</Link>, доступно следующие:
              <ul className='hlist'>
                <li>Профиль</li>
                <li>Заказы</li>
              </ul>
            </div>
            <HelpItem Title={<div>Профиль</div>}>
              На странице профиля пользователь может ознакомиться и отредактировать информацию своего профиля, а также изменить пароль.
            </HelpItem>

            <HelpItem Title={<div>Заказы</div>}>
              В разделе отражены все заказы пользователя с информацией о сумме, дилере и статусе.
            </HelpItem>

            <HelpItem Title={<div>Оплата</div>}>
              Каждый подключенный к оплате дилер в предложениях по запчастям и аксессуарам напротив названия имеет значок пластиковый карты:
              <img src={py1} style={{maxWidth: '100%'}}/>
              <p>В корзине общий заказ пользователя делится на несколько заказов в зависимости от выбранных пользователем предложений различных дилеров. Заказы с возможность онлайн оплаты отмечены пояснением со значком пластиковой карты.</p>
              <img src={py2} style={{maxWidth: '100%'}}/>
              <p>После оформления рядом с заказами дилеров с подключенной онлайн-оплатой отобразится кнопка оплаты онлайн, нажатие, на которую перенаправит пользователя на платёжный шлюз сбербанка для ввода реквизитов пластиковой карты.</p>
              <img src={py3} style={{maxWidth: '100%'}}/>
              <p>Отслеживать статус обработки заказа дилером и статус оплаты можно в разделе заказов пользователя:</p>
              <img src={py4} style={{maxWidth: '100%'}}/>
              <p>Подробная информация о заказе и оплате отображается на детальной странице заказа при нажатии на кнопку "подробнее": </p>
              <img src={py5} style={{maxWidth: '100%'}}/>
              <p>После проведения оплаты пользователем статус заказа меняется на "Оплачен", а сам заказ ожидает дальнейших действий от сотрудника дилера, который может совершить одно из трех действий:</p>
              <ul>
                <li>Подтвердить заказ, полностью списав всю заблокированную сумму</li>
                <li>Частично списать сумму</li>
                <li>Отменить оплату, разблокировав средства</li>
              </ul>
              <p>Подтверждённый заказ с полностью списанной суммой: </p>
              <img src={py6} style={{maxWidth: '100%'}}/>
              <p>Подтверждённый заказ с частично списанной суммой: </p>
              <img src={py7} style={{maxWidth: '100%'}}/>
              <p>Заказ с отменённой оплатой: </p>
              <img src={py8} style={{maxWidth: '100%'}}/>
              <p>Так же дилер имеет возможность полного либо частичного возврата денежных средств за заказ:</p>
              <img src={py9} style={{maxWidth: '100%'}}/>

            </HelpItem>
                                              
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default InstructionUser
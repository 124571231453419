/**
*
* PartsForm
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import OffersResult from './OffersResult';
import 'css/components/pupa.sass';
import 'css/components/parts.sass';
import NoResults from 'components/PodborInit';

class PartsResult extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    this.state = { clickedOffer: null };
    this.handleOfferClick = this.handleOfferClick.bind(this);
  }

  handleOfferClick (id) {
    this.setState({ clickedOffer: id });
  }

  render () {
    const {
      offers, dealers, product, number, regionId, regions, formDealerId, strict, domainDealers, isDealerDomain,
    } = this.props;
    let offersArray = number ? Object.values(offers).filter((one) => one.productId === number) : [];

    if (regionId && regionId !== 'null' && dealers && regions && strict) {
      offersArray = offersArray.filter((one) => regions[regionId].cities.includes(dealers[one.dealerId].cityId));
    }
    if (formDealerId && strict) {
      offersArray = offersArray.filter((one) => one.dealerId === formDealerId);
    }
    if (!strict && regions[regionId]) {
      offersArray.sort((a, b) => {
        if (!regions[regionId].cities.includes(dealers[a.dealerId].cityId) && regions[regionId].cities.includes(dealers[b.dealerId].cityId)) {
          return 1;
        }
        return 0;
      });
    }

    let dealersWithoutOffers = []

    offersArray = offersArray.filter(a => dealers[a.dealerId] && !dealers[a.dealerId].hidden || isDealerDomain )

    // show only not hidden dealers form importer domain and all for dealer domain
    const dealersWithOffers = offersArray.map(a => a.dealerId)
    // console.log(dealersWithOffers)

    if(isDealerDomain) {
      dealersWithoutOffers = domainDealers.filter(dealerId => !dealersWithOffers.includes(dealerId))
    } else {
      dealersWithoutOffers = dealers ? Object.keys(dealers).filter(dealerId => !dealersWithOffers.includes(parseInt(dealerId))) : []
    }

    if(formDealerId && strict) {
      dealersWithoutOffers = dealersWithoutOffers.filter((one) => parseInt(one) === formDealerId);
    }
    
    if(regionId && regionId !== 'null' && regions[regionId]) {
      dealersWithoutOffers = dealersWithoutOffers.filter((one) => regions[regionId].cities.includes(dealers[one].cityId))
    }

    dealersWithoutOffers = dealersWithoutOffers.filter(a => dealers[a] && !dealers[a].hidden || isDealerDomain )

    return (
      <div className="parts__results">
        { product &&
          (
            <React.Fragment>
              <div className="parts__product">
                <b className='ws-pre'>
                  {product.originalId || product.id}
                </b>
                {' '}
                -
                {' '}
                {product.name}
              </div>
              <OffersResult offersArray={offersArray} clickedOffer={this.state.clickedOffer} handleOfferClick={this.handleOfferClick} dealersWithoutOffers={dealersWithoutOffers} {...this.props} />
            </React.Fragment>
          )
        }
        { !product && <NoResults /> }
      </div>
    );
  }
}

PartsResult.propTypes = {
  offers: PropTypes.object.isRequired,
  dealers: PropTypes.object.isRequired,
  product: PropTypes.object,
  msrps: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  strict: PropTypes.bool,
  fromPopup: PropTypes.bool,
};

PartsResult.defaultProps = {
  strict: false,
};

export default PartsResult;

/**
 *
 * AdminOffers
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { makeSelectEntities } from 'models/selectors';

import { getDealers } from 'models/dealers/actions';

import ResDisplayFactory from 'components/ResDisplay';
import Template from './Template';

const Displayer = ResDisplayFactory({
  entity: 'offers',
  prefix: 'admin',
  Template,
  heading: {
    dealer: 'Дилер', price: 'Цена', quantity: 'Количество', orders: 'Заказы',
  },
  noNew: true,
  // FiltersForm: Filters,
});

export class AdminOffers extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount () {
    this.props.getDealers();
  }

  render () {
    const { dealers, initialParams } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
Предложения запчасти
          </title>
          <meta name="description" content="Description of AdminOffers" />
        </Helmet>
        <h1>
Список предложений дилеров по запчасти
        </h1>
        <Displayer dealers={dealers} initialParams={initialParams} templateProps={{ dealers }} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  dealers: makeSelectEntities('dealers'),
});

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    getDealers: () => dispatch(getDealers()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(AdminOffers);

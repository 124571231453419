/**
*
* ResDisplay
*
*/

import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

import { makeSelectEntities } from 'models/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faCheck } from '@fortawesome/free-solid-svg-icons';

import { setPopup } from 'containers/App/actions';

import Button from 'components/Button';
import Filter from 'components/Filter';
import DisplayContainer from 'components/DisplayContainer';
import 'css/components/pupa.sass';

function ResDisplayWrapper (options) {
  const { Template, table = true } = options;
  const { queryOnLoad = true } = options

  const Intro = () => (
    <thead>
      <tr>
        {Object.keys(options.heading).map((th) => (
          <td key={th}>
            {options.heading[th]}
          </td>
        ))}

      </tr>
    </thead>
  );

  class ResDisplay extends React.PureComponent {
    constructor () {
      super();
      this.onFiltersFormSubmit = this.onFiltersFormSubmit.bind(this);
      this.state = { xls: false }
    }

    componentDidMount () {
      const { filtersSubmit } = this.props;
      this.setState({ initialParams: this.props.initialParams || {} },
        () => {
          if(queryOnLoad) {
            filtersSubmit(this.state.initialParams);
          }
        });
    }

    onFiltersFormSubmit (vals) {
      const { filtersSubmit } = this.props;
      this.setState({ xls: false });

      return new Promise((resolve, reject) => {
        filtersSubmit({ ...this.state.initialParams, ...vals.toJS() }).then(resolve).catch(reject);
      });
    }

    render () {
      const {
        filterStatus, ids, entities, setFilter, pagination, getRecords, setPopup, downloadXls, noFilters,
      } = this.props;
      const records = getRecords(ids, entities);
      const { FiltersForm } = options;
      return (
        <React.Fragment>
          {FiltersForm && !noFilters &&
            (
              <div className="panel filters">
                <FiltersForm {...this.props} onSubmit={this.onFiltersFormSubmit} />
              </div>
            )
          }
          { options.xls === true &&
            (
              <div style={{ marginBottom: 15, textAlign: 'right' }}>
                {!this.state.xls &&
                (
                  <span onClick={() => { this.setState({ xls: true }); downloadXls(); }} className="hoverable">
                    <FontAwesomeIcon icon={faFileExcel} />
                  &nbsp;&nbsp;
                  Скачать
                  </span>
                )
                }
                {this.state.xls &&
                (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faFileExcel} />
                    &nbsp;&nbsp;
                    Готово
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faCheck} />
                  </React.Fragment>
                )
                }
              </div>
            )
          }
          <div className={classNames({ panel: table}, { panel_table: table })}>
            <DisplayContainer
              records={records}
              loading={filterStatus && filterStatus.fetching && !filterStatus.initial}
              showInitial={filterStatus && filterStatus.initial}
              error={filterStatus && filterStatus.error}
              empty="Нет записей"
              table={table}
              Template={Template}
              Intro={table ? Intro : (() => null)}
              setPopup={setPopup}
              templateProps={{ successCallback: () => setFilter({}), ...this.props.templateProps }}
            />
          </div>
          {pagination}
          {(((typeof options.noNew === 'undefined' || typeof options.noNew !== 'undefined') && options.noNew !== true) && this.props.noNew === false) &&
            (
              <Button className="new-item button" onClick={() => setPopup(options.popupConstant, { successCallback: () => setFilter({}) })}>
              Добавить
              </Button>
            )
          }
        </React.Fragment>
      );
    }
  }

  const mapStateToProps = createStructuredSelector({
    entities: makeSelectEntities(options.entity),
  });

  function mapDispatchToProps (dispatch) {
    return {
      setPopup: (id, options) => dispatch(setPopup(id, options)),
    };
  }

  ResDisplay.propTypes = {
    noNew: PropTypes.bool,
  };

  ResDisplay.defaultProps = {
    noNew: false,
  };

  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  const withFilter = Filter({
    name: `${options.prefix}-${options.entity}`, path: options.path || 'filter', model: options.entity, initialQuery: false, perPage: options.perPage || 30
  });

  return compose(
    withConnect,
    withFilter
  )(ResDisplay);
}

export default ResDisplayWrapper;

export const actionTypes = {
  GET_PAYABLE_STATUS: '@payment/GET_PAYABLE_STATUS',
  SET_PROCESSING: '@payment/SET_PROCESSING',
  MAKE_PAYMENT: '@payment/MAKE_PAYMENT',
  REFRESH_STATUS: '@payment/REFRESH_STATUS',
  DEPOSIT: '@payment/DEPOSIT',
  REVERSE: '@payment/REVERSE',
  REFUND: '@payment/REFUND',
}


export function getPayableStatus() {
  return {
    type: actionTypes.GET_PAYABLE_STATUS,
  }
}

export function makePayment(orderId, payload = {}) {
  return {
    type: actionTypes.MAKE_PAYMENT,
    orderId,
    payload
  }  
}

export function refreshStatus(orderId, payload = {}) {
  return {
    type: actionTypes.REFRESH_STATUS,
    orderId,
    payload
  }  
}

export function deposit(orderId, payload = {}) {
  return {
    type: actionTypes.DEPOSIT,
    orderId,
    payload
  }  
}

export function reverse(orderId, payload = {}) {
  return {
    type: actionTypes.REVERSE,
    orderId,
    payload
  }  
}

export function refund(orderId, payload = {}) {
  return {
    type: actionTypes.REFUND,
    orderId,
    payload
  }  
}

export function setProcessing(processing) {
  return {
    type: actionTypes.SET_PROCESSING,
    processing
  }  
}
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { withRouter } from 'react-router';

import { queryString } from 'helpers';

import { getToken } from './actions';
import { errors } from './settings';

class SsoReturnPath extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      errorAccess: false,
      errorAuth: false,
      authorizing: true,      
      error: null,
    }
  }


  componentDidMount() {
    const qs = queryString(this.props.location.search)
    
    if (qs.error) {
      this.setState({ errorAccess: true })
    }
    
    if(qs.code) {
      this.setState({ authorizing: true });
      new Promise((resolve, reject) => {
        this.props.getToken({ resolve, reject, code: qs.code, state: qs.state })
      }).then(() => this.setState({ authorizing: false }))
        .catch((error) => this.setState({ authorizing: false, errorAuth: true, error }))

    }
  }

  render() {

    return (
      <div className='container'>
        <h2>Авторизация</h2>
        {this.state.errorAccess && 
          <div style={{fontSize: 16}}>
            Ошибка. Доступ к данным профиля не предоставлен.
          </div>
        }

        {this.state.errorAuth && 
          <div style={{fontSize: 16}}>
            Ошибка. Не удалось авторизоваться. 
            {this.state.error === errors.wrongState && 
              <div>
                Неверный идентификатор состояния.
              </div>
            }
            {this.state.error === errors.scopeFail &&
              <div>
                Неверные разрешения.
              </div>
            }
          </div>
        }        

        {this.state.authorizing && 
          <React.Fragment>
            Авторизация...
          </React.Fragment>
        }
      </div>
      )
  }
}

  const mapStateToProps = createStructuredSelector({

  });

  function mapDispatchToProps(dispatch) {
    return {
      getToken: payload => dispatch(getToken(payload)),
    };
  }

const withConnect = connect(mapStateToProps, mapDispatchToProps)


export default compose(
  withRouter,
  withConnect,
)(SsoReturnPath);

/**
*
* ProductCartButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'helpers';
import Sbutton, { WrapperDiv } from 'components/Sbutton';
import Svg, { SvgWrapper } from 'components/Svg';
import Plus from 'images/plus.svg'
import Minus from 'images/minus.svg'


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  input {
    border: 0;
    width: 60px;
    height: 26px;
    line-height: 26px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    outline: none;
  }
  ${WrapperDiv} {
    vertical-align: top;
    width: 40px;
    padding: 0;
    height: 26px;
  }
  ${SvgWrapper} {
    width: 16px;
    height: 16px;
  }
`

class ProductCartButton extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    this.state = {
      quantity: props.item ? props.item.quantity : 0,
      loading: false,
    };
    this.minusOne = this.minusOne.bind(this);
    this.plusOne = this.plusOne.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.customValue = this.customValue.bind(this);    
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.item && (!this.props.item || newProps.item.quantity !== this.props.item.quantity)) {
      this.setState({ quantity: newProps.item.quantity });
    }
  }

  addToCart = async (quantity) => {
    const { addItemToCart, productId, dealerId, sendRaw } = this.props;
    sendRaw('Purchase_RequestForm', 'change_quantity', quantity > 0 ? 'asc' : 'desc')
    this.setState({ loading: true });
    return new Promise(async (resolve, reject) => {
      try {
        await addItemToCart(productId, dealerId, quantity);
        this.setState({ loading: false });
        resolve();
      } catch (e) {
        this.setState({ loading: false });
        reject(e);
      }
    });
  }

  customValue (e) {
    const { item } = this.props;
    const { addToCart } = this;
    const value = Number(e.target.value);
    if (!value) {
      this.setState({ quantity: this.props.item.quantity });
    } else {
      this.setState({ quantity: value });
      const that = this;
      delay(() => {
        addToCart(value - item.quantity).catch((err) => {
          that.setState({ quantity: item.quantity });
        });
      }, 1000);
    }
  }

  minusOne () {
    const { item } = this.props;
    if (item.quantity > 0) { this.addToCart(-1).catch(e => console.log(e)); }
  }

  plusOne () {
    const { limit } = this.props;
    if (limit === this.state.quantity) { return false; }
    this.addToCart(1).catch(e => console.log(e));
    return true;
  }

  render () {
    const {
      item, limit,
    } = this.props;
    const { loading } = this.state
    const itemInCart = item;
    return (
      <React.Fragment>
        {itemInCart &&
          (
            <Wrapper >
              <Sbutton loading={loading} secondary xsmall onClick={this.minusOne}>
                <Svg icon={Minus} />
              </Sbutton>
              <input type="text" value={this.state.quantity} onChange={this.customValue} />
              <Sbutton loading={loading} secondary xsmall onClick={this.plusOne} className={limit === this.state.quantity ? 'disabled' : ''}>
                <Svg icon={Plus} />
              </Sbutton>
            </Wrapper>

          )
        }
        {!itemInCart &&
          (
            <Button disabled={this.state.loading} loading={this.state.loading} style={{ marginRight: 0 }} secondary small onClick={() => this.addToCart(1)}>
              <FontAwesomeIcon className="hoverable" icon={faShoppingCart} style={{ marginLeft: 0 }} />
            &nbsp;
            В корзину
            </Button>
          )
        }
      </React.Fragment>
    );
  }
}

ProductCartButton.propTypes = {
  addItemToCart: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  dealerId: PropTypes.number.isRequired,
  limit: PropTypes.number,
  item: PropTypes.object,
};

export default ProductCartButton;

import React from 'react'
import styled from 'styled-components'

import vars from 'config/styles'

const Wrapper = styled.div` 
  display: flex;
  margin: 0 -${vars.gap/2}px;
  flex-wrap: wrap;
  & > * {
    margin-top: ${vars.gap}px;
  }
  margin-top: -${vars.gap}px;
`


const CardContainer = ({children}) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}


export default CardContainer; 
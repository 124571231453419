
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/minimal-example.css';

import FullScreen from 'components/FullScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'css/components/mfilters.sass';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

export class MobileFilters extends React.PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      expanded: false,
      categories: false,
      models: false,
    };
  }

  render () {
    const {
      makeUrl, categories, setCategory, categorySlug, models, setModel, modelSlug,
    } = this.props;
    return (
      <div className="mfilters">
        <div className="mfilters__button" onClick={() => { document.body.classList.add('body_popup-mfilters'); this.setState({ expanded: true }); }}>

          Фильтр
          <div className="mfilters__button-icon">
            <FontAwesomeIcon icon={faFilter} />
          </div>
        </div>
        { this.state.expanded &&
          (
            <FullScreen close={() => { document.body.classList.remove('body_popup-mfilters'); this.setState({ expanded: false }); }}>
              <div className="mfilters__title">

              Фильтр
              </div>
              <Accordion>
                <AccordionItem expanded={this.state.categories}>
                  <AccordionItemTitle>
                    <div className="mfilters__ctitle">

                    Категории
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </div>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    {categories && Object.values(categories).map((one) => {
                      const cls = classNames('mfilters__item', { active: one.slug === categorySlug });
                      return (
                        <Link to={makeUrl({ categorySlug: one.slug })} onClick={() => { document.body.classList.remove('body_popup-mfilters'); this.setState({ expanded: false }); setCategory(one.slug); }} className={cls} key={`cat-${one.id}`}>
                          {one.name}
                        </Link>
                      );
                    })}

                  </AccordionItemBody>
                </AccordionItem>

                <AccordionItem expanded={this.state.models}>
                  <AccordionItemTitle>
                    <div className="mfilters__ctitle">

                    Модели
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </div>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    {models && Object.values(models).map((one) => {
                      const cls = classNames('mfilters__item', { active: one.slug === modelSlug });
                      return (
                        <Link to={makeUrl({ modelSlug: one.slug })} onClick={() => { document.body.classList.remove('body_popup-mfilters'); this.setState({ expanded: false }); setModel(one.slug); }} className={cls} key={`model-${one.id}`}>
                          {one.name}
                        </Link>
                      );
                    })}
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
              <Button style={{ marginTop: 15, marginLeft: 15 }} small onClick={() => { document.body.classList.remove('body_popup-mfilters'); this.setState({ expanded: false }); }}>

              Применить
              </Button>

            </FullScreen>
          )
        }
      </div>
    );
  }
}

export default MobileFilters;

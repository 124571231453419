import React from 'react';
import styled from 'styled-components';

import vars from 'config/styles'
import { Flex, FlexFixed, FlexFluid, Gapper, Text1, Text3, Text4, Ellipsis } from 'components/Ui'

import { formatPrice } from 'helpers';

import Svg from 'components/Svg'
import { Col1, Col2, Col3, Col4 } from './Common'

const Wrapper = styled(Flex)`
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: normal;
  @media (max-width: ${vars.bp.mobile}px) {
    flex-wrap: wrap;

    ${Col1} {
      width: auto;
      flex: auto;
      margin-bottom: 6px;
    }
    ${Col3} {
      margin-left: auto;
      width: auto;
      padding: 0;
    }
    ${Col4} {
      display: none;
    }  
  }
`



function SubRow({ price, text = 'Итого:', checkout = false }) {
  return (
    <Wrapper>
      <Col1>
        <Text3 >{text}</Text3>
      </Col1>
   
      <Col3>
        <Text1 >
          {formatPrice(price)}
        </Text1>
      </Col3>

      {!checkout &&
        <Col4/>
      }

    </Wrapper>
  )
}

export default SubRow;
import React from 'react'

import { declOfNum } from 'helpers';


const FoundCount = ({ count }) => {

  return (
    <div className="gpage__count">
      {declOfNum(count, ['Найден', 'Найдено', 'Найдено'])}
      {' '}
      {count}
      {' '}
      {declOfNum(count, ['аксессуар', 'аксессуара', 'аксессуаров'])}
    </div>
  )
}

export default FoundCount;
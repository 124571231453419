/* istanbul ignore file */
import { apicall } from 'utils/api';

export function createNotification (data) {
  return apicall('notifications', { method: 'POST', body: JSON.stringify({ notification: data }) });
} 


export function updateNotification (data) {
  const { id, ...rest } = data;
  return apicall(`notifications/${id}`, { method: 'PATCH', body: JSON.stringify({ notification: rest }) });
}

import React from 'react';

import Dropdown from 'components/Dropdown'

const sortOptions = [
  ["По умолчанию", ''],
  ["По возрастанию цены", 'price_asc'],
  ["По убыванию цены", 'price_desc']
]

const map = {
  '': 'default',
  price_asc: 'asc',
  price_desc: 'desc'
}

class SortResult extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.sendRaw('AccessoriesPage', 'sorting_price', map[value])
    this.props.doSort({ field: value })
    // console.log(e.target.value)
  }

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <span  style={{fontSize: 11}}>Сортировать: &nbsp;</span>
        <Dropdown selected={this.props.selected} options={sortOptions} onChange={this.handleChange}/>
      </div>
    )
  }
}


export default SortResult;

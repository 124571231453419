import React from 'react'

import { Helmet } from 'react-helmet-async';
import Container from 'components/Container';
import HelpItem from 'components/HelpItem'
import { Link } from 'react-router-dom';
import InsDealer from 'images/ins_dealer.png'
import InsDealer2 from 'images/ins_dealer2.png'
import pd1 from 'images/help/pd1.png'
import pd2 from 'images/help/pd2.png'
import pd3 from 'images/help/pd3.png'
import pd4 from 'images/help/pd4.png'
import pd5 from 'images/help/pd5.png'
import pd6 from 'images/help/pd6.png'
import pd7 from 'images/help/pd7.png'
import pd8 from 'images/help/pd8.png'

export class InstructionDealer extends React.PureComponent {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Оригинальные детали ŠKODA
          </title>
          <meta name="description" content="Оригинальные детали ŠKODA" />
        </Helmet>   
        <Container>
          <div className='panel'>
            <h2>Навигация</h2>
            <div>
              Пользователю с ролью дилера в дополнение к разделам, доступным <Link className='green link' to='/help/user'>авторизованному пользователю</Link>, доступна панель управления с разделами:

              <ul className='hlist'>
                <li>Заказы</li>
                <li>Домен</li>
                <li>Аксессуары</li>
                <li>Уведомление о поступлении</li>
                <li>Заявки на подбор</li>
              </ul>
            </div>
            <HelpItem Title={<div>Заказы</div>}>
              В данном разделе отображаются заказы, которые поступили с основного сайта и сайта дилера.
              Каждый заказ содержит следующую информацию: (Номер заказа, Статус, Дата, Информация о
              клиенте, Сумма заказа).
              <br/>
              После поступления заказа следует связаться с клиентом и перевести статус в соответствующий.
              Статус заказа имеет следующие пункты: (Новый, В работе, Выполнен, Отменен). Если от
              клиента поступил отказ, то перевести заказ следует в статус «Отменен».
              <br/>
              Просмотр информации о позициях, включенных в заказ, доступен при нажатии на сумму заказа.
              Для печати бланка используйте иконку «Печать» в списке заказов. Если заказ был сделан
              пользователем без регистрации, то отображается иконка «Без регистрации» рядом с ФИО
              клиента. Для отображения контактной информации по заказу необходимо нажать на иконку
              «Развернуть» возле ФИО клиента. Изменить статус заказа или дополнить информацию о заказе
              комментарием можно, нажав на иконку «Редактировать»
              <div><img src={InsDealer} style={{margin: '20px 0'}}/></div>
              <div><b>Рассылка почтовых уведомлений о заказе</b></div>
              <ol>
                <li>При поступлении заказа на ресурсе уведомление приходит сразу на учетную запись "Ответственный сотрудник".</li>
                <li>Спустя 1 час, с учетом рабочего времени дилера и часового пояса, если заказ не обработан - уведомления приходят на учетные записи "Ответственный сотрудник" + "Руководитель отдела запасных частей".</li>
                <li>Спустя 2 часа, с учетом рабочего времени дилера и часового пояса, если заказ не обработан - заказ получает статус "Просрочен". Уведомления приходят на учетные записи "Ответственный сотрудник" + "Руководитель отдела запасных частей".</li>
                <li>Спустя 24 часа, с учетом рабочего времени дилера и часового пояса, если заказ всё еще не обработан - уведомления приходят на учетные записи "Ответственный сотрудник" + "Руководитель отдела запасных частей" + "Региональный менеджер Aftersales".</li>
              </ol>
            </HelpItem>
            <HelpItem Title={<div>Домен</div>}>
              Когда отсутствуют в разделе привязанные домены, функционал раздела ограничен формой
              заявки на подключение домена, в которой дилер может указать необходимый адрес. После
              одобрения заявки, становится возможным изменять следующие параметры дилерского сайта:
              (Главное изображение; Слоган; Положение слогана)
              <br/>
              Для подключения доменного имени ДЦ, к примеру, «parts.домендц.ру», к ŠKODA «Оригинальные
              детали, запчасти и аксессуары» необходимо создать домен 3-его уровня. Для этого IТ-
              специалисту дилера необходимо прописать следующие параметры в редакторе DNS-записей
              домена: (Хост - parts, Тип - A, Значение - 213.232.229.117). После этого указать созданный домен
              ДЦ в разделе «Домен».
            </HelpItem>

            <HelpItem Title={<div>Аксессуары</div>}>
              В разделе «Аксессуары» можно редактировать товары, отображаемые на доменном имени ДЦ.
              Также можно скрыть товар или поместить его на главную страницу, установив соответствующий
              чекбокс.
              <div><img src={InsDealer2} /></div>
            </HelpItem>

            <HelpItem Title={<div>Уведомление о поступлении</div>}>
              Данный раздел отображает запросы пользователей с сайта ДЦ по отсутствующим товарам у 
              дилера. Отображаются контактные данные клиента и возможность изменить статус. (Новый, В 
              работе, Выполнен).
            </HelpItem>           

            <HelpItem Title={<div>Заявки на подбор</div>}>
              Данный раздел отображает заявки зарегистрированных/незарегистрированных пользователей на подбор деталей. После того, как пользователь отправит запрос на подбор деталей, данная заявка будет отображаться в этом разделе. Первоначально информация о заявке отображается в сокращенном виде. Для просмотра доступны:
              <ul>
                <li>Номер заявки</li>
                <li>Время поступления</li>
                <li>Статус</li>
              </ul>
              <img src={pd1} style={{ maxWidth: '100%' }} />
              <p>Детальная информация о заявке доступна по нажатию на номер заявки.</p>
              <img src={pd2} style={{ maxWidth: '100%' }} />
              <p>В расширенном виде карточка заявки содержит следующие поля: </p>
              <ul>
                <li>Время, прошедшее с момента поступления заявки до принятия в работу </li>
                <li>Количество деталей</li>
                <li>Информация о клиенте</li>
              </ul>
              <img src={pd3} style={{ maxWidth: '100%' }} />
              <p>Просмотр информации о позициях, включенных в заявку, доступен при нажатии на количество деталей с пиктограммой «глаз».</p>
              <img src={pd4} style={{ maxWidth: '100%' }} />
              <p>Изменить статус заявки и посмотреть информацию о пользователе и списке позиций можно, нажав на кнопку «Изменить статус».</p>
              <img src={pd5} style={{ maxWidth: '100%' }} />
              <p>Доступны следующие статусы заявок:</p>
              <ul>
                <li>Новый</li>
                <li>В работе</li>
                <li>Выполнен</li>
              </ul>
              <img src={pd6} style={{ maxWidth: '100%' }} />
              <p>После изменения статуса заявки необходимо нажать на кнопку «Продолжить».</p>
              <img src={pd7} style={{ maxWidth: '100%' }} />
              <p>Через 2 часа, с учетом рабочего времени дилера и часового пояса, если заявка не будет обработана, то рядом со статусом заявки появится надпись «Просрочен», означающая, что заявка не была вовремя обработана.</p>
              <img src={pd8} style={{ maxWidth: '100%' }} />
            </HelpItem>







                                              
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default InstructionDealer
/* istanbul ignore file */
import { apicall } from 'utils/api';



export function createSetting (data) {
  return apicall('settings', { method: 'POST', body: JSON.stringify({ setting: data }) });
}

export function updateSetting (data) {
  const { id, ...rest } = data;
  return apicall(`settings/${id}`, { method: 'PATCH', body: JSON.stringify({ setting: rest }) });
}

import React from 'react';
import styled, { css } from 'styled-components';

import { Flex, FlexFixed, FlexFluid, Gapper, Text1, Text4, Ellipsis } from 'components/Ui'

import { formatPrice } from 'helpers';

import Truck from 'images/truck.svg'

import ProductCartButtons from 'components/ProductCartButtons';
import Confirm from 'components/Confirm';
import Svg, { SvgWrapper } from 'components/Svg'
import Trashcan from 'images/trash.svg'
import { Col1, Col2, Col3, Col4 } from './Common'
import Bonus from 'images/points.svg'
import Loader from 'components/Loader'

import { Frame } from 'components/Ui'

const CFlex = styled(Flex)`
  ${SvgWrapper} {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }  
`

const Title = styled(FlexFixed)`
  ${({ errored }) => errored && css`
    color: red;
    ${Text1} {
      color: red;
    }
    ${Text4} {
      color: red;
    }
  `}

`

function OrderRowDeliveryCheckout({ delivery, dimensionsPresent, loading, error }) {
  return (
    <Frame>
      <Flex title=''>
        <Col1>
          <Gapper gap={4}>
            <CFlex>
              <FlexFixed style={{width: 40, height: 30, marginRight: 20, textAlign: 'center'}}>
                <Svg big icon={Truck} style={{marginRight: 0}}/>
              </FlexFixed>
              <Title errored={!!error}>
                <Text1><Ellipsis>Доставка</Ellipsis></Text1>
            
                {error && <div style={{color: 'red'}}>{error}</div>}

              </Title>
            </CFlex>            
          </Gapper>
        </Col1>
        <Col2>

        </Col2>
        <Col3>
          <Text1>
            {loading && <Loader size={20}/>}
            {!loading && formatPrice(dimensionsPresent ? (delivery.total_price || delivery.price_delivery) : 0)}
          </Text1>
        </Col3>

      </Flex>
    </Frame>
  )
}

export default OrderRowDeliveryCheckout;
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectCurrentUser } from 'containers/App/selectors';
import { getClient } from 'containers/Sso/selectors';
// getRealm
// import { adminClientId, REALMS } from 'containers/Sso/config';
import { ROLES } from 'settings';
import { adminClientId, REALMS } from 'containers/Sso/config'

import Svg, { SvgWrapper } from 'components/Svg';
import ArrowR from 'images/arrow_r.svg';
import Container from 'components/Container';
import { makeSelectCurrentCartId } from 'containers/App/selectors';
import { makeSelectEntities } from 'models/selectors';
import { orderData, formatPrice } from 'helpers';

import CartIcon from 'images/cart.svg';
import ArrowIcon from 'images/arrow.svg';
import ClickOutside from 'react-click-outside'
import vars from 'config/styles'
import * as metricsActions from 'containers/App/metricsActions'

const Wrapper = styled.div`
  height: 48px;
  background-color: #222;
  z-index: 1;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 48px;
`;

const Right = styled.div`
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  & > * {
    margin-left: 27px;
    margin-right: 0;
    @media (max-width: ${vars.bp.mobile}px) {
      margin-left: 10px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  ${Item} {
    span {
      @media (max-width: ${vars.bp.mobile}px) {
        display: none;
      }
    }
  }
  .active {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 3px solid #4ba82e;
    }
  }
`;

const Left = styled.div`
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  margin-right: auto;
  a + a {
    margin-left: 27px;
    margin-right: 0;
    @media (max-width: ${vars.bp.mobile}px) {
      margin-left: 10px;
    }
  }
  .active {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 3px solid #4ba82e;
    }
  }
`;

const ItemStyle = css`
  display: block;
  font-weight: 300;
  font-size: 16px;
  line-height: 48px;
  height: 100%
  color: #fff;
  overflow: hidden;
  &:last-child {
    margin-right: 0;
  }
  ${SvgWrapper} {
    width: 18px;
    height: 18px;
    /* transform: rotate(180deg); */
    margin-right: 4px;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: #fff;
      }
    }
  }
  &:hover {
    ${SvgWrapper} {
      path {
        fill: #4ba82e;
      }
    }
  }
`

const Item = styled(NavLink)`
  ${ItemStyle};
`;

const ItemDiv = styled.div`
  ${ItemStyle};
`

const IconWrapper = styled.div`
  cursor: pointer;
  ${SvgWrapper} {
    transform: rotate(180deg);
    margin-right: 0;
    transition: transform 0.35s ease;
  }
  ${ ({ open }) => open && css`
    ${SvgWrapper} {
      transform: rotate(0);
    }
  `}  
`

const Popover = styled.div`
  position: absolute;
  top: 100%;
  /* left: 0; */
  width: 300px;
  right: 0;
  padding: 20px 48px 20px;
  overflow-y: scroll;
  background: #fff;
  box-shadow: 0 4px 40px rgb(34 34 34 / 10%);
  display: flex;
  flex-flow: column;
`

const PopItem = styled(Link)`
  font-size: 16px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`

const PopMenu = ({ sendRaw, role }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ClickOutside onClickOutside={() => setTimeout(() => setOpen(false), 0)} >
        <ItemDiv onClick={() => setOpen(true)}>
          <IconWrapper open={open}>
            <Svg icon={ArrowIcon} />
          </IconWrapper>
  
        </ItemDiv>
      </ClickOutside>
      {open &&
        <Popover>
          {role > 0 && <>
            <PopItem onClick={() => sendRaw('TopBar', 'click_purchases')} to='/orders'>Заказы</PopItem>
            <PopItem onClick={() => sendRaw('TopBar', 'click_requests')} to='/requests'>Заявки на подбор</PopItem>
          </>}
          <PopItem onClick={() => sendRaw('TopBar', 'click_favourite')} to='/favs'>Избранное</PopItem>
          <PopItem onClick={() => sendRaw('TopBar', 'click_compare')} to='/compare'>Сравнение</PopItem>
        </Popover>
      }    
    </>
  )
}

const SubHeader = props => {
  const { user, client, realm, items, cartId, sendRaw } = props;
  // if (!user) return null;
  const role = user ? user.role : null;
  const { count, price } = orderData(items, cartId);
  // console.log({ realm })
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Left>
            <Item onClick={() => sendRaw('TopBar', 'click_accessories_page')} exact to="/accessories">
              Аксессуары
            </Item>
            <Item onClick={() => sendRaw('TopBar', 'click_parts_page')} exact to="/">
              Оригинальные детали
            </Item>            
          </Left>
          <Right>
            {role &&
              role > ROLES.USER &&
              client !== adminClientId &&
              realm === REALMS.VW && <Item to="/protected">Панель управления</Item>}
            {[
              ROLES.IMPORTER,
              ROLES.ADMIN,
            ].includes(role) &&
              client === adminClientId && <Item to="/admin">Панель управления</Item>}
            {[
              ROLES.DEALER,
            ].includes(role) &&
              client === adminClientId && <Item to="/dashboard">Панель управления</Item>}              
            {realm === REALMS.VW && (
              <Item onClick={() => sendRaw('TopBar', 'click_cart')} to="/cart"><Svg icon={CartIcon} /><span>{formatPrice(price) || '0 руб'}</span></Item>
            )}
            <PopMenu sendRaw={sendRaw} role={role} />
          </Right>
        </Inner>
      </Container>
    </Wrapper>
  );
};



const mapStateToProps = createStructuredSelector({
  user: makeSelectCurrentUser(),
  client: getClient(),
  cartId: makeSelectCurrentCartId(),
  carts: makeSelectEntities('carts'),  
  // realm: getRealm(),
});

function mapDispatchToProps(dispatch) {
  return {
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SubHeader),
);

/*
 *
 * Map
 *
 */

import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';


import Row from 'components/Row'
import Col from 'components/Col'
import Container from 'components/Container'
import Button from 'components/Button'
import superbBg from 'images/superblk.jpg'
import { authRequest } from 'containers/Sso/actions';


export class IdpLanding extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
  }


  render() {

    return (
      <div>
        <Helmet
          title="Единая точка входа"
          meta={[
            { name: 'description', content: 'Единая точка входа' },
          ]}
        />
        <div className="content">
          <div style={{padding: '25px 0 50px', textAlign: 'center'}}>
            <h1 style={{fontSize: 30, textTransform: 'uppercase', fontWeight: 'bold'}}>Единая учетная запись ŠKODA ID</h1>
            <div style={{fontSize: 16, lineHeight: '24px', maxWidth: 630, margin: '0 auto', textAlign: 'justify'}}>
              <p style={{marginBottom: 20}}>Уважаемый пользователь магазина деталей и аксессуаров ŠKODA,</p>
              <p>рады сообщить Вам, наш ресурс переходит на обновленный способ регистрации и аутентификации. С использованием нашей новой технологии ŠKODA ID вход в личный кабинет онлайн-магазина запасных частей и аксессуаров станет еще более безопасным и удобным.</p>
              <p>ŠKODA ID откроет для Вас все преимущества единого входа, обеспечив простой доступ ко всем ресурсам марки, а также к сервисам других брендов VOLKSWAGEN GROUP RUS.</p>
              <p>Технология ŠKODA ID поможет повысить безопасность Вашей персональной информации благодаря возможности двухфакторной аутентификации.</p>
              <p>Кроме того, дополнительным преимуществом технологии станет возможность авторизации с помощью социальных сетей (Vkontakte, Одноклассники, Facebook, Google). Это позволит Вам выбрать наиболее удобный способ входа и сократить потраченное на аутентификацию время до минимума.</p>
          
              <p>Разработка новой технологии велась долгое время, позволив нам вложить в нее весь наш опыт и желание оставаться для Вас самым полезным, удобным и безопасным ресурсом.</p>
              <p>При возникновении трудностей со входом в личный кабинет онлайн-магазина запасных частей и аксессуаров просим Вас обратиться по адресу <a href="mailto:info@parts.skoda-avto.ru">info@parts.skoda-avto.ru</a>. Наши специалисты помогут Вам найти ответ на все возникшие вопросы.</p>
              <p>С уважением, команда ŠKODA AUTO Россия</p>
            </div>
          </div>
          <div style={{}}>
            <div className="containerless">
              <div>
                <Row style={{background: "#fafafa"}}>
                  <Col n={6} s={12} style={{backgroundImage: `url(${superbBg})`, backgroundSize: 'cover', height: 400, backgroundPosition: 'center', position: 'relative'}}>
                    <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,0.3)'}} />
                  </Col>
                  <Col n={6} s={12} style={{display: 'flex', flexFlow: 'column', justifyContent: 'center', height: 400, fontSize: 18,  padding: '0 40px'}}>
                    <div style={{textTransform: 'uppercase', fontWeight: 'bold'}}>
                      Преимущества ŠKODA ID:
                    </div>
                    <ul>
                      <li style={{marginBottom: 10}}>Единый профиль для всех сайтов и приложений концерна</li>
                      <li style={{marginBottom: 10}}>Защита данных при помощи двухфакторной аутентификации</li>
                      <li style={{marginBottom: 10}}>Вход через социальные сети</li>
                      <li style={{marginBottom: 10}}>Управление разрешениями и доступом приложений</li>
                    </ul>
                  </Col>              
                </Row>
              </div>
            </div>
          </div>
          <div style={{fontSize: 16, padding: '45px 0'}}>
            <div style={{fontSize: 16, lineHeight: '24px', maxWidth: 630, margin: '0 auto', textAlign: 'center'}}>
              Уже зарегистрированы? Для входа воспользуйтесь одноразовым паролем, отправленным на адрес Вашей электронной почты, либо используйте функцию сброса пароля.
            </div>
            <div style={{textAlign: 'center', paddingTop: 35}}>
              <Button big onClick={() => this.props.authRequest()}>
                Войти в личный кабинет
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



IdpLanding.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  // dealers: makeSelectDealers(),
});

function mapDispatchToProps(dispatch) {
  return {
    // getDealers: () => dispatch(getDealers()),
    // setMapDealer: (id) => dispatch(setMapDealer(id)),
    authRequest: payload => dispatch(authRequest(payload)),
    setPopup: (id) => dispatch(setPopup(id)),
  };
}





export default connect(mapStateToProps, mapDispatchToProps)(IdpLanding);

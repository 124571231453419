/**
*
* EditReturn
*
*/

import React from 'react';

import FactoryEditEntity from 'components/FactoryEditEntity';

import { editReturn } from 'models/returns/actions';
import { setPopup } from 'containers/App/actions';

import FormReturn from 'components/FormReturn';

const Component = FactoryEditEntity({
  editAction: editReturn,
  EntityForm: FormReturn,
  entity: 'returns',
  dontThrow: true,
  onSuccess: (dispatch) => { dispatch(setPopup(null)); },
  // onSuccess: (dispatch) => {  },
});

const EditReturn = (props) => (
  <Component {...props} />
);

export default EditReturn;

import { actionTypes } from './actions';
import produce from 'immer';


export const initialState = {
  processing: false,
};

const paymentReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_PROCESSING:
        draft.processing = action.processing;
        break;
    }
  });


export default paymentReducer;



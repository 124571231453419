import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const A = styled.a`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${vars.green};
  text-decoration: none;
`

import { all, take, call, put, fork, select, cancel, takeEvery, delay, race } from 'redux-saga/effects';

import jwt_decode from 'jwt-decode';
import { push } from 'connected-react-router';

import api from './api';
import { errors, scopeIsOk } from './settings';
import { clientId, redirectPath } from './config';
import { setUser, logout as logoutAction, completeSsoAuthCheck, finishAuth, setScopes, setClient, setLoggingIn } from './actions';
import { makeSelectIsSsoAuthChecking, getClient, getDomainClient } from './selectors';
import { makeSelectCurrentDomain } from 'containers/App/selectors';
import { AUTH_REQUEST, REG_REQUEST, GET_TOKEN, LOGOUT, COMPLETE_SSO_AUTH_CHECK, INITIAL } from './constants';
import { ensureDomainCheckIsComplete } from 'containers/App/saga'

import { notify, _localStorage, generateRandomString } from 'helpers';
import { isServer } from 'helpers'
import CryptoJS from 'crypto-js'

function base64URL(string) {
  return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const redirPath = (currentDomain) => {
  if(currentDomain && ['test.local', 'test2.local'].includes(currentDomain.name)) return 'http://' + currentDomain.name + ':3001' + redirectPath
  return currentDomain ? `${currentDomain.secure ? 'https' : 'http'}://` + currentDomain.name + redirectPath : null
}


function* redirectParams(action) {
  yield put(setLoggingIn(true))
  const { payload } = action;
  console.log(action)
  const currentDomain = yield select(makeSelectCurrentDomain())
  // console.log( api.authRequestUrl() );
  const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  _localStorage.setItem('auth_state', state)
  const code_verifier = generateRandomString(128)
  const code_challenge = base64URL(CryptoJS.SHA256(code_verifier))
  _localStorage.setItem('code_verifier', code_verifier)

  const issueClient = yield select(getDomainClient(payload.client_id))
  // console.log(issueClient)
  let result = { state, client_id: issueClient, code_challenge, code_challenge_method: 'S256', ...payload }
  if(issueClient) {
    console.log('setting client')
    _localStorage.setItem('client', issueClient);
  }
  if(currentDomain) {
    result.redirect_uri = redirPath(currentDomain)
  }
  return result
}

function* authRequestGen(action) {
  const params = yield call(redirectParams, action);
  const router = yield select(state => state.get('router').toJS())
  const { to } = action
  const { location } = router
  // console.log({ to, location })
  if (location.pathname === '/auto_auth') {
    _localStorage.setItem('redirect_to', to || '/');
  } else {
    _localStorage.setItem('redirect_to', location.pathname);
  }
  // console.log(location.pathname)
  setTimeout(() => {
    // console.log('redir')
    window.location = api.authRequestUrl(params);
  }, 0)
}



function* regRequestGen(action) {
  const params = yield call(redirectParams, action);
  const router = yield select(state => state.get('router').toJS())
  const { location } = router
  _localStorage.setItem('redirect_to', location.pathname);

  setTimeout(() => {
    window.location = api.regRequestUrl(params);
  }, 0)
}


export function* logout() {
  console.log('logout issued')
  yield put(setUser(null));
  yield put(setScopes([]));
  const client = yield select(getClient())
  const refreshToken = _localStorage.getItem("refresh_token");
  try {
    if(refreshToken) {
      yield call(api.logout, refreshToken, client);
    }
  } catch(e) {
    console.error(e)
  }
  _localStorage.removeItem("access_token");
  _localStorage.removeItem("id_token");
  _localStorage.removeItem("refresh_token");
  _localStorage.removeItem("scope");
  yield put(completeSsoAuthCheck());
}


function* persistTokens(data) {
  _localStorage.setItem("access_token", data.access_token);
  _localStorage.setItem("id_token", data.id_token);
  _localStorage.setItem("refresh_token", data.refresh_token);
  _localStorage.setItem("scope", data.scope);
}

function* setUserFromStorage() {
  const id_token = _localStorage.getItem("id_token");
  const access_token = _localStorage.getItem("access_token");
  if (id_token) {
    yield put(setUser(jwt_decode(id_token)));
  }
  if (access_token) {
    yield put(setScopes(jwt_decode(access_token).scope));
    yield put(setClient(jwt_decode(access_token).azp))
  }
}


function* getTokensFromCodeGen(action) {
  const { payload: { resolve, reject, code, session_state, state } } = action;
  if(isServer) return
  try {
    console.log('waiteing for domain')
    yield call(ensureDomainCheckIsComplete)
    console.log('domain complete')
    const currentDomain = yield select(makeSelectCurrentDomain())
    const initialAuthState = _localStorage.getItem("auth_state");
    const issueClient = _localStorage.getItem("client");
    const code_verifier = _localStorage.getItem("code_verifier");

    _localStorage.removeItem('code_verifier');

    // _localStorage.removeItem('client');
    if(state !== initialAuthState) throw new Error(errors.wrongState);
    const response = yield call(api.getToken, code, code_verifier, issueClient, redirPath(currentDomain));
    if (response.error) throw new Error(errors.authFail);
    if (response.access_token) {
      const scope = jwt_decode(response.access_token).scope;
      if (!scopeIsOk(scope)) throw new Error(errors.scopeFail);
    }
    yield call(persistTokens, response);
    yield call(setUserFromStorage);
    yield put(completeSsoAuthCheck());
    yield put(finishAuth());
    notify('Личный кабинет', 'Вход успешно выполнен');
    yield fork(tokenCycle, 3000);
    yield delay(100)
    const redirectTo = _localStorage.getItem('redirect_to');
    _localStorage.removeItem('redirect_to');
    yield delay(150)
    yield put(push(redirectTo || '/'))    
    resolve()
  } catch(e) {
    console.log(e)
    yield put(completeSsoAuthCheck());
    reject(e.message)
  }
}

function* initialLoad(action) {
  if(isServer) return
  const { payload } = action
  console.log('sso initial')
  const loggingIn = payload;
  const refreshToken = _localStorage.getItem("refresh_token");
  try {
    // no refresh token, we are guest
    if (!refreshToken) {
      if(!loggingIn) {
        yield put(completeSsoAuthCheck());
      }      
      throw new Error('guest access');
    }
    const expiresIn = yield refreshAccessToken(refreshToken);
    if(!expiresIn) throw new Error('refresh error');
    yield call(setUserFromStorage);
    yield put(completeSsoAuthCheck());
    yield put(finishAuth());
    yield tokenCycle(expiresIn);
  } catch(e) {
    console.log({e})
    if(!loggingIn) {
      // yield put(completeSsoAuthCheck());
      if (refreshToken) {
        // yield put(logoutAction());
      }
    }
    return false
  }
}


function* refreshAccessToken(refreshToken) {
  try {
    const { exp, azp } = jwt_decode(refreshToken);
    if (Date.now() / 1000 > exp) throw new Error('refresh token expired');
    const { access_token, id_token, expires_in } = yield call(api.refreshAccessToken, refreshToken, azp);
    // yield put(completeSsoAuthCheck());
    _localStorage.setItem("access_token", access_token);
    _localStorage.setItem("id_token", id_token);
    return expires_in * 1000;
  } catch(e) {
    // token refresh failed
    // yield put(completeSsoAuthCheck());
    yield put(logoutAction());
    return false
  }
}


function* refreshLoop(msp) {
  let ms = msp
  while(true) {
    const refreshToken = _localStorage.getItem("refresh_token");
    if (!refreshToken) return false
    yield delay(ms - 5000);
    ms = yield refreshAccessToken(refreshToken);
    if(!ms) return
  }
}

function* tokenCycle(ms) {
  yield race({
    task: call(refreshLoop, ms),
    cancel: take(LOGOUT)
  })
}

export function* ensureSsoAuthCheckIsComplete() {
  if (yield select(makeSelectIsSsoAuthChecking())) {
    yield take(COMPLETE_SSO_AUTH_CHECK);
  }
}

export default function * ssoSaga () {
  yield all([
    takeEvery(AUTH_REQUEST, authRequestGen),
    takeEvery(REG_REQUEST, regRequestGen),
    takeEvery(GET_TOKEN, getTokensFromCodeGen),
    takeEvery(LOGOUT, logout),
    takeEvery(INITIAL, initialLoad),
  ])
}
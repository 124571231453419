/**
*
* ProductOffers
*
*/

import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { push } from 'connected-react-router';

import { makeSelectEntities } from 'models/selectors';
import { makeSelectCurrentCartId, makeSelectCurrentRegionId, makeSelectDomainDealers, makeSelectIsDealerDomain } from 'containers/App/selectors';

import { partsSearch } from 'containers/Parts/actions';


import { addToCart, setPopup } from 'containers/App/actions';

import PartsResult from 'containers/Parts/PartsResult';
class ProductOffers extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);

    this.addToCart = this.addToCart.bind(this);
  }

  componentDidMount () {
    new Promise((resolve, reject) => {
      this.props.partsSearch({ data: { query: this.props.id }, resolve, reject });
    });
  }

  addToCart (productId, dealerId, quantity) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      this.props.addToCart({ data: { productId, dealerId, quantity }, resolve, reject });
    }).catch((err) => { throw err; });
  }

  render () {
    const {
      id, products, offers, dealers, msrps, currentCartId, items, regionId, regions, setPopup, push, domainDealers, isDealerDomain, cities,
    } = this.props;
    const product = products && products[id];

    return (
      <div style={{ maxWidth: 1000 }}>
        <h1>
        Наличие у дилеров
        </h1>
        <PartsResult
          offers={offers}
          regions={regions}
          currentCartId={currentCartId}
          dealers={dealers}
          product={product}
          number={product.id}
          msrps={msrps}
          addItemToCart={this.addToCart}
          items={items}
          regionId={regionId}
          setPopup={setPopup}
          push={push}
          domainDealers={domainDealers}
          isDealerDomain={isDealerDomain}
          cities={cities}
          fromPopup
        />
      </div>
    );
  }
}

ProductOffers.propTypes = {

};

const mapStateToProps = createStructuredSelector({
  cities: makeSelectEntities('cities'),
  products: makeSelectEntities('products'),
  offers: makeSelectEntities('offers'),
  dealers: makeSelectEntities('dealers'),
  msrps: makeSelectEntities('msrps'),
  items: makeSelectEntities('items'),
  regions: makeSelectEntities('regions'),
  currentCartId: makeSelectCurrentCartId(),
  regionId: makeSelectCurrentRegionId(),
  isDealerDomain: makeSelectIsDealerDomain(),
  domainDealers: makeSelectDomainDealers()
});

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    partsSearch: (payload) => dispatch(partsSearch(payload)),
    addToCart: (payload) => dispatch(addToCart(payload)),
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
    push: (path) => dispatch(push(path)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(ProductOffers);

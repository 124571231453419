/* istanbul ignore file */
/*
* model actions
*/

import { SET_ENTITIES, REPLACE_ENTITIES, REPLACE_PASSED_ENTITIES } from './constants';

export function replacePassedEntities (object, toReplace = null) {
  return {
    type: REPLACE_PASSED_ENTITIES,
    entities: object.entities,
    toReplace,
  };
}

// Merges all passed entities, replaces ones whos names are passed in `toReplace` array.
export function setEntities (object) {
  return {
    type: SET_ENTITIES,
    entities: object.entities,
  };
}

export function replaceEntities (object, toReplace = null) {
  return {
    type: REPLACE_ENTITIES,
    entities: object.entities,
    toReplace,
  };
}

import React from 'react'

import 'css/components/order_instructions.sass' 

export default function OrderInstructions() {
  return (
    <React.Fragment>
      <div className='order-steps'>
        <div className='order-steps__one'>
          <div className='order-steps__line'>
            <span>После заказа</span>
          </div>

          <div className='order-steps__text'>
            <b>Новый заказ</b>
            Отправка уведомлений о заказе пользователям с типом:
            <ul>
              <li>Ответственный сотрудник</li>
            </ul>              
          </div>
        </div>

        <div className='order-steps__one'>
          <div className='order-steps__line'>
            <span>1 час спустя*</span>
          </div>

          <div className='order-steps__text'>
            <b>Если заказ не обработан</b>
            Отправка уведомлений о заказе пользователям с типом:
            <ul>
              <li>Ответственный сотрудник</li>
              <li>Руководитель отдела запасных частей</li>
            </ul>          
          </div>
        </div>        

        <div className='order-steps__one'>
          <div className='order-steps__line'>
            <span>2 часа спустя*</span>
          </div>

          <div className='order-steps__text'>
            <b>Если заказ не обработан</b>
            Изменение статуса заказа на "Просрочен". Отправка уведомлений о заказе пользователям с типом:
            <ul>
              <li>Ответственный сотрудник</li>
              <li>Руководитель отдела запасных частей</li>
            </ul>          
          </div>
        </div>

        <div className='order-steps__one'>
          <div className='order-steps__line'>
            <span>24 часа спустя*</span>
          </div>

          <div className='order-steps__text'>
            <b>Все ещё не обработан</b>
            Отправка уведомлений о заказе пользователям с типом:
            <ul>
              <li>Ответственный сотрудник</li>
              <li>Руководитель отдела запасных частей</li>
              <li>Региональный менеджер Aftersales</li>
            </ul>
          </div>
        </div>
      </div>

      <div style={{fontSize: 12, textAlign: 'right', color: '#999', padding: '10px 0'}}>
        * С учётом рабочего времени дилера и часового пояса
      </div>
    </React.Fragment>
    )
}
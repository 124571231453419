import React from 'react'
import styled from 'styled-components'

import Container from 'components/Container'

import { NavLink } from 'react-router-dom';

import { createUrl } from 'helpers';
import { ROLES } from 'settings'
import vars from 'config/styles'
// import Sbutton from 'components/Sbutton'
import { CREATE_FEEDBACK } from 'components/Popup/constants'

const Wrapper = styled.div`
  background: #F4F4F4;
  padding: 48px 0 52px;
`


const Section1 = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 33px;
`

const Section2 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

`

const LinkTemplate = `
  color: #494949;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.02em;
  display: block;
  margin-bottom: 10px;
  &:hover {
    color: ${vars.green}
  }
`


const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 16px;
`

const Link = styled(NavLink)`${LinkTemplate}`
const ExternalLink = styled.a`${LinkTemplate}`

const Col = styled.div`
  
`


const FooterTop = ({ categories, models, setPopup, currentUser }) => {
  return (
    <Wrapper>
      <Container>
        <Section2>
          <Col>
            <h4>
              Модели
            </h4>
            {models && Object.values(models).filter(m => m.status).sort((a,b) => a.position > b.position ? 1: -1 ).map((one) => (
              <Link key={`footer-model-${one.id}`} to={createUrl(one.slug)}>
                {one.name.toUpperCase()}
              </Link>
            ))}
          </Col>        
          <Col>
            <h4>
              Категории
            </h4>
            {categories && Object.values(categories).map((one) => (
              <Link key={`footer-cat-${one.id}`} to={createUrl(null, one.slug)}>
                {one.name}
              </Link>
            ))}
          </Col>
          <Col>
            <h4>
              Ресурсы
            </h4>          
            <ExternalLink target="_blank" href='https://lk.skoda-avto.ru'>
              Личный кабинет ŠKODA
            </ExternalLink>            
          </Col> 
          <Col>
            <h4>
              Полезные ссылки
            </h4>  
            { !currentUser &&
              <Link to="/help">
                Навигация
              </Link>
            }
            { currentUser && currentUser.role == ROLES.USER &&
              <Link to="/help/user">
                Навигация
              </Link>
            }
            { currentUser && currentUser.role == ROLES.DEALER &&
                <Link to="/help/dealer">
                  Навигация
                </Link>                  
            } 
            { currentUser && currentUser.role == ROLES.IMPORTER && 
              <Link to="/help/importer">
                Навигация
              </Link>
            }                                             
            { currentUser && currentUser.role == ROLES.DEALER &&
              <ExternalLink target="_blank" href={require('public/dealer_instruction.pdf')}>
                Инструкция по дилерской учетной записи
              </ExternalLink>         
            }
            {currentUser && currentUser.role == ROLES.DEALER &&
              <ExternalLink target="_blank" href={'https://yuti.ru/static/files/Skodadelivery.mp4'}>
                Видеоинструкция по доставке
              </ExternalLink>
            }
            <ExternalLink target="_blank" href="http://www.skoda-avto.ru/about/privacy-statement">
              Правовые аспекты
            </ExternalLink>
            <Link to="/pages/conf">
              Политика конфиденциальности
            </Link>                
            <Link to="/pages/agreement">
              Пользовательское соглашение
            </Link>                
            <Link to="/pages/payment">
              Оплата и доставка
            </Link>                
            <Link to="/pages/return">
              Гарантия и возврат
            </Link>              
            <Link onClick={() => setPopup(CREATE_FEEDBACK)}>
              Обратная связь
            </Link>
          </Col>                   

        </Section2>        
      </Container>
    </Wrapper>
  )
}

export default FooterTop



import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from 'components/Button';

const Confirm = (fn, title = 'Подтвердите удаление') => {
  confirmAlert({
    customUI: (props) => {
      const { onClose } = props;
      return (
        <div className="confirm">
          <div className="confirm__title">
            {title}
          </div>
          <div className="confirm__buttons">
            <Button small onClick={() => { fn(); onClose(); }}>
              Да
            </Button>
            <Button small onClick={onClose}>
              Нет
            </Button>
          </div>
        </div>
      );
    },
  });
};

export default Confirm;

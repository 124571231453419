/* istanbul ignore file */

import { routerActions } from 'connected-react-router';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { isServer } from 'helpers'
import { adminClientId } from 'containers/Sso/config'

import {
  makeSelectCurrentUser, makeSelectMainDomain, makeSelectIsDealerDomain,
} from 'containers/App/selectors';
import { ROLES } from '../../settings';

export const AuthUser = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => !!makeSelectCurrentUser()(state),
  // authenticatingSelector: state => state.user.isLoading,
  // AuthenticatingComponent: Loading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'AuthUser',
});

export const AuthUnfinishedRegistration = connectedReduxRedirect({
  authenticatedSelector: (state) => {
    const user = state.getIn(['global', 'currentUserId']);
    const idToken = state.getIn(['sso', 'user']);
    return idToken && !user;
  },
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'AuthUnfinishedRegistration',
  redirectPath: "/",
})

export const RedirectWrongClient = (requiredClient) => connectedReduxRedirect({
  authenticatedSelector: (state) => {
    const client = state.getIn(['sso', 'client']);
    return client === requiredClient;
  },
  authenticatingSelector: state => isServer || state.getIn(['global', 'authChecking']),
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'RedirectWrongClient',
  redirectPath: "/",
})

export const RedirectAuthed = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => !state.getIn(['global', 'currentUserId']),
  authenticatingSelector: state => isServer || state.getIn(['global', 'authChecking']),
  // AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'RedirectAuthed',
});

export const AuthDealer = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role === ROLES.DEALER,
  authenticatingSelector: state => isServer || state.getIn(['global', 'authChecking']),
  // AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'AuthDealer',
});

export const AuthImporter = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role >= ROLES.IMPORTER,
  authenticatingSelector: state => isServer || state.getIn(['global', 'authChecking']),
  // AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'AuthImporter',
});

export const AuthAdmin = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role >= ROLES.ADMIN,
  authenticatingSelector: state => isServer || state.getIn(['global', 'authChecking']),
  // AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'AuthAdmin',
});


export const RedirectAdminClient = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const client = state.getIn(['sso', 'client']);
    return client !== adminClientId;
  },
  authenticatingSelector: state => isServer || state.getIn(['global', 'authChecking']),
  // AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'RedirectAdminClient',
});
//

export const userIsAuthenticated = connectedAuthWrapper({
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role >= ROLES.USER,
  wrapperDisplayName: 'userIsAuthenticated',
});

export const userHasRightClient = (requiredClient) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const client = state.getIn(['sso', 'client']);
    return client === requiredClient;
  },
  wrapperDisplayName: 'userHasRightApp',
});

export const userIsNotAuthenticated = connectedAuthWrapper({
  authenticatedSelector: (state) => !state.getIn(['global', 'currentUserId']),
  wrapperDisplayName: 'userIsNotAuthenticated',
});

export const userIsUser = connectedAuthWrapper({
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role === ROLES.USER,
  wrapperDisplayName: 'userIsUser',
});

export const userIsMoreThanUser = connectedAuthWrapper({
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role > ROLES.USER,
  wrapperDisplayName: 'userIsMoreThanUser',
});

export const userIsUserOrGuest = connectedAuthWrapper({
  authenticatedSelector: (state) => !state.getIn(['global', 'currentUserId']) || (makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role === ROLES.USER),
  wrapperDisplayName: 'userIsUserOrGuest',
});

export const userIsDealer = connectedAuthWrapper({
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role === ROLES.DEALER,
  wrapperDisplayName: 'userIsDealer',
});

export const userIsImporter = connectedAuthWrapper({
  authenticatedSelector: (state) => makeSelectCurrentUser()(state) && makeSelectCurrentUser()(state).role === ROLES.IMPORTER,
  wrapperDisplayName: 'userIsImporter',
});

export const domainIsMain = connectedAuthWrapper({
  authenticatedSelector: makeSelectMainDomain(),
  wrapperDisplayName: 'domainIsMain',
});

export const domainIsNotMain = connectedAuthWrapper({
  authenticatedSelector: makeSelectIsDealerDomain(),
  wrapperDisplayName: 'domainIsNotMain',
});

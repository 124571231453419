/**
 *
 * Layout
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Top from 'components/Top';
import Footer from 'components/Footer2';
import { logout, setPopup } from 'containers/App/actions';
import { makeSelectEntities } from 'models/selectors';
import { push } from 'connected-react-router';
import 'css/components/badge.sass';

import {
  makeSelectCurrentUser, makeSelectCurrentCartId, makeSelectCurrentClientId, 
  makeSelectMainDomain, makeSelectCurrentDomain, makeSelectLocation,
  selectCartModItems, selectCartDeliveries,
} from 'containers/App/selectors';

function Layout (options) {
  const mapStateToProps = createStructuredSelector({
    currentUser: makeSelectCurrentUser(),
    cartId: makeSelectCurrentCartId(),
    moditems: selectCartModItems(),
    deliveries: selectCartDeliveries(),
    clientId: makeSelectCurrentClientId(),
    isMainDomain: makeSelectMainDomain(),
    domain: makeSelectCurrentDomain(),
    location: makeSelectLocation(),
    categories: makeSelectEntities('categories'),
    models: makeSelectEntities('models'),
    payables: makeSelectEntities('payables')
  });

  function mapDispatchToProps (dispatch) {
    return {
      logout: () => dispatch(logout()),
      setPopup: (id, opts) => dispatch(setPopup(id, opts)),
      push: (path) => dispatch(push(path)),
      // setTitle: (title) => dispatch(setTitle(title)),
      // setPopup: (id) => dispatch(setPopup(id)),
    };
  }

  function wrapped (Content) {
    // console.log('wrapped')
    class DecoratedLayout extends React.PureComponent {
      componentDidMount () {
        // this.props.setTitle(title)
      }
      render () {

        return (
          <React.Fragment>
            {/*<Header {...this.props} />*/}
            <Top />
            <div className="content">
              <Content {...this.props} />
            </div>
            <Footer {...this.props} />
          </React.Fragment>
        );
      }
    }

    const withConnect = connect(mapStateToProps, mapDispatchToProps);

    return compose(
      withConnect,
    )(DecoratedLayout);
  }

  return wrapped;
}

export default Layout;

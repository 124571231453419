/**
*
* Container
*
*/

import React from 'react';
import PropTypes from 'prop-types';

class Container extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <div className="container" style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

Container.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Container;

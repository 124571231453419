import React from 'react';
import ReactDOM from 'react-dom';

// import a from 'css/components/show-order.sass'
/* istanbul ignore next */
function copyStyles (sourceDoc, targetDoc) {
  // Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
  //   if (styleSheet.cssRules) { // for <style> elements
  //     const newStyleEl = sourceDoc.createElement('style');
  //     Array.from(styleSheet.cssRules).forEach(cssRule => {
  //       // write the text of each rule into the body of the style element
  //       try {
  //         newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
  //       } catch (e) {
  //          console.log(e)
  //       }
  //     });

  //     targetDoc.head.appendChild(newStyleEl);
  //   } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
  //     const newLinkEl = sourceDoc.createElement('link');

  //     newLinkEl.rel = 'stylesheet';
  //     newLinkEl.href = styleSheet.href;
  //     targetDoc.head.appendChild(newLinkEl);
  //   }
  // });
  const oHead = targetDoc.getElementsByTagName('head')[0];
  const arrStyleSheets = sourceDoc.getElementsByTagName('style');
  for (let i = 0; i < arrStyleSheets.length; i += 1) {
    const style = targetDoc.createElement('style');
    style.type = 'text/css';
    style.appendChild(targetDoc.createTextNode(arrStyleSheets[i].cloneNode(true).innerHTML));
    oHead.appendChild(style);
    // oHead.appendChild(arrStyleSheets[i].cloneNode(true));
    // console.log(arrStyleSheets[i].cloneNode(true).innerHTML);
  }
}

/* istanbul ignore next */
class Portal extends React.PureComponent {
/* istanbul ignore next */
  constructor (props) {
    super(props);
    // console.log('construct')
    // STEP 1: create a container <div>
    const windowName = `printElementWindow${(Math.round(Math.random() * 99999)).toString()}`;
    this.externalWindow = window.open('about:blank', windowName, 'width=1000,height=500,left=100,top=100');
    this.containerEl = this.externalWindow.document.createElement('div');
    // console.log('construct finish')

    // this.externalWindow = null;
  }

  /* istanbul ignore next */
  render () {
    if (!this.containerEl) {
      return null;
    }
    // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }

  /* istanbul ignore next */
  componentDidMount () {
    const { externalWindow } = this;
    const { onClose } = this.props;
    // STEP 3: open a new browser window and store a reference to it
    // console.log('mounted')
    if (externalWindow.document.readyState === 'complete') {
      // console.log('printing');
      copyStyles(document, externalWindow.document);
      externalWindow.document.body.appendChild(this.containerEl);
      externalWindow.focus();
      externalWindow.print();
    } else {
      // console.log('onload handler');
      externalWindow.onload = () => {
        // console.log('loaded')
        copyStyles(document, externalWindow.document);
        externalWindow.document.body.appendChild(this.containerEl);
        externalWindow.focus();
        externalWindow.print();
      };
    }

    // const that = this;
    // externalWindow[externalWindow.addEventListener ? 'addEventListener' : 'attachEvent'](
    //   `(${that.externalWindow.attachEvent ? 'on' : ''}load`, function (e) {
    //     console.log(123)
    //     copyStyles(document, that.externalWindow.document);
    //     that.externalWindow.document.body.appendChild(that.containerEl);
    //     that.externalWindow.focus();
    //     that.externalWindow.print();
    //   }, false );

    // console.log('mount finished')

    this.externalWindow.addEventListener('beforeunload', () => {
      // console.log('closing')
      onClose && onClose();
    });

    // const timer = setInterval(() => {
    //   console.log(onClose);
    //   if (externalWindow.closed) {
    //     // onClose && onClose();
    //     onClose();
    //     clearInterval(timer);
    //   }
    // }, 1000);
  }

  /* istanbul ignore next */
  componentWillUnmount () {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    this.externalWindow.close();
  }
}

export default Portal;

import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { updatePodbor } from 'api/podbors';

import { SubmissionError } from 'redux-form/immutable';
import { formatErrors, errorHandler } from 'helpers';
import { apiRequest } from 'containers/App/saga';

import { normalize } from 'normalizr';

import { setEntities } from 'models/actions';
import { Podbor } from 'models';
import { EDIT_PODBOR } from './constants';

export function * editPodborGen (action) {
  const { payload: { data, resolve, reject } } = action;
  console.log(1)
  try {
    const podbor = yield call(apiRequest, updatePodbor, data);
    const normalized = normalize(podbor, Podbor);
    yield put(setEntities(normalized));
    resolve();
  } catch (e) {
    errorHandler(e);
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(EDIT_PODBOR, editPodborGen),
  ]);
}

import { createSelector } from 'reselect';

const selectEntities = (state) => state.get('entities');
const selectBonusState = (state) => state.get('bonus');

const dealerIdSelector = (state, {id}) => id

export const selectDealer = () => createSelector(
  selectEntities,
  dealerIdSelector,
  (entitiesState, id) => {
    const dealers = entitiesState.get('dealers') && entitiesState.get('dealers').toJS() || {}
    return dealers && dealers[id]
  }
);

export const selectCerts = () => createSelector(
  selectBonusState,
  (bonusState) => {
    return bonusState.get('certs') || []
  }
); 

export const selectPoints = () => createSelector(
  selectBonusState,
  (bonusState) => {
    return bonusState.get('points') || 0
  }
); 
/**
*
* Good
*
*/

import React from 'react';
import GoodView from './GoodView';

function Good (props) {
  // const {row, images, setPopup} = props
  // const path = '/accessories/show/'+row.productId
  return (
    <GoodView {...props} />
  );
}

Good.propTypes = {

};

export default Good;

import React from 'react'

import moment from 'moment'
import classNames from 'classnames'


import 'css/components/qc.sass'

function getQuarterRange(date) {
  const start = date.clone().startOf('quarter');
  const end = date.clone().endOf('quarter');
  return { start, end };
}

class QuarterChooser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      year: moment().year(),
      quarter: moment().quarter()
    }
    this.getRange = this.getRange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleQuarterChange = this.handleQuarterChange.bind(this)
    this.setValues = this.setValues.bind(this)
  }

  componentDidMount() {
    const { initialQuarter, initialYear, submit } = this.props
    if (initialQuarter && initialYear) {
      this.setState({ year: initialYear, quarter: initialQuarter })
    }
    setTimeout(() => {
      this.setValues()
      submit && submit()
    }, 0)
  }

  handleYearChange(year) {
    this.setState({ year }, this.setValues)
  }
  
  handleQuarterChange(quarter) {
    this.setState({ quarter }, this.setValues)
  }

  setValues() {
    const { start, end } = this.getRange() 
    const { setStart, setEnd, setQuarter } = this.props
    setQuarter && setQuarter({ year: this.state.year, quarter: this.state.quarter })
    setStart(start.format("YYYY-MM-DD"))
    setEnd(end.format("YYYY-MM-DD"))
  }

  getRange() {
    const { quarter, year } = this.state
    const date = moment().year(year).quarter(quarter)
    const { start, end } = getQuarterRange(date)
    return { start, end }
  }

  render() {
    const years = 3
    const { dateStart, dateEnd } = this.props 
    const { quarter, year } = this.state
    const currentYear = moment().year()
    const currentQuarter = moment().quarter()
    return (
      <div className='qc'>
        <div className='qc__hint'>Год</div>
        <div className='qc__years'>
          {Array.from(Array(years)).map((a,i) => {
            const cur = currentYear - years + i + 1
            return (
              <div className={classNames('qc__year', { active: dateStart && year === cur })} onClick={() => this.handleYearChange(cur)}>{cur}</div>
            )
          })}
        </div>
          <div className='qc__hint'>Квартал</div>
        <div className='qc__quarters'>
          {[1, 2, 3, 4].map(q => {
            if(year === currentYear && q > currentQuarter ) return null
            return (<div className={classNames('qc__quarter', { active: dateStart && quarter === q })} onClick={() => this.handleQuarterChange(q)}>{q}</div>)
          })}
        </div>
      </div>
    )
  }
}



export default QuarterChooser
/**
 *
 * CheckoutSuccess
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { makeSelectPageData } from 'containers/App/selectors';
import { makeSelectEntities } from 'models/selectors';

import { setPageData } from 'containers/App/actions';
import { makePayment, refreshStatus } from 'containers/Payment/actions'


import { EDIT_CLIENT } from 'components/Popup/constants';
import { push } from 'connected-react-router';

import OrderSuccess from './OrderSuccess';
import SuccessOrder from './SuccessOrder';
import Container from 'components/Container';

export class CheckoutSuccess extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);

  }

  componentDidMount () {
    const { orders, computedMatch, currentUser } = this.props
    const orderIds = computedMatch.params.orderIds.split(',')
    if(!orders[orderIds[0]]) {
      this.props.push(!!currentUser ? '/orders' : '/');
    }

  }


  render () {
    const { computedMatch, orders, dealers, payables, makePayment, currentUser, items, products } = this.props
    const orderIds = computedMatch.params.orderIds.split(',')
    return (
      <>
        <OrderSuccess multiple={orderIds.length>1} />
        <Container>
          <div className='panel panel_nm'>
            {orderIds.map(orderId => {
              const order = orders[orderId]
              if (!order) return
              const dealer = order && dealers && dealers[order.dealerId] 
              if(!dealer) return

              return (
                <div style={{marginBottom: 25, paddingBottom: 25, borderBottom: '1px solid grey'}} key={order.number}>
                <SuccessOrder order={order} dealer={dealer} dealers={dealers} payables={payables} makePayment={makePayment} allowedToPay={!!currentUser} />
                </div>
              )
            })}
          </div>
        </Container>
      </>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  cartPageData: makeSelectPageData('cart'),
  orders: makeSelectEntities('orders'),
  dealers: makeSelectEntities('dealers'),
  items: makeSelectEntities('items'),
  products: makeSelectEntities('products'),
  payables: makeSelectEntities('payables'),

});

function mapDispatchToProps (dispatch) {
  return {
    setCartPageData: (name, value) => dispatch(setPageData({ page: 'cart', name, value })),
    makePayment: (orderId, payload) => dispatch(makePayment(orderId, payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(CheckoutSuccess);
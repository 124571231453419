/**
*
* FormNotification
*
*/

import React from 'react';
import { compose } from 'redux';

import {
  Field, reduxForm,
} from 'redux-form/immutable';
import { inputField } from 'components/Form/components';
import Button from 'components/Button';
import RFReactSelect from 'components/Form/components/RFReactSelect';
import { NOTIFICATION_STATUSES_TRANSLATION } from 'settings';


class FormNotification extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const statusOptions = Object.keys(NOTIFICATION_STATUSES_TRANSLATION).map((x) => ({ value: x, label: NOTIFICATION_STATUSES_TRANSLATION[x] }));

    const {
      handleSubmit, submitting, 
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field name="status" component={RFReactSelect} multi={false} clearable={false} options={statusOptions} label="Статус" />

          <Button loading={submitting} type="submit">
            Продолжить
          </Button>
        </form>
      </div>
    );
  }
}

FormNotification.propTypes = {

};


const withForm = reduxForm({ form: 'notification-form' });

export default compose(
  withForm,
)(FormNotification);

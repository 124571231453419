import React from 'react'

import { StickyContainer, Sticky } from 'react-sticky';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ExtraCategories from './ExtraCategories'
const CategoriesMenu = ({ categories, setCategory, categorySlug, makeUrl, hidden, currentUser, sendRaw }) => {
  return (
    <div className="gpage__menu">
      <StickyContainer style={{ height: '100%' }}>

        <Sticky>
          {({ style }) => (
            <div style={style}>
              <div className="gmenu">
                {categories && Object.values(categories).sort((a,b) => a.position > b.position ? 1: -1 ).map((one) => {
                  const cls = classNames('gmenu__item', { active: one.slug === categorySlug });
                  return (
                    <Link to={makeUrl({ categorySlug: one.slug })} onClick={() => { sendRaw('AccessoriesPage', 'choose_category', one.name); setCategory(one.slug); }} className={cls} key={`cat-${one.id}`}>
                      {one.name}
                    </Link>
                  );
                })}
                <ExtraCategories hidden={hidden} currentUser={currentUser} />
              </div>
            </div>
          )}
        </Sticky>
      </StickyContainer>

    </div>
    )
}

export default CategoriesMenu
import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

export const H4 = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${vars.black};
  margin-bottom: 30px;
  ${({ center }) => center && css`
    text-align: center;
  `}
  @media (max-width: ${vars.bp.tablet}px) {
    margin-bottom: 20px;
  }    
  @media (max-width: ${vars.bp.mobile}px) {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
  }     
`

import React from 'react'

import { Helmet } from 'react-helmet-async';
import Container from 'components/Container';
import HelpItem from 'components/HelpItem'
import { Link } from 'react-router-dom';

export class InstructionImporter extends React.PureComponent {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Оригинальные детали ŠKODA
          </title>
          <meta name="description" content="Оригинальные детали ŠKODA" />
        </Helmet>   
        <Container>
          <div className='panel'>
            <h2>Навигация</h2>
            <div>
              Пользователю с ролью импортёра в дополнение к разделам, доступным <Link className='green link' to='/help/user'>авторизованному пользователю</Link>, доступна панель управления с разделами:
              <ul className='hlist'>
                <li>Заказы</li>
                <li>Заявки на подбор</li>
                <li>Аксессуары</li>
                <li>Пользователи</li>
                <li>Дилеры</li>
                <li>Домены</li>
                <li>Региональные менеджеры</li>
                <li>Обновления</li>
                <li>Категории</li>
                <li>Модели</li>
                <li>Регионы</li>
                <li>Города</li>
              </ul>
            </div>
            <HelpItem Title={<div>Заказы</div>}>
              Все заказы, поступающие с основного сайта и сайтов дилеров, отражены и обрабатываются в этом разделе.
              Импортёр имеет возможность детального просмотра и печати информации о заказе, редактирования заказа и просмотра истории изменений.
              Время, через которое был принят заказ в работу, а также отметки об отправленных письмах о заказе дилеру и региональному менеджеру видны здесь же.
            </HelpItem>
            <HelpItem Title={<div>Заявки на подбор</div>}>
              Все заявки на подбор деталей, поступающие с основного сайта и сайтов дилеров, отражены и обрабатываются в этом разделе.
              Импортёр имеет возможность детального просмотра заявки и информации о клиенте.
            </HelpItem>
            <HelpItem Title={<div>Аксессуары</div>}>
              Раздел предназначен для управления аксессуарами сайта. Здесь отображён список всех аксессуаров с возможностью фильтрации по определённым параметрам, а также форма добавления и редактирования аксессуаров. 
              Напротив каждой записи есть ссылка на просмотр истории редактирования.
            </HelpItem>
            <HelpItem Title={<div>Пользователи</div>}>
              Все пользователи зарегистрированные на сайте, выводятся списком в этом разделе. Фильтрация по параметрам фамилии, почты и роли позволяет найти необходимых пользоваетелей. 
              Также импортёр имеет возможность отредактировать каждого пользователя в том числе сменить роль.
            </HelpItem>
            <HelpItem Title={<div>Дилеры</div>}>
              Каждый дилер в списке имеет отметку с датой последнего обновления предложений и кнопку, нажатие на которую, открывает форму редактирования параметров. 
              Форма, помимо основных данных дилера, содержит поле, позволяющее прикрепить регионального менеджера.
            </HelpItem>
            <HelpItem Title={<div>Домены</div>}>
              В данном разделе импортёр принимает заявки на подключение доменов от дилеров и редактирует параметры сайтов.
            </HelpItem>
            <HelpItem Title={<div>Региональные менеджеры</div>}>
              Все региональные менеджеры, которые должны быть прикрепрены к дилерам, добавляются и редактируются в этом разделе.
            </HelpItem>
            <HelpItem Title={<div>Обновления</div>}>
              В разделе отражены все обновления предложений дилера и рекомендованных цен с возможностью детального просмотра отдельной записи.
            </HelpItem>
            <HelpItem Title={<div>Категории</div>}>
              Отображается список категорий аксессуаров с возможностью редактирования и сортировки.
            </HelpItem>
            <HelpItem Title={<div>Модели</div>}>
              Отображается список моделей автомобилей с возможностью редактирования и сортировки.
            </HelpItem>
            <HelpItem Title={<div>Регионы</div>}>
              Отображается список регионов с возможностью редактирования.
            </HelpItem>
            <HelpItem Title={<div>Города</div>}>
              Отображается список городов с возможностью редактирования.
            </HelpItem>


                                              
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default InstructionImporter
import { all, take, call, put, fork, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from 'api/payment'

import { actionTypes } from './actions';
// import {  } from './selectors';
import { isServer } from 'helpers'
import { setEntities, replaceEntities } from 'models/actions';
import { setProcessing } from './actions'
import { normalize } from 'normalizr';
import { ensureSsoAuthCheckIsComplete } from 'containers/Sso/saga';


import { Payable, Order } from '../../models';

import { notify } from 'helpers';

export function* getPayableStatusGen(action) {
  try {
    yield call(ensureSsoAuthCheckIsComplete);
    const payables = yield call(api.getPayableStatus())
    const normalized = normalize(payables, [Payable]);
    yield put(setEntities(normalized));
  } catch(e) {
    console.log(e)
  }
}

export function * makePaymentGen(action) {
  const { orderId, payload: { resolve, reject } } = action
  try {
    // console.log(action)
    yield put(setProcessing(true))
    const result = yield call(api.makePayment(orderId))
    const { url } = result
    resolve && resolve()
    document.location = url
  } catch(e) {
    console.log(e) 
    yield put(setProcessing(false))
    notify('Оплата', 'Не удалось осуществить оплату', 'error')
    reject && reject()
  }
}

export function * refreshStatusGen(action) {
  const { orderId, payload: { resolve, reject } } = action
  try {
    yield put(setProcessing(true))
    const order = yield call(api.refreshStatus(orderId))
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized));
    resolve && resolve()
  } catch(e) {
    console.log(e)
    yield put(setProcessing(false))
    notify('Оплата', 'Не удалось осуществить оплату', 'error')
    reject && reject()
  }
}

export function * depositGen(action) {
  const { orderId, payload: { resolve, reject, amount } } = action
  try {
    yield put(setProcessing(true))
    const order = yield call(api.deposit(orderId, amount))
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized));
    resolve && resolve()
  } catch(e) {
    console.log(e)
    yield put(setProcessing(false))
    notify('Оплата', 'Не удалось подтвердить оплату', 'error')
    reject && reject()
  }
}


export function * reverseGen(action) {
  console.log('reverse')
  const { orderId, payload: { resolve, reject } } = action
  try {
    yield put(setProcessing(true))
    const order = yield call(api.reverse(orderId))
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized));
    resolve && resolve()
  } catch(e) {
    console.log(e)
    yield put(setProcessing(false))
    notify('Оплата', 'Не удалось отменить оплату', 'error')
    reject && reject()
  }
}

export function * refundGen(action) {
  const { orderId, payload: { resolve, reject, amount } } = action
  try {
    yield put(setProcessing(true))
    const order = yield call(api.refund(orderId, amount))
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized));
    resolve && resolve()
  } catch(e) {
    console.log(e)
    yield put(setProcessing(false))
    notify('Оплата', 'Не удалось вернуть средства', 'error')
    reject && reject()
  }
}

export default function * paymentSaga () {
  yield all([
    yield takeEvery(actionTypes.GET_PAYABLE_STATUS, getPayableStatusGen),
    yield takeEvery(actionTypes.MAKE_PAYMENT, makePaymentGen),
    yield takeLatest(actionTypes.REFRESH_STATUS, refreshStatusGen),
    yield takeLatest(actionTypes.DEPOSIT, depositGen),
    yield takeLatest(actionTypes.REVERSE, reverseGen),
    yield takeLatest(actionTypes.REFUND, refundGen),
  ])
}

import { createSelector } from 'reselect';

const selectFilterDomain = (state) => state.get('filters');

const makeSelectFilter = (name) => createSelector(
  selectFilterDomain,
  (substate) => substate && substate.getIn([name, 'request'])
);

const makeSelectFilterResponse = (name) => createSelector(
  selectFilterDomain,
  (substate) => substate && substate.getIn([name, 'response'])

);

const makeSelectFilterStatus = (name) => createSelector(
  selectFilterDomain,
  (substate) => substate && substate.getIn([name, 'status'])
);

export {
  makeSelectFilter,
  makeSelectFilterResponse,
  makeSelectFilterStatus,
};

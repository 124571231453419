
import { request } from 'utils/request';
import { ssoUrl, clientId, redirectPath } from './config';
import { app_url } from 'config';
import { serialize } from 'helpers';
// import { apicall } from 'components/Api';
import querystring from 'querystring';
import { _localStorage } from 'helpers';


function apiCallSso(url, options = {}, headers = {'Content-Type': 'application/json'}){
  // let headers = {'Content-Type': 'application/json'}
  const token = _localStorage.getItem("sso_token")
  console.log('sso api call')
  if(token){
    headers["Authorization"] = "Bearer " + token;
  }
  // mode: "no-cors",
  return request(ssoUrl + url,  Object.assign({ headers }, options))
}


//  phone_required 2fa
const commonParams = {
  response_type: 'code',
  client_id: clientId,
  redirect_uri: app_url + redirectPath,
  scope: "openid phone address email profile",
  themeid: 'skoda',  
}

export default {
  authRequestUrl: (params = {}) => {
    const finalParams = { 
      ...commonParams,
      ...params 
    }
    return `${ssoUrl}/auth?${serialize(finalParams)}`;
  },
  regRequestUrl: (params = {}) => {
    const finalParams = { 
      ...commonParams,
      ...params 
    }    
    return `${ssoUrl}/registrations?${serialize(finalParams)}`;
  },
  getToken: (code, code_verifier, client = null, redirect_uri) => {
    const params = {
      redirect_uri: redirect_uri || commonParams.redirect_uri,
      client_id: client || clientId,
      grant_type: 'authorization_code',
      code,
      code_verifier
      // session_state: '',
    }
    // return apicall('/users/sso', { method: "POST", body: JSON.stringify({ params, path: '/token' })})
    const formData = querystring.stringify(params);
    const contentLength = formData.length;
    return apiCallSso('/token', {method: "POST", body: formData}, {'Content-Length': contentLength, 'Content-Type': 'application/x-www-form-urlencoded'})
  },
  refreshAccessToken: (refreshToken, client = null) => {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: client || clientId,
      // session_state: '',
    }
    const formData = querystring.stringify(params);
    const contentLength = formData.length;
    return apiCallSso('/token', {method: "POST", body: formData}, {'Content-Length': contentLength, 'Content-Type': 'application/x-www-form-urlencoded'})
  },
  logout: (refreshToken, client = null) => {
    const params = {
      refresh_token: refreshToken,
      client_id: client || clientId,
    }
    const formData = querystring.stringify(params);
    const contentLength = formData.length;    
    return apiCallSso('/logout', {method: "POST", body: formData}, {'Content-Length': contentLength, 'Content-Type': 'application/x-www-form-urlencoded'})
  }
}

// export const authUrl = `${ssoUrl}/auth`
// export const tokenUrl = `${ssoUrl}/token`
// export const introspectUrl = `${ssoUrl}/introspect`
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { authRequest } from 'containers/Sso/actions';
import queryString from 'query-string';


const AutoAuth = (props) => {
  const { authRequest, location } = props
  const parsed = queryString.parse(location.search);
  useEffect(() => {
    authRequest({}, parsed.to)
  }, [])
  return null
}


const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return {
    authRequest: (payload, to) => dispatch(authRequest(payload, to)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAuth); 
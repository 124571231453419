/*
 *
 * Conf
 *
 */

import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';


import Row from 'components/Row'
import Col from 'components/Col'
import Container from 'components/Container'
import Button from 'components/Button'
import superbBg from 'images/superblk.jpg'
import { authRequest } from 'containers/Sso/actions';
import { makeSelectIsDealerDomain, makeSelectDomainDealersFull } from 'containers/App/selectors'
import { Link } from 'react-router-dom';
const dealerInfoLink = '/pages/payment'


export class PageReturn extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
  }

  render() {
    const { host, isDealerDomain, dealers } = this.props
    const shouldShowDealerInfo = isDealerDomain && dealers && dealers.length === 1
    const dealer = shouldShowDealerInfo && dealers[0]
    return (
      <div>
        <Helmet
          title="Гарантия и возврат товара"
          meta={[
            { name: 'description', content: 'Гарантия и возврат товара' },
          ]}
        />
        <div className="content">
          <div className="pages-text">
            <h1 className="title">Гарантия и возврат товара</h1>
            <div className="text">
              <h2>Гарантия на товар</h2>
              <p>1.  Условия предоставления гарантии на Товар, а также гарантийные сроки определяются изготовителем Товара и изложены на Сайте и/или в документах, передаваемых Клиенту вместе с Товаром (при наличии). </p>
              <p>2.  Продавец несет ответственность за недостатки, обнаруженные в Товаре в течение гарантийного срока. Гарантийный срок исчисляется с момента передачи Товара Клиенту. </p>
              <p>3.  Предоставляемая Гарантия качества ограничена только дефектами производственного характера и не распространяется на:</p>
              <ul>
                <li>дефекты (недостатки) аксессуаров и принадлежностей (наружных, внутренних и съемных устройств), установленных не заводом-изготовителем и/или не являющихся оригинальным оборудованием или аксессуарами соответствующей марки, а также на неисправности, возникшие под влиянием указанных устройств;</li>
                <li>проявляющиеся в процессе эксплуатации и являющиеся конструктивной особенностью Товара незначительные шумы (щелчки, скрип, вибрация), не влияющие на качество, характеристики и работоспособность автомобиля или его элементов.</li>
              </ul>
              <p>4.  Гарантийные обязательства не распространяются на недостатки (дефекты) Товара, возникшие в результате и по причине:</p>
              <ul>
                <li>нарушения правил эксплуатации и ухода, несоблюдения инструкций и предписаний, которые приводятся в руководстве по эксплуатации и в иных документах, передаваемых вместе с Товаром;</li>
                <li>ненадлежащего использования или эксплуатации Товара;</li>
                <li>естественного износа деталей, узлов и агрегатов, в том числе ускоренного, связанного с манерой вождения, с условиями эксплуатации (например, с качеством дорожного покрытия и др.);</li>
                <li>естественного старения при воздействии окружающей среды резиновых уплотнителей, молдингов, деталей подвески, системы выпуска отработавших газов;</li>
                <li>Дорожно-транспортного происшествия (ДТП) и иного внешнего воздействия (химических веществ, кислоты, частей дорожного покрытия, камней, песка, соли, пожаров; техногенной деятельности человека, небрежности или неправомерных действия, например, вандализма; природных и экологических явлений, например, смолистых осадков деревьев, града, шторма, молнии, сильных ливней, а также стихийных бедствий и т.п.);</li>
                <li>использования горюче-смазочных материалов и эксплуатационных жидкостей, не соответствующих характеристикам, указанным в руководстве по эксплуатации автомобиля, а также использования недостаточного количества эксплуатационных жидкостей и материалов, в том числе, моторного топлива;</li>
                <li>неквалифицированного или несвоевременного ухода и технического обслуживания, в том числе, неквалифицированного ремонта на предприятии, не являющемся официальным дилерским или сервисным центром соответствующей марки;</li>
                <li>внесения неодобренных изготовителем / импортером изменений в конструкцию автомобиля и/или программное обеспечение (в том числе, тюнинг, несанкционированное обновление навигационных карт);</li>
                <li>внесения неодобренных изготовителем / импортером изменений в конструкцию автомобиля и/или программное обеспечение (тюнинг);</li>
                <li>оснащения автомобиля дополнительным оборудованием, аксессуарами, деталями или навесным оборудованием, которые не являются оригинальным оборудованием, аксессуарами, деталями соответствующей марки и не имеют одобрения изготовителя;</li>
                <li>незаявления или несвоевременного заявления о неисправностях официальному дилеру или сервисному центру, а также непредоставления или несвоевременного предоставления Товара для устранения неисправностей вопреки требованиям и рекомендациям официального дилера или сервисного центра.</li>
              </ul>    
              <h2>Возврат на товара</h2>
              <p>1.  Клиент вправе изменить решение о покупке и отказаться от заказанного Товара в любое время до получения заказанного Клиентом Товара, а после его получения – в течение 7 (семи) дней в соответствии с Законом Российской Федерации от 002.1992 № 2300-1 «О защите прав потребителей».</p>
              <p>2.  При возврате Товара Продавец обязан вернуть уплаченную за возвращенный Товар денежную сумму в порядке, предусмотренном в п. 4. настоящих Условий, при условии возврата Клиентом ранее приобретенного им Товара. </p>
              <p>3.  Возврат Товара надлежащего качества проводится, если указанный Товар не был в употреблении, сохранены его товарный вид, потребительские свойства, пломбы, фабричные ярлыки и т.п. При обнаружении следов эксплуатации Товара Продавец оставляет за собой право отказать Клиенту в приемке Товара и возврате денежных средств.</p>
              <p>4.  Порядок возврата Клиентом Товара:</p>
              <ul>
                <li>Клиент обращается к Продавцу и оформляет заявление на возврат Товара и денежных средств (письменное заявление, подписанное Клиентом собственноручно, должно быть передано по месту нахождения Продавца);</li>
                {shouldShowDealerInfo &&
                  <li>Клиент согласовывает с Продавцом время передачи Продавцу возвращаемого Товара в часы работы Продавца по адресу места нахождения Продавца ({dealer.address}) или по иному согласованному адресу и предоставляет Товар в распоряжение Продавца в согласованное время и по согласованному с Продавцом адресу;</li>
                }
                {!shouldShowDealerInfo &&
                  <li>Клиент согласовывает с Продавцом время передачи Продавцу возвращаемого Товара в часы работы Продавца по адресу места нахождения <Link to={dealerInfoLink}>Продавца</Link> или по иному согласованному адресу и предоставляет Товар в распоряжение Продавца в согласованное время и по согласованному с Продавцом адресу;</li>
                }
                <li>Возврат денежных средств Клиенту, за исключением расходов Продавца на доставку Товара от Клиента, производится после проверки Продавцом состояния Товара согласно п. 3. настоящих Условий, не позднее чем через 10 дней с даты получения Продавцом письменного заявления Клиента, при условии возврата Клиентом ранее приобретенного им Товара. Возврат денежных средств Клиенту производится на банковскую карту, с которой был осуществлен платеж.</li>
              </ul>
              <p>5.  Возврат и обмен Товара ненадлежащего качества возможны по основаниям, в порядке и в сроки, предусмотренные Законом Российской Федерации от 07.02.1992 № 2300-1 «О защите прав потребителей».</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



PageReturn.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  isDealerDomain: makeSelectIsDealerDomain(),
  dealers: makeSelectDomainDealersFull(),
});

function mapDispatchToProps(dispatch) {
  return {
    // getDealers: () => dispatch(getDealers()),
    // setMapDealer: (id) => dispatch(setMapDealer(id)),
    setPopup: (id) => dispatch(setPopup(id)),
  };
}





export default connect(mapStateToProps, mapDispatchToProps)(PageReturn);

import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import 'css/components/dropdown.sass'


function useOutsideAlerter(ref, cb) {

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      cb();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}



const Dropdown = ({ options, selected, placeholder, onChange }) => {
  const [open, setOpen] = useState(false);
  const cls = classNames('dropdown', { dropdown_open: open })
  let currentTitle = (options.find(opt => opt[1] === selected) || [''])[0]
  let currentValue = `${selected}`;
  if (!currentTitle) currentTitle = options[0][0]
  if (!selected) currentValue = `${options[0][1]}`
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setOpen(false));    
  return (
    <div className={cls} ref={wrapperRef}>
      <div className="dropdown__title" onClick={() => setOpen(!open)}>
        {currentTitle}
        &nbsp;
        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
      </div>
      {open &&
        <div className="dropdown__popup">
          {options.map(option => {
            const ocls = classNames("dropdown__option", {active: option[1] === currentValue})
            return (
              <div key={option[1]} className={ocls} onClick={() => { onChange(option[1]); setOpen(false) } }>
                <div className="dropdown__option-icon">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                {option[0]}
              </div>
            )
          })}          
        </div>
      }
    </div>

  )
}

export default Dropdown;


    // <select onChange={onChange} value={selected}>
    //   {options.map(option => (
    //     <option value={option[1]}>
    //       {option[0]}
    //     </option>
    //   ))}
    // </select>
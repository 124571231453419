/* istanbul ignore file */
import { request, requestWithPagination, requestRaw } from './request';

import { apiUrl } from '../config';
// import { filterUrl } from '../helpers';
import { _localStorage, isServer }  from 'helpers'


export const apicall = (url, options = {}, headers = { 'Content-Type': 'application/json' }, type = 'regular') => (params = {}) => {
  const token = _localStorage.getItem('auth_token');
  const modHeaders = headers;
  const accessToken = _localStorage.getItem("access_token")
  if(token){
    modHeaders["Authorization"] = "Bearer " + token;
  }
  if(accessToken) {
    modHeaders["Authorization"] = "Bearer " + accessToken;
  }  

  if(isServer) {
    modHeaders.partshost = params.host;
  }

  // console.log(modHeaders)
  switch (type) {
    case 'pagination':
      return requestWithPagination(apiUrl + url, Object.assign({ headers: modHeaders }, options));
    case 'raw':
      return requestRaw(apiUrl + url, Object.assign({ headers: modHeaders }, options));
    case 'rawer':
      return requestRaw(url, Object.assign({ headers: modHeaders }, options));
    case 'absolute':
      return request(url, Object.assign({ headers: modHeaders }, options));
    default:
      return request(apiUrl + url, Object.assign({ headers: modHeaders }, options));
  }
}

// export function filterCall (type, data) {
//   return apicall(filterUrl(type), { method: 'POST', body: JSON.stringify(data) }, { 'Content-Type': 'application/json' }, 'pagination');
// }

// export function filterXlsCall (url, data) {
//   return apicall(url, { method: 'POST', body: JSON.stringify(data) }, { 'Content-Type': 'application/json' }, 'raw');
// }

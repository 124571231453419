import React from 'react'
import styled, { css } from 'styled-components'

export const Flex = styled.div`
  display: flex;
  ${({ center }) => center && `
    align-items: center;
  `}
  ${({ justify }) => justify && `
    justify-content: space-between;
  `}
  ${({ right }) => right && `
    justify-content: flex-end;
  `}  
  ${({ wrap }) => wrap && `
    flex-wrap: wrap;
  `}

  ${({ spaced }) => spaced && `
    margin: 0 -5px;
    & > * {
      margin: 0 5px;
    }
  `}

  ${({ space }) =>
    space &&
    `
    margin: 0 -${space}px;
    & > * {
      margin: 0 ${space}px;
    }
  `}  


`

export const FlexFixed = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

export const FlexFluid = styled.div`
  flex-grow: 1;
  min-width: 1px;
`

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import CCart from './CCart';
import messages from 'config/messages';

import { formatPrice } from 'helpers';
import PriceDisclaimer from 'components/PriceDisclaimer';
import Offer from './Offer';
import OfferEmpty from './OfferEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/Checkbox'
import * as metricsActions from 'containers/App/metricsActions'

import { NOTIFY_STOCK } from 'components/Popup/constants'

const Filter = styled.div`
  padding: 6px 10px;
`

const sorty = (field, sField, sOrder) => {
  if (field !== sField) {
    return faSort
  } else if(sOrder === 'asc') {
    return faSortDown
  } else {
    return faSortUp
  }
}


class OffersResult extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)
    this.state = { sort: { field: 'name', order: 'asc' }, deliverable: false, paymentable: false }
    this.sortTh = this.sortTh.bind(this)
    this.sorted = this.sorted.bind(this)
  }

  componentDidMount() {

  }

  sortTh(field) {
    const { field: sField , order: sOrder } = this.state.sort;
    if (field !== sField) {
      this.setState({sort: {field: field, order: 'asc'}})
    } else {
      this.setState({sort: {field: field, order: sOrder === 'asc' ? 'desc' : 'asc'}})
    }
  }

  sorted(offers, props) {
    const { field, order } = this.state.sort
    const { dealers, cities } = props
    const sortOrder = this.state.sort.order === 'asc' ? 1 : -1
    let sortSelector = () => ''
    if (field === 'name') {
      sortSelector = (row) => dealers[row.dealerId].name.toLowerCase()
    }
    if (field === 'city') {
      sortSelector = (row) => { const dealer = dealers[row.dealerId]; return cities[dealer.city].name.toLowerCase() } 
    }    
    if (field === 'price') {
      sortSelector = (row) => parseFloat(row.price)
    }

    return offers.sort((a,b) => sortSelector(a) > sortSelector(b) ? sortOrder : -sortOrder)
  }

  render() {
    const {
      number, offersArray, msrps, dealers, addItemToCart, items, currentCartId, 
      clickedOffer, handleOfferClick, setPopup, push, fromPopup, dealersWithoutOffers, cities, payables,
      deliverables, sendRaw
    } = this.props;
    const msrp = msrps[number];
    let offersResult = this.sorted(offersArray, this.props)
    if (this.state.deliverable) {
      offersResult = offersResult.filter(offer => (deliverables && dealers && dealers[offer.dealerId] && deliverables[dealers[offer.dealerId].kod]) || (dealers && dealers[offer.dealerId] && dealers[offer.dealerId].freeCityDelivery) )
    }
    if (this.state.paymentable) {
      offersResult = offersResult.filter(offer => payables && dealers && dealers[offer.dealerId] && payables[dealers[offer.dealerId].kod])
    }
    const allDealersWithPayment = offersResult.every(offer => {
      return payables && dealers && dealers[offer.dealerId] && payables[dealers[offer.dealerId].kod]
    })    
    return (
      <React.Fragment>
        { (offersResult.length > 0 || dealersWithoutOffers.length > 0) &&
         (
            <React.Fragment>
              <Filter>
                <Checkbox
                  onClick={() => this.setState({ deliverable: !this.state.deliverable })}
                  checked={this.state.deliverable}>
                    Дилеры с доставкой
                </Checkbox>
              </Filter>
              {(!allDealersWithPayment || this.state.paymentable) &&
                <Filter>
                  <Checkbox
                    onClick={() => { sendRaw('Accessories_BuyForm', 'online_payment', !this.state.paymentable ); this.setState({ paymentable: !this.state.paymentable })}}
                    checked={this.state.paymentable}>
                    Дилеры с оплатой онлайн
                  </Checkbox>
                </Filter>
              }           
              <table className="table parts__table">
                <thead>
                  <tr>
                    <th onClick={() => this.sortTh('name')} className='parts__table-first table__sortable' >
                      Дилер
                      &nbsp;
                      <FontAwesomeIcon icon={sorty('name', this.state.sort.field, this.state.sort.order)} />
                    </th>
                    <th onClick={() => this.sortTh('city')} className='parts__table-first table__sortable' >
                      Город
                      &nbsp;
                      <FontAwesomeIcon icon={sorty('city', this.state.sort.field, this.state.sort.order)} />
                    </th>                    
                    <th onClick={() => this.sortTh('price')} className='table__sortable' >
                      Цена
                      &nbsp;
                      <FontAwesomeIcon icon={sorty('price', this.state.sort.field, this.state.sort.order)} />                      
                    </th>
                  <th />
                    <th>
                      Наличие
                    </th>

                  <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    offersResult.map((offer) => (
                      <React.Fragment key={offer.id}>
                        <Offer sendRaw={sendRaw} offer={offer} items={items} cities={cities} currentCartId={currentCartId} dealer={dealers && dealers[offer.dealerId]} 
                          payable={payables && dealers && dealers[offer.dealerId] && payables[dealers[offer.dealerId].kod]} 
                          deliverable={(deliverables && dealers && dealers[offer.dealerId] && deliverables[dealers[offer.dealerId].kod]) || (dealers && dealers[offer.dealerId] && dealers[offer.dealerId].freeCityDelivery)}
                          addItemToCart={(productId, dealerId, quantity) => { handleOfferClick(offer.id); addItemToCart(productId, dealerId, quantity); }} />
                        { clickedOffer === offer.id &&
                          (
                            <CCart 
                              sendRaw={sendRaw}
                              push={push} 
                              setPopup={setPopup} 
                              fromPopup={fromPopup} 
                              items={items} 
                              cartId={currentCartId} 
                            />
                          )
                        }                              
                      </React.Fragment>
                    ))
                  }

                  {!this.state.deliverable && !this.state.paymentable && dealersWithoutOffers.map(dealerId => {
                    return (
                      <OfferEmpty key={`eo-${number}-${dealerId}`} dealer={dealers[dealerId]} number={number} setPopup={(opts) => setPopup(NOTIFY_STOCK, opts)}/>
                    )
                  })}
                </tbody>
              </table>
            </React.Fragment>
          )
        }
        { !offersResult.length && !dealersWithoutOffers.length && (
            <div style={{ padding: 25 }}>
              Нет предложений дилеров по выбранной детали
            </div>
          )
        }
        {/*msrp.actual &&*/}
        {msrp && msrp.kind !== 1 &&
          (
            <div className='parts__msrp'>
              {messages.msrp[msrp.kind]}

              :&nbsp;
              {formatPrice(msrp.price)}
            </div>
          )
        }
        <PriceDisclaimer msrp={msrp} />
        {' '}        
      </React.Fragment>
    );
  };
}


function mapDispatchToProps(dispatch) {
  return {
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
)(OffersResult);


import React from 'react';
import styled from 'styled-components';
import Box from 'components/Box'

const Wrapper = styled.div`
  textarea {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    width: 100%;
    min-height: 150px;
    padding: 12px 16px;
    &::placeholder {
      color: #CACACA;
      font-weight: 300;
    }
  }
`

function DealerComment({ value, setValue, placeholder = 'Добавьте коментарий к заказу для дилера' }) {

  return (
    <Box>
      <Wrapper>
        <textarea placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} />
      </Wrapper>
    </Box>
  )
}

export default DealerComment;
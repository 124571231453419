/**
*
* Row
*
*/

import React from 'react';


function Row (props) {
  return (
    <div className="row">
      {props.children}
    </div>
  );
}

Row.propTypes = {

};

export default Row;


import {
  call, put, select, takeEvery, all,
} from 'redux-saga/effects';

import { apiRequest } from 'containers/App/saga';
import { getBonuses, applyCert, applyPoints, removeBonus } from 'api/users'
import { actionTypes, setBonusInfo } from './actions';

import { normalize } from 'normalizr';
import { setEntities, replaceEntities } from 'models/actions';
import {
  Cart
} from 'models';




function * getBonusesGen (action) {
  const {
    payload: { resolve, reject },
  } = action;

  try {
    const response = yield call(apiRequest, getBonuses);
    yield put(setBonusInfo(response))

    resolve && resolve(response);
  } catch (e) {
    console.log(e);
    reject && reject(e);
  }
}

function * applyCertGen (action) {
  const {
    payload: { resolve, reject, certCode, dealerId },
  } = action;
  console.log(action)
  try {
    const cart = yield call(apiRequest, applyCert, { certCode, dealerId });
    const normalizedCart = normalize(cart, Cart);
    yield put(replaceEntities(normalizedCart, 'carts'));
    yield put(replaceEntities(normalizedCart, 'items'));


    resolve && resolve(cart);
  } catch (e) {
    console.log(e);
    reject && reject(e);
  }
}

function * applyPointsGen (action) {
  const {
    payload: { resolve, reject, amount, dealerId },
  } = action;

  try {
    const cart = yield call(apiRequest, applyPoints, { amount, dealerId });
    const normalizedCart = normalize(cart, Cart);
    yield put(replaceEntities(normalizedCart, 'carts'));
    yield put(replaceEntities(normalizedCart, 'items'));
    resolve && resolve(cart);
  } catch (e) {
    console.log(e);
    reject && reject(e);
  }
}

function * removeBonusGen (action) {
  const {
    payload: { resolve, reject, dealerId },
  } = action;
  try {
    const cart = yield call(apiRequest, removeBonus, { dealerId });
    const normalizedCart = normalize(cart, Cart);
    yield put(replaceEntities(normalizedCart, 'carts'));
    yield put(replaceEntities(normalizedCart, 'items'));
    resolve && resolve(cart);
  } catch (e) {
    console.log(e);
    reject && reject(e);
  }
}

export default function * bonusSaga () {
  yield all([
    takeEvery(actionTypes.GET_BONUSES, getBonusesGen),
    takeEvery(actionTypes.APPLY_CERT, applyCertGen),
    takeEvery(actionTypes.APPLY_POINTS, applyPointsGen),
    takeEvery(actionTypes.REMOVE_BONUS, removeBonusGen),
  ]);
}

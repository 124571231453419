import React from 'react';
import Button from 'components/Button';
import { formatPrice, orderData } from 'helpers';
import { H7, Gapper } from 'components/Ui'

import 'css/components/ccart.sass';

const CCart = ({
  setPopup, push, fromPopup, items, cartId, sendRaw
}) => {
  const { count, price } = orderData(items, cartId);
  return (
    <React.Fragment>
      <tr className="ccart">
        <td colSpan={5}>
          <div className="ccart__container">
            <Gapper>
              <H7>
                Корзина
              </H7>
              <div className="flex flex_between">
                <div className="pupa">
                  <div className="pupa__title">

                    Аксессуаров
                  </div>
                  <div className="pupa__value">
                    {count}
                    {' '}
                    шт.
                  </div>
                </div>
                <div className="pupa">
                  <div className="pupa__title">
                    На сумму
                  </div>
                  <div className="pupa__value">
                    {formatPrice(price)}
                  </div>
                </div>
                <div className="ccart__buttons">
                  <Gapper>
                    { fromPopup && (
                      <Button small secondary onClick={() => {sendRaw('Accessories_BuyForm', 'click_more_search'); setPopup(null)}}>
                        Продолжить выбор аксессуаров
                      </Button>
                    )}
                    <Button small onClick={() => { sendRaw('Accessories_BuyForm', 'click_purchase'); setPopup(null); push('/cart'); }}>
                      Перейти к оформлению заказа
                    </Button>
                  </Gapper>
                </div>
              </div>
            </Gapper>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default CCart;

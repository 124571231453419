/**
 *
 * Contacts
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectIsDealerDomain, makeSelectCurrentDomain } from 'containers/App/selectors';
import { makeSelectEntities } from 'models/selectors';

import NotFoundPage from 'containers/NotFoundPage/Loadable';

import DealerView from 'components/DealerView';

export class Contacts extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { domain, dealers } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Контакты
          </title>
          <meta name="description" content="Контакты" />
        </Helmet>
        <div className="container">
          <h1>
            Контакты
          </h1>
          <div>
            <DealerView dealer={dealers[domain.dealerId]} />

            {domain.dealers.map((one) => (
              <DealerView key={`dealer-${one}`} dealer={dealers[one]} />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export class Wrappe extends React.PureComponent {
  render () {
    const { isDealerDomain } = this.props;
    const Component = isDealerDomain ? Contacts : () => (
      <div className="container">
        {' '}
        <NotFoundPage />
      </div>
    );
    return (
      <Component {...this.props} />
    );
  }
}

Wrappe.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  isDealerDomain: makeSelectIsDealerDomain(),
  domain: makeSelectCurrentDomain(),
  dealers: makeSelectEntities('dealers'),
});

function mapDispatchToProps (dispatch) {
  return {
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Wrappe);

import React from 'react';
import styled, { css } from 'styled-components';

import { Gapper } from 'components/Ui'

import vars from 'config/styles'

const Logo = styled.div`
  width: 100px;
  img {
    max-width: 100%;
  }
`

const AddressText = styled.div`
  font-size: 14px;
  line-height: 20px;
`

const Status = styled.div`
  ${({ red }) => red && css`
    color: ${vars.red};
  `}
`

const Address = ({ deliveryData }) => {
  return (
    <AddressText>
      {[deliveryData.city, `ул. ${deliveryData.street}`, 
      deliveryData.house && `д. ${deliveryData.house}`, 
      deliveryData.korpus && `корп. ${deliveryData.korpus}`, 
      deliveryData.flat && `кв. ${deliveryData.flat}`].filter(a=>a).join(', ')}
    </AddressText>
  )
}

function DeliveryInfo({ delivery, noLogo = false }) {
  const deliveryData = (delivery || {}).data || {}


  if(!delivery)
    return null                          
  
  const status = delivery.status || {}
  const { status_id, status: status_text } = status
  const unconfirmed = (!status_id || status_id === 11) && delivery.orderId
  const statusText = !unconfirmed ? status_text : ''
  
  return (
    <Gapper>
      {!noLogo &&
        <Logo>
          <img src={deliveryData.delivery.delivery_company_logo} />
        </Logo>
      }
      {deliveryData.deliveryType === 1 &&
        <>
          Пункут выдачи заказов <span style={{fontWeight: 'bold'}}>{deliveryData.delivery.delivery_company_name}:</span>
          <br/>
          <AddressText>{deliveryData.city}, {deliveryData.delivery.address}</AddressText>
        </>
      }    
      {deliveryData.deliveryType === 2 &&
        <>
          Курьерская доставка <span style={{fontWeight: 'bold'}}>{deliveryData.delivery.delivery_company_name}:</span>
          <br/>
          <Address deliveryData={deliveryData}/>
        </>
      }
      {deliveryData.deliveryType === 3 &&
        <>
          <span style={{fontWeight: 'bold'}}>Почта России:</span>&nbsp;
          <Address deliveryData={deliveryData}/>
        </>
      }
      {unconfirmed &&
        <Status red>
          Ожидает подтверждения
        </Status>
      }
      {
        statusText && 
        <div>
          Статус:&nbsp;
          <b>{statusText}</b>
        </div>
      }
    </Gapper>    
  )
}

export default DeliveryInfo;
/**
 *
 * Delivery.js
 *
 */


import React from 'react';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import saga from './saga';
import reducer from './reducer'
import { getDeliveryStatus } from './actions'

class Delivery extends React.PureComponent {
  componentDidMount () {
    this.props.getDeliveryStatus()
  }

  render () {
    return null
  }
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps (dispatch) {
  return {
    getDeliveryStatus: () => dispatch(getDeliveryStatus()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'delivery', saga });

export default compose(
  withSaga,
  withConnect
)(Delivery);

/* istanbul ignore file */
import {
  LOGIN,
  LOGOUT,
  SET_PAGE_DATA,
  SET_CURRENT_USER,
  CHECK_AUTH,
  ADD_TO_CART,
  CHECKOUT_ORDER,
  SET_CURRENT_CART_ID,
  SET_CURRENT_REGION_ID,
  GET_USER,
  SET_POPUP,
  GET_CART,
  MAKE_ORDER,
  GET_ALL_REGION_DATA,
  SET_MENU,
  INITIAL_LOAD,
  INITIAL_SET,
  SET_CURRENT_DOMAIN_ID,
  GET_DEALER_ORDER_COUNT,
  SET_DEALER_ORDER_COUNT,
  GET_MODELS,
  GET_CATEGORIES,
  FAV_TOGGLE,
  SET_FAV,
  NOTIFY_STOCK,
  SET_LOADING,
  SET_AUTH_ERROR,
  SET_MESSAGES_SHOW,
  SET_DOMAIN_LOADING,
  COMPLETE_DOMAIN_CHECK,
  GET_ORDER,
  COMPLETE_AUTH_CHECK,
} from './constants';


export function setMessagesShow (show) {
  return {
    type: SET_MESSAGES_SHOW,
    show,
  };
}

export function notifyStock (payload) {
  return {
    type: NOTIFY_STOCK,
    payload,
  };
}


export function setLoading (payload) {
  return {
    type: SET_LOADING,
    payload
  }
}

export function setDomainLoading (payload) {
  return {
    type: SET_DOMAIN_LOADING,
    payload
  }
}

export function completeDomainCheck () {
  return {
    type: COMPLETE_DOMAIN_CHECK,
  }
}


export function completeAuthCheck () {
  return {
    type: COMPLETE_AUTH_CHECK,
  }
}


export function setFav (favs, tp = 0) {
  return {
    type: SET_FAV,
    favs,
    tp,
  };
}

export function favToggle (payload) {
  return {
    type: FAV_TOGGLE,
    payload,
  };
}

export function getCategories () {
  return {
    type: GET_CATEGORIES,
  };
}

export function getModels (all = false) {
  return {
    type: GET_MODELS,
    all
  };
}

export function initialLoad (payload) {
  return {
    type: INITIAL_LOAD,
    payload
  };
}

export function initialSet (payload) {
  return {
    type: INITIAL_SET,
    payload
  };
}

export function getDealerOrderCount (dealerId) {
  return {
    type: GET_DEALER_ORDER_COUNT,
    dealerId,
  };
}

export function setDealerOrderCount (count) {
  return {
    type: SET_DEALER_ORDER_COUNT,
    count,
  };
}

export function setMenu (id) {
  return {
    type: SET_MENU,
    id,
  };
}

export function login (payload) {
  return {
    type: LOGIN,
    payload,
  };
}

export function logout () {
  return {
    type: LOGOUT,
  };
}

export function setPageData (payload) {
  return {
    type: SET_PAGE_DATA,
    payload,
  };
}

export function setCurrentUser (user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setCurrentCartId (cartId) {
  return {
    type: SET_CURRENT_CART_ID,
    cartId,
  };
}

export function setCurrentRegionId (regionId, query = true) {
  return {
    type: SET_CURRENT_REGION_ID,
    regionId,
    query,
  };
}

export function setCurrentDomainId (domainId) {
  return {
    type: SET_CURRENT_DOMAIN_ID,
    domainId,
  };
}

export function getAllRegionData () {
  return {
    type: GET_ALL_REGION_DATA,
  };
}

export function getUser (userId) {
  return {
    type: GET_USER,
    userId,
  };
}

export function getCart (cartId) {
  return {
    type: GET_CART,
    cartId,
  };
}

export function makeOrder (payload) {
  return {
    type: MAKE_ORDER,
    payload,
  };
}

export function checkAuth () {
  return {
    type: CHECK_AUTH,
  };
}

export function addToCart (payload) {
  return {
    type: ADD_TO_CART,
    payload,
  };
}

export function checkoutOrder () {
  return {
    type: CHECKOUT_ORDER,
  };
}

export function setPopup (popup, options = {}) {
  return {
    type: SET_POPUP,
    popup,
    options,
  };
}

export function setAuthError(authError) {
  return {
    type: SET_AUTH_ERROR,
    authError
  }
}

export function getOrder(payload) {
  return {
    type: GET_ORDER,
    payload
  }
}

import React from 'react';
import styled, { css } from 'styled-components'

export const SvgWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  ${({ small }) => small && css`
    width: 18px;
    height: 18px;
  `}
  ${({ big }) => big && css`
    width: 30px !important;
    height: 30px !important;
  `}  
  svg {
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
`

function Svg({ icon, ...rest }) {
  return (
    <SvgWrapper {...rest} dangerouslySetInnerHTML={{ __html: icon }} />
  );
}


export default Svg;

import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
// import { setPageData } from 'containers/App/actions';
// import { change as formChange, untouch } from 'redux-form/immutable';
import { setPopup } from 'containers/App/actions'
import { SET_PODBOR } from 'components/Popup/constants'
import Box, { Inner } from 'components/Box'
import { Text2, Gapper } from 'components/Ui'

import Button from 'components/Button'
 
class PodborInit extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    const { setPopup } = this.props
    return (
      <Box bged>
        <Inner>
          <Gapper>
            <Text2>Ничего не найдено. Вы можете отправить заявку дилеру для подбора указанной запчасти.</Text2>
            <Button onClick={() => { setPopup(SET_PODBOR, {}) }}>Добавить к подбору</Button>
          </Gapper>
        </Inner>
      </Box>
    )
  }
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps (dispatch) {
  return {
    // setPageData: (name, value) => dispatch(setPageData({ page: 'parts', name, value })),
    // setForm: (name, value) => dispatch(formChange("parts-form", name, value)),
    // untouch: (name) => dispatch(untouch("parts-form", name))
    setPopup: (name, opts) => dispatch(setPopup(name, opts)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect,
)(PodborInit);



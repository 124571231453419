/* istanbul ignore file */
/*
 *
 *
 *
 */

import {
  EDIT_DEALER,
  GET_DEALERS,
  GET_DEALER_LOGS,
} from './constants';

export function editDealer (payload) {
  return {
    type: EDIT_DEALER,
    payload,
  };
}

export function getDealers () {
  return {
    type: GET_DEALERS,
  };
}

export function getDealerLogs (payload) {
  return {
    type: GET_DEALER_LOGS,
    payload,
  };
}

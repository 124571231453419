
export const GET_GOOD_BY_ID = 'SkodaParts/goods/GET_GOOD_BY_ID';
export const GET_GOOD_OFFERS = 'SkodaParts/goods/GET_GOOD_OFFERS';
export const EDIT_DEALER_GOOD = 'SkodaParts/goods/EDIT_DEALER_GOOD';
export const EDIT_GOOD = 'SkodaParts/goods/EDIT_GOOD';
export const GET_GOODS_PRODUCTS = 'SkodaParts/goods/GET_GOODS_PRODUCTS';
export const DELETE_DEALER_GOOD = 'SkodaParts/goods/DELETE_DEALER_GOOD';
export const GET_GOOD_LOGS = 'SkodaParts/goods/GET_GOOD_LOGS';
export const GET_PRICE_BY_ID = 'SkodaParts/goods/GET_PRICE_BY_ID';
export const DELETE_GOOD = 'SkodaParts/goods/DELETE_GOOD';
export const ENSURE_FILTERS_EXIST = 'SkodaParts/goods/ENSURE_FILTERS_EXIST';

/* istanbul ignore file */

import { apicall } from 'utils/api';

export function filterCall (model, data, path = 'filter') {
  return apicall(`${model}/${path}`, { method: 'POST', body: JSON.stringify(data) }, { 'Content-Type': 'application/json' });
}

export function filterXlsCall (url, data, method='POST') {
  return apicall(url, { method: method, body: JSON.stringify(data) }, { 'Content-Type': 'application/json' }, 'raw');
}

export function saveSortOrder (model, data, criteria = {}, path = 'sort') {
  return apicall(`${model}/${path}`, { method: 'POST', body: JSON.stringify({ data, criteria }) }, { 'Content-Type': 'application/json' });
}

/* istanbul ignore file */
import {
  EDIT_ORDER,
  GET_ORDER_LOGS,
} from './constants';

export function editOrder (payload) {
  return {
    type: EDIT_ORDER,
    payload,
  };
}

export function getOrderLogs (payload) {
  return {
    type: GET_ORDER_LOGS,
    payload,
  };
}

/*
  Admin Goods Filters
*/

import React from 'react';
import FiltersHoc from 'components/FiltersHoc';
import { compose } from 'redux';

import {
  Field,
} from 'redux-form/immutable';
import {
  inputField,
} from 'components/Form/components';
import RFReactSelect from 'components/Form/components/RFReactSelect';

class Filters extends React.PureComponent {
  render () {
    // const statusOptions = Object.values(statuses).map((x) => {return {value: x.id, label: x.name}})
    const activeOptions = [{ value: 'true', label: 'Активен' }, { value: 'false', label: 'Неактивен' }];

    return (
      <React.Fragment>
        <Field name="name" type="text" component={inputField} label="Название" />
        <Field name="number" type="text" component={inputField} label="Код дилера" />
        <Field name="status" component={RFReactSelect} multi={false} options={activeOptions} label="Активен" />
      </React.Fragment>
    );
  }
}

const withFilterHoc = FiltersHoc({ name: 'admin-cities-filters' });

export default compose(
  withFilterHoc,
)(Filters);

import React from 'react';
import { Helmet } from 'react-helmet-async';

import Container from 'components/Container';

const OrderSuccess = ({multiple = false}) => (
  <Container>
    <Helmet>
      <title>
        {multiple ? 'Заказы оформлены' : 'Заказ оформлен'}
      </title>
      <meta name="description" content="Заказ оформлен" />
    </Helmet>
    <h1>
      {multiple ? 'Заказы оформлены' : 'Заказ оформлен'}
    </h1>
    <div className="panel">
      { !multiple &&
        <span>Заказ успешно оформлен. Менеджер дилерского центра свяжется с Вами.</span>
      }
      { multiple &&
        <span>Заказы успешно оформлены. Менеджеры дилерских центров свяжутся с Вами.</span> 
      }      
    </div>
  </Container>
);

export default OrderSuccess;

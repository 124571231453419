import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

const OrderNumber = ({ order }) => {
  const existentPayment = order.payment && order.payment.status !== 0;

  return (
    <>
      {!existentPayment && order.number}
      {existentPayment && (
        <>
          <span data-for={`paid-${order.id}`} data-tip="Номер заказа с постфиксом для корректой обработки оплаты">
            {order.payment.orderNumber}
            <FontAwesomeIcon style={{ margin: '-3px 0 0 3px', color: '#eb5757' }} icon={faExclamationCircle} />
          </span>
          <ReactTooltip id={`paid-${order.id}`} />
        </>
      )}
    </>
  );
};

export default OrderNumber;

/**
*
* FormReturn
*
*/

import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form'


import { makeSelectEntities } from 'models/selectors';
import { inputField } from 'components/Form/components';
import { createStructuredSelector } from 'reselect';
import WithOtherField from 'components/Form/components/withOtherField';
import Button from 'components/Button';
import RFReactSelect from 'components/Form/components/RFReactSelect';
import { textField } from 'components/Form/components/'
import FieldItemSelector from './FieldItemSelector'

import { H4, Text2 } from 'components/Ui'

const refuseReasons = ['Возврат по гарантии', 'Возврат без основания']


const validate = (vals) => {
  const errors = {}

  if(!vals.reason) {
    errors.reason = 'Выберите причину отказа'
  }

  if(!vals.items || vals.items.length === 0) {
    errors.items = 'Укажите позиции для возврата'
  }

  return errors
}

function FormReturn (props) { // eslint-disable-line react/prefer-stateless-function

  const {
    handleSubmit, submitting, initialValues, onSubmit, id, items, products
  } = props;  

  const [orderItems, setOrderItems] = useState([])
  useEffect(() => {
    setOrderItems(Object.values(items).filter(item => item.orderId === id).map(item=>({...item, product: products[item.productId]})))
  },[id, items])

  const reasonOptions = refuseReasons.map((x) => ({ value: x, label: x }));

  return (
    <div>
      <H4>Заявка на возврат</H4>
      <Form onSubmit={(vals) => onSubmit(vals)} initialValues={initialValues} validate={validate} render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Text2>Отметьте позиции для возврата</Text2>
          <Field component={FieldItemSelector} name="items" label="Причина возврата" required items={orderItems}/>
          <Field component={WithOtherField} options={reasonOptions} name="reason" label="Причина возврата" required />
          <Field component={textField} name="text" label="Комментарий" />

          <Button xsmall loading={submitting} type="submit">
            Продолжить
          </Button>
        </form>
        )}
      />
    </div>
  )
}

FormReturn.propTypes = {

};

const mapStateToProps = createStructuredSelector({
  items: makeSelectEntities('items'),
  products: makeSelectEntities('products'),
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(FormReturn);

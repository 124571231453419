import React from 'react'
import Container from 'components/Container'

import { H1, Block, Gap, Gapper, Text2 } from 'components/Ui';



const CartThumb = () => {

  return (
    <Container>
      <div className="pep" style={{ fontSize: 16, padding: 40, fontWeight: 300, textAlign: 'center', marginTop: 80, marginBottom: 80 }}>
        Прием заказов временно приостановлен.
      </div>         
    </Container>
  )
}

export default CartThumb
import React from 'react';
import styled from 'styled-components'

import { Text4, Flex } from 'components/Ui'
import Checkbox from 'components/Checkbox'
import vars from 'config/styles'


const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Item = styled.div`
  padding: 5px;
  cursor: pointer;
`

const ErrorText = styled.div`
  color: ${vars.red};
`

export const FieldItemSelector = (field) => {
  const { value, onChange, ...rest } = field.input;
  const { items } = field
  const transformedValue = value || []
  return (
    <Wrapper>
      {items.map(item => {
        const checked = transformedValue.find(vitem=>vitem.id === item.id)
        const transformedItem = { id: item.id, quantity: item.quantity }
        return (
          <Item key={item.id} onClick={() => {
              if(checked) {
                onChange(transformedValue.filter(vitem => vitem.id !== item.id))
              } else {
                onChange(transformedValue.concat([transformedItem]))
              }
            }}>
              <Flex>
                <Checkbox checked={checked}/>
                <div>
                  {item.product && item.product.name}
                  <Text4>{item.productId}</Text4>
                </div>
              </Flex>
            </Item>
        )}
        )
      }
      { field.meta.touched && (field.meta.error || field.meta.submitError) && (
        <ErrorText>
          {(field.meta.error || field.meta.submitError)}
        </ErrorText>
      )}      
    </Wrapper>
  );
};

export default FieldItemSelector
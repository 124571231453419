
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserSlash, faEdit, faPrint, faHistory, faInfoCircle, faChevronUp, faChevronDown, faGlobe
} from '@fortawesome/free-solid-svg-icons';
import { EDIT_ORDER, SHOW_ORDER, SHOW_ORDER_LOGS } from 'components/Popup/constants';
import moment from 'moment';

import { ORDER_STATUSES_TRANSLATION, ORDER_EMAIL_EXPIRATION, PAYMENT_STATUSES_TRANSLATION, orderTimers } from 'settings';
import ReactTooltip from 'react-tooltip';

import { orderStatusToBadgeClass, paymentStatusToBadgeClass, formatDate, declOfNum } from 'helpers';
import Portal from 'components/Portal';
import ShowOrder from 'components/ShowOrder';
import OrderNumber from 'components/OrderNumber';

import 'css/components/pupa.sass';

const duratHuman = (days, hours, minutes) => `${days ? `${days} ${declOfNum(days, ['день', 'дня', 'дней'])}` : ''} ${hours ? `${hours} ${declOfNum(hours, ['час', 'часа', 'часов'])}` : ''} ${minutes} ${declOfNum(minutes, ['минуту', 'минуты', 'минут'])}`;

class Template extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      userExpanded: false,
      infoExpanded: false,
      print: false,
    };
  }

  render () {
    const {
      row, dealers, users, clients, setPopup, successCallback, logs, domains, payables,
    } = this.props;
    const user = users && row.userId && users[row.userId];
    const client = clients && row.clientId && clients[row.clientId];
    const dealer = dealers && row.dealerId && dealers[row.dealerId];
    const base = moment(row.createdAt);
    const right = row.touchedAt ? moment(row.touchedAt) : moment();
    const diff = right.diff(base);
    const diffDuration = moment.duration(diff);
    const diffDuration2 = moment.duration(row.timePassed);
    const [days, hours, minutes] = [Math.floor(diffDuration.asDays()), diffDuration.hours(), diffDuration.minutes()];
    const [hours2, minutes2] = [Math.floor(diffDuration2.asHours()), diffDuration2.minutes()];
    const domain = domains && row.domain && domains[row.domain]
    const payment = row.payment || {}
    const paymentStatus = payment.status || 0
    return (
      <React.Fragment>
        <tr className={this.state.userExpanded || this.state.infoExpanded ? 'table__userExpanded' : null}>
          <td className="table__bold nwrp hoverable" onClick={() => this.setState((state) => ({ infoExpanded: !state.infoExpanded }))}>
            <OrderNumber order={row} />
            <FontAwesomeIcon icon={this.state.infoExpanded ? faChevronUp : faChevronDown} />
            {row.domain && 
              <FontAwesomeIcon icon={faGlobe} data-effect="solid" data-tip={`Заказ с сайта дилера: ${domain.name}`} style={{marginLeft: 10}} /> 
            }
          </td>
          <td className="table__left">
            <span className={`badge ${orderStatusToBadgeClass(row.status)}`}>
              {ORDER_STATUSES_TRANSLATION[row.status]}
            </span>

            {payables && payables[dealer.kod] && 
              <span className={`badge ${paymentStatusToBadgeClass(paymentStatus)}`}>
                {PAYMENT_STATUSES_TRANSLATION[paymentStatus]}
              </span>
            }   

            { row.processStatus === 20 &&
              (
                <span className="badge badge_grey">
                  Просрочен
                </span>
              )
            }      
          </td>
          <td className="table__italic">
            {row.date}
          </td>
          <td className="table__br" />
          <td className="hoverable" onClick={() => this.setState((state) => ({ userExpanded: !state.userExpanded }))}>
            {user && (`${user.surname} ${user.name} ${user.patrimony}`)}
            {client &&
              (
                <React.Fragment>
                  {client.name}
                  &nbsp;
                  <FontAwesomeIcon icon={faUserSlash} data-effect="solid" data-tip="Заказ без регистрации" />
                  <ReactTooltip />
                </React.Fragment>
              )
            }

            &nbsp;
            <FontAwesomeIcon icon={this.state.userExpanded ? faChevronUp : faChevronDown} />
          </td>
          <td>
            {dealer.name}
          </td>
          <td>
            <span className="nwrp hoverable" onClick={() => setPopup(SHOW_ORDER, { id: row.id })}>
              {row.totalPrice}
              {' '}
              руб /
              {row.itemsCount}
              {' '}
              шт
              {' '}
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </td>
          <td className="table__right table__actions nwrp">
            <FontAwesomeIcon icon={faEdit} data-effect="solid" data-tip="Редактировать" onClick={() => setPopup(EDIT_ORDER, { id: row.id, successCallback: () => { successCallback(); } })} />
            {/* <FontAwesomeIcon icon={faPrint} data-effect="solid" data-tip="Печать" onClick={() => setPopup(SHOW_ORDER, { id: row.id, print: true })} /> */}
            <FontAwesomeIcon icon={faPrint} data-effect="solid" data-tip="Печать" onClick={() => this.setState({ print: true })} />
            <FontAwesomeIcon icon={faHistory} data-effect="solid" data-tip="История изменений" onClick={() => setPopup(SHOW_ORDER_LOGS, { id: row.id, number: row.number })} />
            {this.state.print === true &&
              (
                <Portal onClose={() => { this.setState({ print: false }); }}>
                  <ShowOrder id={row.id} />
                </Portal>
              )
            }
          </td>
        </tr>
        {this.state.infoExpanded &&
          (
            <React.Fragment>
              <tr className="table__expander">
                <td colSpan={7}>
                  <div className="flex">
                    { row.touchedAt &&
                    (
                      <div className="pupa">
                        <div className="pupa__title">
                        Был прянят в работу через
                        </div>
                        <div className="pupa__value">
                          <React.Fragment>
                            {duratHuman(0, hours2, minutes2)} &nbsp;
                            <FontAwesomeIcon icon={faInfoCircle} data-effect="solid" data-tip={`Время с учётом рабочих часов дилера`}/> 
                            <div style={{fontSize: 12, marginTop: 8}}>
                              {duratHuman(days, hours, minutes)} &nbsp;
                              <FontAwesomeIcon icon={faInfoCircle} data-effect="solid" data-tip={`Полное время`}/> 
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )
                    }

                    { !row.touchedAt &&
                    (
                      <div className="pupa">
                        <div className="pupa__title">
                        Не обработан уже
                        </div>
                        <div className="pupa__value">
                          <React.Fragment>
                            {duratHuman(0, hours2, minutes2)} &nbsp;
                            <FontAwesomeIcon icon={faInfoCircle} data-effect="solid" data-tip={`Время с учётом рабочих часов дилера`}/> 
                            <div style={{fontSize: 12, marginTop: 8}}>
                              {duratHuman(days, hours, minutes)} &nbsp;
                              <FontAwesomeIcon icon={faInfoCircle} data-effect="solid" data-tip={`Полное время`}/> 
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )
                    }

                    {Object.keys(ORDER_EMAIL_EXPIRATION).map(instance => {
                      const currentLogs = row.logs ? row.logs.filter((logId) => logs[logId].payload.instance === instance ) : []

                      if(currentLogs.length > 0) { 
                        return (
                          <div className="pupa" key={`${row.id}-${instance}`}>
                            <div className="pupa__title">
                              {ORDER_EMAIL_EXPIRATION[instance]}
                            </div>
                            <div className="pupa__value">
                              {currentLogs.map((logId) => (
                                <React.Fragment key={`log-${logId}`}>
                                  { logs[logId].payload.email }
                                  <br/>
                                </React.Fragment>
                              ))}
                            </div>
                          </div>                          
                        )
                      }
                    })} 

                    <ReactTooltip />
                  </div>
                </td>
              </tr>
              <tr className="table__empty" />
            </React.Fragment>
          )
        }
        {this.state.userExpanded &&
          (
            <React.Fragment>
              <tr className="table__expander">
                <td colSpan={7}>
                  <div className="flex flex_pad">
                    <div className="pupa">
                      <div className="pupa__title">
                        Телефон
                      </div>
                      <div className="pupa__value">
                        {user && user.phone}
                        {client && client.phone}
                      </div>
                    </div>
                    <div className="pupa">
                      <div className="pupa__title">
                        Адрес
                      </div>
                      <div className="pupa__value">
                        {user && user.address}
                        {client && client.address}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="table__empty" />
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

export default Template;

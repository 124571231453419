import React from 'react';
import styled, { css } from 'styled-components'
import vars from 'config/styles'

const Label = styled.label`
  padding-left: 26px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: flex;
  align-items: flex-start;
  // height: 20px;
  ${({ empty }) => empty && css`
    padding-left: 0;
  `}
  &:hover {
    // color: ${vars.green};
  }  
  a {
    color: ${vars.green};
  }
  &:hover > ${Mask} {
    border-color: ${vars.green};
  }
`

const Mask = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #CACACA;
  ${({ checked }) => checked && css`
    border: none !important;
    background-color: ${vars.green};
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 6px;
      width: 4px;
      height: 4px;
      background: #fff;
      border-radius: 50%;
    }
  `}  

  ${({ disabled }) => disabled && css`
    background-color: #DADADA!important;
    border: none!important;
    &:before {
      border-bottom: 2px solid #DADADA!important;
      border-left: 2px solid #DADADA!important;      
    }
  `}
`

const Text = styled.div`

`


const Radio = ({ checked, onClick, children, disabled = false, empty }) => {
  return (
    <Label empty={empty} onClick={() => {onClick && !disabled && onClick()}}>
      <Mask checked={checked} disabled={disabled}/>
      {children && <Text>{children}</Text>}
    </Label>
  );

};

export default Radio;




export const AUTH_REQUEST = '@Sso/AUTH_REQUEST';
export const REG_REQUEST = '@Sso/REG_REQUEST';
export const SET_AUTHING = '@Sso/SET_AUTHING';
export const COMPLETE_SSO_AUTH_CHECK = '@Sso/COMPLETE_SSO_AUTH_CHECK';
export const GET_TOKEN = '@Sso/GET_TOKEN';
export const SET_USER = '@Sso/SET_USER';
export const SET_SCOPES = '@Sso/SET_SCOPES';
export const SET_CLIENT = '@Sso/SET_CLIENT';
export const INITIAL = '@Sso/INITIAL';
export const LOGOUT = '@Sso/LOGOUT';
export const FINISH_AUTH = '@Sso/FINISH_AUTH';
export const SET_LOGGING_IN = '@Sso/SET_LOGGING_IN';


/* istanbul ignore file */
import { apicall } from 'utils/api';

export function auth (creds) {
  return apicall('users/login', { method: 'POST', body: JSON.stringify(creds) });
}

export function logout () {
  return apicall('users/logout', { method: 'POST' });
}

export function getUser (userId) {
  return apicall(`users/${userId}`);
}

export function getCurrentUser() {
  return apicall(`users/current`);
}

export function register (data) {
  return apicall('users', { method: 'POST', body: JSON.stringify({ user: data }) });
}

export function updateUser (data) {
  const { id, ...rest } = data;
  return apicall(`users/${id}`, { method: 'PATCH', body: JSON.stringify({ user: rest }) });
}

export function checkToken (token) {
  return apicall('users/check_reset_password', { method: 'POST', body: JSON.stringify({ token }) });
}

export function resetPassword (data) {
  return apicall('users/reset_password', { method: 'POST', body: JSON.stringify(data) });
}

export function restorePassword (data) {
  return apicall('users/restore_password', { method: 'POST', body: JSON.stringify(data) });
}

export function confirmUser (token) {
  return apicall('users/confirmUser', { method: 'POST', body: JSON.stringify({ token }) });
}

export function userFavToggle (goodId, type) {
  return apicall('users/addToFav', { method: 'POST', body: JSON.stringify({ goodId, type }) });
}

export function authping() {
  return apicall('users/authping', { method: "POST"})
}

export function getBonuses() {
  return apicall('users/bonuses')
}

export function applyCert({ certCode, dealerId }) {
  return apicall('users/bonuses/apply_cert', { method: "POST", body: JSON.stringify({ certCode, dealerId }) })
}

export function applyPoints({ amount, dealerId }) {
  return apicall('users/bonuses/apply_points', { method: "POST", body: JSON.stringify({ amount, dealerId }) })
}

export function removeBonus({ dealerId }) {
  return apicall('users/bonuses/remove_bonus', { method: "POST", body: JSON.stringify({ dealerId }) })
}
import React from 'react';
import styled, { css } from 'styled-components'
import { H7, Gapper } from 'components/Ui'

import vars from 'config/styles'

const Wrapper = styled.div`
  margin-bottom: 10px;
`

const Items = styled.div`

`

const Item = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  padding: 0 15px;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1px solid ${vars.grey};
  &:hover {
    background: ${vars.grey};
  }
  ${({ active }) => active && css`
    color: #fff;
    background: ${vars.green} !important;
  `}
`

const ColLogo = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 80px;
    max-height: 32px;    
  }
`

const ColMiddle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-rigth: 25px;
`

const ColLast = styled.div`
  width: 210px;
  display: flex;
  justify-content: space-between;
`

const ColDays = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColPrice = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const DeliveryCompanyField = (field) => {
  const { options = {}, onChangeFull = () => {}, dimensionsPresent } = field
  const { delivery = [] } = options
  const { value, onChange, ...rest } = field.input;
  return (
    <Wrapper> 
      <Items>
        {delivery.map(row => (
          <Item active={value === row.delivery_company_id} onClick={() => {onChangeFull(row); onChange(row.delivery_company_id)}}>
            <ColLogo>
              <img src={row.delivery_company_logo}/>
            </ColLogo>
            <ColMiddle>
            </ColMiddle>
            <ColLast>
              <ColDays>
                {row.delivery_plan_days.min} - {row.delivery_plan_days.max} дн
              </ColDays>
              <ColPrice>
                {dimensionsPresent &&   
                  <>{row.total_price} ₽</>
                }
              </ColPrice>
            </ColLast>                        
          </Item>
        ))}
      </Items>
    </Wrapper>
  );
};

export default DeliveryCompanyField;

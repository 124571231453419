import React from 'react';
import styled from 'styled-components';
import { serverUrl } from 'config';

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
`

function Image({good}) {
  if(!good) return null

  if(!good.images || good.images.length === 0) return null

  const image = good.images.sort((a,b) => a.position > b.position ? 1 : -1 )[0]
  return (
    <Wrapper style={{backgroundImage: `url(${serverUrl + image.imageThumb})`}} />
  )
  
}

export default Image;
/**
 *
 * AdminOrders
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { EDIT_ORDER } from 'components/Popup/constants';
import OrderInstructions from 'components/OrderInstructions';
import { Link } from 'react-router-dom';


import { makeSelectEntities } from 'models/selectors';

import { getDealers } from 'models/dealers/actions';

import ResDisplayFactory from 'components/ResDisplay';

import Filters from './Filters';
import Template from './Template';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Displayer = ResDisplayFactory({
  entity: 'orders',
  prefix: 'admin',
  Template,
  heading: {
    number: 'Номер заказа', status: 'Статус', date: 'Дата', client: 'Клиент', dealer: 'Дилер', price: 'Сумма', actions: '',
  },
  popupConstant: EDIT_ORDER,
  noNew: true,
  FiltersForm: Filters,
  xls: true,
});

export class AdminOrders extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount () {
    this.props.getDealers();
  }

  render () {
    const {
      dealers, users, clients, logs, initialParams, noFilters, domains, payables, title, noInstruction = false
    } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Заказы
          </title>
          <meta name="description" content="Description of AdminOrders" />
        </Helmet>
        <h1>
          {title || 'Заказы'}
          <Link to="/admin/orders" style={{ display: 'inline-block', marginLeft: 20, fontWeight: 'normal', border: '1px solid #dadee1', padding: 8, fontSize: 12, borderRadius: 3, background: '#fff'}}>
            Новый вид
            <FontAwesomeIcon icon={faChevronRight} style={{marginTop: -2, marginLeft: 3}} />
          </Link>

            

        </h1>
        {!noInstruction && <OrderInstructions />}
        <Displayer
          noFilters={noFilters || false}
          dealers={dealers}
          initialParams={initialParams}
          templateProps={{
            dealers, users, clients, logs, domains, payables
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // AdminOrders: makeSelectAdminOrders(),
  users: makeSelectEntities('users'),
  clients: makeSelectEntities('clients'),
  dealers: makeSelectEntities('dealers'),
  logs: makeSelectEntities('logs'),
  domains: makeSelectEntities('domains'),
  payables: makeSelectEntities('payables'),
});

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    getDealers: () => dispatch(getDealers()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(AdminOrders);

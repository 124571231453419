/**
*
* DealerOrderRowShow
*
*/

import React from 'react';

import 'css/components/table.sass';
import { formatPrice } from 'helpers';

class DealerOrderRowShow extends React.PureComponent {
  render () {
    const { n, products, row } = this.props;
    return (
      <tr>
        <td className="table__order0">
          {n}
        </td>
        <td className="table__order0 table__left table__bold">
          {products[row.productId] && products[row.productId].name}
          <br />
          <div className="badge badge_big">
            {products[row.productId] && (products[row.productId].originalId || row.productId)}
          </div>
        </td>
        <td className="table__br table__order3" />
        <td className="table__order3 table__left">
          <span className="hide-lg-ib">
цена:
            {' '}
          </span>
          {' '}
          {formatPrice(row.price)}
        </td>
        <td className="table__order2 table__right nwrp">
          {row.quantity}
          {' '}
          <span className="hide-lg-ib">
шт.
          </span>
        </td>
        <td className="table__right table__order3">
          <span className="hide-lg-ib">
сумма:
            {' '}
          </span>
          {' '}
          {formatPrice(row.quantity * row.price)}
        </td>
        <td>
        </td>
      </tr>
    );
  }
}

DealerOrderRowShow.propTypes = {

};

export default DealerOrderRowShow;

/*
  Admin Goods Filters
*/

import React from 'react';
import FiltersHoc from 'components/FiltersHoc';
import {
  Field,
} from 'redux-form/immutable';
import {
  inputField, datePickerField, checkBoxField
} from 'components/Form/components';
import RFReactSelect from 'components/Form/components/RFReactSelect';

import { ORDER_STATUSES_TRANSLATION, PROCESS_STATUSES_TRANSLATION } from 'settings';

class Filters extends React.PureComponent {
  render () {
    const { dealers } = this.props;
    const statusOptions = Object.keys(ORDER_STATUSES_TRANSLATION).map((x) => ({ value: x, label: ORDER_STATUSES_TRANSLATION[x] }));
    const processStatusOptions = Object.keys(PROCESS_STATUSES_TRANSLATION).map((x) => ({ value: x, label: PROCESS_STATUSES_TRANSLATION[x] }));
    const dealerOptions = dealers ? Object.values(dealers).map((x) => ({ value: x.id, label: x.name })) : [];

    return (
      <React.Fragment>
        <Field type="text" name="number" component={inputField} label="Номер заказа" />
        <Field component={RFReactSelect} name="dealerId" options={dealerOptions} label="Дилер" />
        <Field component={RFReactSelect} name="status" options={statusOptions} label="Статус" />
        <Field name="date_from" label="с" component={datePickerField} type="text" />
        <Field name="date_to" label="по" component={datePickerField} type="text" />
        <Field component={RFReactSelect} name="processStatus" options={processStatusOptions} label="Статус обработки" />
        <Field name="paid" label="Оплаченные заказы" component={checkBoxField} type="text" />

      </React.Fragment>
    );
  }
}

export default FiltersHoc({ name: 'admin-orders-filters' })(Filters);

/*
 *
 * Conf
 *
 */

import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';


import Row from 'components/Row'
import Col from 'components/Col'
import Container from 'components/Container'
import Button from 'components/Button'
import superbBg from 'images/superblk.jpg'
import { authRequest } from 'containers/Sso/actions';


export class PageDelivery extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div>
        <Helmet
          title="Оплата и доставка"
          meta={[
            { name: 'description', content: 'Оплата и доставка' },
          ]}
        />
        <div className="content">
          <div className="pages-text">
            <h1 className="title">Оплата и доставка</h1>
            <div className="text">
              Получение товара возможно только самовывозом в дилерском центре.
            </div>
          </div>
        </div>
      </div>
    );
  }
}



PageDelivery.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  // dealers: makeSelectDealers(),
});

function mapDispatchToProps(dispatch) {
  return {
    // getDealers: () => dispatch(getDealers()),
    // setMapDealer: (id) => dispatch(setMapDealer(id)),
    setPopup: (id) => dispatch(setPopup(id)),
  };
}





export default connect(mapStateToProps, mapDispatchToProps)(PageDelivery);

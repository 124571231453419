/* istanbul ignore file */

import {
  READ_MESSAGE,
} from './constants';

export function readMessage (payload) {
  return {
    type: READ_MESSAGE,
    payload,
  };
}

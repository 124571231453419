/* istanbul ignore file */

import {
  EDIT_RETURN,
} from './constants';

export function editReturn (payload) {
  return {
    type: EDIT_RETURN,
    payload,
  };
}

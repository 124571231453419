
import { fromJS, Map } from 'immutable';
import { SET_ENTITIES, REPLACE_ENTITIES, REPLACE_PASSED_ENTITIES } from './constants';

const initialState = fromJS({});

const entities = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTITIES:
      if (action.entities) {
        return state.mergeDeep(action.entities);
      }
      return state;
    case REPLACE_ENTITIES:
      if (action.toReplace) { return state.setIn([action.toReplace], Map(action.entities[action.toReplace])); }
      return state;
    case REPLACE_PASSED_ENTITIES:
      return state.setIn([action.toReplace], Map({ ...state.get(action.toReplace).toJS(), ...(action.entities[action.toReplace]) }));
    default:
      return state;
  }
};

export default entities;

import React from 'react';
import styled, { css } from 'styled-components';
import LoaderImg from 'images/loader.gif'

const Wrapper = styled.div`
  display: inline-block;
  margin: 0 3px;
  img {
    ${({ size = 16}) => size && css `
      width: ${size}px;
      height: ${size}px;
    `}
  }
`

function Loader({ size = 16 }) {
  return (
    <Wrapper size={size}>
      <img src={LoaderImg} />
    </Wrapper>
  )
}

export default Loader;
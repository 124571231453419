import React from 'react';
import styled from 'styled-components';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { googleApiKey } from 'settings';


const Pointer = withScriptjs(withGoogleMap(({ lat, lng }) =>{
  console.log({lat,lng})

 return <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat, lng }}
  >
     <Marker position={{ lat, lng }} />
  </GoogleMap>
}))

export default ({lat, lng}) => <Pointer
  lat={parseFloat(lat)}
  lng={parseFloat(lng)}
  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleApiKey}`}
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `300px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
/>
/**
 *
 * AdminDealers
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHistory } from '@fortawesome/free-solid-svg-icons';
import { makeSelectEntities } from 'models/selectors';

import { EDIT_DEALER, SHOW_DEALER_LOGS } from 'components/Popup/constants';

import { getAllRegionData } from 'containers/App/actions';
import { getManagers } from 'models/managers/actions';

import ResDisplayFactory from 'components/ResDisplay';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import Filters from './Filters';

function Template (props) {
  const {
    row, updates, setPopup, successCallback,
  } = props;
  return (
    <tr>
      <td>
        {row.id}
      </td>
      <td>
        {row.number}
      </td>
      <td>
        {row.name}
      </td>
      <td>
        <div className={`badge ${row.status === false ? 'badge_palegrey' : ''}`}>
          {row.status === true ? 'Активен' : 'Неактивен'}
        </div>
      </td>
      <td>
        {updates && updates[row.update] && moment(updates[row.update].uploaded).format('DD.MM.YYYY')}
      </td>
      <td className="table__actions nwrp table__right">
        <FontAwesomeIcon icon={faEdit} data-effect="solid" data-tip="Редактировать" onClick={() => setPopup(EDIT_DEALER, { id: row.id, successCallback: () => { successCallback(); } })} />
        <FontAwesomeIcon icon={faHistory} data-effect="solid" data-tip="История изменений" onClick={() => setPopup(SHOW_DEALER_LOGS, { id: row.id, number: row.number })} />
        <ReactTooltip />
      </td>
    </tr>
  );
}

const Displayer = ResDisplayFactory({
  entity: 'dealers',
  prefix: 'admin',
  Template,
  heading: {
    id: 'ID', number: 'Номер', name: 'Название', status: "Статус", updated: 'Обновлён', actions: '',
  },
  popupConstant: EDIT_DEALER,
  FiltersForm: Filters,
});

export class AdminDealers extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount () {
    this.props.getAllRegionData();
    this.props.getManagers();
  }

  render () {
    const { updates, initialParams } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            Дилеры
          </title>
          <meta name="description" content="Дилеры" />
        </Helmet>
        <h1>
          Дилеры
        </h1>

        <Displayer initialParams={initialParams} templateProps={{ updates }} />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  updates: makeSelectEntities('updates'),
});

function mapDispatchToProps (dispatch) {
  return {
    getAllRegionData: () => dispatch(getAllRegionData()),
    getManagers: () => dispatch(getManagers()),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(AdminDealers);

import React from 'react';

const EmptyResult = ({ isEmpty, resetFilters }) => (
  <>
    <span>
      По Вашему запросу ничего не найдено
    </span>
    { isEmpty &&
      (
        <div>
        Попробуйте
          <span className="hoverable" onClick={resetFilters} style={{ marginLeft: 5 }}>
            Сбросить параметры поиска
          </span>
        </div>
      )
    }
  </>
);

export default EmptyResult;
import { fromJS, Map } from 'immutable';
import { actionTypes } from './actions';


export const initialState = fromJS({
  processing: false,
  applying: false,
  error: null,
  calcResult: null,
});

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROCESSING:
      return state.set('processing', action.processing);
    case actionTypes.SET_APPLYING:
      return state.set('applying', action.applying);
    case actionTypes.SET_CALC:
      return state.set('calcResult', action.payload);
    case actionTypes.SET_CALC_ERROR:
      return state.set('error', action.error);
    default:
      return state;      
  }
}

export default deliveryReducer;

/* istanbul ignore file */
import {
  SET_FILTER,
  SET_FILTER_RESPONSE,
  SET_FILTER_STATUS,
  DOWNLOAD_XLS_FILTER,
  SAVE_SORT_ORDER,
} from './constants';

export function downloadXls (name, model) {
  return {
    type: DOWNLOAD_XLS_FILTER,
    name,
    model,
  };
}

export function setFilter (name, payload, model, path) {
  return {
    type: SET_FILTER,
    name,
    payload,
    model,
    path,
  };
}

export function setFilterResponse (name, payload) {
  return {
    type: SET_FILTER_RESPONSE,
    name,
    payload,
  };
}

export function setFilterStatus (name, payload) {
  return {
    type: SET_FILTER_STATUS,
    name,
    payload,
  };
}

export function saveSortOrder (name, payload, model, path) {
  return {
    type: SAVE_SORT_ORDER,
    name,
    model,
    path,
    payload,
  };
}

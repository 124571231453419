/**
*
* Col
*
*/

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

class Col extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { n, sm, md, style } = this.props;

    const cls = classNames({ [`col-${n}`]: n }, { [`col-sm-${sm}`]: sm }, { [`col-md-${md}`]: md });

    return (
      <div style={style} className={cls}>
        {this.props.children}
      </div>
    );
  }
}

Col.propTypes = {
  n: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default Col;
